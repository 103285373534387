import { useEffect, useState, useCallback } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';

export const useTableScroll = (tableLength: number) => {
    const [isScrollNeedToUpdate, setScrollNeedToUpdate] = useState(false);
    const [scroll, setScroll] = useState<PerfectScrollbar>(null);
    const [ref, setRef] = useState(null);

    const scrollbarRef = useCallback((node) => {
        if (node) {
            setRef(node);
        }

        if (node?._outerRef) {
            setScroll(new PerfectScrollbar(node._outerRef, { minScrollbarLength: 24 }));
        }
    }, []);

    useEffect(() => {
        if (tableLength || isScrollNeedToUpdate) {
            scroll?.update();
        }

        setScrollNeedToUpdate(false);
    }, [isScrollNeedToUpdate, tableLength]);

    return {
        setScrollNeedToUpdate,
        scrollbarRef,
        ref
    };
};
