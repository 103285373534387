import React, { useCallback, useEffect, useState } from 'react';
import { autorun, flow } from 'mobx';
import { useStore } from '@store/StoreEffect';
import { useObserver } from 'mobx-react-lite';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';

import { EmptyPage } from '@pages/empty-page/EmptyPage';
import { NavBar } from '@modules/core/components';
import { NavigationTypes, AccountsDetailsNavItems } from '@modules/core/constants';
import { VesselsWithGroupsPage } from '@pages/vessels-page';
import { Account } from '@modules/accounts/models';
import { AccountsGeneral } from '@modules/accounts/components';
import { Organization } from '@modules/organizations/models';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';
import { useGoBack, usePageContainer, useRolesState, useSelectedState } from '@modules/shared/hooks';

export const AccountsDetailsPage = () => {
    const {
        accountStore,
        fleetStoreUsers,
        fleetStoreOrganization,
        fleetStoreAll,
        userStore,
        rolesStoreOrganization,
        rolesStoreAll,
        vesselGroupsStoreUsers
    } = useStore();
    const { currentUser } = userStore;
    const { isSupportUser, isSuperUser } = useRolesState();
    const { userId } = useParams() as { userId: string };
    const match = useRouteMatch();
    const rolesStore = userStore.isSuperUser ? rolesStoreOrganization : rolesStoreAll;
    const { goBack } = useGoBack();

    const [selectedAccount, setSelectedAccount] = useState<Account>(null);
    const [ownerOrganization, setOwnerOrganization] = useState<Organization>(null);

    useEffect(() => {
        const disposer = autorun(async () => {
            await loadAccountById(userId);
        });
        return () => {
            disposer();
            selectedEntityState.resetAccount();
        };
    }, [accountStore, userId]);

    useEffect(() => {
        const disposer = autorun(async () => {
            if (selectedAccount) {
                setOwnerOrganization(selectedAccount.organization);
            }
        });
        return () => disposer();
    }, [currentUser, selectedAccount]);

    const updateSelectedAccount = (selectedAccount: Account) => {
        selectedEntityState.selectOrganization(selectedAccount?.organization);
        selectedEntityState.updatedAccount(selectedAccount);
        setSelectedAccount(selectedAccount);
    };

    const loadAccountById = async (userId: string) => {
        const selectedAccount: Account = await accountStore.getAccount(userId);
        if (selectedAccount) {
            rolesStore.loadRoles(selectedAccount.organization?.id);
        }
        updateSelectedAccount(selectedAccount);
    };

    const onEditAccount = async (editedAccount: Account) => {
        const { roles: updatedRoles, ...updatedFields } = editedAccount;
        const updatedFieldNames = Object.keys(updatedFields);

        const isUpdatedAccount = !!updatedFieldNames.find((field) => {
            return get(updatedFields, field) !== get(account, field);
        });
        const isUpdatedRoles = !isEmpty(xor(account.roles, updatedRoles));

        if (isUpdatedRoles || isUpdatedAccount) {
            const updatedAccount = await accountStore.updateAccount({
                account: editedAccount,
                isUpdatedAccount: !isSupportUser ?? isUpdatedAccount,
                isUpdatedRoles
            });
            if (updatedAccount) {
                editedAccount.roles = editedAccount.roles || account.roles;
                updateSelectedAccount(editedAccount);
            }
        }
    };

    const loadOrganizationVessels = isSuperUser ? fleetStoreOrganization.loadVessels : fleetStoreAll.loadVessels;
    const filterOrganizationVessels = isSuperUser ? fleetStoreOrganization.filterVessels : fleetStoreAll.filterVessels;

    const { organization, account } = useSelectedState();

    const vesselsWithGroupsPageContainerLoadingFunction = useCallback(
        flow(function* () {
            if (userId && userId !== account?.id) {
                return;
            }

            if (fleetStoreUsers.isAllVesselGroupSelected) {
                yield fleetStoreUsers.loadVessels();
            }
        }),

        [organization?.id, account?.id, fleetStoreUsers.isAllVesselGroupSelected]
    );

    const searchArea = 'userDetailPage';
    const vesselsWithGroupsPageState = usePageContainer(
        vesselsWithGroupsPageContainerLoadingFunction,
        fleetStoreUsers.filterVessels,
        fleetStoreUsers.addVessels,
        fleetStoreUsers.editVessels,
        fleetStoreUsers.deleteVessels,
        false,
        searchArea
    );

    return useObserver(() =>
        accountStore.loadingStatusState.isRequestFailed ? (
            <EmptyPage />
        ) : (
            <main className="details-page">
                <header className="details-page-header">
                    <i className="icon icon-chevron left" onClick={() => goBack()} />
                    <span qa-id="account-details-title">{`${selectedAccount?.firstName ?? ''} ${
                        selectedAccount?.lastName ?? ''
                    }`}</span>
                </header>
                <nav>
                    <NavBar match={true} navItems={AccountsDetailsNavItems} navType={NavigationTypes.Upper} />
                </nav>
                <section className="details-page-body">
                    <Switch>
                        <Route path={`${match.url}/general`}>
                            {selectedAccount && (
                                <AccountsGeneral
                                    account={selectedAccount}
                                    editAccount={onEditAccount}
                                    roles={rolesStore.roles}
                                    rolesStore={rolesStore}
                                />
                            )}
                        </Route>
                        <Route path={`${match.url}/fleet`}>
                            <VesselsWithGroupsPage
                                vesselsWithGroupsPageState={vesselsWithGroupsPageState}
                                isVesselGroupsModalVisible
                                fleetStore={fleetStoreUsers}
                                vesselGroupStore={vesselGroupsStoreUsers}
                                availableGroupsForAssign={vesselGroupsStoreUsers.allVesselGroups}
                                modalLoadingFunction={loadOrganizationVessels}
                                modalFilterFunction={filterOrganizationVessels}
                                isEditAllowed={!isSupportUser}
                                isVesselDeletable={() => false}
                                ownerOrganization={ownerOrganization}
                                userId={userId}
                                isShowGroupsFilter={false}
                                editable={() => false}
                            />
                        </Route>
                        <Redirect exact from={match.url} to={`${match.url}/general`} />
                        <Route component={EmptyPage} />
                    </Switch>
                </section>
            </main>
        )
    );
};
