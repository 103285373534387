import React, {
    FunctionComponent,
    useCallback,
    MouseEvent,
    ReactNode,
    useEffect,
    useState,
    useMemo,
    useRef
} from 'react';

import { useDebouncedEffect } from '@modules/shared/hooks/useDebouncedEffect';
import { Portal } from '@modules/core/components';
import './Tooltip.scss';

interface Props {
    content: string;
    width?: number;
    inline?: boolean;
    children: ReactNode;
}

export const Tooltip: FunctionComponent<Props> = (props: Props) => {
    const { content, children, inline, width } = props;
    const tooltipElementRef = useRef<HTMLDivElement>();
    const [visible, setVisible] = useState<boolean>(false);
    const [pointed, setPointed] = useState<boolean>(false);
    const [initialCursorPosition, setInitialCursorPosition] = useState<number[]>([]);
    const [tooltipClassNames, setTooltipClassNames] = useState<string[]>(['tooltip']);

    const getTooltipStyles = useCallback(
        (clientX, clientY) => {
            const tooltipRect = tooltipElementRef.current?.getBoundingClientRect();

            if (tooltipRect) {
                const left = Math.round(clientX - tooltipRect.width / 2);
                const top = Math.round(clientY - tooltipRect.height - 15);
                return { left, top };
            }

            return {};
        },
        [tooltipElementRef]
    );

    const initialTooltipStyles = useMemo(() => {
        if (visible) {
            const [x, y] = initialCursorPosition;
            return getTooltipStyles(x, y);
        }

        return { left: 0, top: 0 };
    }, [initialCursorPosition, getTooltipStyles, visible]);

    useEffect(() => {
        if (visible) {
            setTooltipClassNames(['tooltip', 'tooltip--visible']);
        } else if (tooltipClassNames.includes('tooltip--visible')) {
            setTooltipClassNames(['tooltip']);
        }
    }, [visible]);

    useDebouncedEffect(
        () => {
            if (pointed && !visible) {
                setVisible(true);
            }
        },
        250,
        [setVisible, pointed, visible]
    );

    const onMouseEnter = useCallback(() => {
        setPointed(true);
    }, [setPointed]);

    const onMouseMove = useCallback(
        (e: MouseEvent) => {
            if (visible) {
                const { left, top } = getTooltipStyles(e.clientX, e.clientY);
                tooltipElementRef.current.setAttribute('style', `left:${left}px;top:${top}px`);
            } else {
                setInitialCursorPosition([e.clientX, e.clientY]);
            }
        },
        [getTooltipStyles, visible]
    );

    const onMouseLeave = useCallback(() => {
        setVisible(false);
        setPointed(false);
    }, [setVisible, setPointed]);

    return (
        <div
            className={`with-tooltip ${inline && `inline`}`}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
        >
            <div>{children}</div>
            {pointed && (
                <Portal>
                    <div ref={tooltipElementRef} style={initialTooltipStyles} className={tooltipClassNames.join(' ')}>
                        <div className="tooltip__content" style={{ maxWidth: width }}>
                            {content}
                        </div>
                    </div>
                </Portal>
            )}
        </div>
    );
};
