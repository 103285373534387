import { Configuration, PublicClientApplication, IPublicClientApplication } from '@azure/msal-browser';
import { Dictionary } from '@modules/shared/models';
import { redirectUri } from '@config/config';

export const msal = {
    config: {} as Configuration,
    instance: {} as IPublicClientApplication,
    scopes: [] as string[]
};

export const initializeMsal = (msalConfig: Dictionary<string>) => {
    const config: Configuration = {
        auth: {
            authority: msalConfig.REACT_APP_ADAL_INSTANCE + msalConfig.REACT_APP_TENANT,
            clientId: msalConfig.REACT_APP_TCMS_CLIENT_ID,
            redirectUri: redirectUri,
            postLogoutRedirectUri: redirectUri
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false
        }
    };

    if (!process.env.REACT_APP_DEV) {
        msal.config = config;
        msal.scopes = [msalConfig.REACT_APP_TCMS_CLIENT_ID + '/.default'];
        msal.instance = new PublicClientApplication(msal.config);
    }
};
