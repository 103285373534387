import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider, useAccount } from '@azure/msal-react';
import configsLoader, { redirectUri, msalConfig } from '@config/config';
import { AvailableRoles, RoleType } from '@modules/core/models';
import { RoleUtils, AppInsights } from '@modules/core/utils';
import { initializeMsal, msal } from '@config/msalConfig';

declare global {
    namespace NodeJS {
        interface ProcessEnv {
            REACT_APP_MOCK_SERVER_API_URL: string;
            PORT: string;
            DEV: boolean;
            SASS_PATH: string;
            DISABLE_APP_INSIGHTS: boolean;
        }
    }
}

configsLoader.then(() => {
    if (process.env.REACT_APP_DEV) {
        ReactDOM.render(
            // ToDo errors when using Eniram common ui lib in strict mode
            <App />,
            document.getElementById('root')
        );
        return;
    }

    if (!process.env.REACT_APP_DISABLE_APP_INSIGHTS) {
        AppInsights.init(msalConfig.REACT_APP_INSIGHTS_CODE);
    }
    initializeMsal(msalConfig);

    ReactDOM.render(
        <MsalProvider instance={msal.instance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={{
                    scopes: msal.scopes,
                    redirectStartPage: redirectUri
                }}
            >
                <AppContainer />
            </MsalAuthenticationTemplate>
        </MsalProvider>,
        document.getElementById('root')
    );
});

export const AppContainer = () => {
    const msalInstance = msal.instance;
    const accounts = msalInstance.getAllAccounts();
    let activeAccount = useAccount();
    if (!activeAccount) {
        msalInstance.setActiveAccount(accounts[0]);
        activeAccount = accounts[0];
    }

    const idTokenClaims = activeAccount?.idTokenClaims as Partial<{ roles: RoleType[] }>;
    const roles = (idTokenClaims?.roles || []).map((role: string) => AvailableRoles[role]);

    if (RoleUtils.isBasicUser(roles)) {
        msal.instance.logout();
        return null;
    }

    return <App />;
};

serviceWorker.unregister();
