import React from 'react';

import { LoadingStatusType } from '@modules/core/models';
import { ModalWindow } from '@modules/shared/components';
import { ModalProps } from '@modules/shared/models';

import './ConfirmModal.scss';

interface Props extends ModalProps {
    icon?: any;
    children?: any;
    isCancel?: boolean;
    qaId?: string;
    modalTitle: string;
    submitButtonTitle: string;
    modalContentText?: string;
    className?: string;
    loading?: LoadingStatusType;
}

export const ConfirmModal = (props: Props) => {
    const {
        qaId = 'confirm-popup',
        icon,
        modalIsOpen,
        onCloseModal,
        onSubmit,
        modalTitle,
        submitButtonTitle,
        modalContentText,
        isCancel,
        className = '',
        loading = LoadingStatusType.isLoaded
    } = props;

    const isLoaded = loading === LoadingStatusType.isLoaded;

    return (
        <ModalWindow
            qaId={qaId}
            modalIsOpen={modalIsOpen}
            className={`confirm-popup ${(icon && 'withIcon') || ''} ${className}`}
            title={modalTitle}
            submitButtonName={submitButtonTitle}
            onSubmit={onSubmit}
            onCancel={() => onCloseModal(false)}
            loadingModal={loading}
            isCancel={isCancel}
        >
            {icon && <div className="modal-icon">{icon}</div>}
            <div className="text" qa-id={qaId + '-content'}>
                {isLoaded && modalContentText}
            </div>
        </ModalWindow>
    );
};
