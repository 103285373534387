import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import { AppClientFormEditFields } from '@modules/core/constants/FormFields';
import { usePermissionsState, useRolesState } from '@modules/shared/hooks';
import { CoreUtil, isFormFieldsDirty } from '@modules/core/utils';
import { DateUtil } from '@modules/shared/utils';
import { CopyField, Form, LoadPlaceholder } from '@modules/core/components';
import { ApiClient } from '@modules/api-clients/models';
import { Role } from '@modules/roles/models';
import { LoadingStatusType, Option } from '@modules/core/models';
import { mapFieldVariableGroups } from '@modules/variable-groups/utils';
import { VariableGroup } from '@modules/variable-groups/models';

import './ApiClientsGeneral.scss';

interface Props {
    apiClient: ApiClient;
    editApiClient?: (value: ApiClient) => Promise<void>;
    roles: Role[];
    variableGroups: VariableGroup[];
    loadingStatus: LoadingStatusType;
}

export const ApiClientGeneral = (props: Props) => {
    const { apiClient, roles, variableGroups, loadingStatus, editApiClient } = props;
    const { isSuperUser } = useRolesState();
    const { canEditApiClients } = usePermissionsState();
    const history = useHistory();

    const {
        register,
        getValues,
        control,
        watch,
        setValue,
        handleSubmit,
        formState: { errors, isValid, isDirty },
        clearErrors
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    const watchAllFields = watch();

    const onSubmit = async (data: any) => {
        if (isValid) {
            await editApiClient({
                ...apiClient,
                ...data,
                roles: data.roles.map((role: Option) => role.value),
                variableGroups: (data.variableGroups || []).map((variableGroup: Option) => variableGroup.value)
            });
            clearErrors();
        }
    };

    const fieldsCollection = useMemo(
        () =>
            Object.keys(AppClientFormEditFields).reduce((acc, field) => {
                return {
                    ...acc,
                    [field]: {
                        ...AppClientFormEditFields[field],
                        disabled: !canEditApiClients || AppClientFormEditFields[field].disabled
                    }
                };
            }, AppClientFormEditFields),
        [apiClient]
    );

    const fields = useMemo(() => {
        return [
            ...CoreUtil.mapFields(fieldsCollection, apiClient, ['roles', 'variableGroups']),
            CoreUtil.mapFieldRoles(apiClient.roles, roles, fieldsCollection.roles),
            mapFieldVariableGroups(apiClient.variableGroups, variableGroups, fieldsCollection.variableGroups)
        ];
    }, [apiClient, roles, variableGroups]);

    const isButtonDisabled = useMemo(() => {
        return isFormFieldsDirty(fields, watchAllFields, AppClientFormEditFields) || Object.keys(errors).length;
    }, [isDirty, watchAllFields]);

    const isFormLoaded = apiClient.id && fields?.length;

    return useObserver(() => (
        <main className="api-clients-general details-page details-page__inner">
            <LoadPlaceholder loadingStatus={loadingStatus}>
                <section className="details-page-body">
                    {isFormLoaded && (
                        <>
                            <div className="row">
                                <div className="row-title">Id</div>
                                <div className="row-value row-copy">
                                    <CopyField copyText={apiClient.id}>{apiClient.id}</CopyField>
                                </div>
                            </div>
                            <Form
                                qaId={'api-clients-general'}
                                useForm={{
                                    register,
                                    getValues,
                                    setValue,
                                    control,
                                    formState: { errors },
                                    clearErrors
                                }}
                                fields={fields}
                                customFields={
                                    <div>
                                        <div className="form-control-block">
                                            <label>Registration date</label>
                                            <span>{DateUtil.formatDate(apiClient.creationDate)}</span>
                                        </div>
                                        {isSuperUser && (
                                            <div className="form-control-block form-control-block--organization">
                                                <label>Organization</label>
                                                <span
                                                    onClick={() =>
                                                        CoreUtil.goToPage(
                                                            history,
                                                            `/organizations/${apiClient.organization.id}/api-clients`
                                                        )
                                                    }
                                                >
                                                    {apiClient.organization.name}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                }
                            />
                        </>
                    )}
                </section>
                {canEditApiClients && (
                    <section className="details-page-footer">
                        <button
                            disabled={isButtonDisabled}
                            type="submit"
                            className="button primary"
                            onClick={handleSubmit(onSubmit)}
                        >
                            SAVE
                        </button>
                    </section>
                )}
            </LoadPlaceholder>
        </main>
    ));
};
