import { flow } from 'mobx';
import { alertStore } from '@modules/core/store/AlertStore';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';
import { VesselGroupStore } from './VesselGroupStore';
import { VesselGroup } from '../models';

/**
 * this store is oriented for customer admin only and uses organizations api only
 */
export class VesselGroupStoreAll extends VesselGroupStore {
    constructor() {
        super();
        this.loadVesselGroups = this.loadVesselGroups.bind(this);
        this.editVesselGroup = this.editVesselGroup.bind(this);
        this.addVesselGroup = this.addVesselGroup.bind(this);
        this.deleteVesselGroup = this.deleteVesselGroup.bind(this);
    }

    loadVesselGroups = flow(function* (this: VesselGroupStore) {
        const { api, errorMessageHandler } = apiStore.api.vesselsGroups.getOrganizationVesselGroups;
        try {
            this.loadingGroupsStatusState.load();
            this.vesselGroups = yield HttpClient.TCMS.GET(api());
            this.setDefaultSelectedVesselGroup(this.vesselGroups);
            this.loadingGroupsStatusState.loadSuccess();
        } catch (error) {
            this.loadingGroupsStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    editVesselGroup = flow(function* (this: VesselGroupStore, vesselGroup: Partial<VesselGroup>) {
        const { api: putVesselGroupApi, errorMessageHandler: putVesselGroupErrorHandler } =
            apiStore.api.vesselsGroups.putOrganizationVesselGroup;
        try {
            yield HttpClient.TCMS.PUT(putVesselGroupApi(vesselGroup.id), {
                name: vesselGroup.name
            });
        } catch (error) {
            alertStore.error(error, putVesselGroupErrorHandler);
        }

        const { api, successMessage, errorMessageHandler } = apiStore.api.vessels.putOrganizationVesselGroupVessels;
        try {
            yield HttpClient.TCMS.PUT(api(vesselGroup.id), vesselGroup.vessels);
            const groupToUpdate = this.vesselGroups.find((group) => group.id === vesselGroup.id);
            Object.assign(groupToUpdate, vesselGroup);
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    addVesselGroup = flow(function* (this: VesselGroupStore, vesselGroup: Partial<VesselGroup>) {
        const { api: postVesselGroupApi, errorMessageHandler: postVesselGroupErrorHandler } =
            apiStore.api.vesselsGroups.postOrganizationVesselGroup;
        let addedGroup: VesselGroup;
        try {
            addedGroup = yield HttpClient.TCMS.POST(postVesselGroupApi(), {
                name: vesselGroup.name
            });
        } catch (error) {
            alertStore.error(error, postVesselGroupErrorHandler);
        }

        const { api, successMessage, errorMessageHandler } = apiStore.api.vessels.putOrganizationVesselGroupVessels;
        try {
            yield HttpClient.TCMS.PUT(api(addedGroup.id), vesselGroup.vessels);
            const group = {
                ...addedGroup,
                vessels: vesselGroup.vessels
            };
            this.vesselGroups.push(group);
            alertStore.success(successMessage);
            return group;
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    deleteVesselGroup = flow(function* (this: VesselGroupStore, vesselGroup: VesselGroup) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.vesselsGroups.deleteOrganizationVesselGroup;
        try {
            yield HttpClient.TCMS.DELETE(api(vesselGroup.id));
            const index = this.vesselGroups.findIndex((group) => group.id === vesselGroup.id);
            this.vesselGroups.splice(index, 1);
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });
}
