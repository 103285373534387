import React, { useCallback, useState } from 'react';
import { flow } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { SearchInput } from '@fos/eniram-web-common-ui/dist/components/SearchInput';
import get from 'lodash/get';

import { LoadPlaceholder } from '@modules/core/components';
import { LoadingStatusType } from '@modules/core/models';
import { usePageContainer, usePermissionsState, useRolesState } from '@modules/shared/hooks';
import { ApiClientsTable, ApiClientSecretModal, CreateEditApiClientModal } from '@modules/api-clients/components';
import { ApiClient, ApiClientSecret } from '@modules/api-clients/models';
import { Organization } from '@modules/organizations/models';
import { ConfirmModal } from '@modules/shared/components';
import { useStore } from '@store/StoreEffect';
import ApiClientsStore from '@modules/api-clients/store/ApiClientsStore';

import './ApiClientsPage.scss';

interface Props {
    useOwnerOrganization?: boolean;
    ownerOrganization?: Organization;
    apiClientsStore: ApiClientsStore;
}

export const ApiClientsPage = (props: Props) => {
    const { useOwnerOrganization, ownerOrganization, apiClientsStore } = props;
    const { loadApiClients, filterApiClients, loadingStatusState } = apiClientsStore;
    const { apiClientStore } = useStore();

    const { addApiClient, updateApiClient, deleteApiClient, updateApiClientSecret } = apiClientStore;
    const { isSuperUser } = useRolesState();
    const { canEditApiClients } = usePermissionsState();
    const [isSecretModalVisible, setIsSecretModalVisible] = useState(false);
    const [secret, setSecret] = useState<ApiClientSecret>(null);

    const ownerOrganizationId = ownerOrganization?.id;
    const containerLoadingFunction = useCallback(
        flow(function* () {
            if (!useOwnerOrganization || ownerOrganizationId) {
                yield loadApiClients(ownerOrganizationId);
            }
        }),
        [ownerOrganizationId]
    );

    const {
        entities,
        query,
        isEditMode,
        selectedEntity,
        selectEntity,
        addEntity,
        isCreateEditModalVisible,
        sortEntities,
        setQuery,
        setIsEditMode,
        setAssignModalVisibility,
        setEditModalVisibility,
        setDeleteModalVisibility,
        onCloseCreateEditModal,
        deleteEntity,
        isDeleteModalVisible
    } = usePageContainer(containerLoadingFunction, filterApiClients, addApiClient, updateApiClient, deleteApiClient);

    const newButtonVisible = canEditApiClients && (!isSuperUser || useOwnerOrganization);

    const onOpenEditModal = () => {
        if (updateApiClient) {
            setEditModalVisibility(true);
            setIsEditMode(true);
        }
    };

    const onCreateApiClient = async (apiClient: Partial<ApiClient>) => {
        const newApiClient = await addEntity(apiClient);
        const newApiClientWithSecret = await updateApiClientSecret(newApiClient);
        if (newApiClient) {
            apiClientsStore.insertApiClient(newApiClient);
        }
        setAssignModalVisibility(false);

        if (newApiClientWithSecret) {
            setIsSecretModalVisible(true);
            setSecret(newApiClientWithSecret.secret);
            apiClientsStore.replaceApiClient(newApiClientWithSecret);
        }
    };

    const onEditApiClient = async (editedApiClient: ApiClient, apiClient: ApiClient) => {
        const { ...updatedFields } = editedApiClient;
        const updatedFieldNames = Object.keys(updatedFields);
        const isUpdatedApiClient = updatedFieldNames.find((field) => {
            return get(updatedFields, field) !== get(apiClient, field);
        });

        if (isUpdatedApiClient) {
            const updatedApiClient = await addEntity(editedApiClient, isUpdatedApiClient);
            if (updatedApiClient) {
                apiClientsStore.replaceApiClient(editedApiClient);
            }
        }

        setEditModalVisibility(false);
        selectEntity(null);
    };

    const onSubmit = async (apiClient: ApiClient) => {
        !isEditMode ? await onCreateApiClient(apiClient) : await onEditApiClient(apiClient, selectedEntity);
    };

    const onDelete = async () => {
        const deletedApiClient = await deleteEntity(selectedEntity);
        if (deletedApiClient) {
            apiClientsStore.removeApiClient(deletedApiClient);
        }
        setDeleteModalVisibility(false);
    };

    return useObserver(() => (
        <div className="api-clients-page">
            <main className="card card-api-clients">
                <LoadPlaceholder loadingStatus={loadingStatusState.status || LoadingStatusType.isLoaded}>
                    <header className="card-header">
                        <div>API Clients ({entities.length})</div>
                        <div className="card-header-controls">
                            {newButtonVisible && (
                                <button className="button primary" onClick={() => setAssignModalVisibility(true)}>
                                    NEW
                                </button>
                            )}
                            <SearchInput
                                onChange={(value: string) => setQuery(value)}
                                autofocus={false}
                                placeholder="Search by name"
                                search={query}
                            />
                        </div>
                    </header>
                    <section className="card-body">
                        <ApiClientsTable
                            id="api-clients_table"
                            apiClients={entities}
                            isOrganization={useOwnerOrganization}
                            sortEntities={sortEntities}
                            loadingStatus={loadingStatusState.status}
                            setEditModalVisibility={onOpenEditModal}
                            setDeleteModalVisibility={deleteApiClient ? setDeleteModalVisibility : null}
                            setSelectedApiClient={selectEntity}
                        />
                    </section>
                </LoadPlaceholder>
            </main>

            {isCreateEditModalVisible() && (
                <CreateEditApiClientModal
                    apiClient={selectedEntity}
                    modalIsOpen={isCreateEditModalVisible()}
                    isEditMode={isEditMode}
                    onCloseModal={onCloseCreateEditModal()}
                    onSubmit={onSubmit}
                />
            )}

            {isSecretModalVisible && (
                <ApiClientSecretModal
                    secret={secret}
                    modalIsOpen={isSecretModalVisible}
                    onSubmit={async () => setIsSecretModalVisible(false)}
                    onCloseModal={() => setIsSecretModalVisible(false)}
                />
            )}

            {isDeleteModalVisible && (
                <ConfirmModal
                    modalTitle="DELETE API CLIENT"
                    modalContentText={`Do you want to delete API client named "${selectedEntity.name}"?`}
                    submitButtonTitle="DELETE"
                    modalIsOpen={isDeleteModalVisible}
                    onCloseModal={setDeleteModalVisibility}
                    onSubmit={onDelete}
                    loading={selectedEntity ? LoadingStatusType.isLoaded : LoadingStatusType.isLoading}
                />
            )}
        </div>
    ));
};
