import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { Link, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { autorun } from 'mobx';

import { NavBar } from '@modules/core/components';
import { NavigationTypes } from '@modules/core/constants';
import { AccountsPage } from '@pages/accounts-page/AccountsPage';
import { FleetPage } from '@pages/fleet-page/FleetPage';
import { RolesPage } from '@pages/roles-page/RolesPage';
import { ConfirmModal } from '@modules/shared/components';
import { ConnectedProductsPage } from '@pages/connected-products-page/ConnectedProductsPage';
import { Organization } from '@modules/organizations/models';
import { useStore } from '@store/StoreEffect';
import { usePermissionsState, useRolesState } from '@modules/shared/hooks';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';
import { LoadingStatusType, NavigationItem, RoleType } from '@modules/core/models';
import { EmptyPage } from '@pages/empty-page/EmptyPage';
import { SharingPage } from '@pages/sharing-page/SharingPage';
import { ApiClientsPage } from '@pages/api-clients/ApiClientsPage';

export const OrganizationsDetailsPage = () => {
    const {
        organizationStore,
        userStore,
        fleetStoreOrganization,
        fleetStoreAll,
        vesselSharesStore,
        rolesStoreOrganization,
        accountsStoreOrganization,
        connectedProductsStoreOrganization,
        vesselGroupsStoreOrganization,
        apiClientsStoreOrganization
    } = useStore();
    const { canViewApiClients } = usePermissionsState();

    const { loadVessels, filterVessels } = fleetStoreAll;
    const { loadProducts, overrideVesselOptions } = connectedProductsStoreOrganization;
    const { organizationId } = useParams() as { organizationId: string };
    const [isJoinModalVisible, setIsJoinModalVisible] = useState<boolean>(false);
    const [isJoinButtonClick, setIsJoinButtonClick] = useState<boolean>(true);
    const [isLoadedOrganization, setLoadedOrganization] = useState<boolean>(true);
    const match = useRouteMatch();

    const { isSupportUser, isUserWithAccount } = useRolesState();
    const [selectedOrganization, setSelectedOrganization] = useState<Organization>(null);

    useEffect(() => {
        const disposer = autorun(async () => {
            if (organizationId) {
                const selectedOrganization = await organizationStore.getOrganizationById(organizationId);
                setSelectedOrganization(selectedOrganization);
                selectedEntityState.selectOrganization(selectedOrganization);
                if (organizationStore.loadingOrganizationStatus.status === LoadingStatusType.isLoaded) {
                    setLoadedOrganization(!!selectedOrganization);
                }
            }
        });
        return () => {
            selectedEntityState.selectOrganization(null);
            disposer();
        };
    }, [organizationId, organizationStore]);

    const onJoinOrganization = async () => {
        setIsJoinButtonClick(true);
        await userStore.joinOrganization(organizationId);
        await accountsStoreOrganization.loadAccounts(organizationId);
        setIsJoinButtonClick(false);
        setIsJoinModalVisible(true);
    };

    const isJoinOrgStatus = useObserver(
        () => userStore.currentUser.organization?.id === organizationId || !isUserWithAccount
    );

    const isJoinOrgButtonDisabled = useMemo(
        () => isJoinButtonClick || isJoinOrgStatus,
        [isJoinOrgStatus, isJoinButtonClick]
    );

    useEffect(() => {
        if (!isJoinOrgStatus && organizationId && userStore.currentUser.organization?.id) {
            setIsJoinButtonClick(false);
        }
    }, [isJoinOrgStatus, organizationId, userStore.currentUser]);

    const loadingFunction = useCallback(() => {
        return loadProducts(organizationId);
    }, [organizationId, loadProducts]);

    const saveOptions = useCallback(
        (productId: string, options: any) => {
            return overrideVesselOptions(organizationId, productId, options);
        },
        [organizationId, overrideVesselOptions]
    );

    const navItems: NavigationItem[] = useMemo(() => {
        const items = [
            {
                title: 'Users',
                link: 'users'
            },
            {
                title: 'Fleet',
                link: 'fleet'
            },
            {
                title: 'Products',
                link: 'connected-products'
            },
            {
                title: 'Roles',
                link: 'roles'
            },
            {
                title: 'Sharing',
                link: 'sharing'
            }
        ];

        if (canViewApiClients) {
            items.push({
                title: 'API Clients',
                link: 'api-clients'
            });
        }

        return items;
    }, [canViewApiClients]);

    const checkGroup = (group: any = { name: 'all vessels' }) => group?.name?.toLowerCase() !== 'all vessels';

    return useObserver(() =>
        !isLoadedOrganization ? (
            <EmptyPage />
        ) : (
            <main className="details-page">
                {/* TODO: Create simple navbar for top navigation */}
                <header className="details-page-header">
                    <Link to="/organizations">
                        <i className="icon icon-chevron left" />
                    </Link>
                    <span qa-id="organization-details-title">{selectedOrganization?.name}</span>
                    <button
                        className={
                            'button primary join-organization-btn ' + (isJoinOrgButtonDisabled ? 'disabled' : '')
                        }
                        disabled={isJoinOrgButtonDisabled}
                        onClick={onJoinOrganization}
                    >
                        Join organization
                    </button>
                </header>
                <nav>
                    <NavBar match={true} navItems={navItems} navType={NavigationTypes.Upper} />
                </nav>
                <section className="details-page-body">
                    <Switch>
                        <Route path={`${match.url}/users`}>
                            <AccountsPage
                                isOrganization
                                rolesStore={rolesStoreOrganization}
                                accountsStore={accountsStoreOrganization}
                            />
                        </Route>
                        <Route path={`${match.url}/fleet`}>
                            <FleetPage
                                isOrganisationDetail
                                fleetStore={fleetStoreOrganization}
                                connectedProductsStore={connectedProductsStoreOrganization}
                                vesselGroupStore={vesselGroupsStoreOrganization}
                                ownerOrganization={selectedOrganization}
                                isCreateAllowed={
                                    !userStore.isSupportUser && !checkGroup(selectedEntityState.vesselGroup)
                                }
                                isEditAllowed={!userStore.isSupportUser && !checkGroup(selectedEntityState.vesselGroup)}
                                modalLoadingFunction={loadVessels}
                                modalFilterFunction={filterVessels}
                                filterVesselsGroup={fleetStoreOrganization.filterVesselsByOrg}
                                accessRoles={[RoleType.Admin, RoleType.Superuser, RoleType.Support]}
                            />
                        </Route>
                        <Route path={`${match.url}/connected-products`}>
                            <ConnectedProductsPage
                                connectedProductsStore={connectedProductsStoreOrganization}
                                loadingFunction={loadingFunction}
                                saveFunction={saveOptions}
                                editable={!isSupportUser}
                            />
                        </Route>
                        <Route path={`${match.url}/roles`}>
                            <RolesPage
                                isOrganization
                                accountsStore={accountsStoreOrganization}
                                rolesStore={rolesStoreOrganization}
                            />
                        </Route>
                        <Route path={`${match.url}/sharing`}>
                            <SharingPage
                                isOrganization
                                fleetStore={fleetStoreOrganization}
                                vesselSharesStore={vesselSharesStore}
                            />
                        </Route>
                        {canViewApiClients && (
                            <Route path={`${match.url}/api-clients`}>
                                <ApiClientsPage
                                    useOwnerOrganization
                                    ownerOrganization={selectedOrganization}
                                    apiClientsStore={apiClientsStoreOrganization}
                                />
                            </Route>
                        )}
                        <Redirect exact from={match.url} to={`${match.url}/users`} />
                        <Route component={EmptyPage} />
                    </Switch>
                </section>
                <ConfirmModal
                    modalTitle="JOIN ORGANIZATION"
                    modalContentText="You joined organization. It is recommended to wait 10 minutes before starting work.
                           Until than web site can work unstable."
                    submitButtonTitle="OK"
                    modalIsOpen={isJoinModalVisible}
                    onCloseModal={setIsJoinModalVisible}
                    onSubmit={async () => setIsJoinModalVisible(false)}
                />
            </main>
        )
    );
};
