import { computed, flow, makeObservable, observable } from 'mobx';

import { Vessel } from '@modules/fleet/models';
import { alertStore } from '@modules/core/store/AlertStore';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';
import FleetStoreModule, { FleetStore } from '@modules/fleet/store';
import { computedFn } from 'mobx-utils';
import { VesselGroup } from '@modules/vessel-groups/models';

export class FleetStoreOrganization extends FleetStore {
    vesselsByOrg: Vessel[] = [];
    constructor() {
        super();

        makeObservable(this, {
            fleetStoreAll: computed({ keepAlive: true }),
            vesselsByOrg: observable
        });

        this.addVessels = this.addVessels.bind(this);
        this.loadVessels = this.loadVessels.bind(this);
        this.deleteVessels = this.deleteVessels.bind(this);
        this.filterVesselsByOrg = this.filterVesselsByOrg.bind(this);
    }

    loadVessels = flow(function* (this: FleetStoreOrganization) {
        if (!this.selectedOrganization?.id) {
            return;
        }
        const { api, errorMessageHandler } = apiStore.api.vessels.getOrganizationVessels;
        try {
            this.loadingStatusState.load();
            this.vessels = yield HttpClient.TCMS.GET(api(this.selectedOrganization.id));
            this.vesselsByOrg = this.vessels;
            this.loadingStatusState.loadSuccess();
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    loadVesselsByGroup = computedFn((selectedVesselGroup: VesselGroup) => {
        this.vessels = selectedVesselGroup.vessels.reduce((accumulator, current) => {
            const found = this.vesselsByOrg.find((vessel) => vessel.id === current);
            if (found) accumulator.push(found);
            return accumulator;
        }, []);
    });

    addVessels = flow(function* (this: FleetStore, vesselsToAdd: Vessel[]) {
        if (!this.selectedOrganization?.id) {
            return;
        }
        const { api, successMessage, errorMessageHandler } = apiStore.api.vessels.postOrganizationLinkedVessels;
        try {
            const vesselsIds = vesselsToAdd.map((vessel) => vessel.id);
            yield HttpClient.TCMS.POST(api(this.selectedOrganization.id), vesselsIds);
            this.vessels.unshift(...vesselsToAdd);
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    deleteVessels = flow(function* (this: FleetStore, vesselsToAdd: Vessel[]) {
        if (!this.selectedOrganization?.id) {
            return;
        }
        const { api, successMessage, errorMessageHandler } = apiStore.api.vessels.deleteOrganizationLinkedVessels;
        try {
            yield HttpClient.TCMS.DELETE(
                api(this.selectedOrganization.id),
                vesselsToAdd.map((vessel) => vessel.id)
            );
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    filterVesselsByOrg = computedFn((query: string) => {
        if (!!query) {
            return this.vesselsByOrg.filter((vessel) => this.findFleetsByQuery(vessel, query));
        }

        return this.vesselsByOrg;
    });

    get fleetStoreAll() {
        return FleetStoreModule.fleetStoreAll;
    }

    get fleetStoreVesselGroups() {
        return FleetStoreModule.fleetStoreVesselGroups;
    }
}
