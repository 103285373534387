import { observable, makeObservable } from 'mobx';

export class SearchState {
    search: {
        [pageKey: string]: string;
    };

    constructor() {
        makeObservable(this, {
            search: observable
        });
    }

    setSearch = (pageKey: string, query: string) => {
        this.search = {
            ...this.search,
            [pageKey]: query
        };
    };

    clearSearches = () => {
        this.search = null;
    };
}

export const searchState = new SearchState();
