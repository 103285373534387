import { computed, flow, observable, makeObservable } from 'mobx';
import { computedFn } from 'mobx-utils';
import uniqBy from 'lodash/uniqBy';
import intersectionBy from 'lodash/intersectionBy';

import { Role, RoleGroup } from '@modules/roles/models';
import { Product, ProductPermission } from '@modules/connected-products/models';
import { Dictionary } from '@modules/shared/models';
import { LoadingStatusState } from '@modules/core/models';
import { apiStore } from '@modules/core/store/ApiStore';
import { CoreUtil, HttpClient } from '@modules/core/utils';
import { alertStore } from '@modules/core/store/AlertStore';

export class RolesStore {
    roles: Role[] = [];
    products: Product[] = [];
    roleProductsGroup: {
        [key: string]: RoleGroup;
    } = {};
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            roles: observable,
            products: observable,
            roleProductsGroup: observable,
            loadingStatusState: observable,
            rolesMap: computed
        });
        this.filterRoles = this.filterRoles.bind(this);
        this.addRole = this.addRole.bind(this);
        this.editRole = this.editRole.bind(this);
        this.deleteRole = this.deleteRole.bind(this);
        this.updateRolePermissions = this.updateRolePermissions.bind(this);
        this.findRoleByQuery = this.findRoleByQuery.bind(this);
        this.loadRoles = this.loadRoles.bind(this);
        this.loadProductsByOrg = this.loadProductsByOrg.bind(this);
    }

    get rolesMap(): Dictionary<string> {
        const rolesMap: Dictionary<string> = {};
        this.roles.forEach((role) => {
            if (rolesMap[role.code]) {
                return (rolesMap[role.code] = role.name);
            }
            return (rolesMap[role.id] = role.name);
        });
        return rolesMap;
    }

    productsPermissionsMap = (list: Product[]) => {
        let permissions: ProductPermission[] = [];
        list.forEach((product: Product) => {
            const productPermissions = product.permissions.map((el) => ({
                ...el,
                product: product.code
            }));
            return (permissions = [...permissions, ...productPermissions]);
        });
        return uniqBy(permissions, 'id');
    };

    groupProductsByRole = (roles: Partial<RoleGroup>[], products: Product[]) => {
        const rolesMap: { [key: string]: any } = {};

        const intersect = (arr1: any, arr2: any) => {
            return arr1.filter((n: any) => {
                return arr2.indexOf(n) !== -1;
            });
        };
        roles.forEach((role: Partial<RoleGroup>) => {
            products.forEach((product: any) => {
                const prodPerms = product.permissions.map((per: any) => {
                    return per.id;
                });
                if (intersect(role.permissions, prodPerms)?.length > 0) {
                    if (rolesMap[role.id]) {
                        return (rolesMap[role.id] = {
                            ...rolesMap[role.id],
                            products: [...rolesMap[role.id]?.products, product]
                        });
                    }
                    return (rolesMap[role.id] = {
                        ...role,
                        products: [product]
                    });
                }
            });
        });
        return rolesMap;
    };

    loadRoles = flow(function* (this: RolesStore, organizationId: string, withProducts = false) {
        const { api, errorMessageHandler } = apiStore.api.roles.getOrganizationRoles;
        try {
            this.loadingStatusState.load();

            const roles = yield HttpClient.TCMS.GET(api(organizationId));
            if (withProducts) {
                const products = yield this.loadProductsByOrg(organizationId);
                this.roleProductsGroup = yield this.groupProductsByRole(roles, products);
            }
            this.roles = roles;
            this.loadingStatusState.loadSuccess();
        } catch (error: any) {
            if (error?.status === 404) {
                this.roles = [];
            }
            alertStore.error(error, errorMessageHandler);
            this.loadingStatusState.loadSuccess();
        }
    });

    updateRolePermissions = flow(function* (
        this: RolesStore,
        roleId: string,
        organizationId: string,
        permissions: string[]
    ) {
        const { api, errorMessageHandler } = apiStore.api.roles.putOrganizationRolePermissions;
        try {
            yield HttpClient.TCMS.PUT(api(roleId, organizationId), permissions);
        } catch (error: any) {
            return { ...error, errorMessageHandler };
        }
    });

    deleteRole = flow(function* (
        this: RolesStore,
        { roleId, organizationId }: { roleId: string; organizationId: string }
    ) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.roles.deleteOrganizationRole;
        try {
            yield HttpClient.TCMS.DELETE(api(roleId, organizationId));
            const index = this.roles.findIndex((a) => a.id === roleId);
            this.roles.splice(index, 1);
            this.roleProductsGroup = yield this.groupProductsByRole(this.roles, this.products);
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    addRole = flow(function* (this: RolesStore, data: { [key: string]: any }) {
        const { api, errorMessageHandler, successMessage } = apiStore.api.roles.postOrganizationRoles;
        try {
            const role = yield HttpClient.TCMS.POST(api(data.organizationId), {
                name: data.name,
                description: data.description
            });

            if (role?.id) {
                let roleAssignSuccess = false;
                let rolePermissionsRes: { [key: string]: any } = null;

                for (let i = 0; i < 3; i++) {
                    if (!roleAssignSuccess) {
                        rolePermissionsRes = yield this.updateRolePermissions(
                            role.id,
                            data.organizationId,
                            data.permissions
                        );
                        yield CoreUtil.delayPromise(2000);
                        roleAssignSuccess = !rolePermissionsRes?.status;
                    }
                }

                if (!roleAssignSuccess) {
                    alertStore.error(rolePermissionsRes, rolePermissionsRes.errorMessageHandler);
                }

                this.roles.unshift({ ...role, permissions: rolePermissionsRes || data.permissions });
            }

            this.roleProductsGroup = yield this.groupProductsByRole(this.roles, this.products);

            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    editRole = flow(function* (this: RolesStore, data: { [key: string]: any }) {
        const { api, errorMessageHandler, successMessage } = apiStore.api.roles.putOrganizationRoles;
        try {
            yield HttpClient.TCMS.PUT(api(data.id, data.organizationId), {
                name: data.name,
                description: data.description
            });

            let roleAssignSuccess = false;
            let rolePermissionsRes: { [key: string]: any } = null;

            for (let i = 0; i < 3; i++) {
                if (!roleAssignSuccess) {
                    rolePermissionsRes = yield this.updateRolePermissions(
                        data.id,
                        data.organizationId,
                        data.permissions
                    );
                    yield CoreUtil.delayPromise(2000);
                    roleAssignSuccess = !rolePermissionsRes?.status;
                }
            }

            if (!roleAssignSuccess) {
                alertStore.error(rolePermissionsRes, rolePermissionsRes.errorMessageHandler);
            }

            const roleToUpdate = this.roles.find((r) => r.id === data.id);

            if (data.id) {
                Object.assign(roleToUpdate, data);
            }

            const index = this.roles.findIndex((a) => a.id === roleToUpdate?.id);
            this.roles[index] = {
                ...this.roles[index],
                ...data
            };
            this.roleProductsGroup = yield this.groupProductsByRole(this.roles, this.products);
            if (roleAssignSuccess) {
                alertStore.success(successMessage);
            }
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    loadProductsByOrg = flow(function* (this: RolesStore, organizationId: string) {
        const { api } = apiStore.api.products.getProducts;
        const { api: getConnectedProductsForOrganization } = apiStore.api.products.getConnectedProductsForOrganization;

        try {
            const productsDictionary = yield HttpClient.TCMS.GET(api(organizationId));
            const productsByOrg = yield HttpClient.TCMS.GET(getConnectedProductsForOrganization(organizationId));
            this.products = yield intersectionBy(productsDictionary, productsByOrg, 'code') || [];
            return this.products;
        } catch (error) {
            // ToDO add notification message
        }
    });

    filterRoles = computedFn((query: string) => {
        if (!!query) {
            return this.roles.filter((role: Role) => this.findRoleByQuery(role, query));
        }
        return this.roles;
    });

    findRoleByQuery = computedFn((roleGroup: any, query: string) => {
        return (
            roleGroup.email.toUpperCase().includes(query.toUpperCase()) ||
            `${roleGroup.name.toUpperCase()} ${roleGroup.name.toUpperCase()}`.includes(query.toUpperCase())
        );
    });
}
