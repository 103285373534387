import React, { useCallback, useState } from 'react';

import { ModalProps } from '@modules/shared/models';
import { ModalWindow } from '@modules/shared/components';
import { Organization } from '@modules/organizations/models';
import { VesselSharesFormFields } from '@modules/core/constants';
import { LoadingStatusType } from '@modules/core/models';
import { FleetStore } from '@modules/fleet/store';
import { VesselShare, Steps, FormFields } from '@modules/sharing/models';
import { Vessel } from '@modules/fleet/models';
import { MultiStepForm } from '@modules/sharing/components';

import './ShareVesselsModal.scss';

interface Props extends ModalProps {
    fleetStore: FleetStore;
    organization: Organization;
    onSubmit: (code: string, vessels: Vessel[]) => Promise<void>;
    vesselShares: VesselShare[];
}

export const ShareVesselsModal = (props: Props) => {
    const { modalIsOpen, onCloseModal, fleetStore, organization, vesselShares } = props;

    const [step, setStep] = useState<Steps>(Steps.GET_PARTNER_ID);
    const [stepsFormFields, setStepsFormFields] = useState<FormFields>({
        partnerCode: '',
        partnerName: '',
        vessels: []
    });
    const [isLoading, setIsLoading] = useState(false);

    const onSubmitSharingVessels = async (fields: FormFields) => {
        setIsLoading(true);
        await props.onSubmit(fields.partnerCode, fields.vessels);
        setIsLoading(false);

        onCloseModal(false);
    };

    const setStepFields = async (fields: FormFields) => {
        setStepsFormFields({ ...fields });

        if (step !== VesselSharesFormFields.length - 1) {
            setStep(step + 1);
        } else {
            await onSubmitSharingVessels(fields);
        }
    };

    const renderForm = useCallback(() => {
        return (
            <MultiStepForm
                step={step}
                setStepFields={setStepFields}
                onCloseModal={onCloseModal}
                setStep={setStep}
                stepsFormFields={stepsFormFields}
                fleetStore={fleetStore}
                organization={organization}
                vesselShares={vesselShares}
                setStepsFormFields={setStepsFormFields}
            />
        );
    }, [step]);

    return (
        <ModalWindow
            modalIsOpen={modalIsOpen}
            loadingModal={isLoading ? LoadingStatusType.isLoading : LoadingStatusType.isLoaded}
            className="sharing-vessels-popup"
            title={'Share vessels'}
            onCancel={() => onCloseModal(false)}
            hiddenFooter
        >
            {renderForm()}
        </ModalWindow>
    );
};
