import { flow } from 'mobx';

import { alertStore } from '@modules/core/store/AlertStore';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';
import OrganizationsStore from '@modules/organizations/store/OrganizationsStore';

export default class OrganizationsStoreAll extends OrganizationsStore {
    constructor() {
        super();
        this.loadOrganizations = this.loadOrganizations.bind(this);
    }

    loadOrganizations = flow(function* (this: OrganizationsStoreAll) {
        const { api, errorMessageHandler } = apiStore.api.organizations.getOrganizations;
        try {
            this.loadingStatusState.load();
            const organizations = yield HttpClient.TCMS.GET(api());
            this.organizations = Array.isArray(organizations) ? organizations : [organizations]; // ToDo for admin organization we get object not array
            this.loadingStatusState.loadSuccess();
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });
}
