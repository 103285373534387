import React, { useMemo } from 'react';
import { Controller, Control } from 'react-hook-form';
import { Dropdown } from '@fos/eniram-web-common-ui/dist/components/DropDown';
import { MultiSelectFormField } from '@modules/core/models';

interface Props {
    control: Control;
    tabIndex: number;
    field: MultiSelectFormField;
    rules: { [key: string]: any };
    clearErrors: (p: any) => void;
}

export const MultiSelect = (props: Props) => {
    const { control, field, rules, clearErrors } = props;

    const placeholder = useMemo(() => {
        if (!field.options.length) {
            return field.placeholders?.empty || 'Account has no roles for assignment';
        } else {
            return field.placeholders?.noAssigned || 'Account has no roles, edit permissions';
        }
    }, [field]);

    return (
        <Controller
            control={control}
            name={field.fieldName}
            rules={rules}
            defaultValue={field.defaultValue}
            render={({ field: { onChange } }) => {
                return (
                    <Dropdown
                        qaId={field.qaId}
                        placeholder={placeholder}
                        defaultValue={field.defaultValue}
                        actionFunc={(p) => {
                            clearErrors(field.fieldName);
                            return onChange(p);
                        }}
                        options={field.options}
                        isSearchable
                        isClearable
                        isMulti
                    />
                );
            }}
        />
    );
};
