import React from 'react';
import { useObserver } from 'mobx-react-lite';
import LogoGrey from '@assets/icons/logo-grey.svg';

import './EmptyPage.scss';

export const EmptyPage = () => {
    return useObserver(() => (
        <div className="empty-page">
            <div className="empty-page__content">
                <div className="empty-page__text">
                    This page doesn’t exist.
                    <br />
                    For questions, please contact
                    {` `}
                    <a href="mailto:voyage.support@wartsila.com">voyage.support@wartsila.com</a>
                    <div className="empty-page__logo">
                        <img src={LogoGrey} alt="LogoGrey" />
                    </div>
                </div>
            </div>
        </div>
    ));
};
