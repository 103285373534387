import { SortOrderType } from '@modules/shared/constants';
import get from 'lodash/get';
import { Dictionary } from '@modules/shared/models';

export class SortingUtils {
    static sortAllTagToFirstRow = (array: any) => {
        const all = array.find((item: any) => {
            const name = item.name || `${item.firstName} ${item.lastName}`;
            return name.toLowerCase() === 'all vessels';
        });
        return all ? [all, ...array.filter((item: any) => item.name.toLowerCase() !== 'all vessels')] : array;
    };

    static defaultCompare = (property: string, order: SortOrderType): ((prevItem: any, nextItem: any) => number) => {
        return (prevItem: any, nextItem: any): SortOrderType => {
            if (!prevItem || !nextItem) {
                return order;
            }
            const prevValue = get(prevItem, property);
            const nextValue = get(nextItem, property);

            if (prevValue === nextValue) return SortOrderType.None;

            if (typeof prevValue === 'string' && typeof nextValue === 'string') {
                return SortingUtils.stringCompare(prevValue, nextValue, order);
            }

            if (!nextValue) return order;

            return prevValue > nextValue ? order : -order;
        };
    };

    static sortArrayByParam = (array: any, param?: string) =>
        array.sort((a: any, b: any) => {
            const nameA = param ? a[param] : a;
            const nameB = param ? b[param] : b;
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });

    static roleCompare = (
        property: string,
        order: SortOrderType,
        rolesMap: Dictionary<string>
    ): ((prevItem: any, nextItem: any) => number) => {
        return (prevItem: any, nextItem: any): SortOrderType => {
            if (!prevItem || !nextItem) {
                return order;
            }
            const prevValueArr = get(prevItem, property) || [];
            const nextValueArr = get(nextItem, property) || [];

            const prevValue = SortingUtils.sortArrayByParam(prevValueArr.map((id: string) => rolesMap[id]));
            const nextValue = SortingUtils.sortArrayByParam(nextValueArr.map((id: string) => rolesMap[id]));

            if (typeof prevValue === 'string' && typeof nextValue === 'string') {
                return SortingUtils.stringCompare(prevValue, nextValue, order);
            }

            if (!nextValue) return order;

            return prevValue > nextValue ? order : -order;
        };
    };

    static multiPropCompare = (order: SortOrderType, ...args: string[]): ((prevItem: any, nextItem: any) => number) => {
        return (prevItem: any, nextItem: any): SortOrderType => {
            if (!prevItem || !nextItem) {
                return order;
            }
            const prevValue = SortingUtils.combineProps(prevItem, ...args);
            const nextValue = SortingUtils.combineProps(nextItem, ...args);

            return SortingUtils.stringCompare(prevValue, nextValue, order);
        };
    };

    private static stringCompare = (prevValue: string, nextValue: string, order: SortOrderType): SortOrderType =>
        prevValue.toLowerCase() > nextValue.toLowerCase() ? order : -order;

    private static combineProps = (item: any, ...args: string[]) => {
        return args.reduce((result, property) => `${result} ${get(item, property)}`, '');
    };
}
