import React from 'react';
import { observer } from 'mobx-react-lite';

import { VesselSharesStore } from '@modules/fleet/store';
import { LoadPlaceholder } from '@modules/core/components';
import { VesselSharesTable } from './vessel-shares-table/VesselSharesTable';
import { VesselShare } from '@modules/sharing/models';

import './VesselShares.scss';

interface Props {
    vesselSharesState: {
        entities: VesselShare[];
        sortEntities: (sortFunc: (prevItem: any, nextItem: any) => number) => void;
        [key: string]: any;
    };
    vesselSharesStore: VesselSharesStore;
}

export const VesselShares = observer<Props>((props) => {
    const { vesselSharesStore, vesselSharesState } = props;
    const { loadingStatusState } = vesselSharesStore;

    const { entities, sortEntities } = vesselSharesState;

    return (
        <div className="shared-vessels-page">
            <main className="card">
                <LoadPlaceholder loadingStatus={loadingStatusState.status}>
                    <VesselSharesTable
                        vesselShares={entities}
                        loadingStatus={loadingStatusState.status}
                        loadingDelay={0}
                        sortEntities={sortEntities}
                    />
                </LoadPlaceholder>
            </main>
        </div>
    );
});
