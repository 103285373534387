import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { autorun } from 'mobx';
import { useObserver } from 'mobx-react-lite';

import { Vessel } from '@modules/fleet/models';
import { ConnectedProductsPage } from '@pages/connected-products-page/ConnectedProductsPage';
import { DataSourceFormPage } from '@pages/data-source-form-page/DataSourceFormPage';
import { VesselGeneralInformation } from '@modules/fleet/components';
import { VesselSharesStore, VesselStore } from '@modules/fleet/store';
import { DataSourceStore } from '@modules/core/store/DataSourceStore';
import { ConnectedProductsStore } from '@modules/connected-products/store';
import { useGoBack, useRolesState } from '@modules/shared/hooks';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';
import { NavBar } from '@modules/core/components';
import { NavigationTypes, VesselsDetailsNavItems, VesselsDetailsNavItemsSuperuser } from '@modules/core/constants';
import { LoadingStatusType } from '@modules/core/models';
import { EmptyPage } from '@pages/empty-page/EmptyPage';

import './VesselDetailsPage.scss';

interface Props {
    vesselSharesStore: VesselSharesStore;
    vesselStore: VesselStore;
    dataSourceStore: DataSourceStore;
    connectedProductsStore: ConnectedProductsStore;
}

export const VesselDetailsPage = (props: Props) => {
    const { vesselStore, vesselSharesStore, dataSourceStore, connectedProductsStore } = props;
    const { isSupportUser, isSuperUser } = useRolesState();
    const { loadVesselProducts, overrideVesselOptions } = connectedProductsStore;
    const { vesselId } = useParams() as { vesselId: string };
    const { goBack } = useGoBack();
    const match = useRouteMatch();

    const [selectedVessel, setSelectedVessel] = useState<Vessel>(null);

    useEffect(() => {
        const disposer = autorun(async () => {
            await vesselStore.getVesselById(vesselId);
            setSelectedVessel(vesselStore.vessel);
            selectedEntityState.selectVessel(vesselStore.vessel);
        });
        return () => disposer();

        // Required fleetStore.selectedVessel and selectVesselId. But we need to rerun it only when vesselId was changed
        // eslint-disable-next-line
    }, [vesselId]);

    useEffect(() => {
        const disposer = autorun(() => {
            if (vesselStore.loadingStatusState.status === LoadingStatusType.isLoaded) {
                setSelectedVessel(vesselStore.vessel);
                selectedEntityState.selectVessel(vesselStore.vessel);
            }
        });
        return () => disposer();
    }, [vesselStore.vessel, vesselStore.vessel?.dataSources]);

    const loadVessels = useCallback(() => {
        return loadVesselProducts(vesselId);
    }, [vesselId, loadVesselProducts]);

    const saveServices = useCallback(
        async (productId: string, services: any) => {
            return await overrideVesselOptions(vesselId, productId, services);
        },
        [vesselId, overrideVesselOptions]
    );

    return useObserver(() =>
        vesselStore.loadingStatusState.isRequestFailed ? (
            <EmptyPage />
        ) : (
            <main className="vessel-details-page">
                <header className="vessel-details-page-header">
                    <i className="icon icon-chevron left" onClick={() => goBack()} />
                    <span qa-id="vessel-details-title">{selectedVessel?.name}</span>
                </header>
                <nav>
                    <NavBar
                        match={true}
                        navItems={isSuperUser ? VesselsDetailsNavItemsSuperuser : VesselsDetailsNavItems}
                        navType={NavigationTypes.Upper}
                    />
                </nav>
                <section className="vessel-details-page-body">
                    <Switch>
                        <Route path={`${match.url}/general`}>
                            {selectedVessel && (
                                <VesselGeneralInformation
                                    vessel={selectedVessel}
                                    vesselSharesStore={vesselSharesStore}
                                />
                            )}
                        </Route>
                        <Route path={`${match.url}/datasources`}>
                            <DataSourceFormPage
                                vesselStore={vesselStore}
                                dataSourceStore={dataSourceStore}
                                vesselId={vesselId}
                                dataSources={selectedVessel?.dataSources}
                            />
                        </Route>
                        <Route path={`${match.url}/options`}>
                            <ConnectedProductsPage
                                loadingFunction={loadVessels}
                                saveFunction={saveServices}
                                isGroupTabsHidden={true}
                                editable={isSuperUser && !isSupportUser}
                                connectedProductsStore={connectedProductsStore}
                            />
                        </Route>
                        <Redirect exact from={`${match.url}`} to={`${match.url}/general`} />
                        <Route component={EmptyPage} />
                    </Switch>
                </section>
            </main>
        )
    );
};
