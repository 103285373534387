import { flow, observable, makeObservable } from 'mobx';

import { Product } from '../models';
import { alertStore } from '@modules/core/store/AlertStore';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';

export default class ProductStore {
    products: Product[] = [];

    constructor() {
        makeObservable(this, {
            products: observable
        });

        this.loadProducts = this.loadProducts.bind(this);
    }

    loadProducts = flow(function* (this: ProductStore) {
        const { api, errorMessageHandler } = apiStore.api.products.getProducts;
        try {
            this.products = yield HttpClient.TCMS.GET(api());
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });
}
