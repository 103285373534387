import { observable, action, makeObservable } from 'mobx';

import { CommonApi, UsersApi, OrganizationsApi, SuperUserApi } from '../constants/RolesApi';
import { RoleType } from '../models';

export default class ApiStore {
    api: CommonApi = {};

    constructor() {
        makeObservable(this, {
            api: observable.ref,
            setApiByRole: action
        });
    }

    setApiByRole = (roles: RoleType[]) => {
        this.api = {
            ...this.api,
            ...UsersApi,
            ...OrganizationsApi
        };

        if (roles.includes(RoleType.Superuser) || roles.includes(RoleType.Support)) {
            this.api = {
                ...this.api,
                ...SuperUserApi
            };
        }
    };
}

export const apiStore = new ApiStore();
