import { useObserver } from 'mobx-react-lite';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';

export const useSelectedState = () => {
    const vesselGroup = useObserver(() => selectedEntityState.vesselGroup);
    const account = useObserver(() => selectedEntityState.account);
    const organization = useObserver(() => selectedEntityState.organization);
    const vessel = useObserver(() => selectedEntityState.vessel);
    const apiClient = useObserver(() => selectedEntityState.apiClient);

    return {
        account,
        organization,
        vesselGroup,
        vessel,
        apiClient
    };
};
