import React from 'react';

import { User } from '@modules/core/models';
import { UserMenu } from '@modules/core/components';
import UserStore from '@modules/core/store/UserStore';

import './Header.scss';

interface Props {
    user: User;
    userStore: UserStore;
}

export const Header = (props: Props) => {
    const { user, userStore } = props;

    const userRole =
        (userStore.isSupportUser && 'Support') ||
        (userStore.isSuperUser ? 'Superuser' : (user?.organization?.name ?? '') + ' Administrator');

    return (
        <div className="header" qa-id="header">
            <div className="header-top">
                <div className="user-management-naming">
                    <div>
                        Wärtsilä
                        <span> FOS</span>
                    </div>
                    <div>User Management</div>
                </div>
                <UserMenu user={user} />
            </div>
            <div className="header-user-role" qa-id="header-user-role">
                <span>{userRole}</span>
            </div>
        </div>
    );
};
