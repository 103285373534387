import React from 'react';

import { CustomCheckbox } from '@modules/shared/components';

interface Props {
    id: string;
    isHidden: boolean;
    containerClass: string;
    isChecked: boolean;
    disabled?: boolean;
    handleChange: Function;
}

export const EntityTableCheckbox = (props: Props) => {
    const { id, handleChange, containerClass, isChecked, isHidden, disabled } = props;
    return isHidden ? (
        <div className={containerClass}>
            <CustomCheckbox id={id} isChecked={isChecked} disabled={disabled} handleChange={handleChange} />
        </div>
    ) : null;
};
