export { VesselGroupStore } from './VesselGroupStore';

import { VesselGroupStoreOrganization } from './VesselGroupStoreOrganization';
import { VesselGroupStoreAll } from './VesselGroupStoreAll';
import { VesselGroupStoreUsers } from './VesselGroupStoreUsers';
import { VesselGroupStoreApiClients } from './VesselGroupStoreApiClients';

export default {
    vesselGroupsStoreOrganization: new VesselGroupStoreOrganization(),
    vesselGroupsStoreAll: new VesselGroupStoreAll(),
    vesselGroupsStoreUsers: new VesselGroupStoreUsers(),
    vesselGroupStoreApiClients: new VesselGroupStoreApiClients()
};
