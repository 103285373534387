import { Dictionary } from '@modules/shared/models';
import { Option } from '@modules/core/models';

export class SelectComboboxUtils {
    static mapSelectComboboxValues(
        defaultValues: Dictionary<[]>,
        selectOptions: Option[],
        selectName: string,
        extendSelectName: string
    ) {
        return Object.keys(defaultValues).map((value) => {
            return {
                [selectName]: selectOptions.find((option: any) => option.value === value),
                [extendSelectName]: defaultValues[value]
            };
        });
    }
}
