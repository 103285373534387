import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { LoadingStatusType } from '@modules/core/models';
import { LoadPlaceholder } from '@modules/core/components';

import './ModalWindow.scss';

interface Props {
    modalIsOpen: boolean;
    isCancel?: boolean;
    disabledSubmit?: boolean;
    qaId?: string;
    submitButtonName?: string;
    title: string;
    className: string;
    children: any;
    onSubmit?: () => Promise<void>;
    expandButtonName?: string;
    onExpandButton?: () => void;
    onCancel?: () => void;
    loadingModal?: LoadingStatusType;
    hiddenFooter?: boolean;
}

export const ModalWindow = (props: Props) => {
    const {
        qaId = 'modal-window',
        disabledSubmit = false,
        modalIsOpen,
        children,
        className,
        title,
        submitButtonName,
        onSubmit,
        onCancel,
        expandButtonName,
        onExpandButton,
        isCancel = true,
        loadingModal = LoadingStatusType.isLoaded
    } = props;
    const [loading, setLoading] = useState<LoadingStatusType>(LoadingStatusType.isLoaded);

    const submit = async () => {
        setLoading(LoadingStatusType.isLoading);
        await onSubmit();
        setLoading(LoadingStatusType.isLoaded);
    };

    useEffect(() => {
        return () => setLoading(LoadingStatusType.isLoaded);
    }, []);

    return (
        <>
            <Modal isOpen={modalIsOpen} className={className + ' custom-modal'} ariaHideApp={false}>
                <LoadPlaceholder loadingStatus={loading} shadowed={true} defaultDelay={0} />
                {loading === LoadingStatusType.isLoaded && (
                    <LoadPlaceholder loadingStatus={loadingModal} shadowed={true} defaultDelay={0} />
                )}
                <header qa-id={qaId + '-header'}>
                    <span className="title">{title}</span>
                    <i className="icon icon-close-modal" onClick={onCancel} />
                </header>
                <section qa-id={qaId + '-content'}>{children}</section>
                {!props.hiddenFooter && (
                    <footer className={expandButtonName ? 'buttons-alignment' : ''}>
                        {expandButtonName && (
                            <button className="button secondary" onClick={onExpandButton}>
                                {expandButtonName}
                            </button>
                        )}
                        <div className={'buttons-container'}>
                            <button
                                disabled={disabledSubmit}
                                className="button primary"
                                onClick={submit}
                                qa-id={qaId + '--save-button'}
                            >
                                {submitButtonName}
                            </button>
                            {isCancel && (
                                <button
                                    className="button secondary"
                                    onClick={onCancel}
                                    qa-id={qaId + '--cancel-button'}
                                >
                                    CANCEL
                                </button>
                            )}
                        </div>
                    </footer>
                )}
            </Modal>
        </>
    );
};
