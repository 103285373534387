import React, { useState } from 'react';
import { useObserver } from 'mobx-react-lite';

import { EntityTable, Tag } from '@modules/shared/components';
import { ColumnSize, Dictionary, TableColumn } from '@modules/shared/models';
import { LoadingStatusType } from '@modules/core/models';
import { SortOrderType } from '@modules/shared/constants';
import { CoreUtil, SortingUtils } from '@modules/core/utils';
import { VesselShare } from '@modules/sharing/models';
import { useHistory } from 'react-router-dom';
import { Organization } from '@modules/organizations/models';
import { useRolesState } from '@modules/shared/hooks';

import './SharingTable.scss';

interface Props {
    id: string;
    vesselShares: VesselShare[];
    ownerOrganization?: Organization;
    sortEntities?: (sortFunc: (prevItem: any, nextItem: any) => number) => void;
    setEditModalVisibility: (value: boolean) => any;
    setDeleteModalVisibility: (value: boolean) => any;
    selectedEntity: VesselShare[];
    setSelectedVesselShares: (vesselShares: VesselShare[]) => void;
    setCurrentVesselShare: (vesselShares: VesselShare) => void;
    loadingStatus?: LoadingStatusType;
}

const minColumnHeight = 24;
const minRowHeight = 40;

export const SharingTable = (props: Props) => {
    const { id, sortEntities, vesselShares, selectedEntity } = props;
    const [itemHeightMap, setItemHeightMap] = useState<{ [key: string]: number }>({});
    const [subItemHeightMap, setSubItemHeightMap] = useState<Dictionary<any>>({});
    const [openedRow, setOpenedRow] = useState<Dictionary<any>>({});
    const history = useHistory();
    const { isSupportUser } = useRolesState();

    const initOptions = (options: string[] = [], mainRowIndex?: number) => {
        if (!options.length) {
            return <div />;
        }

        const isOpenedRow = openedRow[mainRowIndex];
        const fullHeight = subItemHeightMap[mainRowIndex] && subItemHeightMap[mainRowIndex];
        const height = isOpenedRow ? subItemHeightMap[mainRowIndex] : minColumnHeight;

        return (
            <div
                className={'flex-table--body divider dividerBottom options-align'}
                style={{
                    height: height
                }}
            >
                <div className="options-container" ref={(el) => el && initSubRowRef(el, mainRowIndex)}>
                    {options.map((option: string, index) => {
                        return (
                            <span key={`tag-${option}-${index}`} className={`option-tag`}>
                                <Tag label={option} id={option} /> {` `}
                            </span>
                        );
                    })}
                </div>
                {fullHeight > minRowHeight && (
                    <div
                        className={'columnWithInfo option'}
                        onClick={() => {
                            setOpenedRow({
                                ...openedRow,
                                [mainRowIndex]: !isOpenedRow
                            });
                        }}
                    >
                        <i className={'options-info'}>
                            <i className={`icon icon-chevron ${isOpenedRow ? '' : 'closed'}`} />
                        </i>
                    </div>
                )}
            </div>
        );
    };

    const initSubRowRef = (el: any, index: number) => {
        const row = subItemHeightMap[index] || minColumnHeight;
        if (row !== el.getBoundingClientRect().height) {
            setSubItemHeightMap({
                ...subItemHeightMap,
                [index]: el?.getBoundingClientRect().height || minColumnHeight
            });
        }
    };

    const columns: TableColumn[] = [
        {
            title: 'Vessel Type',
            columnSize: ColumnSize.COLUMN1,
            property: 'vesseltype',
            className: () => 'divider dividerBottom first-column disabledSort hiddenTitle',
            render: ({ vessel }: VesselShare) => {
                return (
                    <div className="vessel">
                        <div className="vessel-type">
                            {vessel.createdManually ? (
                                <i className="icon icon-ais ais" />
                            ) : (
                                <i className="icon icon-vessel vessel" />
                            )}
                        </div>
                    </div>
                );
            }
        },
        {
            title: 'Vessel name',
            columnSize: ColumnSize.COLUMN3,
            property: 'vessel.name',
            className: () => 'divider dividerBottom',
            onClick: (vesselShare: VesselShare): void =>
                CoreUtil.goToPage(history, `/fleet/vessels/${vesselShare.vessel.id}`),
            render: ({ vessel }: VesselShare) => vessel.name,
            hasSort: false
        },
        {
            title: 'Network partner',
            columnSize: ColumnSize.COLUMN2,
            property: 'organization.name',
            render: (row: VesselShare) => row.organization?.name,
            className: () => 'dividerBottom'
        },
        {
            title: 'Shared options',
            columnSize: ColumnSize.COLUMN6,
            property: 'product.options',
            render: (vessel: VesselShare, index) => initOptions(vessel?.product?.options || [], index),
            className: () => 'dividerBottom disabledSort',
            hidden: true
        },
        {
            title: '-',
            property: 'void',
            columnSize: ColumnSize.COLUMN6,
            className: () => 'dividerBottom disabledSort hiddenTitle'
        }
    ];

    const sortRows = (property: string, order: SortOrderType) => {
        sortEntities(SortingUtils.defaultCompare(property, order));
    };

    const getRowHeight = (index: any) => {
        return itemHeightMap[index] || minRowHeight;
    };

    const setRowHeight = (index: number, size: number) => {
        setItemHeightMap({ ...itemHeightMap, [index]: size });
    };

    return useObserver(() => (
        <div className="sharing-table">
            <EntityTable
                id={id}
                columns={columns}
                listOfEntities={vesselShares || []}
                isArchiveAvailable={false}
                selectedEntity={selectedEntity}
                setSelectedEntities={props.setSelectedVesselShares}
                setCurrentEntity={null}
                sortEntities={sortRows}
                getRowHeight={getRowHeight}
                setRowHeight={setRowHeight}
                itemHeightMap={itemHeightMap}
                loadingDelay={400}
                isCalcSize
                {...(!isSupportUser && {
                    selectableAll: true,
                    selectable: true,
                    isUnlinkAvailable: true,
                    setDeleteModalVisibility: props.setDeleteModalVisibility,
                    setCurrentEntity: (cur: VesselShare) => {
                        props.setCurrentVesselShare(cur);
                    }
                })}
            />
        </div>
    ));
};
