import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { flow } from 'mobx';
import { observer } from 'mobx-react-lite';
import { SearchInput } from '@fos/eniram-web-common-ui/dist/components/SearchInput';
import { CancellablePromise } from 'mobx/dist/api/flow';

import { useStore } from '@store/StoreEffect';
import { usePageContainer, useSelectedState } from '@modules/shared/hooks';
import { ConfirmModal } from '@modules/shared/components';
import { AssignVesselModal, CreateVesselModal, VesselShares, VesselsTable } from '@modules/fleet/components';
import { Vessel } from '@modules/fleet/models';
import { CreatedManuallyVessel, Organization } from '@modules/organizations/models';
import { FleetStore } from '@modules/fleet/store';
import { GroupedVesselTable } from '@modules/vessel-groups/components';
import { SingleValueSelector } from '@fos/eniram-web-common-ui/dist/components/SingleValueSelector';
import { VesselGroup, VesselGroupExtended } from '@modules/vessel-groups/models';
import { VesselGroupStore } from '@modules/vessel-groups/store';
import { vesselsListViewSelector, vesselViewSelector } from '@modules/fleet/constants';
import { CoreUtil as CU } from '@modules/core/utils';
import { mergeGroupsWithVessels } from '@modules/vessel-groups/utils';
import { Dictionary } from '@modules/shared/models';
import { LoadingStatusType, ViewSelector } from '@modules/core/models';
import { LoadPlaceholder } from '@modules/core/components';

import './VesselsPage.scss';

interface Props {
    vesselsWithGroupsPageState: Dictionary<any>;
    fleetStore: FleetStore;
    vesselGroupStore: VesselGroupStore;
    modalLoadingFunction?: (...args: any[]) => CancellablePromise<void>;
    modalFilterFunction?: (value: string) => any[];
    isOrganisationDetail?: boolean;
    isEditAllowed?: boolean;
    isCreateAllowed?: boolean;
    isVesselTitleVisible?: boolean;
    ownerOrganization?: Organization;
    isVesselDeletable?: (vessel: Vessel) => boolean;
    isShowGroupsFilter?: boolean;
    isVesselGroupsModalVisible?: boolean;
    isVesselGroupsHidden?: boolean;
    setIsVesselGroupsHidden?: (value: boolean) => void;
    userId?: string;
    availableGroupsForAssign?: VesselGroup[];
    editable: (vessel: Vessel) => boolean;
}

export const VesselsWithGroupsPage = observer<Props>((props) => {
    const {
        isVesselTitleVisible = false,
        fleetStore,
        isOrganisationDetail,
        modalFilterFunction,
        modalLoadingFunction,
        isEditAllowed,
        isCreateAllowed,
        ownerOrganization,
        isVesselDeletable,
        vesselGroupStore,
        isShowGroupsFilter,
        isVesselGroupsModalVisible,
        isVesselGroupsHidden,
        setIsVesselGroupsHidden,
        availableGroupsForAssign = [],
        userId,
        editable
    } = props;

    const { vesselGroup, organization, account, apiClient } = useSelectedState();
    const { vesselSharesStore } = useStore();
    const { loadVessels, addVessels, filterVessels, loadingStatusState, directlyAssignedVessels } = fleetStore;

    const { loadVesselGroups, filterVesselGroups, editVesselGroup, loadingGroupsStatusState } = vesselGroupStore;

    const [groupedViewSelectorSelected, setGroupedView] = useState<ViewSelector>(vesselViewSelector[0]);
    const [vesselsListViewSelectorSelected, setVesselsListView] = useState<ViewSelector>(vesselsListViewSelector[0]);
    const [loadingVessels, setLoadingVessels] = useState<LoadingStatusType>(LoadingStatusType.isLoading);
    const selectedVesselGroup = useMemo(() => fleetStore.selectedVesselGroup, [fleetStore.selectedVesselGroup?.id]);
    const { userStore, organizationStore } = useStore();

    const isShowVesselGroupsOption = useMemo(() => {
        return !(userStore.isSuperUser && !isOrganisationDetail) && availableGroupsForAssign && isVesselGroupsHidden;
    }, [userStore, isOrganisationDetail, availableGroupsForAssign, isVesselGroupsHidden]);

    const isVesselSharesTable = useMemo(() => {
        return vesselsListViewSelectorSelected.id === 'vesselShares';
    }, [vesselsListViewSelectorSelected]);

    useEffect(() => {
        if (isShowGroupsFilter && selectedVesselGroup && !fleetStore.isAllVesselGroupSelected) {
            fleetStore.loadVesselsByGroup(selectedVesselGroup);
        }
    }, [selectedVesselGroup, filterVessels]);

    const vesselGroupLoadingFunction = useCallback(
        flow(function* () {
            if (userId && userId !== account?.id) {
                return;
            }
            if ((organization || account || apiClient) && !isShowGroupsFilter) {
                yield loadVesselGroups();
            }
        }),
        [organization?.id, account?.id, apiClient?.id]
    );

    const {
        setCreateModalVisibility,
        setAssignModalVisibility,
        setDeleteModalVisibility,
        setEditModalVisibility,
        selectEntity,
        selectedEntity,
        isEditMode,
        entities,
        sortEntities,
        addEntity,
        deleteEntity,
        isCreateEditModalVisible,
        onCloseCreateEditModal,
        isDeleteModalVisible,
        isCreateModalVisible,
        isEditModalVisible,
        isAssignModalVisible,
        ...vesselsState
    } = props.vesselsWithGroupsPageState;

    const vesselGroupState = usePageContainer(vesselGroupLoadingFunction, filterVesselGroups);
    const [vesselsFromSelectedGroups, setVesselsFromSelectedGroups] = useState<Dictionary<boolean>>({});

    const vesselSharesLoadingFunction = useCallback(
        flow(function* () {
            if (isVesselSharesTable) {
                yield vesselSharesStore.loadVesselSharesByOrganization(organization?.id);
            }
        }),

        [organization?.id, isVesselSharesTable]
    );
    const vesselSharesState = usePageContainer(vesselSharesLoadingFunction, vesselSharesStore.filterVesselShares);

    useEffect(() => {
        if (
            loadingStatusState &&
            (loadingStatusState.status === LoadingStatusType.isLoaded ||
                loadingStatusState.status === LoadingStatusType.isError)
        ) {
            setLoadingVessels(LoadingStatusType.isLoaded);
        }
        if (loadingStatusState && loadingStatusState.status === LoadingStatusType.isLoading) {
            setLoadingVessels(LoadingStatusType.isLoading);
        }
    }, [loadingStatusState?.status]);

    useEffect(() => {
        if (!!isCreateEditModalVisible()) {
            modalLoadingFunction();
        }
    }, [modalLoadingFunction, isCreateEditModalVisible]);

    const onSubmitAssign = async (vessels: Vessel[], selectedVesselGroups: VesselGroup[]) => {
        const getVesselsIds = CU.pluck('id');
        const isEqualVessels = isEqual(getVesselsIds(vessels), getVesselsIds(directlyAssignedVessels));
        const isEqualVesselGroups = isEqual(selectedVesselGroups, vesselGroupState.entities);

        if (!isEqualVesselGroups) {
            await editVesselGroup(selectedVesselGroups);
        }

        if (!selectedVesselGroup && isOrganisationDetail) {
            const vesselsGrouped = vesselGroup?.vessels || [];
            editVesselGroup({
                ...selectedVesselGroup,
                vessels: [...vesselsGrouped, ...vessels.map((el) => el.id)]
            });
        } else if (!isEqualVessels) {
            await addEntity(vessels);
        }

        setAssignModalVisibility(false);

        if (!isEqualVesselGroups || !isEqualVessels) {
            await loadVessels(true);
            await loadVesselGroups();
        }
    };

    const changeDeleteModalState = (state: boolean) => {
        setDeleteModalVisibility(state);
    };

    const onSubmitDelete = async (vessel: Vessel) => {
        const { deleteOrganizationVessel } = organizationStore;

        if (ownerOrganization?.id === vessel.owner?.id && vessel.createdManually && isOrganisationDetail) {
            await deleteOrganizationVessel(vessel.id, ownerOrganization?.id);
        } else {
            await deleteEntity([vessel]);
        }

        changeDeleteModalState(false);

        await loadVessels(true);
        await loadVesselGroups();
    };

    const onSubmitCreate = async (data: CreatedManuallyVessel) => {
        const { addOrganizationVessel } = organizationStore;
        const isValidForm = !CU.isEmpty(data.name) && !CU.isEmpty(data.mmsi);

        if (organization?.id && isValidForm) {
            await addOrganizationVessel(organization?.id, data);
        }

        if (isValidForm) {
            setCreateModalVisibility(false);
            await loadVessels(true);
            await loadVesselGroups();
        }
    };

    const onSubmitEdit = async (data: CreatedManuallyVessel) => {
        const { updateOrganizationVessel } = organizationStore;
        const isValidForm = !CU.isEmpty(data.name) && !CU.isEmpty(data.mmsi);
        let updatedVesselId;

        if (organization?.id && isValidForm) {
            updatedVesselId = await updateOrganizationVessel(organization?.id, selectedEntity.id, data);
        }

        if (updatedVesselId) {
            fleetStore.replaceVessel({ ...selectedEntity, ...data });
        }

        if (isValidForm) {
            setEditModalVisibility(false);
        }
    };

    const onViewChange = (value: boolean) => {
        const view = vesselViewSelector.find((_view) => _view.value === value);
        setGroupedView(view);
    };

    const onVesselsListViewChange = (value: boolean) => {
        const view = vesselsListViewSelector.find((_view) => _view.value === value);
        setVesselsListView(view);
    };

    const [extendedGroups, setExtendedGroups] = useState<VesselGroupExtended[]>([]);
    useEffect(() => {
        setExtendedGroups(mergeGroupsWithVessels(vesselGroupState.entities, entities));
    }, [vesselGroupState.entities, entities, setExtendedGroups]);

    const vesselsCount = loadingVessels === LoadingStatusType.isLoaded ? entities.length : 0;
    //TODO: check loading status
    const vesselSharesCount = vesselSharesState.entities.length;

    const vesselViewSelectors = vesselViewSelector.map((selector) => {
        const count = selector.id === 'vessels' ? vesselsCount : vesselGroupState?.entities?.length;
        return {
            ...selector,
            label: `${selector.label} (${count})`
        };
    });

    return (
        <div className="vessels-page">
            <main className="card">
                <LoadPlaceholder loadingStatus={LoadingStatusType.isLoaded}>
                    <header className="card-header">
                        <div className="card-header-block">
                            {isVesselTitleVisible && (
                                <div
                                    className="padding-controls padding-bottom-controls"
                                    qa-id={`${isOrganisationDetail ? 'organization-' : ''}vessels-page-title`}
                                >
                                    Vessels ({isVesselSharesTable ? vesselSharesCount : vesselsCount})
                                </div>
                            )}
                            {isShowVesselGroupsOption && (
                                <span
                                    className="active-link-text padding-bottom-controls"
                                    onClick={() => setIsVesselGroupsHidden(false)}
                                >
                                    Vessels groups ({vesselGroupState.entities.length})
                                </span>
                            )}
                        </div>
                        <div className="card-header-controls">
                            <div className="button-controls">
                                {addVessels && isEditAllowed && !isOrganisationDetail && (
                                    <button
                                        disabled={loadingVessels === LoadingStatusType.isLoading}
                                        className="button primary"
                                        onClick={() => setAssignModalVisibility(true)}
                                    >
                                        Assign vessels
                                    </button>
                                )}
                                {isCreateAllowed && (
                                    <button className="button primary" onClick={() => setCreateModalVisibility(true)}>
                                        Add new vessel
                                    </button>
                                )}
                                {isShowGroupsFilter && (
                                    <SingleValueSelector
                                        title=""
                                        defaultValue={vesselsListViewSelectorSelected.value}
                                        values={vesselsListViewSelector}
                                        actionFunc={onVesselsListViewChange}
                                    />
                                )}
                            </div>
                            {!isShowGroupsFilter && (
                                <SingleValueSelector
                                    title=""
                                    defaultValue={groupedViewSelectorSelected.value}
                                    values={vesselViewSelectors}
                                    actionFunc={onViewChange}
                                />
                            )}
                            <div className="search-controls">
                                <SearchInput
                                    onChange={(value: string) =>
                                        isVesselSharesTable
                                            ? vesselSharesState.setQuery(value)
                                            : vesselsState.setQuery(value)
                                    }
                                    autofocus={false}
                                    placeholder={
                                        isVesselSharesTable
                                            ? 'Search by Name, Organization'
                                            : 'Search by Name, DMS ID, IMO, CMS ID'
                                    }
                                    qaId="vessel-with-groups-search"
                                    search={isVesselSharesTable ? vesselSharesState.query : vesselsState.query}
                                />
                            </div>
                        </div>
                    </header>
                    <section className="card-body">
                        {groupedViewSelectorSelected.value || isShowGroupsFilter ? (
                            <>
                                {isVesselSharesTable ? (
                                    <VesselShares
                                        vesselSharesState={vesselSharesState}
                                        vesselSharesStore={vesselSharesStore}
                                    />
                                ) : (
                                    <VesselsTable
                                        id="vessels_table"
                                        vessels={entities}
                                        deletable={isVesselDeletable}
                                        editable={editable}
                                        loadingStatus={loadingVessels}
                                        loadingDelay={100}
                                        ownerOrganization={ownerOrganization}
                                        sortEntities={sortEntities}
                                        setDeleteModalVisibility={isEditAllowed ? setDeleteModalVisibility : null}
                                        setEditModalVisibility={isEditAllowed ? setEditModalVisibility : null}
                                        setSelectedVessel={selectEntity}
                                    />
                                )}
                            </>
                        ) : (
                            <GroupedVesselTable
                                groups={extendedGroups}
                                ownerOrganization={ownerOrganization}
                                loadingStatus={loadingGroupsStatusState?.status}
                                loadingDelay={100}
                            />
                        )}
                    </section>
                </LoadPlaceholder>
            </main>

            {isAssignModalVisible ? (
                <AssignVesselModal
                    modalTitle={'ASSIGN VESSELS/VESSELS GROUP'}
                    vessel={selectedEntity}
                    loadingVesselsStatusState={loadingStatusState}
                    loadingGroupsStatusState={loadingGroupsStatusState}
                    filterVessels={modalFilterFunction}
                    linkedVessels={entities}
                    skipSelectedVessels={isOrganisationDetail}
                    modalIsOpen={isAssignModalVisible}
                    onCloseModal={onCloseCreateEditModal()}
                    isEditMode={isEditMode}
                    onSubmit={onSubmitAssign}
                    vesselGroups={availableGroupsForAssign}
                    selectedVesselGroups={vesselGroupState.entities}
                    directlyAssignedVessels={directlyAssignedVessels}
                    vesselsFromSelectedGroups={vesselsFromSelectedGroups}
                    setVesselsFromSelectedGroups={setVesselsFromSelectedGroups}
                    isGroupsVisible={isVesselGroupsModalVisible}
                />
            ) : null}

            {(isCreateModalVisible || isEditModalVisible) && (
                <CreateVesselModal
                    isEditMode={isEditModalVisible}
                    modalIsOpen={isCreateModalVisible || isEditModalVisible}
                    onCloseModal={() =>
                        isEditModalVisible ? setEditModalVisibility(false) : setCreateModalVisibility(false)
                    }
                    onSubmit={isEditModalVisible ? onSubmitEdit : onSubmitCreate}
                    {...(isEditModalVisible && {
                        vessel: selectedEntity
                    })}
                />
            )}

            {isDeleteModalVisible && (
                <ConfirmModal
                    modalTitle={'DELETE VESSEL'}
                    modalContentText={'Do you want to delete vessel?'}
                    submitButtonTitle="DELETE"
                    modalIsOpen={isDeleteModalVisible}
                    onCloseModal={() => changeDeleteModalState(false)}
                    onSubmit={() => onSubmitDelete(selectedEntity)}
                />
            )}
        </div>
    );
});

VesselsWithGroupsPage.defaultProps = {
    isShowGroupsFilter: true
};
