import get from 'lodash/get';
import differenceBy from 'lodash/differenceBy';
import difference from 'lodash/difference';

import { Dictionary } from '@modules/shared/models';
import { RegEmail } from '../constants';
import { FormErrorsTypes, FormField, ValidatorFunction } from '../models';
import { CoreUtil } from './CoreUtil';

const { WHITESPACES_ERROR, REQUIRED_ERROR, EMAIL_ERROR } = FormErrorsTypes;

export function validateFormFields(
    v: any,
    field: FormField,
    defaultValidators: [ValidatorFunction, Dictionary<any>?][] = []
): string | true {
    if (!field.validate) return true;

    const errors = (field.validators || defaultValidators).reduce((acc, validatorMeta) => {
        const [validator, options] = validatorMeta;
        const res = validator(v, field, options);
        return { ...acc, ...res };
    }, {} as Dictionary<string>);

    return Object.values(errors)[0] || true;
}

export const DefaultValidators: [ValidatorFunction, Dictionary<any>?][] = [
    [isRequiredValidator],
    [hasOnlyWhitespacesValidator]
];

export function hasOnlySignificantDigitsValidator(
    value: any,
    _field: FormField,
    options: Partial<{ useTrim: boolean }>
): Dictionary<string> {
    if (options.useTrim) {
        value = value?.trim();
    }
    return !value.length || /^(0|([1-9]\d*))$/.test(value)
        ? {}
        : { isNaturalNumber: 'Value must contains only significant digits' };
}

export function isRequiredValidator(value: any): Dictionary<string> {
    return value ? {} : { isRequired: REQUIRED_ERROR };
}

export function isEmailValidator(value: any): Dictionary<string> {
    return RegEmail.test(value) ? {} : { isEmail: EMAIL_ERROR };
}

export function hasOnlyWhitespacesValidator(value: any): Dictionary<string> {
    return value?.length > 0 && !value?.trim() ? { hasWhitespaces: WHITESPACES_ERROR } : {};
}

export function isRequiredLengthValidator(
    value: any,
    _field: FormField,
    options: Partial<{ length: number; useTrim: boolean }>
): Dictionary<string> {
    if (options.useTrim) {
        value = value?.trim();
    }
    return !value.length || value?.length === options.length
        ? {}
        : { isDefinedLength: `Value must be ${options.length} characters long` };
}

export function isFormFieldsDirty(
    defaultValues: any = [],
    formValues: { [key: string]: any },
    fieldsCollection: {
        [key: string]: {
            [key: string]: any;
        };
    },
    checkIsSame = true
) {
    const isEveryFieldsVoid = Object.keys(formValues)
        .map((field: string) => {
            if (typeof formValues[field] === 'boolean') {
                return true;
            }

            if (Array.isArray(formValues[field])) {
                return !!formValues[field]?.length;
            }
            const fieldValue = formValues[field] && formValues[field].trim();
            return !!fieldValue;
        })
        .every((field) => !field);

    const isSomeReqFieldsVoid = Object.keys(fieldsCollection)
        .map((field: string) => {
            if (!get(fieldsCollection[field], 'validate', false)) {
                return true;
            }
            if (get(fieldsCollection[field], 'disabled', false)) {
                return true;
            }

            if (get(fieldsCollection[field], 'type') === 'selectcombobox') {
                const permissionsIds = CoreUtil.getPermissionsId(formValues.SelectCombobox);
                return !!permissionsIds.length;
            }

            const validators: [ValidatorFunction, Dictionary<any>?][] = get(
                fieldsCollection[field],
                'validators',
                DefaultValidators
            );
            if (!validators.find((validator) => validator[0] === isRequiredValidator)) {
                return true;
            }

            if (Array.isArray(formValues[field])) {
                return !!formValues[field]?.length;
            }

            return !!formValues[field];
        })
        .some((field) => !field);

    if (isEveryFieldsVoid || isSomeReqFieldsVoid) {
        return true;
    }

    const fieldsCheck = defaultValues.map((value: any) => {
        if (fieldsCollection[value.fieldName]?.disabled) {
            return true;
        }
        if (!fieldsCollection[value.fieldName]?.validate) {
            const fValues = get(formValues, value.fieldName, '') as [];
            const defaultValue = get(value, 'defaultValue', '');
            return fValues === defaultValue;
        }
        if (get(fieldsCollection[value.fieldName], 'type') === 'selectcombobox') {
            const permissionsIds = CoreUtil.getPermissionsId(formValues.SelectCombobox);
            const defaultValue = get(value, 'defaultValue', []);
            const differenceValues = difference(permissionsIds, defaultValue);
            return defaultValue.length === permissionsIds.length && !differenceValues.length;
        }
        if (get(fieldsCollection[value.fieldName], 'type') === 'multiselect') {
            const defaultValue = get(value, 'defaultValue', []);
            const fValues = get(formValues, value.fieldName, []) as [];
            const differenceValues = differenceBy(fValues, defaultValue, 'value');
            return defaultValue.length === fValues.length && !differenceValues.length;
        }
        return checkIsSame ? value.defaultValue === formValues[value.fieldName] : false;
    });
    return fieldsCheck.every((f: boolean) => f);
}
