import { observable, computed, makeObservable } from 'mobx';
import { computedFn } from 'mobx-utils';
import { CancellablePromise } from 'mobx/dist/api/flow';
import { LoadingStatusState } from '@modules/core/models';
import { Account } from '@modules/accounts/models';
import { Organization } from '@modules/organizations/models';
import { VesselGroup } from '@modules/vessel-groups/models';
import { ApiClient } from '@modules/api-clients/models';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';
import { Vessel } from '../models';

export class FleetStore {
    vessels: Vessel[] = [];
    vessel: Vessel = null;
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            vessels: observable,
            loadingStatusState: observable,
            directlyAssignedVessels: computed({ keepAlive: true }),
            selectedVesselGroup: computed({ keepAlive: true }),
            selectedOrganization: computed({ keepAlive: true }),
            selectedAccount: computed({ keepAlive: true }),
            selectedApiClient: computed({ keepAlive: true })
        });
    }

    loadVessels: (...args: any[]) => CancellablePromise<void>;

    loadVesselsByGroup: (...args: any[]) => void;

    addVessels: (...args: any[]) => CancellablePromise<void>;

    deleteVessels: (...args: any[]) => CancellablePromise<void>;

    editVessels: (...args: any[]) => CancellablePromise<void>;

    filterVesselsByOrg: (...args: any[]) => Vessel[];

    filterVessels = computedFn((query: string) => {
        if (!!query) {
            return this.vessels.filter((vessel) => this.findFleetsByQuery(vessel, query));
        }

        return this.vessels;
    });

    replaceVessel = (vessel: Vessel) => {
        const index = this.vessels.findIndex((a) => a.id === vessel.id);

        if (index !== -1) {
            this.vessels[index] = {
                ...vessel
            };
        }
    };

    findFleetsByQuery = computedFn((vessel: Vessel, query: string = '') => {
        const queryUpperCase = query.toUpperCase();
        const re = /-/g;
        return (
            vessel.name.toUpperCase().includes(queryUpperCase) ||
            vessel.dmsId.replace(re, '').toUpperCase().includes(queryUpperCase.replace(re, '')) ||
            vessel.imoNumber?.toString().toUpperCase().includes(queryUpperCase) ||
            vessel.id.toUpperCase().includes(queryUpperCase) ||
            vessel?.owner?.name?.toUpperCase()?.includes(queryUpperCase)
        );
    });

    updateSelectedAccount = computedFn((account: Account) => {
        selectedEntityState.account = account;
    });

    updateSelectedApiClient = computedFn((apiClient: ApiClient) => {
        selectedEntityState.apiClient = apiClient;
    });

    get directlyAssignedVessels(): Vessel[] {
        return this.vessels.filter((vessel) => vessel.directlyAssigned);
    }

    get selectedVesselGroup(): VesselGroup {
        return selectedEntityState.vesselGroup;
    }

    get selectedOrganization(): Organization {
        return selectedEntityState.organization;
    }

    get selectedAccount(): Account {
        return selectedEntityState.account;
    }

    get selectedApiClient(): ApiClient {
        return selectedEntityState.apiClient;
    }

    get isAllVesselGroupSelected(): boolean {
        return this.selectedVesselGroup?.name === 'All vessels';
    }
}
