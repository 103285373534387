import { observable, makeObservable } from 'mobx';
import { CancellablePromise } from 'mobx/dist/api/flow';
import { computedFn } from 'mobx-utils';

import { Organization } from '../models';
import { LoadingStatusState } from '@modules/core/models';

export default class OrganizationsStore {
    organizations: Organization[] = []; // ToDo convert to map
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            organizations: observable
        });
    }

    loadOrganizations: (...args: any[]) => CancellablePromise<void>;

    filterOrganizations = computedFn((name: string) => {
        if (!!name) {
            return this.organizations.filter((organization) =>
                organization.name.toUpperCase().includes(name.toUpperCase())
            );
        }

        return this.organizations;
    });

    removeOrganization = computedFn((organizationId: string) => {
        const index = this.organizations.findIndex((a) => a.id === organizationId);
        if (index !== -1) {
            this.organizations.splice(index, 1);
        }
    });

    insertOrganization = computedFn((organization: Organization) => {
        this.organizations.unshift(organization);
    });

    replaceOrganization = computedFn((organization: Organization) => {
        const index = this.organizations.findIndex((a) => a.id === organization.id);

        if (index !== -1) {
            this.organizations[index] = {
                ...organization
            };
        }
    });
}
