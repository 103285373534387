import React, { useCallback, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useObserver } from 'mobx-react-lite';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { Dictionary, TableColumn } from '@modules/shared/models';
import { useTableScroll } from '@modules/shared/hooks';
import { VesselGroup } from '@modules/vessel-groups/models';
import { LoadingStatusType } from '@modules/core/models';
import { LoadPlaceholder } from '@modules/core/components';

interface Props {
    id: string;
    groups: any[];
    expandedProperty: string;
    columns: TableColumn[];
    loadingStatus?: LoadingStatusType;
    loadingDelay?: number;
}

export const ExpandedEntityTable = (props: Props) => {
    const { id, groups, expandedProperty, columns, loadingStatus, loadingDelay } = props;

    const [expandedRows, setExpandedRows] = useState<Dictionary<boolean>>({});
    const [data, setData] = useState([]);
    const { setScrollNeedToUpdate, scrollbarRef } = useTableScroll(data?.length);

    const expandRow = useCallback(
        (index: number, id: string) => {
            const dataTemp = data.slice();
            const group = groups.find((_group) => _group.id === id);
            const groupRowsList = group ? group[expandedProperty] : [];

            if (!expandedRows[id]) {
                dataTemp.splice(index + 1, 0, ...groupRowsList);
            } else {
                dataTemp.splice(index + 1, groupRowsList.length);
            }

            setData(dataTemp);
            setExpandedRows((prevValue: Dictionary<boolean>) => {
                const data = { ...prevValue };
                data[id] = !data[id];
                return data;
            });
            setScrollNeedToUpdate(true);
        },
        [data, groups, expandedProperty, setScrollNeedToUpdate]
    );

    useEffect(() => {
        const dataTemp = cloneDeep(groups);
        Object.keys(expandedRows).forEach((id) => {
            const index = dataTemp.findIndex((group: VesselGroup) => group.id === id);
            const group = dataTemp.find((group) => group.id === id);
            const groupRowsList = group ? group[expandedProperty] : [];

            if (expandedRows[id]) {
                dataTemp.splice(index + 1, 0, ...groupRowsList);
            }
        });

        setData(dataTemp);
    }, [groups, setData, expandedRows, expandedProperty]);

    const Row = (props: ListChildComponentProps) => {
        const { data, index, style } = props;
        return data[index] && data[index][expandedProperty] ? (
            <div
                qa-id={`flex-table--${id}--body__column--${expandedProperty}`}
                className="flex-table--body__row expandable"
                onClick={() => expandRow(index, data[index].id)}
                style={style}
            >
                <div className="flex-table--body__row-content">
                    <i className={`icon icon-chevron ${expandedRows[data[index].id] ? '' : 'right'}`} />
                    {data[index].name}
                </div>
            </div>
        ) : (
            <div className="flex-table--body__row" style={style}>
                <div className="flex-table--body__row-content">
                    {columns.map((column: TableColumn, columnIndex: number) => {
                        const className = column.className ? column.className(data[index]) : '';

                        return !column.hidden ? (
                            <div
                                qa-id={`flex-table--${id}--body__column--${column.property || columnIndex}`}
                                className={`flex-table--body__column  ${column.columnSize} ${className}`}
                                style={{ height: style?.height }}
                                key={column.title}
                            >
                                <div className="flex-table--body__column-content">
                                    {column.render ? column.render(data[index]) : data[index][column.property]}
                                </div>
                            </div>
                        ) : null;
                    })}
                </div>
            </div>
        );
    };

    return useObserver(() => {
        return (
            <div className="flex-table expanded-table">
                <div className="flex-table--head">
                    <div className="flex-table--head__row">
                        {columns.map((column: TableColumn, columnIndex: number) => {
                            const className = column.className ? column.className() : '';

                            return !column.hidden ? (
                                <div
                                    qa-id={`flex-table--${id}--head__column--${column.property || columnIndex}`}
                                    className={`flex-table--head__column ${column.columnSize} ${className}`}
                                    key={column.title}
                                >
                                    <div className="flex-table--head__column-content">{column.title}</div>
                                </div>
                            ) : null;
                        })}
                    </div>
                </div>
                <LoadPlaceholder
                    loadingStatus={loadingStatus || LoadingStatusType.isLoaded}
                    defaultDelay={loadingDelay}
                >
                    <div className="flex-table--body" qa-id={id}>
                        <AutoSizer className={id} onResize={() => setScrollNeedToUpdate(true)}>
                            {({ height, width }: { height: number; width: number }) => (
                                <FixedSizeList
                                    className="list"
                                    height={height}
                                    itemCount={data.length}
                                    itemSize={48}
                                    width={width}
                                    ref={scrollbarRef}
                                    itemData={data}
                                >
                                    {(props) => <Row {...props} />}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    </div>
                </LoadPlaceholder>
            </div>
        );
    });
};
