import React, { useMemo, useState } from 'react';
import { useObserver } from 'mobx-react-lite';

import { ApiClient } from '@modules/api-clients/models';
import { DateUtil } from '@modules/shared/utils';
import { CopyField } from '@modules/core/components';
import { ConfirmModal, Tooltip } from '@modules/shared/components';
import { usePermissionsState } from '@modules/shared/hooks';

import './ApiClientsSecret.scss';

interface Props {
    apiClient: ApiClient;
    refreshSecret: (value: ApiClient) => Promise<void>;
}

export const ApiClientsSecret = (props: Props) => {
    const { apiClient, refreshSecret } = props;
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const { canEditApiClients } = usePermissionsState();

    const handleSubmit = async () => {
        setIsButtonDisabled(true);
        await refreshSecret(apiClient);
        setIsButtonDisabled(false);
        setIsConfirmModalVisible(false);
    };

    const secretText = useMemo(() => apiClient.secret?.value || 'Hidden', [apiClient]);

    const tooltipText = useMemo(() => {
        let text = 'Client secret values cannot be viewed';
        if (canEditApiClients) {
            text +=
                ', except for immediately after refreshing. Be sure to save the secret when refreshed before leaving the page';
        }
        return `${text}.`;
    }, [canEditApiClients]);

    return useObserver(() => (
        <main className="api-clients-secret details-page details-page__inner">
            <section className="details-page-body">
                <div className="row row-secret">
                    <div className="row-title">Value</div>
                    <div className="row-value">
                        <CopyField copyText={secretText} hidden={!apiClient.secret?.value}>
                            {secretText}
                        </CopyField>
                        {!apiClient.secret?.value && (
                            <Tooltip content={tooltipText}>
                                <i className="icon icon-attention-grey" />
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="row-title">Expires</div>
                    <div className="row-value">
                        {DateUtil.formatDate(apiClient.secret?.expirationDate, 'DD MMMM YYYY HH:mm')}
                    </div>
                </div>
            </section>
            {canEditApiClients && (
                <section className="details-page-footer">
                    <button
                        disabled={isButtonDisabled}
                        type="submit"
                        className="button primary"
                        onClick={() => setIsConfirmModalVisible(true)}
                    >
                        REFRESH
                    </button>
                </section>
            )}
            <ConfirmModal
                modalTitle="REFRESH SECRET"
                modalContentText={`Do you want to refresh api client secret?`}
                submitButtonTitle="REFRESH"
                modalIsOpen={isConfirmModalVisible}
                onCloseModal={setIsConfirmModalVisible}
                onSubmit={handleSubmit}
            />
        </main>
    ));
};
