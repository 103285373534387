import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SearchInput } from '@fos/eniram-web-common-ui/dist/components/SearchInput';
import { VesselsTable } from '@modules/fleet/components';
import { Vessel } from '@modules/fleet/models';
import { Organization } from '@modules/organizations/models';
import { FleetStore } from '@modules/fleet/store';
import { LoadingStatusType } from '@modules/core/models';
import { LoadPlaceholder } from '@modules/core/components';
import { Dictionary } from '@modules/shared/models';

import './VesselsPage.scss';

interface Props {
    vesselsPageState: Dictionary<any>;
    isEditAllowed?: boolean;
    ownerOrganization?: Organization;
    isVesselDeletable?: (vessel: Vessel) => boolean;
    fleetStore: FleetStore;
}

export const VesselsPage = observer<Props>((props) => {
    const { fleetStore, isEditAllowed, ownerOrganization, isVesselDeletable } = props;

    const { loadingStatusState } = fleetStore;

    const [loadingVessels, setLoadingVessels] = useState<LoadingStatusType>(LoadingStatusType.isLoading);

    const { setDeleteModalVisibility, selectEntity, entities, sortEntities, query, setQuery } = props.vesselsPageState;

    useEffect(() => {
        if (
            loadingStatusState &&
            (loadingStatusState.status === LoadingStatusType.isLoaded ||
                loadingStatusState.status === LoadingStatusType.isError)
        ) {
            setLoadingVessels(LoadingStatusType.isLoaded);
        }
    }, [loadingStatusState?.status]);

    return (
        <div className="vessels-page">
            <main className="card">
                <LoadPlaceholder loadingStatus={LoadingStatusType.isLoaded}>
                    <header className="card-header">
                        <div className="card-header-block">
                            <div className="padding-controls padding-bottom-controls" qa-id={`vessels-page-title`}>
                                Vessels ({entities.length})
                            </div>
                        </div>
                        <div className="card-header-controls">
                            <div className="search-controls">
                                <SearchInput
                                    onChange={(value: string) => setQuery(value)}
                                    autofocus={false}
                                    placeholder="Search by Name, DMS ID, IMO, CMS ID"
                                    search={query}
                                    qaId="vessel-search"
                                />
                            </div>
                        </div>
                    </header>
                    <section className="card-body">
                        {
                            <VesselsTable
                                id="vessels_table"
                                vessels={entities}
                                deletable={isVesselDeletable}
                                editable={(vessel: Vessel) => vessel.createdManually}
                                loadingStatus={loadingVessels}
                                loadingDelay={100}
                                ownerOrganization={ownerOrganization}
                                sortEntities={sortEntities}
                                setDeleteModalVisibility={isEditAllowed ? setDeleteModalVisibility : null}
                                setSelectedVessel={selectEntity}
                            />
                        }
                    </section>
                </LoadPlaceholder>
            </main>
        </div>
    );
});
