import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { AjvError } from '@rjsf/core';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import differenceBy from 'lodash/differenceBy';
import { useRolesState } from '@modules/shared/hooks';
import { DataSourceStore } from '@modules/core/store/DataSourceStore';
import { Dictionary } from '@modules/shared/models';
import { LoadPlaceholder } from '@modules/core/components';
import { DataSourceSchema } from '@modules/core/models';
import { LoadingStatusType, Option as OptionDefault } from '@modules/core/models';
import { FormComponent } from '@modules/core/components/schemaForm/Form';
import { VesselStore } from '@modules/fleet/store';
import { DataSource } from '@modules/fleet/models';

import './DataSourceFormPage.scss';

interface Props {
    vesselStore: VesselStore;
    dataSourceStore: DataSourceStore;
    vesselId: string;
    dataSources: DataSource[];
}

interface Option extends OptionDefault {
    codes?: string[];
    isDisabled?: boolean;
}

export const DataSourceFormPage = (props: Props) => {
    const { isSupportUser } = useRolesState();
    const { vesselStore } = props;
    const { dataSourceStore } = props;
    const { dataSourceSchemas, loadingStatusState } = dataSourceStore;
    const [formData, setFormData] = useState<Dictionary<any>>(null);
    const [defaultFormData, setDefaultFormData] = useState<Dictionary<any>>(null);
    const [errors, setErrors] = useState<Dictionary<Partial<AjvError>[]>>({});
    const [typeOptions, setTypeOptions] = useState<Option[]>([]);
    const [codeOptions, setCodeOptions] = useState<Dictionary<DataSourceSchema[]>>({});

    const readOnlyMode = useMemo(() => isSupportUser, [isSupportUser]);

    useEffect(() => {
        dataSourceStore.loadDataSourceSchemas();
    }, []);

    useEffect(() => {
        if (dataSourceSchemas) {
            initSchema(dataSourceSchemas);
        }
    }, [dataSourceSchemas]);

    useEffect(() => {
        if (props.dataSources?.length) {
            initForm(props.dataSources, true);
        }
    }, [vesselStore.vessel, props.dataSources, dataSourceSchemas]);

    const initSchema = (schemas: DataSourceSchema[]) => {
        const types = transformTypeOptions(schemas);
        setTypeOptions(types);
        setCodeOptions(groupBy(schemas, 'type'));
    };

    const initForm = (dataSources: DataSource[], isStartInit?: boolean) => {
        if (!!dataSources.length) {
            const forms = transformToFormData(dataSources);
            let updatedFormData = {};
            // update the formData with saving the position dataSource in the completed form
            formData &&
                Object.keys(formData).forEach((formKey) => {
                    if (forms[formKey]) {
                        updatedFormData = {
                            ...updatedFormData,
                            [formKey]: forms[formKey]
                        };
                    }
                });
            setFormData({ ...updatedFormData, ...forms });
            if (isStartInit) {
                setDefaultFormData({ ...updatedFormData, ...forms });
            }
        } else {
            return setFormData(null);
        }
    };

    const transformToFormData = (dataSources: DataSource[]): Dictionary<any> => {
        return dataSources.reduce((forms: Dictionary<any>, el) => {
            const type: DataSourceSchema = dataSourceSchemas.find((s: DataSourceSchema) => s.code === el.code);
            const { parameters } = el;
            return {
                ...forms,
                [el.code]: {
                    ...forms[el.code],
                    ...Object.keys(type?.jsonSchema.properties || []).reduce((acc, p) => {
                        return {
                            ...acc,
                            [p]: parameters[p]
                        };
                    }, {}),
                    type: type?.type,
                    code: el.code,
                    id: el?.id
                }
            };
        }, {});
    };

    const transformTypeOptions = (types: any[]): Option[] => {
        const groupsType = groupBy(types, 'type');
        return Object.keys(groupsType).reduce(
            (acc, type) => [
                ...acc,
                {
                    value: type,
                    label: type,
                    codes: groupsType[type]?.map((el) => el.code)
                }
            ],
            []
        );
    };

    const updateForm = (data: Dictionary<any>, prevCode: string, prevType?: string) => {
        // description: If change the newly added form
        if (formData['new'] && !formData[prevCode]) {
            const object = formData;
            delete object.new;
            return setFormData({
                ...object,
                [data?.code || 'new']: {
                    type: data.type,
                    code: data.code
                }
            });
        }

        // description: If mutable form with existing code and code changes (overwrite key in state)
        // description: overwrite the keys to update the form correctly
        if (formData && formData[prevCode] && data.code !== prevCode) {
            return setFormData({
                ...Object.keys(formData).reduce((acc: any, key: string) => {
                    if (key === prevCode) {
                        return {
                            ...acc,
                            [data.code]: data
                        };
                    }
                    return {
                        ...acc,
                        [key]: formData[key]
                    };
                }, {})
            });
        }

        // description: If the current type of the form does not match the new type
        // and the previous conditions are not valid
        // the code is set automatically, since the code is a unique key.
        // It will be overwritten if the user selects the appropriate code in Select Input
        if (data?.type !== prevType) {
            const options = codeOptions[data?.type] || [];
            const newFormData: Dictionary<any> = {};
            Object.keys(formData).forEach((code) => {
                if (code === prevCode) {
                    newFormData[options[0].code] = {
                        type: options[0].type,
                        code: options[0].code
                    };
                } else {
                    newFormData[code] = formData[code];
                }
            });
            return setFormData(newFormData);
        }

        // description: If the previous code does not match the current one, we rewrite the form with all the changed data
        if (data.code === prevCode) {
            return setFormData({
                ...formData,
                [prevCode]: { ...formData[prevCode], ...data }
            });
        }
    };

    const getOptions = (optionsGroups: Dictionary<any> = {}, type: string = ''): Option[] => {
        if (type && optionsGroups[type]) {
            const optionsList = optionsGroups[type].reduce((acc: Option[], el: any) => {
                return [
                    ...acc,
                    {
                        value: el.code,
                        label: el.title,
                        codes: [el.code],
                        isDisabled: formData[el.code]
                    }
                ];
            }, []);
            return uniqBy(optionsList, 'value') as any[];
        }
        return [];
    };

    const onAdd = () => {
        setFormData({ ...formData, new: { type: '', code: '' } });
    };

    const onSubmit = async () => {
        const data: DataSource[] = Object.keys(formData || []).reduce((acc: DataSource[], key) => {
            const { code, id, ...parameters } = formData[key];
            delete parameters.type;
            return [
                ...acc,
                {
                    id,
                    code,
                    parameters
                }
            ];
        }, []);

        const deletedDataSources = differenceBy(props.dataSources, data, 'id');

        if (deletedDataSources) {
            await Promise.all(
                deletedDataSources.map((ds: DataSource) => {
                    return dataSourceStore.deleteDataSource(props.vesselId, ds);
                })
            );
        }

        await Promise.all(
            data.map((ds: DataSource) => {
                const dataSource = props.dataSources?.find((d: DataSource) => d.id === ds.id);
                if (dataSource) {
                    if (!isEqual(dataSource.parameters, ds.parameters)) {
                        return dataSourceStore.updateDataSource(props.vesselId, {
                            ...ds,
                            id: ds.id
                        });
                    }
                } else {
                    return dataSourceStore.createDataSource(props.vesselId, ds);
                }
            })
        );

        await vesselStore.getVesselById(props.vesselId);
    };

    const onCancel = () => {
        setFormData(defaultFormData);
    };

    const getSchema = (type: string, code: string) => {
        if (dataSourceSchemas) {
            const dataSourceSchema: any = find(dataSourceSchemas, (obj: any) => {
                return obj.type === type && obj.code === code;
            });
            return dataSourceSchema?.jsonSchema;
        }
    };

    const onDeleteForm = (code: string) => {
        const forms = formData;
        delete forms[code];
        if (isEmpty(forms)) {
            return setFormData(null);
        }
        setFormData({ ...forms });
    };

    const areFieldsUpdated = useCallback(
        (newParams: Dictionary<any>, propsParams: DataSource[] = []) => {
            let isSame = false;

            if (!newParams && !propsParams.length) {
                return isSame;
            }

            if (newParams && propsParams.every((pp) => newParams[pp.code])) {
                isSame = Object.keys(newParams)?.every((key: string) => {
                    if (propsParams) {
                        const { id: propsId, ...pParams } = transformToFormData(propsParams)[key] || {};
                        const { id, ...params } = newParams[key] || {};
                        if (id === propsId) {
                            return isEqual(pParams, params);
                        }
                    }
                    return false;
                });
            }
            return !isSame;
        },
        [formData]
    );

    const isSaveDisabled = useMemo(() => {
        const formChanged = !areFieldsUpdated(formData, props.dataSources);
        if (formData) {
            const isEveryRequiredFieldNotEmpty = Object.keys(formData).every((formCode) => {
                const schema = dataSourceSchemas.find((s) => s.code === formData[formCode]?.code);
                const requiredFields = schema?.jsonSchema.required || [];
                const form = formData[formCode];
                return requiredFields.every((field: string) => {
                    return form[field];
                });
            });
            const isEveryFieldValid = Object.keys(formData).every((formCode: string) => {
                const formErrors = errors[formCode];
                return !formErrors?.length;
            });
            return (
                readOnlyMode || !isEveryRequiredFieldNotEmpty || !isEveryFieldValid || !!formData['new'] || formChanged
            );
        }
        return formChanged;
    }, [formData, props.dataSources, errors]);

    const isCancelDisabled = useMemo(() => {
        return readOnlyMode || !areFieldsUpdated(formData, props.dataSources);
    }, [formData, props.dataSources, errors]);

    const isAddDisabled = useMemo(() => {
        return readOnlyMode || (formData && !!formData['new']);
    }, [formData]);

    const isAddHidden = useMemo(() => {
        const everyCodesUsed = typeOptions.reduce((acc: boolean[], type: Option) => {
            return [...acc, type?.codes.every((code) => formData && formData[code])];
        }, []);
        return !everyCodesUsed.some((code) => !code);
    }, [formData]);

    const isDeleteDisabled = useMemo(() => {
        return readOnlyMode || !formData;
    }, [formData]);

    const FormFooter = () => {
        return (
            <footer className="card-footer">
                <button className="button primary" onClick={onSubmit} disabled={isSaveDisabled}>
                    SAVE
                </button>
                <button className="button secondary" onClick={onCancel} disabled={isCancelDisabled}>
                    CANCEL
                </button>
            </footer>
        );
    };

    const formBlock = (form?: any, isLast?: boolean) => {
        const enumType = typeOptions;
        const enumCode = getOptions(codeOptions, form?.type);
        const schema = form && getSchema(form?.type, form?.code);
        return (
            <div className="data-source-form" key={form?.type + form?.code}>
                <FormComponent
                    qaId={`${form?.type ? form?.type + '-' : ''}${form?.code ? form?.code + '-' : ''}data-source`}
                    formData={form}
                    schema={{
                        type: 'object',
                        required: schema?.required,
                        properties: {
                            type: {
                                title: 'Type',
                                type: 'string',
                                default: form?.type,
                                enum: enumType,
                                disabled: defaultFormData && !!defaultFormData[form?.code]?.type
                            },
                            code: {
                                title: 'Name',
                                type: 'string',
                                default: form?.code,
                                enum: enumCode,
                                disabled: defaultFormData && !!defaultFormData[form?.code]?.code
                            },
                            ...schema?.properties
                        }
                    }}
                    onSubmit={onSubmit}
                    setErrors={(errs) => setErrors({ ...errors, [form.code]: errs })}
                    onDeleteForm={() => onDeleteForm(form?.code || 'new')}
                    onChange={(data: any) => updateForm({ ...data, id: form.id }, form.code, form.type)}
                    readOnlyMode={readOnlyMode}
                    isHiddenSubmit
                />
                <div className="data-source__footer">
                    {!readOnlyMode && !isAddHidden && isLast && (
                        <div className="add-btn">
                            <button className={`button secondary`} onClick={onAdd} disabled={isAddDisabled}>
                                <div className={`icon ${!isAddDisabled ? 'icon-plus' : 'icon-plus-gray'}`} />
                                ADD
                            </button>
                        </div>
                    )}
                    {!readOnlyMode && (
                        <button
                            className="button secondary trash"
                            onClick={() => onDeleteForm(form?.code || 'new')}
                            disabled={isDeleteDisabled}
                        >
                            DELETE
                        </button>
                    )}
                </div>
            </div>
        );
    };

    const formKeys = useMemo(() => Object.keys(formData || []), [formData]);

    return useObserver(() => (
        <main className="card card-data-sources" qa-id="data-sources">
            <LoadPlaceholder loadingStatus={loadingStatusState.status || LoadingStatusType.isLoaded} defaultDelay={300}>
                <section className="card-body">
                    <div className="form-body">
                        {!formData ? (
                            <div className="empty-block">
                                <div className="empty-block__text">Data sources not added</div>
                                {!readOnlyMode && formKeys.length < 1 && (
                                    <div className="add-btn">
                                        <button className={`button secondary`} onClick={onAdd} disabled={isAddDisabled}>
                                            <div className="icon icon-plus" />
                                            ADD
                                        </button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            formKeys.reduce((acc: any[], key: string, index) => {
                                if (formData[key]) {
                                    return [...acc, formBlock(formData[key], index === formKeys.length - 1)];
                                }
                                return acc;
                            }, [])
                        )}
                    </div>
                </section>
                {!readOnlyMode && <FormFooter />}
            </LoadPlaceholder>
        </main>
    ));
};
