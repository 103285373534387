import { observable, computed, flow, makeObservable } from 'mobx';

import { Organization, CreatedManuallyVessel } from '../models';
import { alertStore } from '@modules/core/store/AlertStore';
import { LoadingStatusState } from '@modules/core/models';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';

export default class OrganizationStore {
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            loadingStatusState: observable,
            loadingOrganizationStatus: computed
        });
        this.deleteOrganization = this.deleteOrganization.bind(this);
        this.addOrganization = this.addOrganization.bind(this);
        this.editOrganization = this.editOrganization.bind(this);
        this.deleteOrganization = this.deleteOrganization.bind(this);
    }

    getOrganizationById = flow(function* (this: OrganizationStore, orgId: string) {
        const { api, errorMessageHandler } = apiStore.api.organizations.getOrganization;
        try {
            this.loadingStatusState.load();
            const organization = yield HttpClient.TCMS.GET(api(orgId));
            this.loadingStatusState.loadSuccess();
            return organization;
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    get loadingOrganizationStatus() {
        return this.loadingStatusState;
    }

    addOrganizationVessel = flow(function* (id: string, data: CreatedManuallyVessel) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.vessels.postOrganizationVessel;
        try {
            yield HttpClient.TCMS.POST(api(id), data);
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    updateOrganizationVessel = flow(function* (id: string, vesselId: string, data: CreatedManuallyVessel) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.vessels.putOrganizationVessel;
        try {
            yield HttpClient.TCMS.PUT(api(id, vesselId), data);
            alertStore.success(successMessage);
            return vesselId;
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    deleteOrganizationVessel = flow(function* (vesselId: string, orgId: string) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.vessels.deleteOrganizationVessel;
        try {
            yield HttpClient.TCMS.DELETE(api(vesselId, orgId));
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    addOrganization = flow(function* (this: OrganizationStore, data: Organization) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.organizations.postOrganizations;
        try {
            const organization = yield HttpClient.TCMS.POST(api(), data);
            alertStore.success(successMessage);
            return organization;
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    editOrganization = flow(function* (this: OrganizationStore, data: Organization) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.organizations.putOrganization;
        try {
            yield HttpClient.TCMS.PUT(api(data.id), data);
            alertStore.success(successMessage);
            return data;
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    deleteOrganization = flow(function* (this: OrganizationStore, id: string) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.organizations.deleteOrganization;
        try {
            yield HttpClient.TCMS.DELETE(api(id));
            alertStore.success(successMessage);
            return id;
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });
}
