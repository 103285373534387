import { FormField } from '../models';
import { hasOnlySignificantDigitsValidator, isRequiredLengthValidator, isRequiredValidator } from '../utils';

interface FormFieldsTemplate {
    [key: string]: FormField;
}

export const AccountsFormFields: FormFieldsTemplate = {
    email: {
        type: 'inputEmail',
        label: 'Login email',
        validate: true
    },
    firstName: {
        type: 'input',
        label: 'First name',
        validate: true
    },
    lastName: {
        type: 'input',
        label: 'Last name',
        validate: true
    },
    position: {
        type: 'input',
        label: 'User position',
        validate: true
    },
    roles: {
        type: 'multiselect',
        label: 'Roles',
        validate: true
    }
};

export const AccountsFormEditFields: FormFieldsTemplate = {
    email: {
        type: 'inputEmail',
        label: 'Login email',
        validate: true,
        disabled: true
    },
    firstName: {
        type: 'input',
        label: 'First name',
        validate: true
    },
    lastName: {
        type: 'input',
        label: 'Last name',
        validate: true
    },
    position: {
        type: 'input',
        label: 'User position',
        validate: true
    },
    roles: {
        type: 'multiselect',
        label: 'Roles',
        validate: true
    }
};

export const VesselFormFields: FormFieldsTemplate = {
    name: {
        type: 'input',
        label: 'Name',
        validate: true
    },
    mmsi: {
        type: 'input',
        label: 'MMSI',
        validate: true,
        validators: [
            [isRequiredValidator],
            [hasOnlySignificantDigitsValidator, { useTrim: true }],
            [isRequiredLengthValidator, { length: 9, useTrim: true }]
        ]
    },
    imoNumber: {
        type: 'input',
        label: 'IMO',
        validate: true,
        validators: [
            [hasOnlySignificantDigitsValidator, { useTrim: true }],
            [isRequiredLengthValidator, { length: 7, useTrim: true }]
        ]
    }
};

export const OrganizationFormFields: FormFieldsTemplate = {
    name: {
        qaId: 'organization-name',
        type: 'input',
        label: 'Name',
        validate: true
    },
    isInternal: {
        qaId: 'organization-is-internal-flag',
        type: 'checkbox',
        label: 'Internal usage',
        validate: false
    }
};

export const CreateRoleFormFields: FormFieldsTemplate = {
    name: {
        type: 'input',
        label: 'Role name',
        validate: true
    },
    description: {
        type: 'input',
        label: 'Description'
    },
    permissions: {
        type: 'selectcombobox',
        validate: true
    }
};

export const VesselSharesFormFields: FormFieldsTemplate[] = [
    {
        partnerCode: {
            type: 'input',
            label: '',
            validate: true,
            disabled: true
        }
    },
    {
        partnerName: {
            type: 'input',
            label: 'Now sharing with:',
            disabled: true
        },
        vessels: {
            type: 'multilistcombobox',
            label: '',
            validate: true
        }
    }
];

export const AppClientFormFields: FormFieldsTemplate = {
    name: {
        type: 'input',
        label: 'Name',
        validate: true
    }
};

export const AppClientFormEditFields: FormFieldsTemplate = {
    name: {
        type: 'input',
        label: 'Name',
        validate: true,
        disabled: true
    },
    roles: {
        type: 'multiselect',
        label: 'Roles',
        validate: true,
        placeholders: {
            empty: 'Api Client has no roles for assignment',
            noAssigned: 'Api Client has no roles, edit permissions'
        }
    },
    variableGroups: {
        type: 'multiselect',
        label: 'Variable Groups',
        // Remove when BE will be ready [FOSWEB-11723]
        hidden: true
    }
};
