import { flow } from 'mobx';

import { Product } from '@modules/connected-products/models';
import { alertStore } from '@modules/core/store/AlertStore';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';
import { ConnectedProductsStore } from './ConnectedProductsStore';

export class ConnectedProductsStoreAll extends ConnectedProductsStore {
    constructor() {
        super();
        this.loadProducts = this.loadProducts.bind(this);
        this.loadVesselProducts = this.loadVesselProducts.bind(this);
        this.overrideVesselOptions = this.overrideVesselOptions.bind(this);
    }

    loadProducts = flow(function* (this: ConnectedProductsStoreAll, isSuperUser: boolean) {
        const { api, errorMessageHandler } = apiStore.api.products.getConnectedProducts;
        try {
            this.products = [];
            this.loadingStatusState.load();
            const availableProducts: Product[] = yield HttpClient.TCMS.GET(api(), {
                includeExpiredOptions: true
            });

            if (isSuperUser) {
                availableProducts.forEach((product) => {
                    if (!product.options) {
                        product.options = [];
                    }
                });
            } else {
                yield this.mergeOptions(availableProducts);
            }

            this.products = availableProducts;
            this.loadingStatusState.loadSuccess();
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    loadVesselProducts = flow(function* (this: ConnectedProductsStoreAll, id: string) {
        const { api, errorMessageHandler } = apiStore.api.products.getConnectedProductsForVessels;
        try {
            this.products = [];
            this.loadingStatusState.load();
            const availableProducts = yield HttpClient.TCMS.GET(api(id), {
                includeExpiredOptions: true
            });

            yield this.mergeOptions(availableProducts);
            this.products = availableProducts;
            this.loadingStatusState.loadSuccess();
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    overrideVesselOptions = flow(function* (
        this: ConnectedProductsStoreAll,
        vesselId: string,
        productCode: string,
        optionCodes: any[]
    ) {
        const { api, errorMessageHandler } = apiStore.api.products.overrideOptions;
        try {
            yield HttpClient.TCMS.PUT(api(vesselId, productCode), optionCodes);
            this.products = this.overrideOptions(productCode, optionCodes, this.products);
            alertStore.success({ message: 'Options successfully updated' });
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });
}
