import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, VariableSizeList } from 'react-window';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

import { Dictionary, SortOrderState, TableColumn } from '@modules/shared/models';
import { EntityTableCheckbox } from '../table-checkbox/EntityTableCheckbox';
import { SortOrderType } from '@modules/shared/constants';
import { LoadingStatusType } from '@modules/core/models';
import { LoadPlaceholder } from '@modules/core/components';
import { useTableScroll } from '@modules/shared/hooks';
import { CoreUtil, SortingUtils } from '@modules/core/utils';

interface EntityRowWrapperProps {
    data: any;
    index: number;
    style: any;
}

interface CustomIconControl {
    icon: string;
    callback: (...args: any) => boolean;
}

interface Props {
    id: string;
    listOfEntities: any[];
    columns: TableColumn[];
    setCurrentEntity: any;
    currentEntity?: any;
    loadingStatus?: LoadingStatusType;
    loadingDelay?: number;
    alreadySelectedIds?: Dictionary<boolean>;
    disabledAndSelectedIds?: Dictionary<boolean>;
    selectable?: boolean;
    isCalcSize?: boolean;
    isUnlinkAvailable?: boolean;
    selectableAll?: boolean;
    isArchiveAvailable?: boolean; // ToDo will be removed after adjustments in tables
    customIconControls?: CustomIconControl[];
    hiddenControls?: (data?: any) => boolean;
    deletable?: (...args: any) => boolean;
    editable?: (...args: any) => boolean;
    sortEntities?: (...args: any) => void;
    setEditModalVisibility?: (state: boolean) => any;
    setDeleteModalVisibility?: (state: boolean) => any;
    selectedEntity?: any[];
    setSelectedEntities?: (state: any[]) => void;
    setAlreadySelectedVesselsIds?: (state: any) => void;
    onSelectEntity?: (entityId: string, isSelected: boolean, data?: any) => void;
    getRowHeight?: (index: number) => number;
    setRowHeight?: (index: number, size: any) => void;
    itemHeightMap?: Dictionary<number>;
    className?: string;
}

export const EntityTable = (props: Props) => {
    const {
        listOfEntities,
        isCalcSize,
        columns,
        sortEntities,
        setCurrentEntity,
        currentEntity,
        setEditModalVisibility,
        setDeleteModalVisibility,
        setAlreadySelectedVesselsIds,
        isArchiveAvailable,
        isUnlinkAvailable,
        customIconControls,
        hiddenControls,
        deletable,
        editable,
        selectable,
        selectableAll = selectable,
        id,
        alreadySelectedIds,
        disabledAndSelectedIds = {},
        loadingStatus,
        loadingDelay = 0,
        selectedEntity,
        setSelectedEntities,
        className = ''
    } = props;

    const editEntity = (entity: any) => {
        setCurrentEntity(entity);
        setEditModalVisibility(true);
    };

    const deleteEntity = (entity: any) => {
        setCurrentEntity(entity);
        setDeleteModalVisibility(true);
    };

    const [sortOrder, setSortOrder] = useState<SortOrderState>({
        property: '',
        value: SortOrderType.None
    });

    const tableLength = useObserver(() => listOfEntities.length);

    const sortTable = (property: string) => {
        if (!property || !sortEntities) {
            return;
        }

        let value = SortOrderType.Asc;

        if (property !== sortOrder.property) {
            setSortOrder({
                property: property,
                value: value
            });
        } else {
            value = sortOrder.value === SortOrderType.Asc ? SortOrderType.Des : SortOrderType.Asc;
            setSortOrder({
                property: property,
                value: value
            });
        }

        sortEntities(property, value);
        reCalculateTableSizes(true, loadingDelay);
    };

    const chevronClass = (property: string): string => {
        if (sortOrder.value === SortOrderType.None) {
            return '';
        }

        return sortOrder.property === property && sortOrder.value === SortOrderType.Asc ? 'up' : '';
    };

    const selectedClass = useCallback(
        (item: any): string => {
            const rowId = item?.id || item?.code;
            const currentId = currentEntity?.id || currentEntity?.code;
            return rowId && rowId === currentId ? 'selected' : '';
        },
        [currentEntity, listOfEntities]
    );

    const [selectedEntitiesDictionary, setSelectedEntitiesDictionary] = useState<Dictionary<boolean>>({});
    const [isAllEntitiesSelected, setIsAllEntitiesSelected] = useState<boolean>(false);
    const [loadingTableFormation, setLoadingTableFormation] = useState<LoadingStatusType>(LoadingStatusType.isLoaded);
    const [rowSizes, setRowSizes] = useState<Dictionary<number>>(props.itemHeightMap);
    const { setScrollNeedToUpdate, scrollbarRef, ref } = useTableScroll(listOfEntities?.length);
    const [debounce] = useState(() => CoreUtil.debounce());

    useEffect(() => {
        if (isCalcSize && props.itemHeightMap) {
            reCalculateTableSizes(true, 0);
        }
    }, [props.itemHeightMap, listOfEntities]);

    const reCalculateTableSizes = useCallback(
        (isUpdate: boolean, delay = 200) => {
            const rowsSizesIsUpdated = !isEqual(rowSizes, props.itemHeightMap);
            if (rowsSizesIsUpdated) {
                setLoadingTableFormation(LoadingStatusType.isLoading);
                debounce(() => {
                    setScrollNeedToUpdate(isUpdate);
                    setRowSizes(props.itemHeightMap);
                    ref?.resetAfterIndex(0);
                    setLoadingTableFormation(LoadingStatusType.isLoaded);
                }, delay);
            }
        },
        [props.itemHeightMap, listOfEntities, sortOrder]
    );

    const onSelectEntity = (isChecked: boolean, entity: any) => {
        if (isArray(selectedEntity)) {
            const entitiesToSelect = isChecked
                ? selectedEntity.filter((item) => item.id !== entity.id)
                : [...selectedEntity, entity];

            setSelectedEntities(entitiesToSelect);
        }

        const entitiesDictionaryToSelect = isChecked
            ? omit(selectedEntitiesDictionary, entity.id)
            : { ...selectedEntitiesDictionary, [entity.id]: true };

        setSelectedEntitiesDictionary(entitiesDictionaryToSelect);

        if (props.onSelectEntity) {
            props.onSelectEntity(entity.id, selectedEntitiesDictionary[entity.id], entity);
        }
    };

    const onSelectAllEntities = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;

        if (isArray(selectedEntity)) {
            const entitiesToSelect = target.checked
                ? [...new Set([...selectedEntity, ...listOfEntities])]
                : selectedEntity.filter((item) => !listOfEntities.some((elem) => elem.id === item.id));
            setSelectedEntities(entitiesToSelect);
        }

        let entitiesToSelect = { ...selectedEntitiesDictionary };

        if (target.checked) {
            listOfEntities.forEach((entity) => {
                if (!disabledAndSelectedIds || !disabledAndSelectedIds[entity.id]) {
                    entitiesToSelect = {
                        ...entitiesToSelect,
                        [entity.id]: target.checked
                    };
                }
            });
            setSelectedEntitiesDictionary(entitiesToSelect);
            setAlreadySelectedVesselsIds && setAlreadySelectedVesselsIds(entitiesToSelect);
        } else {
            const listOfEntitiesIDs = listOfEntities.map((item) => item.id);
            setSelectedEntitiesDictionary(omit(entitiesToSelect, listOfEntitiesIDs));
            setAlreadySelectedVesselsIds && setAlreadySelectedVesselsIds({});
        }

        setIsAllEntitiesSelected(target.checked);
    };

    useEffect(() => {
        if (!selectedEntity?.length) {
            setSelectedEntitiesDictionary({});
        }
    }, [selectedEntity, setSelectedEntitiesDictionary]);

    useEffect(() => {
        if (!isEmpty(alreadySelectedIds)) {
            setSelectedEntitiesDictionary({
                ...alreadySelectedIds
            });
        }
    }, [alreadySelectedIds, setSelectedEntitiesDictionary]);

    useEffect(() => {
        if (selectable && listOfEntities.length > 0) {
            const isAllEntitiesSelected = listOfEntities.every((elem) =>
                Object.keys(selectedEntitiesDictionary).some((entityId) => entityId === elem.id)
            );

            setIsAllEntitiesSelected(isAllEntitiesSelected);
        } else {
            setIsAllEntitiesSelected(false);
        }
    }, [listOfEntities, selectable, disabledAndSelectedIds, selectedEntitiesDictionary]);

    /**
     * row with virtual scroll
     * @param props values set by react-window
     */
    const EntityRowWrapper = (prop: EntityRowWrapperProps) => {
        const { data, index, style } = prop;

        const combinedData = { ...selectedEntitiesDictionary, ...disabledAndSelectedIds };

        return SortingUtils.sortAllTagToFirstRow(data).map((item: any, i: number) => {
            const isChecked = combinedData[item.id];

            return i === index ? (
                <div
                    qa-id={`${id}__table-row--${index}`}
                    className={`flex-table--body__row ${selectedClass(item)} dividerBottom`}
                    key={i}
                    style={style}
                >
                    <div className="flex-table--body__row-content">
                        <EntityTableCheckbox
                            id={`tbl_checkbox_${id}_${item.id}`}
                            containerClass="flex-table--body__column"
                            isChecked={isChecked}
                            isHidden={selectable}
                            disabled={disabledAndSelectedIds && !!disabledAndSelectedIds[item.id]}
                            handleChange={() => onSelectEntity(isChecked, item)}
                        />

                        {columns.map((column: TableColumn, columnIndex: number) => {
                            const className = column.className ? column.className(item) : '';
                            return !column.hidden ? (
                                <div
                                    qa-id={`flex-table--${id}--body__column--${column.property || columnIndex}`}
                                    className={`flex-table--body__column ${column.columnSize} ${className}`}
                                    key={column.title}
                                    onClick={() => (column.onClick ? column.onClick(item) : null)}
                                    ref={(el) => {
                                        if (el && isCalcSize) {
                                            if (props.getRowHeight(index) !== el.getBoundingClientRect().height) {
                                                props.setRowHeight(index, el.getBoundingClientRect().height);
                                            }
                                        }
                                    }}
                                >
                                    <span className="flex-table--body__column-content">
                                        {column.render ? column.render(item, index) : item[column.property]}
                                        {column.isEdit && (
                                            <div className="flex-table--body__controls column-2">
                                                {editable(item) && (
                                                    <i className="icon icon-edit" onClick={() => editEntity(item)} />
                                                )}
                                            </div>
                                        )}
                                    </span>
                                </div>
                            ) : null;
                        })}
                        {!hiddenControls(item) ? (
                            <div
                                className={`column-2 flex-table--body__controls ${
                                    columns?.some(
                                        (col) => col.className && col.className(item)?.includes('dividerBottom')
                                    ) && 'dividerBottom'
                                }`}
                            >
                                {customIconControls?.map((control, index) => (
                                    <i
                                        key={index}
                                        className={`icon ${control.icon}`}
                                        onClick={() => control.callback(item)}
                                    />
                                ))}
                                {!columns.some((col) => col.isEdit) && setEditModalVisibility && editable(item) && (
                                    <i className="icon icon-edit" onClick={() => editEntity(item)} />
                                )}
                                {isArchiveAvailable && <i className="icon icon-archive" />}
                                {setDeleteModalVisibility && deletable(item) && (
                                    <span className={isUnlinkAvailable ? 'alignToCenter' : ''}>
                                        <i
                                            className={`icon ${isUnlinkAvailable ? 'icon-unlink' : 'icon-garbage'}`}
                                            onClick={() => deleteEntity(item)}
                                        />
                                    </span>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null;
        });
    };

    return useObserver(() => (
        <div className={`flex-table ${isCalcSize && 'calc-size-align-content'} ${className}`}>
            <div className="flex-table--head">
                <div className="flex-table--head__row">
                    <EntityTableCheckbox
                        id={`tbl_checkbox_select_all_${id}`}
                        containerClass="flex-table--body__column"
                        isChecked={isAllEntitiesSelected}
                        isHidden={selectableAll}
                        handleChange={onSelectAllEntities}
                    />
                    {columns.map((column: TableColumn) => {
                        const className = column.className ? column.className() : '';
                        return !column.hidden ? (
                            <div
                                className={`flex-table--head__column ${column.columnSize} ${className}`}
                                key={column.title}
                                onClick={() => sortTable(column.property)}
                            >
                                <span className="flex-table--head__column-content">{column.title}</span>
                                {sortEntities ? (
                                    <i className={`icon icon-chevron ${chevronClass(column.property)}`} />
                                ) : null}
                            </div>
                        ) : null;
                    })}
                    {!hiddenControls() ? <div className="flex-table--head__controls column-2" /> : null}
                </div>
            </div>
            <LoadPlaceholder
                loadingStatus={loadingStatus || LoadingStatusType.isLoaded}
                defaultDelay={loadingDelay}
                shadowed
            >
                <div className={`flex-table--body ${isCalcSize && 'flex-table--line-break'}`} qa-id={id}>
                    {loadingTableFormation === LoadingStatusType.isLoading && (
                        <LoadPlaceholder loadingStatus={loadingTableFormation} shadowed={true} defaultDelay={0} />
                    )}
                    <AutoSizer className={id} onResize={() => reCalculateTableSizes(true)}>
                        {({ height, width }: { height: number; width: number }) => {
                            const prop = {
                                height,
                                width,
                                itemCount: tableLength,
                                itemData: listOfEntities,
                                ref: scrollbarRef,
                                className: 'list'
                            };
                            return isCalcSize ? (
                                <VariableSizeList {...prop} itemSize={(i) => props.getRowHeight(i)}>
                                    {EntityRowWrapper}
                                </VariableSizeList>
                            ) : (
                                <FixedSizeList {...prop} itemSize={40}>
                                    {EntityRowWrapper}
                                </FixedSizeList>
                            );
                        }}
                    </AutoSizer>
                </div>
            </LoadPlaceholder>
        </div>
    ));
};

EntityTable.defaultProps = {
    deletable: () => true,
    editable: () => true,
    hiddenControls: () => false,
    alreadySelectedIds: {}
};
