import { flow, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { LoadingStatusState } from '@modules/core/models';
import { HttpClient } from '@modules/core/utils';
import { alertStore } from '@modules/core/store/AlertStore';
import { apiStore } from '@modules/core/store/ApiStore';
import { VesselReference } from '../models';

export class VesselReferenceStore {
    vesselReferences: VesselReference[] = [];
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            vesselReferences: observable.ref
        });
        this.loadVesselReferences = this.loadVesselReferences.bind(this);
        this.filterVesselReferences = this.filterVesselReferences.bind(this);
    }

    loadVesselReferences = flow(function* (this: VesselReferenceStore, apiClientId: string, organizationId: string) {
        const { api, errorMessageHandler } = apiStore.api.apiClients.getOrganizationApiClientVesselReferences;
        try {
            this.loadingStatusState.load();
            this.vesselReferences = yield HttpClient.TCMS.GET(api(apiClientId, organizationId));
            this.loadingStatusState.loadSuccess();
        } catch (error: any) {
            this.vesselReferences = [];
            alertStore.error(error, errorMessageHandler);
            this.loadingStatusState.loadSuccess();
        }
    });

    filterVesselReferences = computedFn(() => {
        return this.vesselReferences;
    });
}
