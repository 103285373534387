import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Organization } from '@modules/organizations/models';
import { EntityTable, Tooltip } from '@modules/shared/components';
import { ColumnSize, TableColumn } from '@modules/shared/models';
import { SortOrderType } from '@modules/shared/constants';
import { DateUtil } from '@modules/shared/utils';
import { SortingUtils, CoreUtil } from '@modules/core/utils';
import { AvailablePermissions, LoadingStatusType } from '@modules/core/models';
import { useRolesState, usePermissionCheck } from '@modules/shared/hooks';

interface Props {
    id: string;
    organizations: Organization[];
    sortEntities?: (sortFunc: (prevItem: any, nextItem: any) => number) => void;
    setEditModalVisibility: (value: boolean) => any;
    setDeleteModalVisibility: (value: boolean) => any;
    setSelectedOrganization: (organization: Organization) => void;
    loadingStatus: LoadingStatusType;
}

export const OrganizationsTable = (props: Props) => {
    const match = useRouteMatch();
    const history = useHistory();
    const { isSupportUser } = useRolesState();

    const {
        organizations,
        setSelectedOrganization,
        setEditModalVisibility,
        setDeleteModalVisibility,
        sortEntities,
        id,
        loadingStatus
    } = props;

    const NameWithTooltip = (organization: Organization) => (
        <Tooltip content={organization.name}>{organization.name}</Tooltip>
    );

    const columns: TableColumn[] = [
        {
            title: 'ORGANIZATION NAME',
            columnSize: ColumnSize.COLUMN3,
            property: 'name',
            className: () => 'divider',
            onClick: (organization: Organization) =>
                CoreUtil.goToPage(history, `${match.path}/${organization.id}/users`),
            render: NameWithTooltip
        },
        {
            title: 'REGISTRATION DATE',
            columnSize: ColumnSize.COLUMN3,
            property: 'registrationDate',
            render: (organization: Organization) => DateUtil.formatDate(organization.registrationDate)
        }
    ];

    const sortOrganizations = (property: string, order: SortOrderType) => {
        sortEntities(SortingUtils.defaultCompare(property, order));
    };

    const isEntityModifiable = () => !isSupportUser;

    return useObserver(() => (
        <EntityTable
            id={id}
            listOfEntities={organizations}
            sortEntities={sortOrganizations}
            loadingStatus={loadingStatus}
            columns={columns}
            setEditModalVisibility={setEditModalVisibility}
            setDeleteModalVisibility={
                usePermissionCheck(AvailablePermissions.Organization.Delete) && setDeleteModalVisibility
            }
            setCurrentEntity={setSelectedOrganization}
            deletable={isEntityModifiable}
            editable={isEntityModifiable}
            loadingDelay={0}
        />
    ));
};
