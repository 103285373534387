import { createContext } from 'react';
import orgStore from '@modules/organizations/store';
import prodStore from '@modules/products/store';
import accountsStoreModule from '@modules/accounts/store';
import fleetStoreModule, { VesselStore } from '@modules/fleet/store';
import vesselSharesStoreModule, { VesselSharesStore } from '@modules/sharing/store';
import userStore from '@modules/core/store';
import alertStore from '@modules/core/store';
import connectedStoreModule from '@modules/connected-products/store';
import rolesStoreAll from '@modules/roles/store';
import OrganizationStore from '@modules/organizations/store/OrganizationStore';
import OrganizationsStoreAll from '@modules/organizations/store/OrganizationsStoreAll';
import ProductStore from '@modules/products/store/ProductStore';
import UserStore from '@modules/core/store/UserStore';
import dataSourceStore from '@modules/core/store/DataSourceStore';
import apiClientsStoreModule from '@modules/api-clients/store';
import ApiClientsStoreAll from '@modules/api-clients/store/ApiClientsStoreAll';
import ApiClientsStoreOrganization from '@modules/api-clients/store/ApiClientsStoreOrganization';
import ApiClientStore from '@modules/api-clients/store/ApiClientStore';
import variableGroupStoreModule from '@modules/variable-groups/store';
import { DataSourceStore } from '@modules/core/store/DataSourceStore';
import { ConnectedProductsStoreAll } from '@modules/connected-products/store/ConnectedProductsStoreAll';
import { ConnectedProductsStoreOrganization } from '@modules/connected-products/store/ConnectedProductsStoreOrganization';
import vesselGroupStoreModule from '@modules/vessel-groups/store';
import { FleetStoreOrganization } from '@modules/fleet/store/FleetStoreOrganization';
import { FleetStoreUsers } from '@modules/fleet/store/FleetStoreUsers';
import { FleetStoreApiClients } from '@modules/fleet/store/FleetStoreApiClients';
import { FleetStoreAll } from '@modules/fleet/store/FleetStoreAll';
import { FleetStoreVesselGroups } from '@modules/fleet/store/FleetStoreVesselGroups';
import { VesselGroupStoreAll } from '@modules/vessel-groups/store/VesselGroupStoreAll';
import { VesselGroupStoreOrganization } from '@modules/vessel-groups/store/VesselGroupStoreOrganization';
import { VesselGroupStoreUsers } from '@modules/vessel-groups/store/VesselGroupStoreUsers';
import { VesselGroupStoreApiClients } from '@modules/vessel-groups/store/VesselGroupStoreApiClients';
import { RolesStore } from '@modules/roles/store/RolesStore';
import { RolesStoreOrganization } from '@modules/roles/store/RolesStoreOrganization';
import { RolesStoreAll } from '@modules/roles/store/RolesStoreAll';
import { AccountsStoreAll } from '@modules/accounts/store/AccountsStoreAll';
import { AccountStore } from '@modules/accounts/store/AccountStore';
import { AccountsStoreOrganization } from '@modules/accounts/store/AccountsStoreOrganization';
import { VariableGroupStore } from '@modules/variable-groups/store/VariableGroupStore';
import { VesselReferenceStore } from '@modules/variable-groups/store/VesselReferenceStore';

export const StoreContext = createContext<AppStore>({
    ...prodStore,
    ...orgStore,
    ...accountsStoreModule,
    ...fleetStoreModule,
    ...vesselSharesStoreModule,
    ...dataSourceStore,
    ...userStore,
    ...alertStore,
    ...connectedStoreModule,
    ...vesselGroupStoreModule,
    ...rolesStoreAll,
    ...apiClientsStoreModule,
    ...variableGroupStoreModule
});

export interface AppStore {
    productStore: ProductStore;
    organizationStore: OrganizationStore;
    organizationsStoreAll: OrganizationsStoreAll;
    accountsStoreAll: AccountsStoreAll;
    accountStore: AccountStore;
    accountsStoreOrganization: AccountsStoreOrganization;
    rolesStore: RolesStore;
    rolesStoreOrganization: RolesStoreOrganization;
    rolesStoreAll: RolesStoreAll;
    fleetStoreAll: FleetStoreAll;
    fleetStoreOrganization: FleetStoreOrganization;
    fleetStoreUsers: FleetStoreUsers;
    fleetStoreApiClients: FleetStoreApiClients;
    fleetStoreVesselGroups: FleetStoreVesselGroups;
    vesselStore: VesselStore;
    vesselSharesStore: VesselSharesStore;
    vesselGroupsStoreAll: VesselGroupStoreAll;
    vesselGroupsStoreOrganization: VesselGroupStoreOrganization;
    vesselGroupsStoreUsers: VesselGroupStoreUsers;
    vesselGroupStoreApiClients: VesselGroupStoreApiClients;
    userStore: UserStore;
    dataSourceStore: DataSourceStore;
    connectedProductsStoreAll: ConnectedProductsStoreAll;
    connectedProductsStoreOrganization: ConnectedProductsStoreOrganization;
    apiClientsStoreOrganization: ApiClientsStoreOrganization;
    apiClientsStoreAll: ApiClientsStoreAll;
    apiClientStore: ApiClientStore;
    variableGroupStore: VariableGroupStore;
    vesselReferenceStore: VesselReferenceStore;
}
