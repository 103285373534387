import { Dictionary } from '@modules/shared/models';
import { LoadingStatusState, LoadingStatusType } from '../models';

export function getCommonLoadingStatus(states: LoadingStatusState[]): LoadingStatusType {
    const dict = states.reduce((acc, state) => {
        acc[state.status] = (acc[state.status] || 0) + 1;
        return acc;
    }, {} as Dictionary<number>);

    if (dict[LoadingStatusType.isError]) {
        return LoadingStatusType.isError;
    } else if (dict[LoadingStatusType.isLoading]) {
        return LoadingStatusType.isLoading;
    } else {
        return LoadingStatusType.isLoaded;
    }
}
