import { ViewSelector } from '@modules/core/models';

export const userRoles = [
    { label: 'User', value: 'User' },
    { label: 'User group', user: 'User group' },
    { label: 'Organization', value: 'Organization' }
];

export const userAccessRights = [
    { label: 'Can View', value: 'Can View' },
    { label: 'Can Edit', value: 'Can Edit' }
];

export const vesselViewSelector: ViewSelector[] = [
    {
        id: 'vessels',
        label: 'List of vessels',
        value: true
    },
    {
        id: 'groups',
        label: 'Vessel Groups',
        value: false
    }
];

export const vesselsListViewSelector: ViewSelector[] = [
    {
        id: 'vessels',
        label: 'All vessels',
        value: true
    },
    {
        id: 'vesselShares',
        label: 'Shared vessels',
        value: false
    }
];
