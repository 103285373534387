import { NavigationItem } from '../models';

export const AccountsDetailsNavItems: NavigationItem[] = [
    {
        title: 'General',
        link: 'general',
        replace: true
    },
    {
        title: 'Fleet',
        link: 'fleet',
        replace: true
    }
];
