import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useObserver } from 'mobx-react-lite';

import { ApiClient } from '@modules/api-clients/models';
import { ModalProps } from '@modules/shared/models';
import { ModalWindow } from '@modules/shared/components';
import { CoreUtil, isFormFieldsDirty } from '@modules/core/utils';
import { Form } from '@modules/core/components';
import { AppClientFormEditFields, AppClientFormFields } from '@modules/core/constants/FormFields';

import './CreateEditApiClientModal.scss';

interface Props extends ModalProps {
    isEditMode?: boolean;
    apiClient?: ApiClient;
}

export const CreateEditApiClientModal = (props: Props) => {
    const { modalIsOpen, onCloseModal, isEditMode, apiClient } = props;

    const {
        register,
        getValues,
        control,
        setValue,
        handleSubmit,
        watch,
        formState: { errors, isValid, isDirty },
        clearErrors
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    });
    const watchAllFields = watch();

    const onSubmit = async () => {
        if (!isValid) {
            return;
        }
        let data = getValues();
        if (isEditMode) {
            data = {
                ...apiClient,
                ...data
            };
        }
        await props.onSubmit(data);
    };

    const fieldsCollection = useMemo(() => (isEditMode ? AppClientFormEditFields : AppClientFormFields), [isEditMode]);

    const mapFields = useMemo(() => [...CoreUtil.mapFields(fieldsCollection, apiClient)], [apiClient]);

    const isButtonDisabled = useMemo(() => {
        return isFormFieldsDirty(mapFields, watchAllFields, fieldsCollection) || Object.keys(errors).length;
    }, [isDirty, watchAllFields]);

    const isFormLoaded = true;

    return useObserver(() => (
        <ModalWindow
            disabledSubmit={isButtonDisabled}
            modalIsOpen={modalIsOpen}
            className="create-edit-api-client-popup"
            title={isEditMode ? 'EDIT API CLIENT' : 'NEW API CLIENT'}
            submitButtonName={isEditMode ? 'SAVE' : 'CREATE'}
            onSubmit={handleSubmit(onSubmit)}
            onCancel={() => onCloseModal(false)}
        >
            {isFormLoaded && (
                <Form
                    qaId="create-edit-api-client"
                    isEditMode={isEditMode}
                    useForm={{
                        register,
                        setValue,
                        getValues,
                        control,
                        formState: { errors },
                        clearErrors
                    }}
                    fields={mapFields}
                />
            )}
        </ModalWindow>
    ));
};
