import { observable, makeObservable } from 'mobx';
import { computedFn } from 'mobx-utils';
import { CancellablePromise } from 'mobx/dist/api/flow';
import { LoadingStatusState } from '@modules/core/models';
import { VesselGroup } from '../models';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';

export class VesselGroupStore {
    vesselGroups: VesselGroup[] = [];
    loadingStatusState = new LoadingStatusState();
    loadingGroupsStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            vesselGroups: observable,
            loadingStatusState: observable
        });
    }

    loadVesselGroups: (...args: any[]) => CancellablePromise<void>;

    addVesselGroup: (...args: any[]) => CancellablePromise<any>;

    deleteVesselGroup: (...args: any[]) => CancellablePromise<void>;

    editVesselGroup: (...args: any[]) => CancellablePromise<void>;

    filterVesselGroups = computedFn((query: string) => {
        if (!!query) {
            return this.vesselGroups.filter((group) => group.name.toUpperCase().includes(query.toUpperCase()));
        }

        return this.vesselGroups;
    });

    getVesselGroupById = computedFn((id: string) => {
        return this.vesselGroups.find((group) => group.id === id);
    });

    setDefaultSelectedVesselGroup = (groups: VesselGroup[]) => {
        selectedEntityState.selectVesselGroup(groups.find((el) => el.name?.toLowerCase() === 'all vessels'));
    };

    get allVesselsGroup() {
        return this.vesselGroups.find((el) => el.name?.toLowerCase() === 'all vessels');
    }
}
