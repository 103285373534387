import React, { useCallback, useEffect, useState } from 'react';
import { autorun, flow } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import xor from 'lodash/xor';

import { useStore } from '@store/StoreEffect';
import { ApiClientsDetailsNavItems, NavigationTypes } from '@modules/core/constants';
import {
    useGoBack,
    usePageContainer,
    usePermissionsState,
    useRolesState,
    useSelectedState
} from '@modules/shared/hooks';
import { ApiClient } from '@modules/api-clients/models';
import { NavBar } from '@modules/core/components';
import { ApiClientGeneral, ApiClientsSecret } from '@modules/api-clients/components';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';
import { EmptyPage } from '@pages/empty-page/EmptyPage';
import { VesselsWithGroupsPage } from '@pages/vessels-page';
import { getCommonLoadingStatus } from '@modules/core/utils';
// import { VesselVariableGroupsPage } from '../variable-groups-page/VesselVariableGroupsPage';

export const ApiClientsDetailsPage = () => {
    const {
        apiClientStore,
        fleetStoreApiClients,
        fleetStoreOrganization,
        fleetStoreAll,
        vesselGroupStoreApiClients,
        rolesStoreOrganization,
        rolesStoreAll,
        variableGroupStore
    } = useStore();
    const { goBack } = useGoBack();
    const { isSuperUser } = useRolesState();
    const { canEditApiClients } = usePermissionsState();
    const { apiClientId } = useParams() as { apiClientId: string };
    const match = useRouteMatch();
    const rolesStore = isSuperUser ? rolesStoreOrganization : rolesStoreAll;

    const [selectedApiClient, setSelectedApiClient] = useState<ApiClient>(null);

    useEffect(() => {
        const disposer = autorun(async () => {
            await loadApiClientById(apiClientId);
        });
        return () => {
            disposer();
            selectedEntityState.selectApiClient(null);
        };
    }, [apiClientId]);

    const updateSelectedApiClient = (selectedApiClient: ApiClient) => {
        selectedEntityState.selectOrganization(selectedApiClient?.organization);
        selectedEntityState.selectApiClient(selectedApiClient);
        setSelectedApiClient(selectedApiClient);
    };

    const loadApiClientById = async (apiClientId: string) => {
        const apiClient: ApiClient = await apiClientStore.getApiClient(apiClientId);
        if (apiClient) {
            await rolesStore.loadRoles(apiClient.organization.id);
            // Uncomment when BE will be ready [FOSWEB-11723]
            // await variableGroupStore.loadVariableGroups(apiClient.organization.id);
        }
        updateSelectedApiClient(apiClient);
    };

    const onEditApiClient = async (editedApiClient: ApiClient) => {
        const { roles: updatedRoles } = editedApiClient;
        const isUpdatedRoles = xor(apiClient.roles, updatedRoles).length > 0;
        const isUpdatedVariableGroups = xor(apiClient.variableGroups, editedApiClient.variableGroups).length > 0;
        if (isUpdatedRoles || isUpdatedVariableGroups) {
            const updatedApiClient = await apiClientStore.updateApiClient(
                editedApiClient,
                false,
                isUpdatedRoles,
                isUpdatedVariableGroups
            );
            if (updatedApiClient) {
                editedApiClient.roles = editedApiClient.roles || apiClient.roles;
                updateSelectedApiClient(editedApiClient);
            }
        }
    };

    const refreshApiClientSecret = async (apiClient: ApiClient) => {
        const updatedApiClient: ApiClient = await apiClientStore.updateApiClientSecret(apiClient);
        updateSelectedApiClient(updatedApiClient);
    };

    const loadOrganizationVessels = isSuperUser ? fleetStoreOrganization.loadVessels : fleetStoreAll.loadVessels;
    const filterOrganizationVessels = isSuperUser ? fleetStoreOrganization.filterVessels : fleetStoreAll.filterVessels;

    const { apiClient } = useSelectedState();

    const vesselsWithGroupsPageContainerLoadingFunction = useCallback(
        flow(function* () {
            if (!apiClient?.id) {
                return;
            }

            if (fleetStoreApiClients.isAllVesselGroupSelected) {
                yield fleetStoreApiClients.loadVessels();
            }
        }),

        [apiClient?.id, fleetStoreApiClients.isAllVesselGroupSelected]
    );

    const vesselsWithGroupsPageState = usePageContainer(
        vesselsWithGroupsPageContainerLoadingFunction,
        fleetStoreApiClients.filterVessels,
        fleetStoreApiClients.addVessels,
        fleetStoreApiClients.editVessels,
        fleetStoreApiClients.deleteVessels,
        false,
        'apiClientDetailPage'
    );

    return useObserver(() =>
        apiClientStore.loadingStatusState.isRequestFailed ? (
            <EmptyPage />
        ) : (
            <main className="details-page">
                <header className="details-page-header">
                    <i className="icon icon-chevron left" onClick={() => goBack()} />
                    <span qa-id="api-clients-details-title">{`${selectedApiClient?.name ?? ''}`}</span>
                </header>
                <nav>
                    <NavBar match={true} navItems={ApiClientsDetailsNavItems} navType={NavigationTypes.Upper} />
                </nav>
                <section className="details-page-body">
                    <Switch>
                        <Route path={`${match.url}/general`}>
                            {selectedApiClient && (
                                <ApiClientGeneral
                                    apiClient={selectedApiClient}
                                    roles={rolesStore.roles}
                                    variableGroups={variableGroupStore.variableGroups}
                                    editApiClient={onEditApiClient}
                                    loadingStatus={getCommonLoadingStatus([
                                        rolesStore.loadingStatusState,
                                        variableGroupStore.loadingStatusState
                                    ])}
                                />
                            )}
                        </Route>
                        <Route path={`${match.url}/secret`}>
                            {selectedApiClient && (
                                <ApiClientsSecret
                                    apiClient={selectedApiClient}
                                    refreshSecret={refreshApiClientSecret}
                                />
                            )}
                        </Route>
                        <Route path={`${match.url}/fleet`}>
                            <VesselsWithGroupsPage
                                vesselsWithGroupsPageState={vesselsWithGroupsPageState}
                                isVesselGroupsModalVisible
                                fleetStore={fleetStoreApiClients}
                                vesselGroupStore={vesselGroupStoreApiClients}
                                availableGroupsForAssign={vesselGroupStoreApiClients.allVesselGroups}
                                modalLoadingFunction={loadOrganizationVessels}
                                modalFilterFunction={filterOrganizationVessels}
                                isEditAllowed={canEditApiClients}
                                isVesselDeletable={() => false}
                                ownerOrganization={selectedApiClient?.organization}
                                isShowGroupsFilter={false}
                                editable={() => false}
                            />
                        </Route>
                        {/* Uncomment when BE will be ready [FOSWEB-11723] */}
                        {/* <Route path={`${match.url}/variables`}>
                            <VesselVariableGroupsPage
                                apiClient={apiClient}
                                variableGroupMap={variableGroupStore.variableGroupMap}
                            />
                        </Route> */}
                        <Redirect exact from={match.url} to={`${match.url}/general`} />
                        <Route component={EmptyPage} />
                    </Switch>
                </section>
            </main>
        )
    );
};
