import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { NavigationItem } from '@modules/core/models';
import { NavigationTypes } from '@modules/core/constants';

import './Navbar.scss';

interface Props {
    navItems: NavigationItem[];
    navType: NavigationTypes;
    match?: boolean;
    onChange?: () => void;
}

export const NavBar = (props: Props) => {
    const { match, navItems, navType } = props;
    const routeMatch = useRouteMatch();
    const matchUrl = match ? `${routeMatch.url}` : '';

    const menuItems = navItems.map((navItem) => (
        <li key={navItem.title} qa-id={`nav-link-${navItem.link}`}>
            <NavLink
                replace={navItem.replace}
                to={`${matchUrl}/${navItem.link}`}
                activeClassName="active-link"
                {...(props.onChange && {
                    onClick: props.onChange
                })}
            >
                {navItem.title}
            </NavLink>
        </li>
    ));

    const menuType = navType ?? 'left';

    return (
        <div className={`${menuType}-bar-container`} qa-id={`navigation-bar-${navType}`}>
            <ul className="navigation-menu">{menuItems}</ul>
        </div>
    );
};
