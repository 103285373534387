import { computed, makeObservable } from 'mobx';

import { Organization } from '@modules/organizations/models';
import { RolesStore } from './RolesStore';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';

export class RolesStoreOrganization extends RolesStore {
    constructor() {
        super();

        makeObservable(this, {
            selectedOrganization: computed
        });
    }

    get selectedOrganization(): Organization {
        return selectedEntityState.organization;
    }
}
