import { IS_DEV } from '@config/config';
import { Dictionary } from '@modules/shared/models';
import { CoreUtil } from '@modules/core/utils/CoreUtil';
import { AppInsights } from './AppInsights';

interface TokenFunctions {
    getToken: () => Promise<string>;
}

/**
 * Simple wrapper for REST APIs.
 */
export class HttpResource {
    baseUrl: string;
    headers: any;
    tokenFunctions: TokenFunctions;
    initialized: Promise<any>;

    constructor(baseUrl: string, headers: any, tokenFunctions?: TokenFunctions) {
        this.baseUrl = baseUrl;
        this.headers = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            ...headers
        };
        this.tokenFunctions = tokenFunctions as TokenFunctions;
        this.initialized = new Promise<void>((resolve) => {
            resolve();
        });
    }

    async getHeaders() {
        const accessToken = IS_DEV ? null : await this.tokenFunctions.getToken();
        return {
            ...this.headers,
            Authorization: 'Bearer ' + accessToken
        };
    }

    GET(url: string, query?: Dictionary<any>): Promise<any> {
        const configuredUrl = this.convertQuery(url, query);
        return this.initialized.then(async () =>
            this.fetchPromise(this.baseUrl + configuredUrl, await this.getHeaders(), 'GET')
        );
    }

    GET_TEXT(url: string): Promise<any> {
        return this.initialized.then(async () =>
            this.fetchPromise(this.baseUrl + url, await this.getHeaders(), 'GET', null, false)
        );
    }

    POST(url: string, data?: any, headers?: any): Promise<any> {
        return this.initialized.then(async () => {
            const baseHeaders = await this.getHeaders();
            const combinedHeaders = headers
                ? {
                      ...baseHeaders,
                      ...headers
                  }
                : baseHeaders;
            return this.fetchPromise(
                this.baseUrl + url,
                combinedHeaders,
                'POST',
                data ? (combinedHeaders['Content-Type'] === 'application/json' ? JSON.stringify(data) : data) : null
            );
        });
    }

    PUT(url: string, data: any, headers?: any, parseJson?: boolean): Promise<any> {
        return this.initialized.then(async () => {
            const baseHeaders = await this.getHeaders();
            const combinedHeaders = headers
                ? {
                      ...baseHeaders,
                      ...headers
                  }
                : baseHeaders;
            return this.fetchPromise(
                this.baseUrl + url,
                combinedHeaders,
                'PUT',
                data ? (combinedHeaders['Content-Type'] === 'application/json' ? JSON.stringify(data) : data) : null,
                typeof parseJson === 'undefined' ? true : parseJson
            );
        });
    }

    DELETE(url: string, data?: any): Promise<any> {
        return this.initialized.then(async () =>
            this.fetchPromise(this.baseUrl + url, await this.getHeaders(), 'DELETE', JSON.stringify(data))
        );
    }

    private checkStatus(response: any) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }

        return response.text().then((text: any) => {
            let json = {};
            if (text) {
                try {
                    json = JSON.parse(text);
                } catch (e) {
                    console.warn('Could not parse response text: ' + text, e);
                }
            }
            // eslint-disable-next-line no-throw-literal
            throw { status: response.status, responseJSON: json };
        });
    }

    private fetchByMethod(url: string, headers: any, method: string, data?: any) {
        const options = {
            headers: headers,
            method: method,
            credentials: 'same-origin',
            body: undefined as any
        };
        if (data) {
            options.body = data;
        }
        return fetch(url, options as RequestInit);
    }

    private runFetchByMethod(
        url: string,
        headers: any,
        method: string,
        resolve: Function,
        reject: Function,
        data?: any,
        parseJson = true
    ) {
        const tryFetch = () => {
            this.fetchByMethod(url, headers, method, data)
                .then(this.checkStatus)
                .then((response) => {
                    // Needs to check for empty responses as the API might return
                    // empty response for status code 200
                    response.headers.get('Content-Type') === 'application/pdf'
                        ? response.blob().then((blob: Blob) => resolve(blob))
                        : response
                              .text()
                              .then((text: string) => {
                                  resolve(text.length > 0 ? (parseJson ? JSON.parse(text) : text) : null);
                              })
                              .catch((e: any) => {
                                  reject(e);
                              });
                })
                .catch((e) => {
                    const properties = { method, url, data, errorType: 'request' };
                    AppInsights.trackException(e, properties);
                    reject(e);
                });
        };

        tryFetch();
    }

    private fetchPromise(url: string, headers: any, method: string, data?: any, parseJson = true) {
        return new Promise((resolve, reject) => {
            this.runFetchByMethod(url, headers, method, resolve, reject, data, parseJson);
        });
    }

    private convertQuery(url: string, query: Dictionary<any>) {
        if (!query) {
            return url;
        }

        return url + CoreUtil.serializeQueryParams(query);
    }
}
