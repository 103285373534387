import React from 'react';
import { SingleValueSelector } from '@fos/eniram-web-common-ui/dist/components/SingleValueSelector';

import { ProductLinks } from '@modules/connected-products/components';
import { Product } from '@modules/products/models';
import './ConnectedProductsHeader.scss';

interface Props {
    isCreateButtonVisible: boolean;
    products: Product[];
    selectedProduct: Product;
    isHidden?: boolean;
    onChangeProduct?: (selectedValue: any) => void;
}

export const ConnectedProductsHeader = (props: Props) => {
    const { onChangeProduct, selectedProduct, products, isCreateButtonVisible, isHidden } = props;

    const availableProductsTab = products && (
        <SingleValueSelector
            title=""
            defaultValue={selectedProduct?.code}
            actionFunc={onChangeProduct}
            values={products}
        />
    );

    return (
        !isHidden &&
        selectedProduct && (
            <header className="card-connected-products--header">
                {availableProductsTab}
                <div className="card-connected-products--controls">
                    {isCreateButtonVisible ? (
                        <button className="button primary" qa-id="products-page-new-option">
                            NEW OPTION
                        </button>
                    ) : null}
                    <ProductLinks product={selectedProduct} description={selectedProduct?.description} />
                </div>
            </header>
        )
    );
};
