import { computed, observable, makeObservable } from 'mobx';
import { Product, Option } from '@modules/connected-products/models';
import { LoadingStatusState } from '@modules/core/models';
import { CancellablePromise } from 'mobx/dist/api/flow';
import { DateUtil } from '@modules/shared/utils';
import { apiStore } from '@modules/core/store/ApiStore';
import { CoreUtil, HttpClient } from '@modules/core/utils';

export class ConnectedProductsStore {
    products: Product[] = [];
    loadingStatusState = new LoadingStatusState();
    loadProducts: (...args: any[]) => CancellablePromise<void>;
    loadVesselProducts: (...args: any[]) => CancellablePromise<void>;
    overrideVesselOptions: (...args: any[]) => CancellablePromise<void>;

    constructor() {
        makeObservable(this, {
            products: observable,
            loadingStatusState: observable,
            productsTabs: computed
        });
    }

    get productsTabs() {
        return this.products.map((product: Product) => ({
            label: product.name,
            value: product.code
        }));
    }

    overrideOptions(productCode: string, optionCodes: string[], products: Product[]): Product[] {
        const overrideProduct = products.find((product) => product.code === productCode);
        return products.map((product) => {
            if (product.code === productCode) {
                return {
                    ...overrideProduct,
                    options: overrideProduct?.options.map((option) => {
                        return {
                            ...option,
                            ...(!(option.expirationDate && DateUtil.isBefore(option.expirationDate)) && {
                                subscribed: option?.immutable || optionCodes.includes(option?.code)
                            })
                        };
                    })
                };
            }
            return product;
        });
    }

    async mergeOptions(products: Product[]) {
        const { api } = apiStore.api.products.getProducts;
        const allProducts = await HttpClient.TCMS.GET(api());
        for (const product of products) {
            const currentProduct = allProducts.find((product_: Product) => product.code === product_.code);
            const optionsByProduct: Option[] = currentProduct.options ?? [];
            product.name = currentProduct.name;
            product.description = currentProduct.description;
            product.homeUrl = currentProduct.homeUrl;
            product.userManualUrl = currentProduct.userManualUrl;

            if (!product.options) {
                product.options = optionsByProduct;
            } else {
                product.options.forEach((option: Option) => {
                    option.subscribed = true;
                    option.immutable = !!(
                        option.expirationDate ||
                        (!CoreUtil.isNil(option.connectedByUser) && !option.connectedByUser)
                    );

                    if (option.expirationDate && DateUtil.isBefore(option.expirationDate)) {
                        option.subscribed = false;
                    }

                    const duplicateIndex = optionsByProduct.findIndex(
                        (option_: Option) => option_.code === option.code
                    );

                    if (duplicateIndex !== -1) {
                        option.description = optionsByProduct[duplicateIndex].description;
                        option.name = optionsByProduct[duplicateIndex].name;
                        optionsByProduct.splice(duplicateIndex, 1);
                    }
                });
                product.options = [...product.options, ...optionsByProduct];
            }
        }
    }
}
