import { Dictionary } from '@modules/shared/models';
import { AlertMessage, Option } from '../models';
import { Role } from '@modules/roles/models';
import { RoleUtils } from '@modules/core/utils/index';
import intersectionBy from 'lodash/intersectionBy';

export class CoreUtil {
    static delayPromise(time: number): Promise<any> {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    static debounce() {
        let timeout: NodeJS.Timeout;

        return (callback: Function, delay: number) => {
            if (timeout) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(() => callback(), delay);
        };
    }

    static pluck(property: string, list?: any[]): any {
        if (arguments.length === 1) return (_list: any[]) => CoreUtil.pluck(property, _list);

        const willReturn: any[] = [];

        list.map((x) => {
            if (x[property] !== undefined) {
                willReturn.push(x[property]);
            }
        });

        return willReturn;
    }

    static compose(...fns: Function[]): Function {
        if (fns.length === 0) {
            throw new Error('compose requires at least one argument');
        }

        return (...args: any[]) => {
            const list = fns.slice();
            if (list.length > 0) {
                const fn = list.pop();
                let result = fn(...args);
                while (list.length > 0) {
                    result = list.pop()(result);
                }

                return result;
            }
        };
    }

    static intersection(listA: any[], listB?: any[]) {
        if (arguments.length === 1) {
            return (_list: any[]) => CoreUtil.intersection(listA, _list);
        }

        return listA.filter((value) => listB.includes(value));
    }

    static not(value: any): boolean {
        return !value;
    }

    static type(value: any) {
        const typeOf = typeof value;

        if (value === null) {
            return 'Null';
        } else if (value === undefined) {
            return 'Undefined';
        } else if (typeOf === 'boolean') {
            return 'Boolean';
        } else if (typeOf === 'number') {
            return Number.isNaN(value) ? 'NaN' : 'Number';
        } else if (typeOf === 'string') {
            return 'String';
        } else if (Array.isArray(value)) {
            return 'Array';
        } else if (value instanceof RegExp) {
            return 'RegExp';
        }

        const asStr = value && value.toString ? value.toString() : '';

        if (['true', 'false'].includes(asStr)) return 'Boolean';
        if (!Number.isNaN(Number(asStr))) return 'Number';
        if (asStr.startsWith('async')) return 'Async';
        if (asStr === '[object Promise]') return 'Promise';
        if (typeOf === 'function') return 'Function';
        if (value instanceof String) return 'String';

        return 'Object';
    }

    static isEmpty(value: any): boolean {
        const valueType = CoreUtil.type(value);
        if (['Undefined', 'NaN', 'Number', 'Null'].includes(valueType)) {
            return false;
        }

        if (!value) return true;

        if (valueType === 'Object') {
            return Object.keys(value).length === 0;
        }

        if (valueType === 'Array') {
            return value.length === 0;
        }

        return false;
    }

    static anyPass(predicates: Function[]): any {
        return (value: any) => {
            let counter = 0;
            while (counter < predicates.length) {
                if (predicates[counter](value)) {
                    return true;
                }
                counter++;
            }

            return false;
        };
    }

    static isNil(value: any): boolean {
        return value === null || value === undefined;
    }

    static filter(predicate: Function, list?: any[]): any {
        if (arguments.length === 1) return (_list: any[]) => CoreUtil.filter(predicate, _list);
        return list.filter((value, index) => predicate(value, index));
    }

    static uniq(list: any[]): any[] {
        let index: number = -1;
        const willReturn: any[] = [];

        while (++index < list.length) {
            const value = list[index];

            if (!willReturn.includes(value)) {
                willReturn.push(value);
            }
        }

        return willReturn;
    }

    static expandList(item: any, expand = '') {
        return {
            [expand]: this.mapToOption(item[expand])
        };
    }

    static mapToExpandOption(list: any[], { valueParam = 'id', labelParam = 'name', expand = '' } = {}): Option[] {
        return list.map((item: any) => {
            return {
                value: item[valueParam],
                label: item[labelParam],
                ...(expand && this.expandList(item, expand))
            };
        });
    }

    static mapToOption(list: any[], { valueParam = 'id', labelParam = 'name' } = {}): Option[] {
        return list.map((item: any) => {
            if (typeof item === 'object') {
                return {
                    value: item[valueParam],
                    label: item[labelParam]
                };
            }
            return {
                value: item,
                label: item
            };
        });
    }

    static getPermissionsId(arr: any[] = []): string[] {
        const permissions = [] as string[];
        arr.forEach((item: any) => {
            const actualPermissions = intersectionBy(item?.product?.permissions, item?.permissions, 'value');
            actualPermissions.map((el: any) => permissions.push(el.value));
        });
        return permissions;
    }

    static mapFieldRoles(roleIds?: string[], roles: Role[] = [], fieldsCollection?: Dictionary<any>) {
        const rolesOptions = this.mapToOption(roles);

        const accRoles = () => {
            const uRolesIds = roleIds || [];
            const userRolesData = RoleUtils.getRoleDataByRoleIds(uRolesIds, roles);
            return this.mapToOption(userRolesData);
        };
        return {
            fieldName: 'roles',
            defaultValue: accRoles(),
            options: rolesOptions,
            ...fieldsCollection
        };
    }

    static mapFields(fields: Dictionary<any>, values?: Dictionary<any>, exclude: string[] = ['roles']): any[] {
        return Object.keys(fields || values).reduce((acc: any[], el: string) => {
            if (el && fields[el] && !exclude.includes(el)) {
                return [
                    ...acc,
                    {
                        fieldName: el,
                        defaultValue: values && values[el],
                        ...fields[el]
                    }
                ];
            }
            return acc;
        }, []);
    }

    static goToPage(history: any, path: string): any {
        return history.push(path);
    }

    static defaultErrorMessage(error: any): AlertMessage {
        const errorKeyMap: Dictionary<string> = {};
        let message = 'Unknown error';
        let description = '';
        if (!error) {
            return {
                message
            };
        }
        if (error.status === 403) {
            message = 'Access denied';
            description = 'You may not access this data';
        } else if (error.status === 404) {
            message = 'Resource not found';
            description = 'The server returned failed to find data';
        } else if (error.status === 401) {
            // 401 unauthorized: let's reload to go back to login page
            window.location.reload();
            message = 'Please wait, reloading application to renew your session';
            return;
        } else if (error.status === 0) {
            message = 'Server is currently unavailable';
        } else {
            const responseJSON = error.responseJSON
                ? error.responseJSON
                : error.responseText && error.responseText.responseJSON
                ? error.responseText.responseJSON
                : null;
            if (responseJSON) {
                if (responseJSON.key && errorKeyMap[responseJSON.key]) {
                    message = errorKeyMap[error.responseJSON.key];
                } else if (responseJSON.message) {
                    description = responseJSON.message;
                }
            }
        }

        return {
            message: message,
            description: description
        };
    }

    static serializeQueryParams(obj?: Dictionary<any>): string {
        if (!obj) {
            return '';
        }
        const keys = Object.keys(obj);
        const parameters = keys.reduce(function (a, k) {
            if (obj[k]) {
                a.push(k + '=' + obj[k]);
            }
            return a;
        }, []);
        return keys.length !== 0 && parameters.length ? '?' + parameters.join('&') : '';
    }
}
