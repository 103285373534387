import { AlertMessage } from '@modules/core/models';
import { CoreUtil } from '@modules/core/utils';

export class RolesErrorHandlers {
    static DeleteRoleErrorHandler(error: any): AlertMessage {
        const message = 'Failed to delete role';
        let description = '';
        switch (error.status) {
            case 404:
                description = 'Role with this id does not exist';
                break;
            case 400:
                description = 'Role cannot be deleted if assigned to a user';
                break;
            default:
                return CoreUtil.defaultErrorMessage(error);
        }

        return {
            message: message,
            description: description
        };
    }

    static AddRoleErrorHandler(error: any): AlertMessage {
        const message = 'Failed to create role';
        let description = '';
        switch (error.status) {
            case 400:
                description = 'A role with this name already exists';
                break;
            default:
                return CoreUtil.defaultErrorMessage(error);
        }

        return {
            message: message,
            description: description
        };
    }

    static GetRoleErrorHandler(error: any): AlertMessage {
        const message = 'Failed to get role';
        let description = '';
        switch (error.status) {
            case 404:
                description = 'No roles have been added for this organization';
                break;
            default:
                return CoreUtil.defaultErrorMessage(error);
        }

        return {
            message: message,
            description: description
        };
    }

    static UpdateRoleErrorHandler(error: any): AlertMessage {
        const message = 'Failed to update role';
        let description = '';
        switch (error.status) {
            case 400:
                description = 'A role with this name already exists';
                break;
            default:
                return CoreUtil.defaultErrorMessage(error);
        }

        return {
            message: message,
            description: description
        };
    }

    static UpdateRolePermissionsErrorHandler(error: any): AlertMessage {
        const message = 'Failed to updated role permissions';
        let description = '';
        switch (error.status) {
            case 400:
                description = '';
                break;
            default:
                return CoreUtil.defaultErrorMessage(error);
        }

        return {
            message: message,
            description: description
        };
    }
}
