import { flow, computed, makeObservable } from 'mobx';

import { alertStore } from '@modules/core/store/AlertStore';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';
import { Organization } from '@modules/organizations/models';
import { VesselGroupStore } from './VesselGroupStore';
import { VesselGroup } from '../models';

export class VesselGroupStoreOrganization extends VesselGroupStore {
    constructor() {
        super();

        makeObservable(this, {
            selectedOrganization: computed
        });

        this.loadVesselGroups = this.loadVesselGroups.bind(this);
        this.editVesselGroup = this.editVesselGroup.bind(this);
        this.addVesselGroup = this.addVesselGroup.bind(this);
        this.deleteVesselGroup = this.deleteVesselGroup.bind(this);
    }

    loadVesselGroups = flow(function* (this: VesselGroupStoreOrganization) {
        if (!this.selectedOrganization?.id) {
            return;
        }
        const { api, errorMessageHandler } = apiStore.api.vesselsGroups.getOrganizationVesselGroups;
        try {
            this.loadingGroupsStatusState.load();

            const vesselGroups: VesselGroup[] = yield HttpClient.TCMS.GET(api(this.selectedOrganization.id));

            this.vesselGroups = vesselGroups.map((group) => ({
                name: group.name,
                id: group.id,
                wellknown: group.wellknown,
                vessels: group.vessels
            }));

            this.setDefaultSelectedVesselGroup(this.vesselGroups);

            this.loadingGroupsStatusState.loadSuccess();
        } catch (error) {
            this.loadingGroupsStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    editVesselGroup = flow(function* (this: VesselGroupStoreOrganization, vesselGroup: Partial<VesselGroup>) {
        if (!this.selectedOrganization?.id || !vesselGroup?.id) {
            return;
        }
        const { api: putVesselGroupApi, errorMessageHandler: putVesselGroupErrorHandler } =
            apiStore.api.vesselsGroups.putOrganizationVesselGroup;

        try {
            yield HttpClient.TCMS.PUT(putVesselGroupApi(vesselGroup.id, this.selectedOrganization.id), {
                name: vesselGroup.name
            });
        } catch (error) {
            alertStore.error(error, putVesselGroupErrorHandler);
        }
        const { api, successMessage, errorMessageHandler } = apiStore.api.vessels.putOrganizationVesselGroupVessels;
        try {
            yield HttpClient.TCMS.PUT(api(vesselGroup.id, this.selectedOrganization.id), vesselGroup.vessels);
            const groupToUpdate = this.vesselGroups.find((group) => group.id === vesselGroup.id);
            Object.assign(groupToUpdate, vesselGroup);
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    addVesselGroup = flow(function* (this: VesselGroupStoreOrganization, vesselGroup: Partial<VesselGroup>) {
        if (!this.selectedOrganization?.id) {
            return;
        }

        const { api: postVesselGroupApi, errorMessageHandler: postVesselGroupErrorHandler } =
            apiStore.api.vesselsGroups.postOrganizationVesselGroup;
        let addedGroup: VesselGroup;
        try {
            addedGroup = yield HttpClient.TCMS.POST(postVesselGroupApi(this.selectedOrganization.id), {
                name: vesselGroup.name
            });
        } catch (error) {
            alertStore.error(error, postVesselGroupErrorHandler);
        }
        const { api, successMessage, errorMessageHandler } = apiStore.api.vessels.putOrganizationVesselGroupVessels;
        try {
            yield HttpClient.TCMS.PUT(api(addedGroup.id, this.selectedOrganization.id), vesselGroup.vessels);
            const group = {
                ...addedGroup,
                vessels: vesselGroup.vessels
            };
            this.vesselGroups.push(group);
            alertStore.success(successMessage);
            return group;
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    deleteVesselGroup = flow(function* (this: VesselGroupStoreOrganization, vesselGroup: VesselGroup) {
        if (!this.selectedOrganization?.id) {
            return;
        }
        const { api, successMessage, errorMessageHandler } = apiStore.api.vesselsGroups.deleteOrganizationVesselGroup;
        try {
            yield HttpClient.TCMS.DELETE(api(vesselGroup.id, this.selectedOrganization.id));
            const index = this.vesselGroups.findIndex((group) => group.id === vesselGroup.id);
            this.vesselGroups.splice(index, 1);
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    get selectedOrganization(): Organization {
        return selectedEntityState.organization;
    }
}
