import { flow } from 'mobx';

import { HttpClient } from '@modules/core/utils';
import { apiStore } from '@modules/core/store/ApiStore';
import { alertStore } from '@modules/core/store/AlertStore';
import { FleetStore } from './FleetStore';
import { Vessel } from '../models';

export class FleetStoreApiClients extends FleetStore {
    constructor() {
        super();

        this.addVessels = this.addVessels.bind(this);
        this.deleteVessels = this.deleteVessels.bind(this);
        this.loadVessels = this.loadVessels.bind(this);
        this.loadOrganizationVessels = this.loadOrganizationVessels.bind(this);
    }

    loadOrganizationVessels = flow(function* (this: FleetStoreApiClients) {
        if (!this.selectedOrganization?.id) {
            return [];
        }
        const { api, errorMessageHandler } = apiStore.api.vessels.getOrganizationVessels;
        try {
            return yield HttpClient.TCMS.GET(api(this.selectedOrganization.id));
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
            return [];
        }
    });

    loadVessels = flow(function* (this: FleetStoreApiClients, isUpdate = false) {
        this.vessels = [];

        if (!this.selectedApiClient) {
            return;
        }

        const { api: getDirAssVesselsApi, errorMessageHandler: getDirAssVesselsErrorHandler } =
            apiStore.api.vessels.getOrganizationApiClientDirectlyAssignedVessels;
        let apiClient = this.selectedApiClient;

        if (isUpdate) {
            const { api: getApiClientApi, errorMessageHandler: getApiClientErrorHandler } =
                apiStore.api.apiClients.getApiClient;

            try {
                this.loadingStatusState.load();
                apiClient = yield HttpClient.TCMS.GET(getApiClientApi(apiClient.id));
                this.updateSelectedApiClient(apiClient);
                this.loadingStatusState.loadSuccess();
            } catch (error) {
                this.loadingStatusState.loadError();
                alertStore.error(error, getApiClientErrorHandler);
            }
        }

        try {
            this.loadingStatusState.load();
            const vesselsByOrganization: Vessel[] = yield this.loadOrganizationVessels();
            const directlyAssignedVessels: Vessel[] = yield HttpClient.TCMS.GET(
                getDirAssVesselsApi(this.selectedApiClient.id, this.selectedApiClient.organization?.id)
            );
            this.vessels = vesselsByOrganization
                .filter((vessel) => apiClient.vessels.includes(vessel.id))
                .map((vessel) => ({
                    ...vessel,
                    directlyAssigned: !!directlyAssignedVessels.find((directVessel) => directVessel.id === vessel.id)
                }));
            this.loadingStatusState.loadSuccess();
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, getDirAssVesselsErrorHandler);
        }
    });

    addVessels = flow(function* (this: FleetStoreApiClients, vesselsToAdd: Vessel[]) {
        if (!this.selectedApiClient) {
            return;
        }
        const { api, successMessage, errorMessageHandler } =
            apiStore.api.vessels.putOrganizationApiClientDirectlyAssignedVessels;

        try {
            yield HttpClient.TCMS.PUT(
                api(this.selectedApiClient.id, this.selectedApiClient.organization?.id),
                vesselsToAdd.map((vessel) => vessel.id)
            );
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    deleteVessels = flow(function* (this: FleetStoreApiClients, vesselsToDelete: Vessel[]) {
        if (!this.selectedApiClient) {
            return;
        }
        const { api, successMessage, errorMessageHandler } =
            apiStore.api.vessels.putOrganizationApiClientDirectlyAssignedVessels;
        try {
            const newVessels = this.directlyAssignedVessels.filter((directlyAssigned) =>
                vesselsToDelete.find((vessel) => directlyAssigned.id !== vessel.id)
            );

            yield HttpClient.TCMS.PUT(
                api(this.selectedApiClient.id, this.selectedApiClient.organization?.id),
                newVessels.map((vessel) => vessel.id)
            );
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });
}
