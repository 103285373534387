import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { reaction } from 'mobx';

import { Alert } from 'modules/core/models';
import { AlertTypes } from 'modules/core/models';
import { alertStore } from '@modules/core/store/AlertStore';

import './Toaster.scss';

interface Props {
    toasterId: string;
}

/**
 * TODO:
 * add fading animation when toster disappears
 */

export const Toaster = (props: Props) => {
    const { toasterId } = props;
    const history = useHistory();
    const [alerts, setAlerts] = useState<Alert[]>([]);

    useEffect(() => {
        const historyUnlisten = history.listen(() => {
            alertStore.clear(toasterId);
        });

        return () => {
            historyUnlisten();
        };
    });

    useEffect(() => {
        reaction(
            () => alertStore.alerts,
            () => {
                setAlerts(alertStore.alerts.filter((alert) => alert.id === toasterId));
            }
        );
    }, [toasterId]);

    const removeAlert = (alert: Alert) => {
        alertStore.remove(alert);
    };

    const cssClasses = (alert: Alert) => {
        if (!alert) return;

        const classes = ['alert', 'alert-dismissable'];

        const alertTypeClass = {
            [AlertTypes.success]: 'alert-success',
            [AlertTypes.error]: 'alert-error',
            [AlertTypes.info]: 'alert-info',
            [AlertTypes.warning]: 'alert-warning',
            [AlertTypes.default]: 'alert-default',
            [AlertTypes.clear]: ''
        };

        classes.push(alertTypeClass[alert.type]);

        return classes.join(' ');
    };

    if (!alerts.length) return null;

    return (
        <div className="toster-container">
            {alerts.map((alert, index) => (
                <div key={index} className={cssClasses(alert)}>
                    <div className="alert-block">
                        <i className={`icon icon-alert ${alert.type}`}></i>
                        <div className="alert-display">
                            <span className="alert-display--message">{alert.message}</span>
                            {alert.description ? (
                                <span className="alert-display--description">{alert.description}</span>
                            ) : null}
                        </div>
                    </div>
                    {alert.options?.dismissable ? (
                        <i className="alert-close-icon" onClick={() => removeAlert(alert)}>
                            &times;
                        </i>
                    ) : null}
                </div>
            ))}
        </div>
    );
};
