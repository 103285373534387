import React, { useState, useEffect } from 'react';

import { useStore } from '@store/StoreEffect';
import { RoleType } from '@modules/core/models';

interface Props {
    accessRoles: RoleType[];
    excludeRoles?: RoleType[];
    children: any;
    exact?: boolean;
    excludeSuperUser?: boolean;
}

export const RoleRestriction = (props: Props) => {
    const { children, accessRoles, exact, excludeSuperUser } = props;
    const { userStore } = useStore();
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const visible = excludeSuperUser
            ? !userStore.isSuperUser && userStore.isAccessable(accessRoles, exact)
            : userStore.isAccessable(accessRoles, exact);
        setIsVisible(visible);
    }, [accessRoles, exact, userStore, userStore.roles]);

    return isVisible ? <>{children}</> : null;
};
