import { flow, computed, makeObservable } from 'mobx';

import { alertStore } from '@modules/core/store/AlertStore';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';
import { VesselGroupStore } from './VesselGroupStore';
import { VesselGroup } from '../models';
import { Account } from '@modules/accounts/models';
import VesselGroupStoreModule from '@modules/vessel-groups/store';
import UserStoreModule from '@modules/core/store';

export class VesselGroupStoreUsers extends VesselGroupStore {
    constructor() {
        super();

        makeObservable(this, {
            selectedAccount: computed,
            parentStore: computed({ keepAlive: true }),
            allVesselGroups: computed({ keepAlive: true })
        });

        this.loadVesselGroups = this.loadVesselGroups.bind(this);
        this.editVesselGroup = this.editVesselGroup.bind(this);
    }

    loadVesselGroups = flow(function* (this: VesselGroupStoreUsers) {
        this.vesselGroups = [];
        if (!this.selectedAccount?.id) {
            return;
        }
        const { api, errorMessageHandler } = apiStore.api.vesselsGroups.getOrganizationUsersVesselGroups;
        try {
            this.loadingGroupsStatusState.load();

            const groups: VesselGroup[] = yield HttpClient.TCMS.GET(
                api(this.selectedAccount.id, this.selectedAccount.organization?.id)
            );
            yield this.parentStore.loadVesselGroups(this.selectedAccount.organization?.id);
            const allVesselGroups = this.allVesselGroups;

            this.vesselGroups = groups.reduce((accumulator, { id }) => {
                const found = allVesselGroups.find((group) => group.id === id);
                if (found) accumulator.push(found);
                return accumulator;
            }, []);

            this.loadingGroupsStatusState.loadSuccess();
        } catch (error) {
            this.loadingGroupsStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    editVesselGroup = flow(function* (this: VesselGroupStoreUsers, vesselGroups: VesselGroup[]) {
        if (!this.selectedAccount?.id) {
            return;
        }

        const { api, successMessage, errorMessageHandler } =
            apiStore.api.vesselsGroups.putOrganizationUsersVesselGroups;

        try {
            this.loadingStatusState.load();
            yield HttpClient.TCMS.PUT(
                api(this.selectedAccount.id, this.selectedAccount.organization?.id),
                vesselGroups.map((group) => group.id)
            );
            this.vesselGroups = vesselGroups;
            this.loadingStatusState.loadSuccess();
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    get selectedAccount(): Account {
        return selectedEntityState.account;
    }

    get parentStore(): VesselGroupStore {
        return UserStoreModule.userStore.isSuperUser
            ? VesselGroupStoreModule.vesselGroupsStoreOrganization
            : VesselGroupStoreModule.vesselGroupsStoreAll;
    }

    get allVesselGroups(): VesselGroup[] {
        return this.parentStore.vesselGroups;
    }
}
