import { computed, flow, makeObservable, observable } from 'mobx';
import omit from 'lodash/omit';

import { selectedEntityState } from '@modules/core/store/SelectedEntityState';
import { HttpError, LoadingStatusState } from '@modules/core/models';
import { apiStore } from '@modules/core/store/ApiStore';
import { alertStore } from '@modules/core/store/AlertStore';
import { VariableGroup } from '@modules/variable-groups/models';
import { HttpClient } from '@modules/core/utils';
import { Organization } from '@modules/organizations/models';
import { ApiClient, BaseApiClient } from '../models';

export default class ApiClientStore {
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            loadingStatusState: observable,
            selectedOrganization: computed
        });

        this.getApiClient = this.getApiClient.bind(this);
        this.addApiClient = this.addApiClient.bind(this);
        this.updateApiClient = this.updateApiClient.bind(this);
        this.updateApiClientSecret = this.updateApiClientSecret.bind(this);
        this.deleteApiClient = this.deleteApiClient.bind(this);
    }

    addApiClient = flow(function* (this: ApiClientStore, apiClient: ApiClient) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.apiClients.postOrganizationApiClient;
        try {
            this.loadingStatusState.load();
            const addedApiClient = yield HttpClient.TCMS.POST(
                api(this.selectedOrganization?.id),
                this.mapToBaseApiClient(apiClient)
            );

            const newApiClient: ApiClient = {
                ...apiClient,
                ...addedApiClient
            };

            this.loadingStatusState.loadSuccess();
            alertStore.success(successMessage);

            return newApiClient;
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    updateApiClient = flow(function* (
        this: ApiClientStore,
        apiClient: ApiClient,
        isUpdatedApiClient = false,
        isUpdatedRoles = false,
        isUpdatedVariableGroups = false
    ) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.apiClients.putOrganizationApiClient;
        try {
            this.loadingStatusState.load();
            if (isUpdatedApiClient) {
                const baseApiClient = this.mapToBaseApiClient(apiClient);
                yield HttpClient.TCMS.PUT(api(this.selectedOrganization?.id, baseApiClient), baseApiClient);
            }
            if (isUpdatedRoles) {
                yield this.updateApiClientRoles(apiClient.id, apiClient.organization.id, apiClient.roles);
            }
            if (isUpdatedVariableGroups) {
                yield this.updateApiClientVariableGroups(
                    apiClient.id,
                    apiClient.organization.id,
                    apiClient.variableGroups
                );
            }
            this.loadingStatusState.loadSuccess();
            alertStore.success(successMessage);
            return apiClient;
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    updateApiClientRoles = flow(function* (
        this: ApiClientStore,
        apiClientId: string,
        organizationId: string,
        roleIds: string[]
    ) {
        const { api, errorMessageHandler } = apiStore.api.apiClients.putOrganizationApiClientRoles;

        try {
            yield HttpClient.TCMS.PUT(api(apiClientId, organizationId), roleIds);
            return true;
        } catch (e: any) {
            alertStore.error(e, errorMessageHandler);
            return false;
        }
    });

    updateApiClientSecret = flow(function* (this: ApiClientStore, apiClient: ApiClient) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.apiClients.putOrganizationApiClientSecret;
        try {
            this.loadingStatusState.load();
            const secret = yield HttpClient.TCMS.PUT(api(apiClient.organization?.id, apiClient.id), {});
            const updatedApiClient = {
                ...apiClient,
                secret
            };
            this.loadingStatusState.loadSuccess();
            alertStore.success(successMessage);
            return updatedApiClient;
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    updateApiClientVariableGroups = flow(function* (
        this: ApiClientStore,
        apiClientId: string,
        organizationId: string,
        variableGroupsIds: number[]
    ) {
        const { api, errorMessageHandler } = apiStore.api.apiClients.putOrganizationApiClientVariableGroups;
        try {
            yield HttpClient.TCMS.PUT(api(apiClientId, organizationId), variableGroupsIds);
            return true;
        } catch (e: any) {
            alertStore.error(e, errorMessageHandler);
            return false;
        }
    });

    deleteApiClient = flow(function* (this: ApiClientStore, apiClient: ApiClient) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.apiClients.deleteOrganizationApiClient;
        try {
            const orgId = apiClient.organization?.id ?? this.selectedOrganization?.id;
            yield HttpClient.TCMS.DELETE(api(orgId, apiClient.id));
            alertStore.success(successMessage);
            return apiClient;
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    getApiClient = flow(function* (this: ApiClientStore, apiClientId: string) {
        const { api, errorMessageHandler } = apiStore.api.apiClients.getApiClient;
        try {
            this.loadingStatusState.load();
            const apiClient = yield HttpClient.TCMS.GET(api(apiClientId));
            // Uncomment when BE will be ready [FOSWEB-11723]
            // const orgId = apiClient.organization?.id ?? this.selectedOrganization?.id;
            // const assignedVariableGroups: VariableGroup[] = yield this.getApiClientVariableGroups(apiClientId, orgId);

            // Remove when BE will be ready [FOSWEB-11723]
            const assignedVariableGroups: VariableGroup[] = [];
            this.loadingStatusState.loadSuccess();
            return {
                ...apiClient,
                variableGroups: assignedVariableGroups.map((group) => group.id)
            };
        } catch (e: any) {
            const error: HttpError = e;
            this.loadingStatusState.loadError(error.status);
            alertStore.error(error, errorMessageHandler);
        }
    });

    getApiClientVariableGroups = flow(function* (this: ApiClientStore, apiClientId: string, organizationId: string) {
        const { api, errorMessageHandler } = apiStore.api.apiClients.getOrganizationApiClientVariableGroups;
        try {
            this.loadingStatusState.load();
            const assignedVariableGroups = yield HttpClient.TCMS.GET(api(apiClientId, organizationId));
            this.loadingStatusState.loadSuccess();
            return assignedVariableGroups;
        } catch (e: any) {
            const error: HttpError = e;
            this.loadingStatusState.loadError(error.status);
            alertStore.error(error, errorMessageHandler);
        }
    });

    get selectedOrganization(): Organization {
        return selectedEntityState.organization;
    }

    private mapToBaseApiClient(apiClient: ApiClient): BaseApiClient {
        return <BaseApiClient>omit(apiClient, ['variableGroups']);
    }
}
