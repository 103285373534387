import React, { useEffect, useMemo, useState } from 'react';
import Form, { AjvError, IChangeEvent, FieldTemplateProps } from '@rjsf/core';
import { JSONSchema7 } from 'json-schema';

import { Dictionary } from '@modules/shared/models';
import { Tooltip } from '@modules/shared/components';
import { Dropdown } from '@fos/eniram-web-common-ui/dist/components/DropDown';
import { LoadingStatusType } from '@modules/core/models';
import { LoadPlaceholder } from '@modules/core/components';

import './Form.scss';

interface Props {
    schema: Dictionary<any>;
    errors?: Partial<AjvError>[];
    setErrors: (errors: AjvError[]) => void;
    onSubmit: (formData: Dictionary<any>) => void;
    onChange?: (formData: Dictionary<any>) => void;
    onDeleteForm?: (code: string) => void;
    isHiddenSubmit?: boolean;
    readOnlyMode?: boolean;
    footer?: JSX.Element;
    formData?: Dictionary<any>;
    qaId?: string;
}

const SelectFieldTemplate = (fieldTemplateProps: FieldTemplateProps, options: any[] = []) => {
    const { id, classNames, onChange, label, disabled, readonly } = fieldTemplateProps;
    const defaultValue = options.find((option) => option.value === fieldTemplateProps.schema.default);
    return (
        <div key={id} className={`field-row ${classNames}`}>
            <label>{label}</label>
            <div className="field">
                {readonly ? (
                    <span qa-id={'form-' + id + '-dropdown-read-only'}>{defaultValue?.label}</span>
                ) : (
                    <Dropdown
                        qaId={'form-' + id + '-dropdown'}
                        defaultValue={[defaultValue]}
                        actionFunc={(val) => onChange(val.value)}
                        options={options}
                        disabled={readonly || disabled}
                        isMulti={false}
                        isClearable={false}
                        isSearchable={false}
                    />
                )}
            </div>
        </div>
    );
};

const InputFieldTemplate = (fieldTemplateProps: FieldTemplateProps) => {
    const {
        id,
        classNames,
        rawDescription: description,
        errors,
        disabled,
        readonly,
        onChange,
        label
    } = fieldTemplateProps;
    const inputErrors = (errors?.props?.errors || []).map((error: any) => error);
    return (
        <div className={`field-row ${classNames}`}>
            <label>{label}</label>
            <div className="field">
                {readonly ? (
                    <span qa-id={'form-' + id + '-input-read-only'}>{fieldTemplateProps.formData}</span>
                ) : (
                    <div className={`input-with-icon ${!!inputErrors.length && 'has-error'}`}>
                        <input
                            qa-id={'form-' + id + '-input'}
                            key={id}
                            type="string"
                            disabled={readonly || disabled}
                            onChange={(event) => onChange(`${event.target.value}` || undefined)}
                            value={fieldTemplateProps.formData ? fieldTemplateProps.formData : ''}
                        />
                        {description && (
                            <Tooltip content={description} width={350}>
                                <i className={`icon icon-attention-${!!inputErrors?.length ? 'red' : 'grey'}`} />
                            </Tooltip>
                        )}
                        <div className="error">{inputErrors.join(', ')}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

const IntegerFieldTemplate = (fieldTemplateProps: FieldTemplateProps) => {
    const { id, classNames, rawDescription, errors, disabled, readonly, onChange, label } = fieldTemplateProps;
    const inputErrors = (errors?.props?.errors || []).map((error: any) => error);
    return (
        <div className={`field-row ${classNames}`}>
            <label>{label}</label>
            <div className="field">
                {readonly ? (
                    <span qa-id={'form-' + id + '-input-integer-read-only'}>{fieldTemplateProps.formData}</span>
                ) : (
                    <>
                        <input
                            qa-id={'form-' + id + '-input-integer'}
                            key={id}
                            type="integer"
                            placeholder={rawDescription}
                            disabled={readonly || disabled}
                            className={`${!!inputErrors.length && 'has-error'}`}
                            onChange={(event) => onChange(Number(event.target.value) || undefined)}
                            value={fieldTemplateProps.formData ? fieldTemplateProps.formData : ''}
                        />
                        <div className="error">{inputErrors}</div>
                    </>
                )}
            </div>
        </div>
    );
};

export const FormComponent = (props: Props) => {
    const [formData, setFormData] = useState<any>(null);

    useEffect(() => {
        if (props.formData) {
            setFormData(
                Object.keys(props?.schema?.properties).reduce(
                    (acc, el) => ({
                        ...acc,
                        [el]: props.formData[el] || undefined
                    }),
                    {}
                )
            );
        }
    }, [props.formData, props.schema]);

    const transformErrors = (errs: AjvError[]) => {
        return errs
            .filter((el: AjvError) => {
                return el.name !== 'enum';
            })
            .map((el: AjvError) => {
                if (el.name === 'pattern') {
                    return {
                        ...el,
                        message: 'data is incorrect'
                    };
                }
                return el;
            });
    };

    const onSubmit = (data: any) => {
        props.onSubmit(data);
    };

    const onChange = (e: IChangeEvent<any>) => {
        props.setErrors(e?.errors);
        setFormData(e?.formData);
        props.onChange(e?.formData);
    };

    const uiSchema = useMemo(() => {
        const getFieldTemplate = (field: any) => {
            switch (field.type) {
                case 'string':
                    return field.enum
                        ? (props: FieldTemplateProps) => SelectFieldTemplate(props, field.enum)
                        : InputFieldTemplate;
                case 'select':
                    return (props: FieldTemplateProps) => SelectFieldTemplate(props, field.enum);
                case 'integer':
                    return IntegerFieldTemplate;
                default:
                    return null;
            }
        };

        return (
            props?.schema &&
            Object.keys(props?.schema?.properties).reduce((acc: object, key) => {
                const field = props?.schema?.properties[key];
                return {
                    ...acc,
                    [key]: {
                        'ui:FieldTemplate': getFieldTemplate(field),
                        'ui:readonly': props.readOnlyMode || field.disabled
                    }
                };
            }, {})
        );
    }, [props.schema]);

    const schema: JSONSchema7 = useMemo(() => {
        const jsonSchema = props?.schema;

        if (!jsonSchema) {
            return {
                type: 'object',
                required: [],
                properties: undefined
            };
        }

        return {
            ...jsonSchema,
            required: jsonSchema.required || [],
            properties: {
                ...jsonSchema.properties,
                ...Object.keys(jsonSchema.properties).reduce((acc: Dictionary<any>, key: string) => {
                    return {
                        ...acc,
                        [key]: {
                            ...jsonSchema.properties[key],
                            default: props.formData && props.formData[key]
                        }
                    };
                }, {})
            }
        };
    }, [props.schema, props.formData]);

    const isValidate = useMemo(() => {
        // description: Start form validation if all required fields are filled
        return (
            formData &&
            Object.keys(formData).every((el) => {
                const requiredFields = (schema.required || []).reduce(
                    (acc: Dictionary<any>, key: string) => ({
                        ...acc,
                        [key]: formData[key]
                    }),
                    {}
                );
                if (requiredFields[el]) {
                    return formData[el];
                }
                return true;
            })
        );
    }, [formData]);

    return (
        <div qa-id={props.qaId} className={`form-body ${props.readOnlyMode && 'disabled'}`}>
            <LoadPlaceholder loadingStatus={LoadingStatusType.isLoaded} defaultDelay={0}>
                <Form<any>
                    formData={formData}
                    schema={schema}
                    uiSchema={uiSchema}
                    onSubmit={onSubmit}
                    showErrorList={false}
                    liveValidate={isValidate}
                    onChange={onChange}
                    transformErrors={transformErrors}
                >
                    {props.isHiddenSubmit && <div />}
                    {props.footer}
                </Form>
            </LoadPlaceholder>
        </div>
    );
};
