import { action, computed, flow, observable, makeObservable, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';
import { AvailableRoles, RoleType, User, AvailablePermissions } from '@modules/core/models';
import { alertStore } from './AlertStore';
import { apiStore } from './ApiStore';
import { HttpClient, RoleUtils } from '@modules/core/utils';
import { PermissionsUtils } from '@modules/core/utils/PermissionsUtils';
import { AccountInfo } from '@azure/msal-browser';
import { IS_DEV } from '@config/config';

const Mocked_Roles = ['Administrator', AvailablePermissions.ApiClients.writeAll];

export default class UserStore {
    azureUser: AccountInfo;
    currentUser: User = { id: '', role: '' };
    roles: RoleType[] = [];
    permissions: RoleType[] = [];

    constructor() {
        makeObservable(this, {
            currentUser: observable.ref,
            roles: observable.ref,
            permissions: observable.ref,
            loadUserRoles: action,
            isSuperUser: computed,
            isSupportUser: computed,
            isUser: computed,
            isUserWithAccount: computed,
            canViewApiClients: computed,
            canEditApiClients: computed
        });

        this.loadCurrentUser = this.loadCurrentUser.bind(this);
        this.joinOrganization = this.joinOrganization.bind(this);
    }

    // TODO: refactor this store to use RolesApi
    loadCurrentUser = flow(function* (this: UserStore) {
        try {
            this.currentUser = yield HttpClient.TCMS.GET('users/me', {
                fields: 'organization'
            });
        } catch (error) {
            const idTokenClaims = this.azureUser?.idTokenClaims as {
                [key: string]: any;
            };

            this.currentUser = {
                id: IS_DEV ? 'oid' : idTokenClaims?.oid || idTokenClaims?.sid,
                firstName: IS_DEV ? 'Admin' : this.azureUser.name,
                lastName: '',
                role: ''
            };
        }
    });

    setAzureUser = (account: AccountInfo) => {
        this.azureUser = account;
    };

    loadUserRoles = () => {
        try {
            let roles: RoleType[];
            if (process.env.REACT_APP_DEV) {
                roles = Mocked_Roles as RoleType[];
            } else {
                const idTokenClaims = <Partial<{ roles: RoleType[] }>>this.azureUser?.idTokenClaims;
                roles = idTokenClaims?.roles || [];
            }

            runInAction(() => {
                this.roles = roles.map((role: string) => AvailableRoles[role]);
                // Discrimination: Permissions are then allowed to completely replace roles.
                // Discrimination: permissions come in the same property as roles are now.
                this.permissions = roles;
            });
            apiStore.setApiByRole(this.roles);
        } catch (error) {
            alertStore.error(error, () => ({ message: 'Failed to load user`s permissions' }));
        }
    };

    joinOrganization = flow(function* (this: UserStore, organizationId: string) {
        try {
            yield HttpClient.TCMS.PUT('admin/users/me/organization', organizationId);
            yield this.loadCurrentUser();
            alertStore.success({ message: 'You has successfully joined organization' });
        } catch (error) {
            alertStore.error(error, () => ({ message: 'Failed to join organization' }));
        }
    });

    isAccessable = computedFn((accessRoles: RoleType[], exact: boolean) =>
        RoleUtils.isAccessible(accessRoles, exact, this.roles)
    );

    isAccessablePermission = computedFn((permission: string) =>
        PermissionsUtils.isAccessible(permission, this.permissions)
    );

    //TODO: split Super and Support rules
    get isSuperUser() {
        return this.isAccessable([RoleType.Superuser, RoleType.Support], false);
    }

    get isSupportUser() {
        return this.isAccessable([RoleType.Support], false) && !this.isAccessable([RoleType.Superuser], false);
    }

    get isUser() {
        return this.isAccessable([RoleType.User], false) && !this.isAccessable([RoleType.Superuser], false);
    }

    get isUserWithAccount() {
        return this.isAccessable([RoleType.User, RoleType.Admin], false);
    }

    get canViewApiClients() {
        return (
            this.canEditApiClients ||
            this.isAccessablePermission(AvailablePermissions.ApiClients.read) ||
            this.isAccessablePermission(AvailablePermissions.ApiClients.readAll)
        );
    }

    get canEditApiClients() {
        return (
            this.isAccessablePermission(AvailablePermissions.ApiClients.write) ||
            this.isAccessablePermission(AvailablePermissions.ApiClients.writeAll)
        );
    }
}

export const userStore = new UserStore();
