import { observable, makeObservable, flow } from 'mobx';
import { DataSource } from '@modules/fleet/models';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';
import { alertStore } from '@modules/core/store/AlertStore';
import { DataSourceSchema, LoadingStatusState } from '@modules/core/models';

export class DataSourceStore {
    dataSourceSchemas: DataSourceSchema[] = [];
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            dataSourceSchemas: observable
        });
        this.loadDataSourceSchemas = this.loadDataSourceSchemas.bind(this);

        this.createDataSource = this.createDataSource.bind(this);
        this.updateDataSource = this.updateDataSource.bind(this);
        this.deleteDataSource = this.deleteDataSource.bind(this);
    }

    loadDataSourceSchemas = flow(function* (this: DataSourceStore) {
        const { api } = apiStore.api.dataSources.getDataSourceSchemas;

        try {
            this.loadingStatusState.load();
            const response = yield fetch(api());
            this.dataSourceSchemas = yield response.json();
            this.loadingStatusState.loadSuccess();
        } catch (error) {
            //TODO: error message
            this.loadingStatusState.loadSuccess();
        }
    });

    createDataSource = flow(function* (this: DataSourceStore, vesselId: string, data: DataSource) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.dataSources.postDataSource;
        try {
            this.loadingStatusState.load();
            yield HttpClient.TCMS.POST(api(vesselId), data);
            this.loadingStatusState.loadSuccess();
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
            this.loadingStatusState.loadSuccess();
        }
    });

    updateDataSource = flow(function* (this: DataSourceStore, vesselId: string, data: DataSource) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.dataSources.putDataSource;
        try {
            this.loadingStatusState.load();
            const { id, ...parameters } = data;
            yield HttpClient.TCMS.PUT(api(vesselId, id), parameters);
            this.loadingStatusState.loadSuccess();
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
            this.loadingStatusState.loadSuccess();
        }
    });

    deleteDataSource = flow(function* (this: DataSourceStore, vesselId: string, data: DataSource) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.dataSources.deleteDataSource;
        try {
            this.loadingStatusState.load();
            const { id } = data;
            yield HttpClient.TCMS.DELETE(api(vesselId, id));
            this.loadingStatusState.loadSuccess();
            alertStore.success(successMessage);
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
            this.loadingStatusState.loadSuccess();
        }
    });
}

export default {
    dataSourceStore: new DataSourceStore()
};
