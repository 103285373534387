import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useObserver } from 'mobx-react-lite';
import { autorun, IReactionDisposer } from 'mobx';
import { ModalProps } from '@modules/shared/models';
import { ModalWindow } from '@modules/shared/components';
import { useStore } from '@store/StoreEffect';
import { Form } from '@modules/core/components';
import { Organization } from '@modules/organizations/models';
import { Account } from '@modules/accounts/models';
import { CoreUtil, isFormFieldsDirty } from '@modules/core/utils';
import { LoadingStatusType, Option } from '@modules/core/models';
import { AccountsFormEditFields, AccountsFormFields } from '@modules/core/constants';

import './CreateAccountModal.scss';

interface Props extends ModalProps {
    isEditMode: boolean;
    account?: Account;
    selectedOrganization?: Organization;
    loadingStatus?: LoadingStatusType;
}

export const CreateAccountModal = (props: Props) => {
    const { modalIsOpen, onCloseModal, isEditMode, selectedOrganization, loadingStatus } = props;

    const {
        register,
        getValues,
        control,
        setValue,
        handleSubmit,
        watch,
        formState: { errors, isValid, isDirty },
        clearErrors
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    });
    const watchAllFields = watch();
    const { userStore, rolesStoreAll, rolesStoreOrganization, accountStore } = useStore();
    const { currentUser, isSuperUser } = userStore;
    const [organization, setOrganization] = useState<Organization>(null);
    const [loading, setLoading] = useState<LoadingStatusType>(LoadingStatusType.isLoading);
    const [account, setAccount] = useState<Account>(props.account);
    const [debounce] = useState(() => CoreUtil.debounce());
    const rolesStore = userStore.isSuperUser ? rolesStoreOrganization : rolesStoreAll;
    const { loadingStatusState: loadingRoles } = rolesStore;
    const fieldsCollection = useMemo(
        () => (!!props.account?.id ? AccountsFormEditFields : AccountsFormFields),
        [props.account]
    );

    useEffect(() => {
        if (organization?.id) {
            rolesStore.loadRoles(organization?.id);
        }
    }, [rolesStore, organization?.id]);

    useEffect(() => {
        let disposer: IReactionDisposer;
        debounce(() => {
            disposer = autorun(async () => {
                if (props.account?.organization?.id) {
                    const loadOrgAccount = await accountStore.getAccountOfOrganization(
                        props.account.id,
                        props.account.organization.id
                    );
                    if (loadOrgAccount) {
                        setAccount({
                            ...account,
                            ...loadOrgAccount
                        });
                    }
                }
                const isRolesLoaded = loadingRoles.status === LoadingStatusType.isLoaded;
                if (isRolesLoaded) {
                    if (modalIsOpen && !isEditMode) {
                        setLoading(LoadingStatusType.isLoaded);
                    }
                    const isAccountLoaded = loadingStatus === LoadingStatusType.isLoaded;
                    if (modalIsOpen && isEditMode && isAccountLoaded && account?.id) {
                        if (account?.id) {
                            setValue('email', account.email);
                        }
                        setLoading(LoadingStatusType.isLoaded);
                    }
                }
            });
        }, 400);

        return () => {
            if (disposer) {
                disposer();
            }
        };
    }, [debounce, modalIsOpen, isEditMode, props.account, loadingStatus, loadingRoles]);

    const onSubmit = async () => {
        if (!isValid) {
            return;
        }
        let data = getValues();
        if (isEditMode) {
            data = {
                ...account,
                ...data
            };
        }
        await props.onSubmit({
            ...data,
            roles: data.roles.map((role: Option) => role.value),
            organization: organization
        });
    };

    useEffect(() => {
        const disposer = autorun(() => {
            const organization = isSuperUser
                ? account?.organization ?? selectedOrganization
                : currentUser?.organization;
            setOrganization(organization);
        });
        return () => disposer();
    }, [account, currentUser, isSuperUser, selectedOrganization]);

    const isLoading = loading === LoadingStatusType.isLoading;

    const storeRoles = useMemo(() => {
        return !isLoading ? rolesStore.roles || [] : [];
    }, [rolesStore.roles, loadingRoles, isLoading]);

    const mapFields = useMemo(
        () => [
            ...CoreUtil.mapFields(fieldsCollection, account),
            CoreUtil.mapFieldRoles(account?.roles, storeRoles, fieldsCollection.roles)
        ],
        [account, storeRoles, isLoading]
    );

    const fields = useMemo(() => {
        return !isLoading ? mapFields : [];
    }, [account, mapFields, isLoading]);

    const isButtonDisabled = useMemo(() => {
        return isFormFieldsDirty(mapFields, watchAllFields, fieldsCollection) || Object.keys(errors).length;
    }, [isDirty, watchAllFields]);

    const isFormLoaded = !isLoading && fields.length > 0;

    return useObserver(() => (
        <ModalWindow
            disabledSubmit={isButtonDisabled}
            modalIsOpen={modalIsOpen}
            className="create-account-popup"
            title={isLoading ? '' : isEditMode ? 'EDIT ACCOUNT' : 'NEW ACCOUNT'}
            submitButtonName={isLoading ? '' : isEditMode ? 'SAVE' : 'CREATE'}
            onSubmit={handleSubmit(onSubmit)}
            onCancel={() => onCloseModal(false)}
            loadingModal={loading}
        >
            {isFormLoaded && (
                <Form
                    qaId={'create-account'}
                    isEditMode={isEditMode}
                    useForm={{
                        register,
                        setValue,
                        getValues,
                        control,
                        formState: { errors },
                        clearErrors
                    }}
                    fields={fields}
                    customFields={
                        <div>
                            {userStore.isSuperUser && (
                                <div className="form-control-block">
                                    <label>Organization</label>
                                    <span>{organization?.name}</span>
                                </div>
                            )}
                        </div>
                    }
                />
            )}
        </ModalWindow>
    ));
};
