import {
    AuthenticationResult,
    EventMessage,
    EventMessageUtils,
    InteractionRequiredAuthError,
    InteractionStatus,
    IPublicClientApplication
} from '@azure/msal-browser';

import { HttpResource } from '@modules/core/utils';
import { msal } from '@config/msalConfig';
import { IS_DEV, msalConfig } from '@config/config';
import { AppInsights } from './AppInsights';

export class HttpClient {
    static TCMS: HttpResource;

    private static callbackId = '';
    private static interactionStatus: InteractionStatus = InteractionStatus.None;

    static init() {
        this.callbackId = msal.instance.addEventCallback((message: EventMessage) => {
            const status = EventMessageUtils.getInteractionStatusFromEvent(message);

            if (status) {
                this.interactionStatus = status;
            }
        });

        HttpClient.TCMS = new HttpResource(
            msalConfig.REACT_APP_TCMS_API_URL,
            {},
            {
                getToken: IS_DEV ? null : async () => (await this.acquireTokenSilent())?.accessToken
            }
        );
    }

    static deinit() {
        msal.instance.removeEventCallback(this.callbackId);
    }

    private static async acquireTokenSilent() {
        let response: AuthenticationResult;
        const instance: IPublicClientApplication = msal.instance;
        const scopes = msal.scopes;
        try {
            response = await instance.acquireTokenSilent({
                scopes,
                // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#block_iframe_reload
                redirectUri: '/redirect'
            });
        } catch (error) {
            const props = { errorType: 'auth' };
            AppInsights.trackException(error, props);

            // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/errors.md#browserautherrors
            if (this.interactionStatus !== InteractionStatus.None) {
                return;
            }

            if (error instanceof InteractionRequiredAuthError) {
                try {
                    // maybe retrieval of this token requires user permission/interaction
                    await instance.acquireTokenRedirect({
                        scopes: scopes
                    });
                } catch (error) {
                    AppInsights.trackException(error, props);
                    // Still an error? Do login flow again
                    await instance.loginRedirect({
                        scopes
                    });
                }
            } else {
                // Other type of error? Do login flow again
                await instance.loginRedirect({
                    scopes
                });
            }
        }
        return response;
    }
}
