import { NavigationItem } from '../models';

export const ApiClientsDetailsNavItems: NavigationItem[] = [
    {
        title: 'General',
        link: 'general',
        replace: true
    },
    {
        title: 'Secret',
        link: 'secret',
        replace: true
    },
    {
        title: 'Fleet',
        link: 'fleet',
        replace: true
    }
    // Uncomment when BE will be ready [FOSWEB-11723]
    // {
    //     title: 'Variables',
    //     link: 'variables',
    //     replace: true
    // }
];
