import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export class AppInsights {
    static instance: ApplicationInsights;

    static init(code: string) {
        if (!code) {
            return;
        }
        AppInsights.instance = new ApplicationInsights({
            config: {
                instrumentationKey: code,
                disableTelemetry: true
            }
        });
        AppInsights.instance.loadAppInsights();
    }

    static trackException(exception: any, props?: { [key: string]: any }) {
        if (!AppInsights.instance || !exception) {
            return;
        }
        const userId = AppInsights.instance.context.user.id;
        const properties = { userId, ...props };
        AppInsights.instance.trackException({ exception }, properties);
    }
}
