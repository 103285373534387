import React, { useCallback, useEffect, useMemo } from 'react';
import { flow } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import get from 'lodash/get';
import { SearchInput } from '@fos/eniram-web-common-ui/dist/components/SearchInput';

import { useStore } from '@store/StoreEffect';
import { AccountsTable, CreateAccountModal } from '@modules/accounts/components';
import { Account } from '@modules/accounts/models';
import { usePageContainer, useRolesState, useSelectedState } from '@modules/shared/hooks';
import { ConfirmModal } from '@modules/shared/components';
import { AccountsDetailsPage } from './details/AccountsDetailsPage';
import { AccountsStore } from '@modules/accounts/store/AccountsStore';
import { RolesStore } from '@modules/roles/store/RolesStore';
import { LoadingStatusType } from '@modules/core/models';
import { LoadPlaceholder } from '@modules/core/components';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';

import './AccountsPage.scss';

interface Props {
    isOrganization?: boolean;
    accountsStore: AccountsStore;
    rolesStore: RolesStore;
}

export const AccountsPage = (props: Props) => {
    const match = useRouteMatch();
    return (
        <Switch>
            <Route path={`${match.path}/:userId`}>
                <AccountsDetailsPage />
            </Route>
            <Route path={`${match.path}`}>
                <AccountsPageTable {...props} />
            </Route>
        </Switch>
    );
};

export const AccountsPageTable = (props: Props) => {
    const { isOrganization, accountsStore, rolesStore } = props;
    const { userStore, accountStore } = useStore();

    const { organization, account: selectedAccount } = useSelectedState();
    const { isSupportUser, isSuperUser } = useRolesState();

    const match = useRouteMatch();
    const { filterAccounts, loadAccounts, loadingStatusState } = accountsStore;

    const { addAccount, deleteAccount } = accountStore;
    const { updateAccount } = accountStore;
    const { currentUser } = userStore;

    const newButtonVisible = addAccount && !isSupportUser && (!isSuperUser || isOrganization);
    const selectedOrganization = useMemo(() => organization, [organization]);
    const currentUserOrganization = useMemo(() => currentUser?.organization, [currentUser]);
    const ownerOrganizationId = isSuperUser ? selectedAccount?.organization?.id : currentUserOrganization?.id;
    const organizationId = useMemo(
        () => (isOrganization ? selectedOrganization?.id : ownerOrganizationId),
        [selectedOrganization, currentUserOrganization, isOrganization]
    );
    const isLoadedPage = useMemo(
        () => isSupportUser || isSuperUser || !!currentUser?.id,
        [organization?.id, currentUser?.organization, isOrganization]
    );

    const containerLoadingFunction = useCallback(
        flow(function* () {
            if (isLoadedPage) {
                let fields = 'roles';
                if (isSuperUser || isSupportUser) {
                    fields = 'organization';
                }
                yield loadAccounts(organizationId, fields);
            }
        }),
        [organizationId]
    );

    useEffect(() => {
        if (organizationId) {
            rolesStore.loadRoles(organizationId);
        }
    }, [organizationId]);

    const searchArea = 'usersPage';
    const {
        setAssignModalVisibility,
        setEditModalVisibility,
        setDeleteModalVisibility,
        selectEntity,
        selectedEntity,
        isEditMode,
        setQuery,
        entities,
        sortEntities,
        addEntity,
        editEntity,
        deleteEntity,
        isCreateEditModalVisible,
        onCloseCreateEditModal,
        isDeleteModalVisible,
        setIsEditMode,
        query
    } = usePageContainer(
        containerLoadingFunction,
        filterAccounts,
        addAccount,
        updateAccount,
        deleteAccount,
        false,
        searchArea
    );

    const onOpenEditAccountModal = () => {
        if (updateAccount) {
            setEditModalVisibility(true);
            setIsEditMode(true);
        }
    };

    const onCreateAccount = async (account: Partial<Account>) => {
        const newAccount = await addEntity(account);
        if (newAccount) {
            accountsStore.insertAccount(newAccount);
        }
        setAssignModalVisibility(false);
    };

    const onEditAccount = async (editedAccount: Account, account: Account) => {
        const { roles: updatedRoles, ...updatedFields } = editedAccount;
        const updatedFieldNames = Object.keys(updatedFields);

        const isUpdatedAccount = !!updatedFieldNames.find((field) => {
            return get(updatedFields, field) !== get(account, field);
        });
        const isUpdatedRoles = !isEmpty(xor(account.roles, updatedRoles));

        if (isUpdatedRoles || isUpdatedAccount) {
            const updatedAccount = await editEntity({ account: editedAccount, isUpdatedAccount, isUpdatedRoles });
            if (updatedAccount) {
                editedAccount.roles = editedAccount.roles || account.roles;
                accountsStore.replaceAccount(editedAccount);
                selectedEntityState.account = editedAccount;
            }
        }

        setEditModalVisibility(false);
        selectEntity(null);
    };

    const onSubmit = async (account: Account) => {
        !isEditMode ? await onCreateAccount(account) : await onEditAccount(account, selectedEntity);
    };

    const onDelete = async () => {
        const deletedAccount = await deleteEntity(selectedEntity);
        if (deletedAccount) {
            accountsStore.removeAccount(deletedAccount);
        }
        setDeleteModalVisibility(false);
    };

    return useObserver(() => (
        <div className="accounts-page">
            <Switch>
                <Route path={`${match.path}`}>
                    <main className="card">
                        <LoadPlaceholder loadingStatus={loadingStatusState.status || LoadingStatusType.isLoaded}>
                            <header className="card-header">
                                <div>{`Users (${entities.length})`}</div>
                                <div className="card-header-controls">
                                    {addAccount && newButtonVisible && (
                                        <button
                                            className="button primary"
                                            onClick={() => setAssignModalVisibility(true)}
                                        >
                                            NEW
                                        </button>
                                    )}
                                    <SearchInput
                                        onChange={(value: string) => setQuery(value)}
                                        autofocus={false}
                                        placeholder="Search by name or email"
                                        search={query}
                                    />
                                </div>
                            </header>
                            <section className="card-body">
                                <AccountsTable
                                    isOrganization={isOrganization}
                                    id="organization_accounts_table"
                                    accounts={entities}
                                    rolesMap={rolesStore.rolesMap}
                                    sortEntities={sortEntities}
                                    loadingStatus={loadingStatusState.status}
                                    setEditModalVisibility={onOpenEditAccountModal}
                                    setDeleteModalVisibility={deleteAccount ? setDeleteModalVisibility : null}
                                    setSelectedAccount={selectEntity}
                                />
                            </section>
                        </LoadPlaceholder>
                    </main>

                    {isCreateEditModalVisible() ? (
                        <CreateAccountModal
                            loadingStatus={loadingStatusState.status}
                            selectedOrganization={organization}
                            account={selectedEntity}
                            modalIsOpen={isCreateEditModalVisible()}
                            onCloseModal={onCloseCreateEditModal()}
                            isEditMode={isEditMode}
                            onSubmit={onSubmit}
                        />
                    ) : null}

                    {isDeleteModalVisible ? (
                        <ConfirmModal
                            modalTitle="DELETE ACCOUNT"
                            modalContentText={`Do you want to delete account with ${selectedEntity?.email} email?`}
                            submitButtonTitle="DELETE"
                            modalIsOpen={isDeleteModalVisible}
                            onCloseModal={setDeleteModalVisibility}
                            onSubmit={onDelete}
                            loading={selectedEntity ? LoadingStatusType.isLoaded : LoadingStatusType.isLoading}
                        />
                    ) : null}
                </Route>
            </Switch>
        </div>
    ));
};
