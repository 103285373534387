import { flow } from 'mobx';
import { alertStore } from '@modules/core/store/AlertStore';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';
import { FleetStore } from './FleetStore';

export class FleetStoreAll extends FleetStore {
    constructor() {
        super();

        this.loadVessels = this.loadVessels.bind(this);
    }

    loadVessels = flow(function* (this: FleetStore) {
        const { api, errorMessageHandler } = apiStore.api.vessels.getVessels;
        try {
            this.loadingStatusState.load();
            this.vessels = yield HttpClient.TCMS.GET(api());
            this.loadingStatusState.loadSuccess();
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
            throw error;
        }
    });
}
