import React from 'react';
import { useObserver } from 'mobx-react-lite';

import { useStore } from '@store/StoreEffect';
import { CheckboxSlider, EntityTable } from '@modules/shared/components';
import { Option } from '@modules/connected-products/models';
import { DateUtil } from '@modules/shared/utils';
import { RoleType } from '@modules/core/models';
import { ColumnSize, Dictionary } from '@modules/shared/models';

import './ConnectedProductsTable.scss';

interface Props {
    options: Option[];
    isProductMode?: boolean;
    onSubscribe: (code: any) => void;
    subscriptions: Dictionary<Option>;
}

export const ConnectedProductsTable = (props: Props) => {
    const { options, onSubscribe, subscriptions, isProductMode } = props;
    const { userStore } = useStore();

    const isColumnHidden = (roles: RoleType[]) => {
        return isProductMode && userStore.isAccessable(roles, false);
    };

    const columns = [
        {
            title: 'OPTION',
            columnSize: ColumnSize.COLUMN2,
            className: (option: Option) => {
                if (!option || isColumnHidden([RoleType.Superuser, RoleType.Support])) {
                    return 'divider';
                }

                return `${subscriptions[option?.code] ? 'option-subscribed' : 'option-unsubscribe'} divider`;
            },
            // eslint-disable-next-line react/display-name
            render: (option: Option) => <span title={option.code}>{option.name}</span>
        },
        {
            title: '',
            columnSize: ColumnSize.COLUMN1,
            className: () => 'product-options-slider',
            // eslint-disable-next-line react/display-name
            render: (option: Option) =>
                !option.immutable ? (
                    <CheckboxSlider
                        isChecked={!!subscriptions[option.code]}
                        disabled={option.immutable}
                        handleChange={() => onSubscribe(option)}
                        id={'product-option-switcher-' + option.code}
                    />
                ) : (
                    <i className="icon icon-lock"></i>
                ),
            hidden:
                isColumnHidden([RoleType.Superuser, RoleType.Support]) ||
                !userStore.isSuperUser ||
                userStore.isSupportUser
        },
        {
            title: 'DATE',
            columnSize: ColumnSize.COLUMN1,
            render: (option: Option) => DateUtil.formatDate(option.expirationDate),
            hidden: isColumnHidden([RoleType.Superuser, RoleType.Support])
        },
        {
            title: 'INFO',
            columnSize: ColumnSize.COLUMN3,
            property: 'description'
        },
        {
            title: 'STATUS',
            columnSize: ColumnSize.COLUMN3,
            render: (option: Option) => {
                if (subscriptions[option.code] && !option.immutable) {
                    return 'This option was turned on by Wartsila Admin';
                }

                if (DateUtil.isBefore(option.expirationDate)) {
                    return 'License is over';
                }
            }
        }
    ];

    return useObserver(() => (
        <EntityTable
            id="options_table"
            listOfEntities={options}
            hiddenControls={() => true}
            columns={columns}
            setEditModalVisibility={null}
            setDeleteModalVisibility={null}
            setCurrentEntity={null}
        />
    ));
};
