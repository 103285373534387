import React, { useCallback, useMemo, useState } from 'react';
import { flow } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { Dropdown } from '@fos/eniram-web-common-ui/dist/components/DropDown';
import uniqBy from 'lodash/uniqBy';
import intersectionBy from 'lodash/intersectionBy';

import { useStore } from '@store/StoreEffect';
import { RolesTable } from '@modules/roles/components';
import { Role, RoleGroup } from '@modules/roles/models';
import { Product } from '@modules/connected-products/models';
import { usePageContainer, useRolesState, useSelectedState } from '@modules/shared/hooks';
import { AccountsStore } from '@modules/accounts/store/AccountsStore';
import { RolesStore } from '@modules/roles/store/RolesStore';
import { CreateRoleModal } from '@modules/roles/components';
import { ConfirmModal, Tooltip } from '@modules/shared/components';
import { LoadingStatusType, Option } from '@modules/core/models';
import { LoadPlaceholder } from '@modules/core/components';

import './RolesPage.scss';

interface Props {
    isOrganization?: boolean;
    accountsStore: AccountsStore;
    rolesStore: RolesStore;
}

export const RolesPage = (props: Props) => {
    const { isOrganization, rolesStore } = props;

    const { userStore } = useStore();
    const { currentUser } = userStore;
    const { organization } = useSelectedState();
    const { isSupportUser, isSuperUser } = useRolesState();
    const {
        loadRoles,
        roles,
        filterRoles,
        loadingStatusState,
        roleProductsGroup,
        productsPermissionsMap,
        addRole,
        editRole,
        deleteRole
    } = rolesStore;

    const [filteredPermissionsOptions, setFilteredPermissionsOptions] = useState<Option[]>([]);
    const newButtonVisible = !isSupportUser && (!isSuperUser || isOrganization);
    const organizationId = useMemo(
        () => (isOrganization ? organization?.id : currentUser?.organization?.id),
        [organization, currentUser, isOrganization]
    );

    const containerLoadingFunction = useCallback(
        flow(function* () {
            if (organizationId) {
                yield loadRoles(organizationId, true);
            }
        }),
        [isOrganization, loadRoles, organizationId]
    );

    const {
        entities,
        sortEntities,
        setEditModalVisibility,
        setCreateModalVisibility,
        setAssignModalVisibility,
        selectEntity,
        selectedEntity,
        isCreateEditModalVisible,
        isDeleteInnerModalVisible,
        setDeleteInnerModalVisibility,
        addEntity,
        editEntity,
        deleteEntity,
        onCloseCreateEditModal
    } = usePageContainer(containerLoadingFunction, filterRoles, addRole, editRole, deleteRole);

    const rolesMapData = useCallback(
        (array: Partial<Role>[]) => {
            return array.map((role: Partial<Role>) => {
                const permissionsData =
                    roleProductsGroup[role.id]?.products.length > 0
                        ? productsPermissionsMap(roleProductsGroup[role.id].products)
                        : [];
                const permissionsMap = roleProductsGroup[role.id]?.permissions
                    ? roleProductsGroup[role.id]?.permissions.map((id: string) => ({ id }))
                    : [];
                const permissionsWithDescr = intersectionBy(permissionsData, permissionsMap, 'id');
                return roleProductsGroup[role.id]
                    ? {
                          ...roleProductsGroup[role.id],
                          permissionsWithDescr,
                          permissionsData
                      }
                    : {
                          ...role,
                          permissionsWithDescr: [],
                          permissions: [],
                          products: [],
                          permissionsData: []
                      };
            });
        },
        [entities, roleProductsGroup, roles]
    );

    const arrayContainsAnotherArray = (role: Partial<RoleGroup>, filterPermissions: Option[]) => {
        if (!filterPermissions.length) {
            return true;
        }

        const intersections = uniqBy(intersectionBy(role.permissionsWithDescr, filterPermissions, 'name'), 'name');

        return filterPermissions.length === intersections.length;
    };

    const rolesList = useMemo(() => rolesMapData(entities), [entities, roleProductsGroup, roles]);

    const initAvailablePermissionsOptions = useCallback(
        (array: Product[] = []) => {
            const allProductspermissions = productsPermissionsMap(array);
            return uniqBy(allProductspermissions, 'name').map((el) => ({
                value: el.name,
                name: el.name,
                label: <Tooltip content={el.description}>{el.name}</Tooltip>
            }));
        },
        [entities, roleProductsGroup, roles]
    );

    const onSubmitCreate = async (data: { [key: string]: any }) => {
        data.organizationId = organizationId;
        await addEntity(data);
        setCreateModalVisibility(false);
        await onCloseCreateEditModal()();
    };

    const onEditRole = async (data: { [key: string]: any }) => {
        data.id = selectedEntity?.id;
        data.organizationId = selectedEntity?.organizationId || organizationId;
        await editEntity(data);
        await onCloseCreateEditModal()();
    };

    const onSubmitDelete = async () => {
        await deleteEntity({ roleId: selectedEntity.id, organizationId });
        await onCloseCreateEditModal()();
        setDeleteInnerModalVisibility(false);
    };

    return useObserver(() => (
        <div className="roles-page">
            <main className="card">
                <LoadPlaceholder loadingStatus={loadingStatusState.status || LoadingStatusType.isLoaded}>
                    <header className="card-header">
                        <div>Roles ({rolesList.length})</div>
                        <div className="card-header-controls">
                            {newButtonVisible && (
                                <button className="button primary" onClick={() => setAssignModalVisibility(true)}>
                                    NEW
                                </button>
                            )}
                            <div className="card-dropdown">
                                <label>Permission filter:</label>
                                <Dropdown
                                    qaId={`search-by-permission`}
                                    placeholder={'Select permission(s)'}
                                    actionFunc={setFilteredPermissionsOptions}
                                    options={initAvailablePermissionsOptions(rolesStore.products)}
                                    isSearchable
                                    isClearable
                                    isMulti
                                    value={filteredPermissionsOptions}
                                />
                            </div>
                        </div>
                    </header>
                    <section className="card-body">
                        <RolesTable
                            isOrganization={isOrganization}
                            id="roles_table"
                            roles={rolesList.filter((rg) => {
                                return arrayContainsAnotherArray(rg, filteredPermissionsOptions);
                            })}
                            filteredPermissions={filteredPermissionsOptions}
                            sortEntities={sortEntities}
                            setEditModalVisibility={setEditModalVisibility}
                            setDeleteModalVisibility={null}
                            loadingStatus={loadingStatusState && loadingStatusState.status}
                            setSelectedRole={selectEntity}
                            editRole={onEditRole}
                        />
                    </section>
                    {isCreateEditModalVisible() && (
                        <CreateRoleModal
                            rolesStore={rolesStore}
                            isEditMode={!!selectedEntity}
                            modalIsOpen={isCreateEditModalVisible()}
                            onCloseModal={onCloseCreateEditModal()}
                            onSubmit={!selectedEntity ? onSubmitCreate : onEditRole}
                            organizationId={organizationId}
                            role={selectedEntity}
                            handleDelete={setDeleteInnerModalVisibility}
                        />
                    )}
                    {isDeleteInnerModalVisible ? (
                        <ConfirmModal
                            qaId="delete-role"
                            modalTitle="DELETE ROLE"
                            modalContentText="Do you want to delete role?"
                            submitButtonTitle="DELETE"
                            modalIsOpen={isDeleteInnerModalVisible}
                            onCloseModal={() => setDeleteInnerModalVisibility(false)}
                            onSubmit={onSubmitDelete}
                        />
                    ) : null}
                </LoadPlaceholder>
            </main>
        </div>
    ));
};
