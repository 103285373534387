import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { ApiClient } from '@modules/api-clients/models';
import { LoadingStatusType } from '@modules/core/models';
import { EntityTable, Tooltip } from '@modules/shared/components';
import { ColumnSize, TableColumn } from '@modules/shared/models';
import { DateUtil } from '@modules/shared/utils';
import { SortOrderType } from '@modules/shared/constants';
import { CoreUtil, SortingUtils } from '@modules/core/utils';
import { usePermissionsState, useRolesState } from '@modules/shared/hooks';
import { CopyField } from '@modules/core/components';

interface Props {
    id: string;
    apiClients: ApiClient[];
    isOrganization: boolean;
    sortEntities?: (sortFunc: (prevItem: any, nextItem: any) => number) => void;
    setEditModalVisibility: (value: boolean) => any;
    setDeleteModalVisibility: (value: boolean) => any;
    setSelectedApiClient: (apiClient: ApiClient) => void;
    loadingStatus: LoadingStatusType;
}

const NameWithTooltip = (apiClient: ApiClient) => <Tooltip content={apiClient.name}>{apiClient.name}</Tooltip>;

export const ApiClientsTable = observer((props: Props) => {
    const {
        id,
        apiClients,
        isOrganization,
        loadingStatus,
        sortEntities,
        setEditModalVisibility,
        setDeleteModalVisibility,
        setSelectedApiClient
    } = props;

    const history = useHistory();
    const { isSuperUser } = useRolesState();
    const { canEditApiClients } = usePermissionsState();

    const isOrganizationColumnHidden = useMemo(
        () => !isSuperUser || (isSuperUser && isOrganization),
        [isSuperUser, isOrganization]
    );

    const columns: TableColumn[] = [
        {
            title: 'NAME',
            columnSize: ColumnSize.COLUMN2,
            property: 'name',
            className: () => 'divider',
            render: NameWithTooltip,
            onClick: (apiClient: ApiClient) => CoreUtil.goToPage(history, `/api-clients/${apiClient.id}`)
        },
        {
            title: 'ID',
            columnSize: ColumnSize.COLUMN3,
            property: 'id',
            className: () => 'with-copy-field',
            render: (row: ApiClient) => <CopyField copyText={row.id}>{row.id}</CopyField>
        },
        {
            title: 'SECRET EXPIRES',
            columnSize: ColumnSize.COLUMN2,
            property: 'secret.expirationDate',
            render: (apiClient: ApiClient) =>
                DateUtil.formatDate(apiClient.secret?.expirationDate, 'DD MMMM YYYY HH:mm')
        },
        {
            title: 'ORGANIZATION',
            columnSize: ColumnSize.COLUMN2,
            property: 'organization.name',
            render: (row: ApiClient) => row.organization?.name,
            onClick: (apiClient: ApiClient) =>
                CoreUtil.goToPage(history, `/organizations/${apiClient.organization.id}`),
            hidden: isOrganizationColumnHidden
        },
        {
            title: 'REGISTRATION DATE',
            columnSize: ColumnSize.COLUMN2,
            property: 'creationDate',
            render: (apiClient: ApiClient) => DateUtil.formatDate(apiClient.creationDate)
        }
    ];

    const sortApiClients = (property: string, order: SortOrderType) => {
        sortEntities(SortingUtils.defaultCompare(property, order));
    };

    const isEntityModifiable = () => canEditApiClients;

    return (
        <EntityTable
            id={id}
            listOfEntities={apiClients}
            sortEntities={sortApiClients}
            loadingStatus={loadingStatus}
            columns={columns}
            setEditModalVisibility={canEditApiClients && setEditModalVisibility}
            setDeleteModalVisibility={canEditApiClients && setDeleteModalVisibility}
            setCurrentEntity={setSelectedApiClient}
            deletable={isEntityModifiable}
            editable={() => false}
            customIconControls={
                canEditApiClients
                    ? [
                          {
                              icon: 'icon-secret',
                              callback: (item: ApiClient) =>
                                  CoreUtil.goToPage(history, `/api-clients/${item.id}/secret`)
                          }
                      ]
                    : null
            }
            loadingDelay={0}
        />
    );
});
