export enum ColumnSize {
    COLUMN1 = 'column-1',
    COLUMN2 = 'column-2',
    COLUMN3 = 'column-3',
    COLUMN4 = 'column-4',
    COLUMN5 = 'column-5',
    COLUMN6 = 'column-6',
    COLUMN7 = 'column-7',
    COLUMN8 = 'column-8',
    COLUMN9 = 'column-9',
    COLUMN10 = 'column-10',
    COLUMN11 = 'column-11',
    COLUMN12 = 'column-12'
}
