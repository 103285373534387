import { NavigationItem } from '../models';

export const VesselsDetailsNavItems: NavigationItem[] = [
    {
        title: 'General',
        link: 'general',
        replace: true
    },
    {
        title: 'Options',
        link: 'options',
        replace: true
    }
];

export const VesselsDetailsNavItemsSuperuser: NavigationItem[] = [
    {
        title: 'General',
        link: 'general',
        replace: true
    },
    {
        title: 'Data sources',
        link: 'datasources',
        replace: true
    },
    {
        title: 'Options',
        link: 'options',
        replace: true
    }
];
