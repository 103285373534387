import { AlertMessage } from '@modules/core/models';
import { CoreUtil } from '@modules/core/utils';

export class OrganizationsErrorHandlers {
    static DeleteOrganizationErrorHandler(error: any): AlertMessage {
        const message = 'Failed to delete organization';
        let description = '';
        switch (error.status) {
            case 404:
                description = 'Organization with this id was not found';
                break;
            case 400:
                description = 'Organization has users or vessels';
                break;
            default:
                return CoreUtil.defaultErrorMessage(error);
        }

        return {
            message: message,
            description: description
        };
    }
    static GetConnectedProductsOrganizationErrorHandler(error: any): AlertMessage {
        const message = 'Failed to load connected products';
        let description = '';
        switch (error.status) {
            case 404:
                description = 'Organization with this id was not found';
                break;
            case 400:
                description = 'Organization with this id is not valid';
                break;
            default:
                return CoreUtil.defaultErrorMessage(error);
        }

        return {
            message: message,
            description: description
        };
    }
}
