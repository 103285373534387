import React, { useCallback, useEffect } from 'react';
import { flow } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { Organization } from '@modules/organizations/models';
import { FleetStore } from '@modules/fleet/store';
import { DataSourceStore } from '@modules/core/store/DataSourceStore';
import { VesselGroupStore } from '@modules/vessel-groups/store';
import { ConnectedProductsStore } from '@modules/connected-products/store/ConnectedProductsStore';
import { VesselGroupsPage } from '@pages/vessel-groups-page/VesselGroupsPage';
import { VesselsWithGroupsPage, VesselsPage } from '@pages/vessels-page';
import { RoleRestriction } from '@modules/core/components';
import { LoadingStatusType, RoleType } from '@modules/core/models';
import { usePageContainer, usePersistStorage, useSelectedState } from '@modules/shared/hooks';
import { CancellablePromise } from 'mobx/dist/api/flow';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';
import { LoadPlaceholder } from '@modules/core/components';
import { Vessel } from '@modules/fleet/models';

import './FleetPage.scss';

interface Props {
    modalLoadingFunction?: (...args: any[]) => CancellablePromise<void>;
    modalFilterFunction?: (value: string) => any[];
    filterVesselsGroup?: (value: string) => any[];
    isEditDeleteAllowed?: boolean;
    ownerOrganization?: Organization;
    fleetStore?: FleetStore;
    dataSourceStore?: DataSourceStore;
    vesselGroupStore: VesselGroupStore;
    connectedProductsStore: ConnectedProductsStore;
    isVesselGroupsModalVisible?: boolean;
    isOrganisationDetail?: boolean;
    isCreateAllowed?: boolean;
    isEditAllowed?: boolean;
    isShowGroupsFilter?: boolean;
    accessRoles?: RoleType[];
}

export const FleetPage = observer<Props>((props) => {
    const {
        vesselGroupStore,
        fleetStore,
        ownerOrganization,
        isOrganisationDetail,
        modalLoadingFunction,
        modalFilterFunction,
        isEditAllowed,
        isCreateAllowed,
        filterVesselsGroup,
        accessRoles,
        isShowGroupsFilter = true
    } = props;
    const [isVesselGroupsHidden, setIsVesselGroupHidden] = usePersistStorage('vesselGroupsHidden', false);

    useEffect(() => {
        selectedEntityState.resetVesselGroup();
    }, []);

    const { loadVessels, addVessels, deleteVessels, filterVessels, editVessels } = fleetStore;

    const vesselsPageContainerLoadingFunction = useCallback(
        flow(function* () {
            if (!isShowGroupsFilter) {
                yield loadVessels();
            }
        }),
        []
    );

    const vesselsPageState = usePageContainer(
        vesselsPageContainerLoadingFunction,
        filterVessels,
        addVessels,
        editVessels,
        deleteVessels,
        false
    );

    const { organization, account } = useSelectedState();
    const { userId } = useParams() as { userId: string };

    const vesselsWithGroupsPageContainerLoadingFunction = useCallback(
        flow(function* () {
            if (userId && userId !== account?.id) {
                return;
            }
            if (fleetStore.isAllVesselGroupSelected) {
                yield loadVessels();
            }
        }),

        [organization?.id, account?.id, fleetStore.isAllVesselGroupSelected]
    );

    const searchAreaVessels = 'fleetPage';
    const vesselsWithGroupsPageState = usePageContainer(
        vesselsWithGroupsPageContainerLoadingFunction,
        filterVessels,
        addVessels,
        editVessels,
        deleteVessels,
        false,
        searchAreaVessels
    );

    const isVesselDeletable = (vessel: Vessel) =>
        ownerOrganization?.id === vessel.owner?.id && vessel.createdManually && isOrganisationDetail;

    return (
        <div className="fleet-page">
            {isShowGroupsFilter && (
                <RoleRestriction accessRoles={accessRoles} exact={false} excludeSuperUser={!isOrganisationDetail}>
                    <main className={`card vessel-groups ${isVesselGroupsHidden ? 'hidden' : ''}`}>
                        <VesselGroupsPage
                            filterVessels={filterVesselsGroup}
                            fleetStore={fleetStore}
                            vesselGroupStore={vesselGroupStore}
                            setIsVesselGroupsHidden={setIsVesselGroupHidden}
                            isVesselGroupsHidden={isVesselGroupsHidden}
                        />
                    </main>
                </RoleRestriction>
            )}
            <main className="card vessels">
                {isShowGroupsFilter ? (
                    <LoadPlaceholder
                        loadingStatus={LoadingStatusType.isLoaded}
                        {...(isShowGroupsFilter && {
                            prerenderComponent: false,
                            defaultDelay: 300
                        })}
                    >
                        <VesselsWithGroupsPage
                            vesselsWithGroupsPageState={vesselsWithGroupsPageState}
                            isOrganisationDetail={isOrganisationDetail}
                            isVesselGroupsModalVisible={!isOrganisationDetail}
                            fleetStore={fleetStore}
                            vesselGroupStore={vesselGroupStore}
                            ownerOrganization={ownerOrganization}
                            modalLoadingFunction={modalLoadingFunction}
                            modalFilterFunction={modalFilterFunction}
                            isVesselGroupsHidden={isVesselGroupsHidden}
                            setIsVesselGroupsHidden={setIsVesselGroupHidden}
                            isVesselTitleVisible={isShowGroupsFilter}
                            isCreateAllowed={isCreateAllowed}
                            isEditAllowed={isEditAllowed}
                            isVesselDeletable={isVesselDeletable}
                            editable={(vessel: Vessel) => vessel.createdManually}
                        />
                    </LoadPlaceholder>
                ) : (
                    <VesselsPage
                        vesselsPageState={vesselsPageState}
                        fleetStore={fleetStore}
                        ownerOrganization={ownerOrganization}
                        isEditAllowed={isEditAllowed}
                    />
                )}
            </main>
        </div>
    );
});
