import { observable, flow, makeObservable } from 'mobx';
import { BuildInfo } from '../models';
import { msalConfig } from '@config/config';
import { alertStore } from './AlertStore';

export default class BuildInfoStore {
    buildInfo: BuildInfo = {
        versionFE: null,
        versionBE: null,
        copyright: null,
        timestamp: null
    };

    constructor() {
        makeObservable(this, {
            buildInfo: observable
        });

        this.loadBuildInfo = this.loadBuildInfo.bind(this);
    }

    loadBuildInfo = flow(function* (this: BuildInfoStore) {
        try {
            const buildInfoResponse = yield fetch(`${window.location.origin}/buildInfo.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                }
            });

            const buildInfoJson = yield buildInfoResponse.json();

            this.buildInfo.versionFE = buildInfoJson.version;
            this.buildInfo.timestamp = buildInfoJson.timestamp;
        } catch {
            alertStore.warn({
                message: 'Failed to load FE version',
                description: 'buildInfo.json is abcsent, the application was built manually'
            });
        }

        try {
            const buildInfoResponse = yield fetch(`${msalConfig.REACT_APP_TCMS_BASE_API_URL}meta`);
            const buildInfoJson = yield buildInfoResponse.json();

            this.buildInfo.versionBE = buildInfoJson.version;
            this.buildInfo.copyright = buildInfoJson.copyright;
        } catch {
            alertStore.error('Failed to load BE version', 'GET /meta request returned 500 error');
        }
    });
}

export const buildInfoStore = new BuildInfoStore();
