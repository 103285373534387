import React, { ReactNode, createContext, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useRingBuffer } from '@modules/shared/hooks';

interface Props extends RouteComponentProps {
    children: ReactNode;
    discardLocations: readonly RegExp[];
    depth: number;
}

export type LocationBufferType = { pathname?: string } | RouteComponentProps['location'];

export type PreviousLocationType = {
    locationBuffer?: LocationBufferType[];
    removeFirstLocation?: () => void;
};

export const PreviousLocationContext = createContext({});
export const PreviousLocationProvider = withRouter(
    ({ children, discardLocations, depth, location, history }: Props) => {
        const [locationBuffer, unshiftLocationBuffer, shiftLocationBuffer] = useRingBuffer<LocationBufferType>(depth);

        useEffect(() => {
            const isLocationDiscarded = (pathname: string) => discardLocations.some((exp) => pathname.match(exp));

            if (history.action === 'POP' || isLocationDiscarded(location.pathname)) {
                return;
            }

            if (location.pathname && locationBuffer[0]?.pathname !== location.pathname) {
                unshiftLocationBuffer([location]);
            }
        }, [location, history.action, unshiftLocationBuffer, locationBuffer]);

        const removeFirstLocation = () => shiftLocationBuffer();

        return (
            <PreviousLocationContext.Provider value={{ locationBuffer, removeFirstLocation }}>
                {children}
            </PreviousLocationContext.Provider>
        );
    }
);
