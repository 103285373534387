import { action, makeObservable } from 'mobx';

import { alertStore } from './AlertStore';

export default class ClipboardStore {
    constructor() {
        makeObservable(this, {
            copy: action
        });
    }

    copy = (text: string) => {
        try {
            navigator.clipboard.writeText(text);
            alertStore.success({
                message: 'The text was copied successfully'
            });
        } catch (e) {
            alertStore.error(e, {
                message: 'The text was not copied'
            });
        }
    };
}

export const clipboardStore = new ClipboardStore();
