import { flow } from 'mobx';

import { Account } from '../models';
import { alertStore } from '@modules/core/store/AlertStore';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';
import { AccountsStore } from '@modules/accounts/store/AccountsStore';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';

export class AccountsStoreOrganization extends AccountsStore {
    constructor() {
        super();
        this.loadAccounts = this.loadAccounts.bind(this);
    }

    loadAccounts = flow(function* (this: AccountsStoreOrganization, organizationId: string) {
        if (!organizationId) {
            return;
        }
        const { api, errorMessageHandler } = apiStore.api.users.getOrganizationUsers;

        try {
            this.loadingStatusState.load();
            const accounts = yield HttpClient.TCMS.GET(api(organizationId));
            accounts.forEach((account: Account) => {
                account.organization = selectedEntityState.organization;
            });
            this.accounts = accounts;
            this.loadingStatusState.loadSuccess();
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });
}
