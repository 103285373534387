import { dataSourceConfig } from '@config/config';
import { Dictionary } from '@modules/shared/models';
import { AlertMessage } from '../models';
import { CoreUtil } from '../utils';
import { UsersErrorHandlers, RolesErrorHandlers, OrganizationsErrorHandlers } from '../utils/error-handlers';

export interface ApiModel {
    api: (...args: any) => string;
    successMessage?: AlertMessage;
    errorMessageHandler?: (error?: any) => AlertMessage;
}

type ServerResponse = {
    status: number;
    responseJSON: any;
};

type ServerError = {
    message: string;
    modelState: Dictionary<string[]>;
};

const humanizeServerError = (response: ServerResponse, defaultMessage?: string) => {
    const error: ServerError = response.responseJSON;

    if (!error) {
        return defaultMessage;
    }

    const startCase = (s: string) => s.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2').toLowerCase();
    const incorrectFragments = Object.keys(error?.modelState || {}).reduce((result, current) => {
        const fragments = current.split('.');
        if (fragments.length > 1) {
            return [...result, startCase(fragments[1])];
        }

        return result;
    }, []);

    const joinFragments = (fragments: string[]) => {
        if (fragments.length === 1) {
            return fragments[0];
        } else if (fragments.length === 2) {
            return fragments.join(' and ');
        }

        return fragments.slice(0, -1).join(', ') + ' and ' + fragments.slice(-1);
    };

    if (incorrectFragments.length > 0) {
        return `${error.message} You have entered an invalid ${joinFragments(incorrectFragments)}.`;
    }

    return error.message;
};

// TODO: make unique errors messages for every request
const defaultDescription = (error: any) => `Server returned ${error.status} for this request`;

export type CommonApi = {
    products?: {
        getProducts?: ApiModel;
        getOrganizationProducts?: ApiModel;
        getConnectedProducts?: ApiModel;
        getConnectedProductsForVessels?: ApiModel;
        getConnectedProductsForOrganization?: ApiModel;
        overrideOptions?: ApiModel;
        overrideOptionsForOrganization?: ApiModel;
    };

    organizations?: {
        getOrganizations?: ApiModel;
        getOrganization?: ApiModel;
        putOrganizations?: ApiModel;
        putOrganization?: ApiModel;
        putMyOrganization?: ApiModel;
        postOrganizations?: ApiModel;
        deleteOrganization?: ApiModel;
    };

    users?: {
        getUsers?: ApiModel;
        getUser?: ApiModel;
        getOrganizationUsers?: ApiModel;
        getMyUser?: ApiModel;
        getOrganizationUser?: ApiModel;
        putOrganizationUser?: ApiModel;
        postUsers?: ApiModel;
        postOrganizationUsers?: ApiModel;
        putOrganizationUserRoles?: ApiModel;
        postOrganizationUserInvitations?: ApiModel;
        deleteOrganizationUser?: ApiModel;
    };

    roles?: {
        getRoles?: ApiModel;
        getOrganizationRoles?: ApiModel;
        getAvailableProducts?: ApiModel;
        postOrganizationRoles?: ApiModel;
        putOrganizationRoles?: ApiModel;
        putOrganizationRolePermissions?: ApiModel;
        deleteOrganizationRole?: ApiModel;
    };

    vessels?: {
        getVessels?: ApiModel;
        getMyVessels?: ApiModel;
        getVesselById?: ApiModel;
        getOrganizationVessel?: ApiModel;
        getOrganizationVessels?: ApiModel;
        getOrganizationUserDirectlyAssignedVessels?: ApiModel;
        getOrganizationApiClientDirectlyAssignedVessels?: ApiModel;
        getOrganizationVesselGroupVessels?: ApiModel;
        putOrganizationUserDirectlyAssignedVessels?: ApiModel;
        putOrganizationApiClientDirectlyAssignedVessels?: ApiModel;
        putOrganizationVesselGroupVessels?: ApiModel;
        postOrganizationVessel?: ApiModel;
        putOrganizationVessel?: ApiModel;
        postOrganizationLinkedVessels?: ApiModel;
        deleteOrganizationVessel?: ApiModel;
        deleteOrganizationLinkedVessels?: ApiModel;
        getVesselShares?: ApiModel;
        deleteVesselShares?: ApiModel;
        postVesselShares?: ApiModel;
    };

    vesselsGroups?: {
        getVesselGroups?: ApiModel;
        getOrganizationVesselGroupWithVessels?: ApiModel;
        getOrganizationUsersVesselGroups?: ApiModel;
        getOrganizationApiClientsVesselGroups?: ApiModel;
        getOrganizationVesselGroups?: ApiModel;
        putOrganizationVesselGroup?: ApiModel;
        putOrganizationUsersVesselGroups?: ApiModel;
        putOrganizationApiClientsVesselGroups?: ApiModel;
        postVesselGroups?: ApiModel;
        postOrganizationVesselGroup?: ApiModel;
        deleteOrganizationVesselGroup?: ApiModel;
    };

    dataSources?: {
        getDataSourceSchemas: ApiModel;
        postDataSource: ApiModel;
        putDataSource: ApiModel;
        deleteDataSource: ApiModel;
    };

    apiClients?: {
        getApiClients?: ApiModel;
        getApiClient?: ApiModel;
        getOrganizationApiClients?: ApiModel;
        postOrganizationApiClient?: ApiModel;
        putOrganizationApiClient?: ApiModel;
        putOrganizationApiClientRoles?: ApiModel;
        deleteOrganizationApiClient?: ApiModel;
        putOrganizationApiClientSecret?: ApiModel;
        getOrganizationApiClientVariableGroups?: ApiModel;
        putOrganizationApiClientVariableGroups?: ApiModel;
        getOrganizationApiClientVesselReferences?: ApiModel;
    };

    variableGroups?: {
        getOrganizationVariableGroups?: ApiModel;
        getApiClientVariableGroups?: ApiModel;
        putApiClientVariableGroups?: ApiModel;
    };
};

export const SuperUserApi: CommonApi = {
    // TODO: add error handler for different error status types
    // replace default error message handlers
    products: {
        getProducts: {
            api: () => 'admin/products',
            errorMessageHandler: (error) => ({
                message: 'Failed to load products',
                description: defaultDescription(error)
            })
        },
        getOrganizationProducts: {
            api: (orgId: string) => `admin/organizations/${orgId}/products`,
            errorMessageHandler: () => ({
                message: 'Failed to load products',
                description: 'Organization have no registered products'
            })
        },
        getConnectedProducts: {
            api: () => 'admin/products',
            errorMessageHandler: (error) => ({
                message: 'Failed to load products',
                description: defaultDescription(error)
            })
        },
        getConnectedProductsForVessels: {
            api: (vesselId: string) => `admin/vessels/${vesselId}/connectedproducts`,
            errorMessageHandler: (error) => ({
                message: 'Failed to load vessels products',
                description: defaultDescription(error)
            })
        },
        getConnectedProductsForOrganization: {
            api: (orgId) => `admin/organizations/${orgId}/connectedProducts`,
            errorMessageHandler: (error) =>
                OrganizationsErrorHandlers.GetConnectedProductsOrganizationErrorHandler(error)
        },
        overrideOptions: {
            api: (vesselId, productCode) =>
                `admin/vessels/${vesselId}/connectedproducts/${productCode}/options/overrides`,
            errorMessageHandler: () => ({
                message: 'Failed to update options'
            })
        },
        overrideOptionsForOrganization: {
            api: (organizationId, productCode) =>
                `admin/organizations/${organizationId}/connectedproducts/${productCode}/options/overrides`,
            errorMessageHandler: () => ({
                message: 'Failed to update options'
            })
        }
    },

    organizations: {
        getOrganizations: {
            api: () => 'admin/organizations',
            errorMessageHandler: (error) => ({
                message: 'Failed to load organizations',
                description: defaultDescription(error)
            })
        },
        getOrganization: {
            api: (orgId: string) => `admin/organizations/${orgId}`,
            errorMessageHandler: (error) => ({
                message: 'Failed to load organization',
                description: defaultDescription(error)
            })
        },
        putOrganization: {
            api: (orgId: string) => `admin/organizations/${orgId}`,
            successMessage: { message: 'Organization info successfully updated' },
            errorMessageHandler: (error) => ({
                message: 'Failed to update organization info',
                description: defaultDescription(error)
            })
        },
        putMyOrganization: { api: () => 'admin/users/me/organization' },
        postOrganizations: {
            api: () => 'admin/organizations',
            successMessage: { message: 'Organization successfully created' },
            errorMessageHandler: (error) => ({
                message: 'Failed to create organization',
                description: defaultDescription(error)
            })
        },
        deleteOrganization: {
            api: (organizationId: string) => `admin/organizations/${organizationId}`,
            successMessage: { message: 'Organization successfully deleted' },
            errorMessageHandler: OrganizationsErrorHandlers.DeleteOrganizationErrorHandler
        }
    },

    users: {
        getUsers: {
            api: (orgId, fields = '') => `admin/users?fields=${fields}`,
            errorMessageHandler: (error) => ({
                message: 'Failed to load users',
                description: defaultDescription(error)
            })
        },
        getUser: {
            api: (userId: string, fields: string) => `admin/users/${userId}${CoreUtil.serializeQueryParams({ fields })}`
        },
        getOrganizationUsers: {
            api: (orgId: string) => `admin/organizations/${orgId}/users?fields=roles`
        },
        getOrganizationUser: {
            api: (userId: string, orgId: string) =>
                `admin/organizations/${orgId}/users/${userId}?fields=organization,roles,vessels`,
            errorMessageHandler: UsersErrorHandlers.GetUserErrorHandler
        },
        putOrganizationUser: {
            api: (userId: string, orgId: string) => `admin/organizations/${orgId}/users/${userId}`,
            successMessage: { message: 'User information was updated' },
            errorMessageHandler: UsersErrorHandlers.UpdateUserErrorHandler
        },
        postOrganizationUsers: {
            api: (orgId: string) => `admin/organizations/${orgId}/users`,
            successMessage: { message: 'User successfully created' },
            errorMessageHandler: UsersErrorHandlers.CreateUserErrorHandler
        },
        putOrganizationUserRoles: {
            api: (userId: string, orgId: string) => `admin/organizations/${orgId}/users/${userId}/roles`,
            successMessage: { message: 'User role information was updated' },
            errorMessageHandler: () => ({ message: 'Role assignment error' })
        },
        postOrganizationUserInvitations: {
            api: (userId: string, orgId: string) => `admin/organizations/${orgId}/users/${userId}/invitations`,
            successMessage: { message: 'Invitation was successfully sent' },
            errorMessageHandler: () => ({ message: 'Failed to resend invitation' })
        },
        deleteOrganizationUser: {
            api: (userId: string, orgId: string) => `admin/organizations/${orgId}/users/${userId}`,
            successMessage: { message: 'User successfully deleted' },
            errorMessageHandler: () => ({ message: 'Failed to delete user' })
        }
    },

    roles: {
        getRoles: { api: () => 'admin/roles' },
        getOrganizationRoles: {
            api: (orgId: string) => `admin/organizations/${orgId}/roles`,
            errorMessageHandler: RolesErrorHandlers.GetRoleErrorHandler
        },
        postOrganizationRoles: {
            api: (orgId: string) => `admin/organizations/${orgId}/roles`,
            successMessage: { message: 'Role created' },
            errorMessageHandler: RolesErrorHandlers.AddRoleErrorHandler
        },
        putOrganizationRoles: {
            api: (roleId: string, orgId: string) => `admin/organizations/${orgId}/roles/${roleId} `,
            successMessage: { message: 'Role updated' },
            errorMessageHandler: RolesErrorHandlers.UpdateRoleErrorHandler
        },
        putOrganizationRolePermissions: {
            api: (roleId: string, orgId: string) => `admin/organizations/${orgId}/roles/${roleId}/permissions`,
            successMessage: { message: 'Role permissions updated' },
            errorMessageHandler: RolesErrorHandlers.UpdateRolePermissionsErrorHandler
        },
        deleteOrganizationRole: {
            api: (roleId: string, orgId: string) => `admin/organizations/${orgId}/roles/${roleId}`,
            successMessage: { message: 'Role successfully deleted' },
            errorMessageHandler: RolesErrorHandlers.DeleteRoleErrorHandler
        }
    },

    vessels: {
        getVessels: { api: () => 'admin/vessels?fields=owner' },
        getVesselById: {
            api: (vesselId: string, parameters: Dictionary<string> = { fields: 'datasources,owner' }) => {
                return `admin/vessels/${vesselId}${CoreUtil.serializeQueryParams(parameters)}`;
            }
        },
        getOrganizationVessel: {
            api: (vesselId: string, orgId: string, parameters: Dictionary<string> = { fields: 'owner' }) => {
                return `admin/organizations/${orgId}/vessels/${vesselId}${CoreUtil.serializeQueryParams(parameters)}`;
            }
        },
        getOrganizationVessels: {
            api: (orgId: string, parameters: Dictionary<string> = { fields: 'owner' }) => {
                return `admin/organizations/${orgId}/vessels${CoreUtil.serializeQueryParams(parameters)}`;
            }
        },
        getOrganizationUserDirectlyAssignedVessels: {
            api: (userId: string, orgId: string, parameters: Dictionary<string> = { fields: 'owner' }) => {
                return `admin/organizations/${orgId}/users/${userId}/vessels/directlyassigned${CoreUtil.serializeQueryParams(
                    parameters
                )}`;
            }
        },
        getOrganizationApiClientDirectlyAssignedVessels: {
            api: (apiClientId: string, orgId: string, parameters: Dictionary<string> = { fields: 'owner' }) => {
                return `admin/organizations/${orgId}/apiclients/${apiClientId}/vessels/directlyassigned${CoreUtil.serializeQueryParams(
                    parameters
                )}`;
            }
        },
        getOrganizationVesselGroupVessels: {
            api: (groupId: string, orgId: string) => `admin/organizations/${orgId}/vesselgroups/${groupId}/vessels`
        },
        putOrganizationUserDirectlyAssignedVessels: {
            api: (userId: string, orgId: string) =>
                `admin/organizations/${orgId}/users/${userId}/vessels/directlyassigned`,
            successMessage: { message: 'Vessels for user updated' },
            errorMessageHandler: () => ({ message: 'Failed to update users vessels' })
        },
        putOrganizationApiClientDirectlyAssignedVessels: {
            api: (apiClientId: string, orgId: string) =>
                `admin/organizations/${orgId}/apiclients/${apiClientId}/vessels/directlyassigned`,
            successMessage: { message: 'Vessels for Api Client updated' },
            errorMessageHandler: () => ({ message: 'Failed to update Api Clients vessels' })
        },
        putOrganizationVesselGroupVessels: {
            api: (groupId: string, orgId: string) => `admin/organizations/${orgId}/vesselgroups/${groupId}/vessels`,
            successMessage: { message: 'Vessels in vessel groups updated' },
            errorMessageHandler: () => ({ message: 'Failed to update vessels in vessel groups' })
        },
        postOrganizationVessel: {
            api: (orgId: string) => `admin/organizations/${orgId}/vessels`,
            successMessage: { message: 'Vessel added' },
            errorMessageHandler: (error) => ({
                message: 'Failed to add vessel',
                description: humanizeServerError(error)
            })
        },
        putOrganizationVessel: {
            api: (orgId: string, vesselId: string) => `admin/organizations/${orgId}/vessels/${vesselId}`,
            successMessage: { message: 'Vessel edited' },
            errorMessageHandler: (error) => ({
                message: 'Failed to edited vessel',
                description: humanizeServerError(error)
            })
        },
        postOrganizationLinkedVessels: {
            api: (orgId: string) => `admin/organizations/${orgId}/vessels/linked`,
            successMessage: { message: 'Vessel linked' },
            errorMessageHandler: () => ({ message: 'Failed to link vessel' })
        },
        deleteOrganizationVessel: {
            api: (vesselId: string, orgId: string) => `admin/organizations/${orgId}/vessels/${vesselId}`,
            successMessage: { message: 'Vessel deleted' },
            errorMessageHandler: () => ({ message: 'Failed to delete vessel' })
        },
        deleteOrganizationLinkedVessels: {
            api: (orgId: string) => `admin/organizations/${orgId}/vessels/linked`,
            successMessage: { message: 'Vessel unlinked' },
            errorMessageHandler: () => ({ message: 'Failed to unlink vessel' })
        },
        getVesselShares: {
            api: ({ orgId, vesselId }: { orgId: string; vesselId?: string }) =>
                `admin/organizations/${orgId}/vesselshares${CoreUtil.serializeQueryParams({ 'vessel.id': vesselId })}`,
            errorMessageHandler: () => ({ message: 'Failed to load vessel shares' })
        },
        postVesselShares: {
            api: (orgId: string) => `admin/organizations/${orgId}/vesselshares`,
            errorMessageHandler: () => ({ message: 'Failed to save vessel shares' })
        },
        deleteVesselShares: {
            api: (orgId: string) => `admin/organizations/${orgId}/vesselshares`,
            errorMessageHandler: () => ({ message: 'Failed to delete vessel shares' })
        }
    },

    vesselsGroups: {
        getOrganizationVesselGroupWithVessels: {
            api: (groupId: string, orgId: string) =>
                `admin/organizations/${orgId}/vesselgroups/${groupId}?fields=vessels`
        },
        getOrganizationUsersVesselGroups: {
            api: (userId: string, orgId: string) => `admin/organizations/${orgId}/users/${userId}/vesselgroups`
        },
        getOrganizationApiClientsVesselGroups: {
            api: (apiClientId: string, orgId: string) =>
                `admin/organizations/${orgId}/apiclients/${apiClientId}/vesselgroups`
        },
        getOrganizationVesselGroups: {
            api: (orgId: string) => `admin/organizations/${orgId}/vesselgroups?fields=vessels`
        },
        putOrganizationVesselGroup: {
            api: (groupId: string, orgId: string) => `admin/organizations/${orgId}/vesselgroups/${groupId}`,
            successMessage: { message: 'Vessel group updated' },
            errorMessageHandler: () => ({ message: 'Failed to update vessel group info' })
        },
        putOrganizationUsersVesselGroups: {
            api: (userId: string, orgId: string) => `admin/organizations/${orgId}/users/${userId}/vesselgroups`,
            successMessage: { message: 'Users vessel groups updated' },
            errorMessageHandler: () => ({ message: 'Failed to update vessel groups for user' })
        },
        putOrganizationApiClientsVesselGroups: {
            api: (apiClientId: string, orgId: string) =>
                `admin/organizations/${orgId}/apiclients/${apiClientId}/vesselgroups`,
            successMessage: { message: 'Api Clients vessel groups updated' },
            errorMessageHandler: () => ({ message: 'Failed to update vessel groups for Api Clients' })
        },
        postOrganizationVesselGroup: {
            api: (orgId: string) => `admin/organizations/${orgId}/vesselgroups`,
            successMessage: { message: 'Vessel group created' },
            errorMessageHandler: () => ({ message: 'Failed to create vessel group' })
        },
        deleteOrganizationVesselGroup: {
            api: (groupId: string, orgId: string) => `admin/organizations/${orgId}/vesselgroups/${groupId}`,
            successMessage: { message: 'Vessel group deleted' },
            errorMessageHandler: () => ({ message: 'Failed to delete vessel group' })
        }
    },

    dataSources: {
        // TODO: add build number instead of timestamp [FOSWEB-11653]
        getDataSourceSchemas: {
            api: () => `${dataSourceConfig.REACT_APP_TCMS_DATASOURCESCHEMAS_URL}?timestamp=${new Date().getTime()}`
        },
        postDataSource: {
            api: (vesselId: string) => `admin/vessels/${vesselId}/datasources`,
            successMessage: { message: 'Data source has been created' },
            errorMessageHandler: (error) => ({
                message: 'Failed to create data source',
                description: humanizeServerError(error)
            })
        },
        putDataSource: {
            api: (vesselId: string, dsId: string) => `admin/vessels/${vesselId}/datasources/${dsId}`,
            successMessage: { message: 'Data source has been updated' },
            errorMessageHandler: (error) => ({
                message: 'Failed to update data source',
                description: humanizeServerError(error)
            })
        },
        deleteDataSource: {
            api: (vesselId: string, dsId: string) => `admin/vessels/${vesselId}/datasources/${dsId}`,
            successMessage: { message: 'Data source has been deleted' },
            errorMessageHandler: (error) => ({
                message: 'Failed to delete data source',
                description: humanizeServerError(error)
            })
        }
    },

    apiClients: {
        getApiClients: {
            api: (fields = 'organization,secret') => `admin/apiclients?fields=${fields}`,
            errorMessageHandler: (error) => ({
                message: 'Failed to load Api Clients',
                description: defaultDescription(error)
            })
        },
        getApiClient: {
            api: (apiClientId: string, fields = 'organization,secret,vessels,roles') =>
                `admin/apiclients/${apiClientId}?fields=${fields}`
        },
        getOrganizationApiClients: {
            api: (orgId, fields = 'secret') => `admin/organizations/${orgId}/apiclients?fields=${fields}`,
            errorMessageHandler: (error) => ({
                message: 'Failed to load organization Api Clients',
                description: defaultDescription(error)
            })
        },
        postOrganizationApiClient: {
            api: (orgId) => `admin/organizations/${orgId}/apiclients`,
            successMessage: { message: 'Api Client successfully created' },
            errorMessageHandler: (error) => ({
                message: 'Failed to create Api Client',
                description: defaultDescription(error)
            })
        },
        putOrganizationApiClient: {
            api: (orgId: string, apiClientId: string) => `admin/organizations/${orgId}/apiclients/${apiClientId}`,
            successMessage: { message: 'Api Client information was updated' },
            errorMessageHandler: (error) => ({
                message: 'Failed to create Api Client',
                description: defaultDescription(error)
            })
        },
        putOrganizationApiClientRoles: {
            api: (apiClientId: string, orgId: string) => `admin/organizations/${orgId}/apiclients/${apiClientId}/roles`,
            successMessage: { message: 'Api Client role information was updated' },
            errorMessageHandler: () => ({ message: 'Role assignment error' })
        },
        deleteOrganizationApiClient: {
            api: (orgId: string, apiClientId: string) => `admin/organizations/${orgId}/apiclients/${apiClientId}`,
            successMessage: { message: 'Api Client deleted' },
            errorMessageHandler: () => ({ message: 'Failed to delete Api Client' })
        },
        putOrganizationApiClientSecret: {
            api: (orgId: string, apiClientId: string) =>
                `admin/organizations/${orgId}/apiclients/${apiClientId}/secret`,
            successMessage: { message: 'Api Client secret successfully updated' },
            errorMessageHandler: (error) => ({
                message: 'Failed to update Api Client secret',
                description: defaultDescription(error)
            })
        },
        getOrganizationApiClientVariableGroups: {
            api: (apiClientId: string, orgId: string) =>
                `admin/organizations/${orgId}/apiclients/${apiClientId}/variablegroups`,
            successMessage: { message: 'Api Сlient variable groups successfully loaded' },
            errorMessageHandler: () => ({ message: 'Failed to load Api Client variable groups' })
        },
        putOrganizationApiClientVariableGroups: {
            api: (apiClientId: string, orgId: string) =>
                `admin/organizations/${orgId}/apiclients/${apiClientId}/variablegroups`,
            successMessage: { message: 'Api Сlient variable groups successfully updated' },
            errorMessageHandler: () => ({ message: 'Failed to update Api Client variable groups' })
        },
        getOrganizationApiClientVesselReferences: {
            api: (apiClientId: string, orgId: string) =>
                `admin/organizations/${orgId}/apiclients/${apiClientId}/vesselreferences`,
            errorMessageHandler: () => ({ message: 'Failed to load Api Client vessel references' })
        }
    },

    variableGroups: {
        getOrganizationVariableGroups: {
            api: (orgId: string) => `admin/organizations/${orgId}/variablegroups`
        }
    }
};

export const OrganizationsApi: CommonApi = {
    products: {
        getProducts: {
            api: () => 'organizations/my/products',
            errorMessageHandler: (error) => ({
                message: 'Failed to load products',
                description: defaultDescription(error)
            })
        },
        getConnectedProducts: {
            api: () => 'organizations/my/connectedproducts',
            errorMessageHandler: (error) => ({
                message: 'Failed to load products',
                description: defaultDescription(error)
            })
        },
        getConnectedProductsForOrganization: {
            api: () => 'organizations/my/connectedproducts',
            errorMessageHandler: (error) =>
                OrganizationsErrorHandlers.GetConnectedProductsOrganizationErrorHandler(error)
        },
        getConnectedProductsForVessels: {
            api: (vesselId: string) => `organizations/my/vessels/${vesselId}/connectedproducts`,
            errorMessageHandler: (error) => ({
                message: 'Failed to load vessels products',
                description: defaultDescription(error)
            })
        }
    },

    organizations: {
        getOrganizations: {
            api: () => 'organizations/my',
            errorMessageHandler: (error) => ({
                message: 'Failed to load organizations',
                description: defaultDescription(error)
            })
        },
        putOrganizations: { api: () => 'organizations/my' }
    },

    users: {
        getUsers: {
            api: (orgId, fields = 'roles') => `organizations/my/users${CoreUtil.serializeQueryParams({ fields })}`
        },
        getUser: {
            api: (userId: string, fields: string) =>
                `organizations/my/users/${userId}${CoreUtil.serializeQueryParams({ fields })}`
        },
        getOrganizationUser: {
            api: (userId: string) =>
                `organizations/my/users/${userId}${CoreUtil.serializeQueryParams({ fields: 'organization,vessels' })}`,
            errorMessageHandler: UsersErrorHandlers.GetUserErrorHandler
        },
        putOrganizationUser: {
            api: (userId: string) => `organizations/my/users/${userId}`,
            successMessage: { message: 'User information was updated' },
            errorMessageHandler: UsersErrorHandlers.UpdateUserErrorHandler
        },
        postOrganizationUsers: {
            api: () => 'organizations/my/users',
            successMessage: { message: 'User successfully created' },
            errorMessageHandler: UsersErrorHandlers.CreateUserErrorHandler
        },
        putOrganizationUserRoles: {
            api: (userId: string) => `organizations/my/users/${userId}/roles`,
            successMessage: { message: 'User role information was updated' },
            errorMessageHandler: () => ({ message: 'Role assignment error' })
        },
        postOrganizationUserInvitations: {
            api: (userId: string) => `organizations/my/users/${userId}/invitations`,
            successMessage: { message: 'Invitation was successfully sent' },
            errorMessageHandler: () => ({ message: 'Failed to resend invitation' })
        },
        deleteOrganizationUser: {
            api: (userId: string) => `organizations/my/users/${userId}`,
            successMessage: { message: 'User successfully deleted' },
            errorMessageHandler: () => ({ message: 'Failed to delete user' })
        }
    },

    roles: {
        getRoles: { api: () => 'organizations/my/roles' },
        getOrganizationRoles: { api: () => 'organizations/my/roles' },
        postOrganizationRoles: {
            api: () => 'organizations/my/roles',
            successMessage: { message: 'Role created' },
            errorMessageHandler: RolesErrorHandlers.AddRoleErrorHandler
        },
        putOrganizationRoles: {
            api: (roleId: string) => `organizations/my/roles/${roleId}`,
            successMessage: { message: 'Role updated' },
            errorMessageHandler: RolesErrorHandlers.UpdateRoleErrorHandler
        },
        putOrganizationRolePermissions: {
            api: (roleId: string) => `organizations/my/roles/${roleId}/permissions`,
            successMessage: { message: 'Role permissions updated' },
            errorMessageHandler: RolesErrorHandlers.UpdateRolePermissionsErrorHandler
        },
        deleteOrganizationRole: {
            api: (roleId: string) => `organizations/my/roles/${roleId}`,
            successMessage: { message: 'Role successfully deleted' },
            errorMessageHandler: RolesErrorHandlers.DeleteRoleErrorHandler
        }
    },

    vessels: {
        getVessels: { api: () => 'organizations/my/vessels' },
        getOrganizationVessels: { api: () => 'organizations/my/vessels' },
        getVesselById: {
            api: (vesselId: string, parameters: Dictionary<string> = { fields: 'datasources,owner' }) => {
                return `organizations/my/vessels/${vesselId}${CoreUtil.serializeQueryParams(parameters)}`;
            }
        },
        getOrganizationUserDirectlyAssignedVessels: {
            api: (userId: string) => `organizations/my/users/${userId}/vessels/directlyassigned`
        },
        getOrganizationApiClientDirectlyAssignedVessels: {
            api: (apiClientId: string) => `organizations/my/apiclients/${apiClientId}/vessels/directlyassigned`
        },
        getOrganizationVesselGroupVessels: {
            api: (groupId: string) => `organizations/my/vesselgroups/${groupId}/vessels`
        },
        putOrganizationUserDirectlyAssignedVessels: {
            api: (userId: string) => `organizations/my/users/${userId}/vessels/directlyassigned`,
            successMessage: { message: 'Vessels for user updated' },
            errorMessageHandler: () => ({ message: 'Failed to update users vessels' })
        },
        putOrganizationApiClientDirectlyAssignedVessels: {
            api: (apiClientId: string) => `organizations/my/apiclients/${apiClientId}/vessels/directlyassigned`,
            successMessage: { message: 'Vessels for Api Client updated' },
            errorMessageHandler: () => ({ message: 'Failed to update Api Clients vessels' })
        },
        putOrganizationVesselGroupVessels: {
            api: (groupId: string) => `organizations/my/vesselgroups/${groupId}/vessels`,
            successMessage: { message: 'Vessels in vessel groups updated' },
            errorMessageHandler: () => ({ message: 'Failed to update vessels in vessel groups' })
        },
        getVesselShares: {
            api: ({ vesselId }: { vesselId?: string }) =>
                `organizations/my/vesselshares${CoreUtil.serializeQueryParams({ 'vessel.id': vesselId })}`,
            errorMessageHandler: () => ({ message: 'Failed to load vessel shares' })
        },
        postVesselShares: {
            api: () => `organizations/my/vesselshares`,
            errorMessageHandler: () => ({ message: 'Failed to save vessel shares' })
        }
    },

    vesselsGroups: {
        getVesselGroups: { api: () => 'organizations/my/vesselgroups?fields=vessels' },
        getOrganizationVesselGroupWithVessels: {
            api: (groupId: string) => `organizations/my/vesselgroups/${groupId}?fields=vessels`
        },
        getOrganizationUsersVesselGroups: { api: (userId: string) => `organizations/my/users/${userId}/vesselgroups` },
        getOrganizationApiClientsVesselGroups: {
            api: (apiClientId: string) => `organizations/my/apiclients/${apiClientId}/vesselgroups`
        },
        getOrganizationVesselGroups: { api: () => `organizations/my/vesselgroups?fields=vessels` },
        putOrganizationVesselGroup: {
            api: (groupId: string) => `organizations/my/vesselgroups/${groupId}`,
            successMessage: { message: 'Vessel group updated' },
            errorMessageHandler: (error) => ({
                message: 'Failed to update vessel group info',
                description: humanizeServerError(error)
            })
        },
        putOrganizationUsersVesselGroups: {
            api: (userId: string) => `organizations/my/users/${userId}/vesselgroups`,
            successMessage: { message: 'Users vessel groups updated' },
            errorMessageHandler: () => ({ message: 'Failed to update vessel groups for user' })
        },
        putOrganizationApiClientsVesselGroups: {
            api: (apiClientId: string) => `organizations/my/apiclients/${apiClientId}/vesselgroups`,
            successMessage: { message: 'Api Clients vessel groups updated' },
            errorMessageHandler: () => ({ message: 'Failed to update vessel groups for Api Client' })
        },
        postVesselGroups: { api: () => 'organizations/my/vesselgroups' },
        postOrganizationVesselGroup: {
            api: () => `organizations/my/vesselgroups`,
            successMessage: { message: 'Vessel group created' },
            errorMessageHandler: (error) => ({
                message: 'Failed to create vessel group',
                description: humanizeServerError(error)
            })
        },
        deleteOrganizationVesselGroup: {
            api: (groupId: string) => `organizations/my/vesselgroups/${groupId}`,
            successMessage: { message: 'Vessel group deleted' },
            errorMessageHandler: () => ({ message: 'Failed to delete vessel group' })
        }
    },

    dataSources: {
        // TODO: add build number instead of timestamp [FOSWEB-11653]
        getDataSourceSchemas: {
            api: () => `${dataSourceConfig.REACT_APP_TCMS_DATASOURCESCHEMAS_URL}?timestamp=${new Date().getTime()}`
        },
        postDataSource: {
            api: (vesselId: string) => `organizations/my/vessels/${vesselId}/datasources`,
            successMessage: { message: 'Data source has been created' },
            errorMessageHandler: (error) => ({
                message: 'Failed to create data source',
                description: humanizeServerError(error)
            })
        },
        putDataSource: {
            api: (vesselId: string, dsId: string) => `organizations/my/vessels/${vesselId}/datasources/${dsId}`,
            successMessage: { message: 'Data source has been updated' },
            errorMessageHandler: (error) => ({
                message: 'Failed to update data source',
                description: humanizeServerError(error)
            })
        },
        deleteDataSource: {
            api: (vesselId: string, dsId: string) => `organizations/my/vessels/${vesselId}/datasources/${dsId}`,
            successMessage: { message: 'Data source has been deleted' },
            errorMessageHandler: (error) => ({
                message: 'Failed to delete data source',
                description: humanizeServerError(error)
            })
        }
    },

    apiClients: {
        getApiClients: {
            api: (fields = 'secret') => `organizations/my/apiclients?fields=${fields}`,
            errorMessageHandler: (error) => ({
                message: 'Failed to load Api Clients',
                description: defaultDescription(error)
            })
        },
        getApiClient: {
            api: (apiClientId: string, fields = 'organization,secret,vessels,roles') =>
                `organizations/my/apiclients/${apiClientId}?fields=${fields}`
        },
        postOrganizationApiClient: {
            api: () => `organizations/my/apiclients`,
            successMessage: { message: 'Api Client successfully created' },
            errorMessageHandler: (error) => ({
                message: 'Failed to create Api Client',
                description: defaultDescription(error)
            })
        },
        putOrganizationApiClient: {
            api: (orgId: string, apiClientId: string) => `organizations/my/apiclients/${apiClientId}`,
            successMessage: { message: 'Api Client information was updated' },
            errorMessageHandler: (error) => ({
                message: 'Failed to create Api Client',
                description: defaultDescription(error)
            })
        },
        putOrganizationApiClientRoles: {
            api: (apiClientId: string) => `organizations/my/apiclients/${apiClientId}/roles`,
            successMessage: { message: 'Api Client role information was updated' },
            errorMessageHandler: () => ({ message: 'Role assignment error' })
        },
        deleteOrganizationApiClient: {
            api: (orgId: string, apiClientId: string) => `organizations/my/apiclients/${apiClientId}`,
            successMessage: { message: 'Api Client deleted' },
            errorMessageHandler: () => ({ message: 'Failed to delete Api Client' })
        },
        putOrganizationApiClientSecret: {
            api: (orgId: string, apiClientId: string) => `organizations/my/apiclients/${apiClientId}/secret`,
            successMessage: { message: 'Api Client secret successfully updated' },
            errorMessageHandler: (error) => ({
                message: 'Failed to update Api Client secret',
                description: defaultDescription(error)
            })
        },
        getOrganizationApiClientVariableGroups: {
            api: (apiClientId: string) => `organizations/my/apiclients/${apiClientId}/variablegroups`,
            successMessage: { message: 'Api Сlient variable groups successfully loaded' },
            errorMessageHandler: () => ({ message: 'Failed to load Api Client variable groups' })
        },
        putOrganizationApiClientVariableGroups: {
            api: (apiClientId: string) => `organizations/my/apiclients/${apiClientId}/variablegroups`,
            successMessage: { message: 'Api Client variable groups successfully updated' },
            errorMessageHandler: () => ({ message: 'Failed to update Api Client variable groups' })
        },
        getOrganizationApiClientVesselReferences: {
            api: (apiClientId: string) => `organizations/my/apiclients/${apiClientId}/vesselreferences`,
            errorMessageHandler: () => ({ message: 'Failed to load Api Client vessel references' })
        }
    },

    variableGroups: {
        getOrganizationVariableGroups: {
            api: () => `organizations/my/variablegroups`
        }
    }
};

export const UsersApi = {
    getMyUser: { api: () => 'users/me' },
    getMyVessels: { api: () => 'users/me/vessels' }
};
