import React, { FunctionComponent } from 'react';
import { Tooltip } from '@modules/shared/components';

interface Props {
    content: any;
    inline?: boolean;
}

export const WithTooltip: FunctionComponent<Props> = ({ content, inline }: Props) => (
    <Tooltip content={content} inline={inline}>
        {content}
    </Tooltip>
);
