import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useObserver } from 'mobx-react-lite';
import { Vessel } from '@modules/fleet/models';
import { ModalWindow } from '@modules/shared/components';
import { ModalProps } from '@modules/shared/models';
import { Form } from '@modules/core/components';
import { CoreUtil, isFormFieldsDirty } from '@modules/core/utils';
import { VesselFormFields } from '@modules/core/constants';

import './CreateVesselModal.scss';

interface FormAnswers {
    mmsi?: string;
    name: string;
    imoNumber: string;
}
interface CreateEditVessel extends ModalProps {
    isEditMode?: boolean;
    vessel: Vessel;
}

export const CreateVesselModal = (props: CreateEditVessel) => {
    const { modalIsOpen, onCloseModal, isEditMode, vessel } = props;
    const {
        register,
        getValues,
        control,
        watch,
        setValue,
        handleSubmit,
        formState: { errors, isValid },
        clearErrors,
        reset
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    useEffect(() => {
        return reset();
    }, []);

    const watchAllFields = watch();

    const onSubmit = async (data: FormAnswers) => {
        if (isValid) {
            await props.onSubmit(data);
        }
    };

    const fields = useMemo(() => {
        return CoreUtil.mapFields(VesselFormFields, vessel);
    }, []);

    const isButtonDisabled = useMemo(() => {
        return isFormFieldsDirty(fields, watchAllFields, VesselFormFields) || Object.keys(errors).length;
    }, [watchAllFields]);

    return useObserver(() => (
        <ModalWindow
            disabledSubmit={isButtonDisabled}
            modalIsOpen={modalIsOpen}
            className="create-vessel-popup"
            title={isEditMode ? 'EDIT VESSEL' : 'ADD NEW VESSEL'}
            submitButtonName={isEditMode ? 'SAVE' : 'ADD'}
            onSubmit={handleSubmit(onSubmit)}
            onCancel={() => onCloseModal(false)}
        >
            <Form
                qaId={'create-vessel'}
                useForm={{
                    register,
                    getValues,
                    setValue,
                    control,
                    formState: { errors },
                    clearErrors
                }}
                fields={fields}
            />
        </ModalWindow>
    ));
};
