import { Vessel } from '@modules/fleet/models';
import { SortingUtils } from '@modules/core/utils';
import { VesselGroup, VesselGroupExtended } from '@modules/vessel-groups/models';

const mergeGroupsWithVessels = (groups: VesselGroup[], vessels: Vessel[]): VesselGroupExtended[] =>
    SortingUtils.sortAllTagToFirstRow(
        groups.map<VesselGroupExtended>((groupInstance) => ({
            ...groupInstance,
            vessels: groupInstance.vessels?.reduce<Vessel[]>((result, id) => {
                const vessel = vessels.find((vessel) => vessel.id === id);
                return vessel ? [...result, vessel] : result;
            }, [])
        }))
    );

export default mergeGroupsWithVessels;
