import { useState, useEffect, useCallback } from 'react';

export const useRingBuffer = <T>(capacity: number): [T[], Function, Function, Function] => {
    const [buffer, setBuffer] = useState<T[]>([]);

    const crop = useCallback(() => {
        if (buffer.length > capacity) {
            setBuffer(buffer.filter((item: T, index: number) => index < capacity));
        }
    }, [setBuffer, buffer, capacity]);

    const unshift = useCallback(
        (items: T[]) => {
            setBuffer([...items, ...buffer]);
        },
        [setBuffer, buffer]
    );

    const shift = useCallback(() => setBuffer([...buffer.slice(1)]), [setBuffer, buffer]);

    const reset = useCallback(() => setBuffer([]), [setBuffer]);

    useEffect(() => crop(), [crop]);

    return [buffer, unshift, shift, reset];
};
