import React, { useEffect, useMemo, useState } from 'react';
import { autorun, IReactionDisposer } from 'mobx';
import { useForm } from 'react-hook-form';
import { useObserver } from 'mobx-react-lite';
import intersectionWith from 'lodash/intersectionWith';
import omit from 'lodash/omit';

import { RolesStore } from '@modules/roles/store/RolesStore';
import { LoadingStatusType } from '@modules/core/models';
import { ModalWindow } from '@modules/shared/components';
import { ModalProps, Dictionary } from '@modules/shared/models';
import { Form } from '@modules/core/components';
import { SelectCombobox } from '@modules/core/components/form/Fields';
import { CoreUtil, isFormFieldsDirty, RoleUtils, SelectComboboxUtils } from '@modules/core/utils';
import { CreateRoleFormFields } from '@modules/core/constants';
import { RoleGroup } from '@modules/roles/models';
import { ProductPermission } from '@modules/connected-products/models/Product';

import './CreateRoleModal.scss';

interface Props extends ModalProps {
    rolesStore: RolesStore;
    isEditMode?: boolean;
    role?: Partial<RoleGroup>;
    organizationId?: string;
    handleDelete?: (status: boolean) => void;
    onSubmit: (data: Dictionary<any>) => Promise<void>;
}

export const CreateRoleModal = (props: Props) => {
    const {
        modalIsOpen,
        onCloseModal,
        isEditMode,
        role,
        organizationId: organizationIdProps,
        handleDelete,
        onSubmit,
        rolesStore
    } = props;

    const [organizationId, setOrganization] = useState<string>('');
    const [loading, setLoading] = useState<LoadingStatusType>(LoadingStatusType.isLoading);
    const [debounce] = useState(() => CoreUtil.debounce());
    const permissionsOptions = useMemo(() => {
        return {
            placeholder: 'Permission(s)',
            options: RoleUtils.mapPermissionsByProduct(rolesStore.products, 'toOptions')
        };
    }, [rolesStore.products]);
    const permissionsDefaultValues = useMemo(() => {
        const permissions = role?.permissions || [];
        const products = RoleUtils.mapPermissionsByProduct(rolesStore.products) as { [key: string]: any };
        return Object.keys(products).reduce((acc, item: string) => {
            const currentPerm = intersectionWith(
                products[item],
                permissions,
                (permission: ProductPermission, permissionId: string) => permission.id === permissionId
            );
            if (!!currentPerm.length) {
                return {
                    ...acc,
                    [item]: CoreUtil.mapToOption(currentPerm, { valueParam: 'id' })
                };
            }
            return acc;
        }, {});
    }, [rolesStore.products]);
    const productsOptions = useMemo(() => {
        return {
            placeholder: 'Product',
            options: CoreUtil.mapToExpandOption(rolesStore.products, {
                valueParam: 'code',
                expand: 'permissions'
            })
        };
    }, [rolesStore.products]);
    const selectComboboxValues = useMemo(() => {
        return SelectComboboxUtils.mapSelectComboboxValues(
            permissionsDefaultValues,
            productsOptions.options,
            'product',
            'permissions'
        );
    }, [permissionsDefaultValues, rolesStore.products]);

    const {
        register,
        getValues,
        control,
        setValue,
        handleSubmit,
        watch,
        formState: { errors, isValid },
        clearErrors
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    const watchAllFields = watch(['name', 'description', 'SelectCombobox']);

    useEffect(() => {
        if (modalIsOpen && organizationId) {
            initFields();
        }
    }, [rolesStore, organizationId]);

    useEffect(() => {
        if (modalIsOpen && selectComboboxValues && productsOptions?.options) {
            setValue('SelectCombobox', selectComboboxValues);
        }
    }, [selectComboboxValues, productsOptions]);

    useEffect(() => {
        const disposer = autorun(() => {
            setOrganization(organizationIdProps);
        });
        return () => disposer();
    }, [role, organizationIdProps]);

    useEffect(() => {
        let disposer: IReactionDisposer;
        debounce(() => {
            disposer = autorun(() => {
                if (modalIsOpen && organizationId && !isEditMode) {
                    setLoading(LoadingStatusType.isLoaded);
                }
                if (modalIsOpen && organizationId && role && isEditMode) {
                    setLoading(LoadingStatusType.isLoaded);
                }
            });
        }, 400);

        return () => {
            if (disposer) {
                disposer();
            }
        };
    }, [debounce, modalIsOpen, isEditMode, role, organizationId]);

    const initFields = () => {
        rolesStore.loadProductsByOrg(organizationId);
    };

    const onSubmitRole = async () => {
        if (isValid) {
            const { SelectCombobox, ...data } = getValues();
            const permissions = CoreUtil.getPermissionsId(SelectCombobox);
            await onSubmit({
                permissions,
                ...data
            });
        }
    };

    const onDelete = () => {
        handleDelete(true);
    };

    const fields = useMemo(() => {
        const basicFields = omit(CreateRoleFormFields, 'permissions');

        return CoreUtil.mapFields(basicFields, role);
    }, []);

    const renderSelectComboboxInput = () => {
        return (
            <div>
                <div className="sub-title">Permissions</div>
                <SelectCombobox
                    control={control}
                    fieldName="permissions"
                    selectName="product"
                    extendSelectName="permissions"
                    selectOptions={productsOptions}
                    extendSelectOptions={permissionsOptions}
                    defaultValues={selectComboboxValues}
                    rules={{ required: true }}
                    errors={errors}
                    clearErrors={clearErrors}
                    descriptions={{
                        noComboboxes: 'Must select one or more permissions',
                        noOptions: 'Unable to create role, no permissions available'
                    }}
                />
            </div>
        );
    };

    const isButtonDisabled = useMemo(() => {
        return (
            isFormFieldsDirty(
                [
                    ...fields,
                    {
                        defaultValue: role?.permissions || [],
                        fieldName: 'permissions',
                        type: 'selectcombobox',
                        validate: true
                    }
                ],
                getValues(),
                CreateRoleFormFields
            ) || Object.keys(errors).length
        );
    }, [watchAllFields]);

    const isLoading = useMemo(() => loading === LoadingStatusType.isLoading, [loading]);

    return useObserver(() => (
        <ModalWindow
            qaId={`${isEditMode ? 'edit-role' : 'add-role'}-modal-window`}
            disabledSubmit={isButtonDisabled}
            loadingModal={loading}
            modalIsOpen={modalIsOpen}
            className="create-role-popup"
            title={isEditMode ? 'EDIT ROLE' : 'ADD ROLE'}
            submitButtonName={isEditMode ? 'SAVE' : 'CREATE'}
            onSubmit={handleSubmit(onSubmitRole)}
            onCancel={() => onCloseModal(false)}
            {...(isEditMode && {
                onExpandButton: onDelete,
                expandButtonName: 'DELETE'
            })}
        >
            {!isLoading && fields.length > 0 && (
                <Form
                    qaId={'create-role'}
                    useForm={{
                        register,
                        getValues,
                        setValue,
                        control,
                        formState: { errors },
                        clearErrors
                    }}
                    fields={fields}
                    customFields={renderSelectComboboxInput()}
                />
            )}
        </ModalWindow>
    ));
};
