import React, { useMemo, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import filter from 'lodash/filter';

import { Dictionary } from '@modules/shared/models';
import { Product, ProductPermission } from '@modules/connected-products/models';
import { EntityTable, WithTooltip, Tag, ConfirmModal } from '@modules/shared/components';
import { ColumnSize, TableColumn } from '@modules/shared/models';
import { Role, RoleGroup } from '@modules/roles/models';
import { LoadingStatusType, Option } from '@modules/core/models';
import { useRolesState } from '@modules/shared/hooks';
import { SortOrderType } from '@modules/shared/constants';
import { SortingUtils } from '@modules/core/utils';

import './RolesTable.scss';

interface Props {
    id: string;
    roles: Partial<RoleGroup>[];
    isOrganization?: boolean;
    sortEntities?: (sortFunc: (prevItem: any, nextItem: any) => number) => void;
    setEditModalVisibility: (value: boolean) => any;
    setDeleteModalVisibility: (value: boolean) => any;
    setSelectedRole: (role: Role) => void;
    editRole: (role: Role) => void;
    loadingStatus?: LoadingStatusType;
    filteredPermissions?: Option[];
}

export const RolesTable = (props: Props) => {
    const {
        id,
        roles,
        setDeleteModalVisibility,
        loadingStatus,
        sortEntities,
        setEditModalVisibility,
        setSelectedRole
    } = props;
    const { isSupportUser } = useRolesState();
    const [description, setDescription] = useState<string>('');
    const [itemHeightMap, setItemHeightMap] = useState<{ [key: string]: number }>({});
    const [subItemHeightMap, setSubItemHeightMap] = useState<Dictionary<any>>({});
    const [openedSubRow, setOpenedSubRow] = useState<Dictionary<any>>({});
    const filteredPermissions: Dictionary<boolean> = useMemo(
        () =>
            props.filteredPermissions.reduce(
                (acc, permission) => ({
                    ...acc,
                    [permission.value]: !!permission.value
                }),
                {}
            ),
        [props.filteredPermissions]
    );

    const RoleWithTooltip = (role: RoleGroup) => <WithTooltip content={role.name} />;
    const DescriptionWithTooltip = (role: RoleGroup) => <WithTooltip content={role.description} />;
    const ProductsWithTooltip = (role: RoleGroup, mainRowIndex: number) => {
        return role.products.map((product: Product, subRowIndex: number) => {
            const isOpenedSubRow = openedSubRow[mainRowIndex] && openedSubRow[mainRowIndex].includes(subRowIndex);

            const fullHeight = subItemHeightMap[mainRowIndex] && subItemHeightMap[mainRowIndex][subRowIndex];
            const height = isOpenedSubRow ? fullHeight || 21 : 21;
            return (
                <div
                    className={'flex-table--body__sub-row divider dividerBottom'}
                    key={`${product.id}-${subRowIndex}`}
                    style={{
                        height: height
                    }}
                >
                    <span>{product.name}</span>
                </div>
            );
        });
    };

    const initPermissions = (role: RoleGroup, mainRowIndex?: number) => {
        const permissions = role.permissionsWithDescr;
        return role.products.map((product: Product, subRowIndex: number) => {
            const currentProdPer = filter(permissions, { product: product.code }) || [];

            const isOpenedSubRow = openedSubRow[mainRowIndex] && openedSubRow[mainRowIndex].includes(subRowIndex);
            const fullHeight = subItemHeightMap[mainRowIndex] && subItemHeightMap[mainRowIndex][subRowIndex];
            const height = isOpenedSubRow ? subItemHeightMap[mainRowIndex][subRowIndex] || 21 : 21;

            return (
                <div
                    key={`${product.id}-${subRowIndex}-ProductPerm`}
                    className={'flex-table--body__sub-row divider dividerBottom permissions-align'}
                    style={{
                        height: height
                    }}
                >
                    <div
                        className="permissions-container"
                        ref={(el) => el && initSubRowRef(el, mainRowIndex, subRowIndex)}
                    >
                        {currentProdPer
                            .sort((a, b) => {
                                if (filteredPermissions[a.name]) {
                                    return -1;
                                }
                                if (filteredPermissions[b.name]) {
                                    return 1;
                                }
                                return 0;
                            })
                            .map((p: ProductPermission) => {
                                const isDesiredPermission = filteredPermissions[p.name];
                                return (
                                    <span
                                        key={`tag-${p.id}`}
                                        className={`permission-tag ${isDesiredPermission && 'desiredPermission'}`}
                                        onClick={() => setDescription(p.description)}
                                    >
                                        <Tag key={`tag-${p.id}`} label={p.name.slice(0, 100)} id={p.id} /> {` `}
                                    </span>
                                );
                            })}
                    </div>
                    {fullHeight > 31 && (
                        <div
                            className={'columnWithInfo permission permissions-align'}
                            onClick={() =>
                                !isOpenedSubRow
                                    ? setOpenedSubRow({
                                          ...openedSubRow,
                                          [mainRowIndex]: [...(openedSubRow[mainRowIndex] || []), subRowIndex]
                                      })
                                    : setOpenedSubRow({
                                          ...openedSubRow,
                                          [mainRowIndex]: (openedSubRow[mainRowIndex] || []).filter(
                                              (i: number) => i !== subRowIndex
                                          )
                                      })
                            }
                        >
                            <i className={'permissions-info'}>
                                <i className={`icon icon-chevron ${isOpenedSubRow ? '' : 'closed'}`} />
                            </i>
                        </div>
                    )}
                </div>
            );
        });
    };

    const initSubRowRef = (el: any, index: number, subIndex: number) => {
        const row = subItemHeightMap[index] || 20;
        if (row && row[subIndex] !== el.getBoundingClientRect().height) {
            setSubItemHeightMap({
                ...subItemHeightMap,
                [index]: {
                    ...row,
                    [subIndex]: el?.getBoundingClientRect().height || 20
                }
            });
        }
    };

    const columns: TableColumn[] = [
        {
            title: 'ROLES NAME',
            columnSize: ColumnSize.COLUMN3,
            property: 'name',
            className: () => 'divider dividerBottom first-column editable',
            render: RoleWithTooltip,
            isEdit: true
        },
        {
            title: 'DESCRIPTION',
            columnSize: ColumnSize.COLUMN2,
            property: 'description',
            render: DescriptionWithTooltip,
            className: () => 'divider dividerBottom disabledSort',
            hasSort: false
        },
        {
            title: 'PRODUCTS',
            columnSize: ColumnSize.COLUMN4,
            property: 'products',
            render: (role: any, indexRow) => {
                return ProductsWithTooltip(role, indexRow);
            },
            className: () => 'divider dividerBottom disabledSort withSubRow',
            hasSort: false
        },
        {
            title: 'PERMISSIONS',
            columnSize: ColumnSize.COLUMN7,
            property: 'permissions',
            render: (role: any, indexRow) => initPermissions(role, indexRow),
            className: () => 'dividerBottom disabledSort withSubRow',
            hasSort: false
        }
    ];

    const sortRoles = (property: string, order: SortOrderType) => {
        sortEntities(SortingUtils.defaultCompare(property, order));
    };

    const isEntityModifiable = () => !isSupportUser;

    const getRowHeight = (index: any) => {
        return itemHeightMap[index] || 40;
    };

    const setRowHeight = (index: number, size: number) => {
        setItemHeightMap({ ...itemHeightMap, [index]: size });
    };

    return useObserver(() => (
        <>
            <EntityTable
                id={id}
                listOfEntities={roles}
                loadingDelay={400}
                loadingStatus={loadingStatus}
                columns={columns}
                setEditModalVisibility={setEditModalVisibility}
                setDeleteModalVisibility={isSupportUser ? null : setDeleteModalVisibility}
                setCurrentEntity={setSelectedRole}
                deletable={isEntityModifiable}
                editable={isEntityModifiable}
                getRowHeight={getRowHeight}
                setRowHeight={setRowHeight}
                itemHeightMap={itemHeightMap}
                sortEntities={sortRoles}
                hiddenControls={() => true}
                isCalcSize
            />
            <ConfirmModal
                modalTitle="DESCRIPTION"
                className="popup-description-align-text"
                modalContentText={description}
                icon={<i className="icon icon-about-big-blue" />}
                submitButtonTitle="OK"
                modalIsOpen={!!description}
                onCloseModal={() => setDescription('')}
                onSubmit={async () => setDescription('')}
                isCancel={false}
            />
        </>
    ));
};
