import { observable, makeObservable, flow, computed } from 'mobx';
import { computedFn } from 'mobx-utils';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

import { LoadingStatusState, HttpError } from '@modules/core/models';
import { VesselShare } from '@modules/sharing/models';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';
import { alertStore } from '@modules/core/store/AlertStore';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';
import { Vessel } from '@modules/fleet/models';

export class VesselSharesStore {
    vesselShares: VesselShare[] = [];
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            vesselShares: observable,
            loadingStatusState: observable,
            vesselSharesList: computed({ keepAlive: true })
        });

        this.loadVesselSharesByVessel = this.loadVesselSharesByVessel.bind(this);
        this.loadVesselSharesByOrganization = this.loadVesselSharesByOrganization.bind(this);
        this.unshareVessels = this.unshareVessels.bind(this);
        this.getPartnerOrganizationByCode = this.getPartnerOrganizationByCode.bind(this);
        this.addVesselSharesToPartner = this.addVesselSharesToPartner.bind(this);
    }

    findVesselSharesByQuery = computedFn(({ vessel, organization }: VesselShare, query: string) => {
        const queryUpperCase = query.toUpperCase();
        return (
            vessel.name.toUpperCase().includes(queryUpperCase) ||
            organization.name.toUpperCase().includes(queryUpperCase)
        );
    });

    filterVesselShares = computedFn((query: string) => {
        const vessels = !!this.vesselSharesFilteredByGroup.length
            ? this.vesselSharesFilteredByGroup
            : this.vesselShares;
        if (!!query) {
            return vessels.filter((vesselShare) => this.findVesselSharesByQuery(vesselShare, query));
        }

        return vessels;
    });

    loadVesselSharesByVessel = flow(function* (this: VesselSharesStore, vesselId: string, orgId?: string) {
        const { api, errorMessageHandler } = apiStore.api.vessels.getVesselShares;
        try {
            this.loadingStatusState.load();
            this.vesselShares = yield HttpClient.TCMS.GET(api({ orgId, vesselId }));
            this.loadingStatusState.loadSuccess();
        } catch (e: any) {
            this.loadingStatusState.loadError();
            const error: HttpError = e;
            alertStore.error(error, errorMessageHandler);
        }
    });

    loadVesselSharesByOrganization = flow(function* (this: VesselSharesStore, orgId?: string) {
        const { api, errorMessageHandler } = apiStore.api.vessels.getVesselShares;
        try {
            this.loadingStatusState.load();
            const vesselShares = yield HttpClient.TCMS.GET(api({ orgId }));
            this.vesselShares = vesselShares.map((el: VesselShare) => ({
                id: `vessel-${el.vessel.id}__orgShare-${el.organization.id}`,
                ...el
            }));
            this.loadingStatusState.loadSuccess();
        } catch (e: any) {
            this.loadingStatusState.loadError();
            const error: HttpError = e;
            alertStore.error(error, errorMessageHandler);
        }
    });

    unshareVessels = flow(function* (
        this: VesselSharesStore,
        data: { organizationId: string; vesselShares: VesselShare[] }
    ) {
        const { api, errorMessageHandler } = apiStore.api.vessels.deleteVesselShares;
        try {
            yield HttpClient.TCMS.DELETE(
                api(data.organizationId),
                data.vesselShares.map((el: VesselShare) => ({
                    organizationId: el.organization.id,
                    vesselId: el.vessel.id
                }))
            );
            this.vesselShares = differenceWith(this.vesselShares, data.vesselShares, isEqual);
            alertStore.success({
                message: `Sharing of vessel${data.vesselShares.length > 1 ? 's' : ''} was stopped`
            });
        } catch (e: any) {
            const error: HttpError = e;
            alertStore.error(error, errorMessageHandler);
        }
    });

    getPartnerOrganizationByCode = flow(function* (this: VesselSharesStore, partnerCode: string) {
        const { api, errorMessageHandler } = apiStore.api.organizations.getOrganization;
        try {
            return yield HttpClient.TCMS.GET(api(partnerCode));
        } catch (e: any) {
            const error: HttpError = e;
            alertStore.error(error, errorMessageHandler);
            return { ...error, isError: true };
        }
    });

    addVesselSharesToPartner = flow(function* (
        this: VesselSharesStore,
        orgId: string,
        partnerCode: string,
        vessels: Vessel[]
    ) {
        const { api, errorMessageHandler } = apiStore.api.vessels.postVesselShares;
        try {
            const vesselShares = vessels.map((el: Vessel) => ({
                organizationId: partnerCode,
                vesselId: el.id,
                options: []
            }));

            yield HttpClient.TCMS.POST(api(orgId), vesselShares);

            alertStore.success({
                message: vesselShares.length > 1 ? 'Vessels were successfully shared' : 'Vessel was successfully shared'
            });

            this.loadVesselSharesByOrganization(orgId);
        } catch (e: any) {
            const error: HttpError = e;
            alertStore.error(error, errorMessageHandler);
        }
    });

    get vesselSharesFilteredByGroup(): VesselShare[] {
        const vesselsByVesselGroup = selectedEntityState.vesselGroup?.vessels || [];
        return vesselsByVesselGroup.reduce((accumulator, id) => {
            const founds = this.vesselSharesList.filter(({ vessel }) => vessel.id === id);
            return [...accumulator, ...founds];
        }, []);
    }

    get vesselSharesList(): VesselShare[] {
        return this.vesselShares;
    }

    resetVesselShares = computedFn(() => {
        this.vesselShares = [];
    });
}
