import { flow, makeObservable, observable } from 'mobx';
import { Account } from '../models';
import { LoadingStatusState, HttpError } from '@modules/core/models';
import { CoreUtil, HttpClient } from '@modules/core/utils';
import { apiStore } from '@modules/core/store/ApiStore';
import { alertStore } from '@modules/core/store/AlertStore';

export class AccountStore {
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            loadingStatusState: observable
        });

        this.getAccount = this.getAccount.bind(this);
        this.addAccount = this.addAccount.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.updateAccount = this.updateAccount.bind(this);
        this.updateAccountFields = this.updateAccountFields.bind(this);
        this.updateAccountRoles = this.updateAccountRoles.bind(this);
        this.getAccountOfOrganization = this.getAccountOfOrganization.bind(this);
    }

    addAccount = flow(function* (this: AccountStore, account: Account) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.users.postOrganizationUsers;
        try {
            this.loadingStatusState.load();
            const addedAccount = yield HttpClient.TCMS.POST(api(account.organization.id), {
                firstName: account.firstName,
                lastName: account.lastName,
                email: account.email,
                position: account.position
            });

            let roleAssignSuccess = false;

            for (let i = 0; i < 3; i++) {
                if (!roleAssignSuccess) {
                    const isSuccess = yield this.updateAccountRoles(
                        addedAccount.id,
                        account.organization.id,
                        account.roles
                    );
                    addedAccount.roles = isSuccess ? account.roles : [];
                    yield CoreUtil.delayPromise(500);
                    roleAssignSuccess = isSuccess;
                }
            }

            const newAccount: Account = {
                ...account,
                ...addedAccount
            };

            this.loadingStatusState.loadSuccess();
            alertStore.success(successMessage);

            return newAccount;
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    deleteAccount = flow(function* (this: AccountStore, account: Account) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.users.deleteOrganizationUser;
        try {
            yield HttpClient.TCMS.DELETE(api(account.id, account.organization?.id));
            alertStore.success(successMessage);

            return account;
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });

    updateAccount = flow(function* (
        this: AccountStore,
        {
            account,
            isUpdatedAccount = false,
            isUpdatedRoles = false
        }: { account: Account; isUpdatedAccount: boolean; isUpdatedRoles: boolean }
    ) {
        const { successMessage } = apiStore.api.users.putOrganizationUser;
        try {
            this.loadingStatusState.load();
            if (isUpdatedAccount) {
                yield this.updateAccountFields(account);
            }
            if (isUpdatedRoles) {
                const isSuccess = yield this.updateAccountRoles(account.id, account.organization.id, account.roles);
                account.roles = isSuccess ? account.roles : null;
            }

            this.loadingStatusState.loadSuccess();
            if (isUpdatedAccount || account.roles !== null) {
                alertStore.success(successMessage);
            }
            return account;
        } catch (e: any) {
            const error: HttpError = e;
            this.loadingStatusState.loadError(error.status);
        }
    });

    updateAccountFields = flow(function* (this: AccountStore, account: Partial<Account>) {
        const { api, errorMessageHandler } = apiStore.api.users.putOrganizationUser;
        try {
            return yield HttpClient.TCMS.PUT(api(account.id, account.organization?.id), {
                firstName: account.firstName,
                lastName: account.lastName,
                position: account.position
            });
        } catch (e: any) {
            const error: HttpError = e;
            alertStore.error(error, errorMessageHandler);
            throw error;
        }
    });

    updateAccountRoles = flow(function* (
        this: AccountStore,
        accountId: string,
        organizationId: string,
        roleIds: string[]
    ) {
        const { api, errorMessageHandler } = apiStore.api.users.putOrganizationUserRoles;

        try {
            yield HttpClient.TCMS.PUT(api(accountId, organizationId), roleIds);
            return true;
        } catch (e: any) {
            const error: HttpError = e;
            alertStore.error(error, errorMessageHandler);
            return false;
        }
    });

    getAccount = flow(function* (this: AccountStore, id: string) {
        const { api, errorMessageHandler } = apiStore.api.users.getUser;
        try {
            this.loadingStatusState.load();
            const account = yield HttpClient.TCMS.GET(api(id, 'organization,vessels,roles'));
            this.loadingStatusState.loadSuccess();
            return account;
        } catch (e: any) {
            const error: HttpError = e;
            this.loadingStatusState.loadError(error.status);
            alertStore.error(error, errorMessageHandler);
        }
    });

    getAccountOfOrganization = flow(function* (this: AccountStore, accountId: string, organizationId: string) {
        const { api, errorMessageHandler } = apiStore.api.users.getOrganizationUser;
        try {
            this.loadingStatusState.load();
            const loadOrgAccount = yield HttpClient.TCMS.GET(api(accountId, organizationId));
            this.loadingStatusState.loadSuccess();
            return loadOrgAccount;
        } catch (e: any) {
            const error: HttpError = e;
            this.loadingStatusState.loadError(error.status);
            alertStore.error(error, errorMessageHandler);
        }
    });
}
