import React from 'react';

interface Props {
    label: string;
    id: string;
    handleDelete?: Function;
    disabled?: boolean;
    url?: string;
    desired?: boolean;
}

export const Tag = (props: Props) => {
    const { label = '', id, handleDelete, disabled, desired } = props;

    return (
        <div key={id} className={`tag ${disabled ? 'disabled' : ''} ${desired ? 'desired' : ''}`}>
            {label}
            {disabled ? <i className="icon icon-close-modal" onClick={() => handleDelete(id)} /> : null}
        </div>
    );
};
