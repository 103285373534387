import { makeObservable, observable } from 'mobx';
import { CancellablePromise } from 'mobx/dist/internal';
import { computedFn } from 'mobx-utils';

import { LoadingStatusState } from '@modules/core/models';
import { ApiClient } from '../models';

export default class ApiClientsStore {
    apiClients: ApiClient[] = [];
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            apiClients: observable
        });
    }

    loadApiClients: (...args: any[]) => CancellablePromise<void>;

    filterApiClients = computedFn((name: string) => {
        if (name) {
            const uppercasedName = name.toUpperCase();
            return this.apiClients.filter((client) => client.name.toUpperCase().includes(uppercasedName));
        }

        return this.apiClients;
    });

    removeApiClient = computedFn((apiClient: ApiClient) => {
        const index = this.apiClients.findIndex((client) => client.id === apiClient.id);
        if (index !== -1) {
            this.apiClients.splice(index, 1);
        }
    });

    insertApiClient = computedFn((apiClient: ApiClient) => {
        this.apiClients.unshift(apiClient);
    });

    replaceApiClient = computedFn((apiClient: ApiClient) => {
        const index = this.apiClients.findIndex((client) => client.id === apiClient.id);

        if (index !== -1) {
            this.apiClients[index] = {
                ...apiClient
            };
        }
    });
}
