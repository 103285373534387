import React, { useState } from 'react';
import { ChangeHandler } from 'react-hook-form';
import { CheckboxFormField } from '@modules/core/models';

interface Props {
    tabIndex: number;
    field: CheckboxFormField;
    register: (
        Ref: any,
        RegisterOptions?: any
    ) => { onChange: ChangeHandler; onBlur: ChangeHandler; name: string; ref: React.Ref<any> };
}

export const Checkbox = (props: Props) => {
    const { register, field, tabIndex = 0 } = props;

    const [isChecked, setChecked] = useState<boolean>(!!field.defaultValue);

    return (
        <div className={`checkbox ${field.disabled ? 'disabled' : ''}`} qa-id={field.qaId}>
            <input
                tabIndex={tabIndex}
                qa-checked={`${isChecked}`}
                defaultChecked={isChecked}
                type="checkbox"
                id="checkbox"
                {...register(field.fieldName, {
                    onChange: (e: boolean) => {
                        setChecked(!isChecked);
                        return e;
                    }
                })}
            />
            <label htmlFor={'checkbox'}>
                {isChecked ? <i className="icon icon-checkbox"></i> : null}
                {field.label}
            </label>
        </div>
    );
};
