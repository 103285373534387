import React from 'react';

interface Props {
    isHidden?: boolean;
    onSubmit: () => void;
    onCancel: () => void;
}

export const ConnectedProductsFooter = (props: Props) => {
    const { onSubmit, isHidden, onCancel } = props;

    return !isHidden ? (
        <footer className="card-footer">
            <button className="button primary" onClick={onSubmit}>
                SAVE
            </button>
            <button className="button secondary" onClick={onCancel}>
                CANCEL
            </button>
        </footer>
    ) : null;
};
