import { Vessel } from '@modules/fleet/models';
import { DateUtil } from '@modules/shared/utils';
import { ColumnSize, TableColumn } from '@modules/shared/models';

export function getVesselsTableColumns(addNewVesselMode: boolean): TableColumn[] {
    return [
        {
            title: 'STATUS',
            property: 'status',
            columnSize: ColumnSize.COLUMN3,
            hidden: addNewVesselMode
        },
        {
            title: 'VESSEL NAME',
            property: 'name',
            columnSize: ColumnSize.COLUMN3
        },
        {
            title: 'IMO',
            property: 'imoNumber',
            columnSize: ColumnSize.COLUMN2
        },
        {
            title: 'DMS ID',
            property: 'dmsId',
            columnSize: ColumnSize.COLUMN7
        },
        {
            title: 'ORGANIZATION',
            property: 'owner.name',
            columnSize: ColumnSize.COLUMN3,
            render: (v) => v?.owner?.name
        },
        {
            title: 'REGISTRATION DATE',
            columnSize: ColumnSize.COLUMN2,
            render: (vessel: Vessel) => DateUtil.formatDate(vessel.registrationDate),
            hidden: addNewVesselMode
        }
    ];
}

export function getVesselGroupTableColumns(): TableColumn[] {
    return [
        {
            title: 'GROUP NAME',
            property: 'name',
            columnSize: ColumnSize.COLUMN3
        }
    ];
}
