import React from 'react';
import { ChangeHandler } from 'react-hook-form';
import { InputEmailFormField, InputFormField } from '@modules/core/models';

interface Props {
    register: (
        Ref: any,
        RegisterOptions?: any
    ) => { onChange: ChangeHandler; onBlur: ChangeHandler; name: string; ref: React.Ref<any> };
    setValue?: (name: string, value: unknown, config?: Object) => void;
    field: InputFormField | InputEmailFormField;
    rules: { [key: string]: any };
    errors: { [key: string]: any };
    placeholder?: string;
    tabIndex: number;
}

export const Input = (props: Props) => {
    const { register, field, rules, errors, placeholder, setValue, tabIndex } = props;

    const trimString = (event: any) => {
        const value = event.target.value;
        if (typeof value === 'string') {
            const valueTrim = value.trim();
            if (setValue) {
                setValue(field.fieldName, valueTrim);
            }
            return valueTrim;
        }
        return value;
    };

    return (
        <input
            qa-id={field.qaId}
            tabIndex={tabIndex}
            key={field.fieldName}
            placeholder={placeholder}
            defaultValue={field.defaultValue}
            disabled={field.disabled}
            className={`${errors[field.fieldName] && 'has-error'}`}
            {...register(field.fieldName, {
                ...rules
            })}
            onBlur={trimString}
        />
    );
};
