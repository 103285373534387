import { makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';
import { Account } from '../models';
import { LoadingStatusState } from '@modules/core/models';
import { CancellablePromise } from 'mobx/dist/api/flow';

export class AccountsStore {
    accounts: Account[] = [];
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            accounts: observable,
            loadingStatusState: observable
        });
    }

    loadAccounts: (...args: any[]) => CancellablePromise<void>;
    resendInvitation: (...args: any[]) => CancellablePromise<void>;

    removeAccount = computedFn((account: Account) => {
        const index = this.accounts.findIndex((a) => a.id === account.id);
        if (index !== -1) {
            this.accounts.splice(index, 1);
        }
    });

    insertAccount = computedFn((account: Account) => {
        this.accounts.unshift(account);
    });

    replaceAccount = computedFn((account: Account) => {
        const index = this.accounts.findIndex((a) => a.id === account.id);

        if (index !== -1) {
            this.accounts[index] = {
                ...account
            };
        }
    });

    filterAccounts = computedFn((query: string) => {
        if (!!query) {
            return this.accounts.filter((account) => this.findAccountByQuery(account, query));
        }

        return this.accounts;
    });

    findAccountById = computedFn((id: string) => {
        return [...this.accounts, ...this.accounts].find((account) => account.id === id);
    });

    findAccountByQuery = computedFn((account: Account, query: string) => {
        return (
            account.email.toUpperCase().includes(query.toUpperCase()) ||
            `${account.firstName.toUpperCase()} ${account.lastName.toUpperCase()}`.includes(query.toUpperCase()) ||
            `${account.lastName.toUpperCase()} ${account.firstName.toUpperCase()}`.includes(query.toUpperCase())
        );
    });
}
