import React from 'react';

import { Vessel } from '@modules/fleet/models';
import { Organization } from '@modules/organizations/models';

import './LinkedVessel.scss';

interface Props {
    vessel: Vessel;
    ownerOrganization?: Organization;
}

export const LinkedVessel = (props: Props) => {
    const { vessel, ownerOrganization } = props;
    const isLinked = ownerOrganization && vessel.owner?.id !== ownerOrganization?.id;

    return (
        <div className="linked-vessel">
            <div className={`links-wrap ${ownerOrganization ? 'with-linked' : ''}`}>
                <div>
                    {vessel.createdManually ? (
                        <i className="icon icon-ais ais" />
                    ) : (
                        <i className="icon icon-vessel vessel" />
                    )}
                </div>
                {isLinked && <i className="icon icon-linked" />}
            </div>
            <span>{vessel.name}</span>
        </div>
    );
};
