import { flow } from 'mobx';

import { alertStore } from '@modules/core/store/AlertStore';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';
import { ConnectedProductsStore } from './ConnectedProductsStore';
import { HttpError } from '@modules/core/models';

export class ConnectedProductsStoreOrganization extends ConnectedProductsStore {
    constructor() {
        super();
        this.loadProducts = this.loadProducts.bind(this);
        this.overrideVesselOptions = this.overrideVesselOptions.bind(this);
    }

    loadProducts = flow(function* (this: ConnectedProductsStoreOrganization, id: string) {
        const { api, errorMessageHandler } = apiStore.api.products.getConnectedProductsForOrganization;
        try {
            this.products = [];

            this.loadingStatusState.load();
            const availableProducts = yield HttpClient.TCMS.GET(api(id), {
                includeExpiredOptions: true
            });

            yield this.mergeOptions(availableProducts);
            this.products = availableProducts;
            this.loadingStatusState.loadSuccess();
        } catch (e: any) {
            const error: HttpError = e;
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    overrideVesselOptions = flow(function* (
        this: ConnectedProductsStoreOrganization,
        organizationId: string,
        productCode: string,
        optionCodes: any[]
    ) {
        const { api, errorMessageHandler } = apiStore.api.products.overrideOptionsForOrganization;
        try {
            yield HttpClient.TCMS.PUT(api(organizationId, productCode), optionCodes);
            this.products = this.overrideOptions(productCode, optionCodes, this.products);
            alertStore.success({ message: 'Options successfully updated' });
        } catch (error) {
            alertStore.error(error, errorMessageHandler);
        }
    });
}
