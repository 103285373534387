import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { Form } from '@modules/core/components';
import { ModalProps } from '@modules/shared/models';
import { ModalWindow } from '@modules/shared/components';
import { Organization } from '@modules/organizations/models';
import { CoreUtil, isFormFieldsDirty } from '@modules/core/utils';
import { OrganizationFormFields } from '@modules/core/constants';

import './CreateOrganizationModal.scss';

interface Props extends ModalProps {
    isEditMode: boolean;
    organization?: Organization;
}

export const CreateOrganizationModal = (props: Props) => {
    const { modalIsOpen, onCloseModal, isEditMode, organization } = props;
    const {
        register,
        getValues,
        setValue,
        control,
        watch,
        clearErrors,
        handleSubmit,
        formState: { isValid, errors }
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    const watchAllFields = watch();

    const onSubmit = async () => {
        if (isValid) {
            await props.onSubmit(getValues());
        }
    };

    const fields = useMemo(() => {
        return CoreUtil.mapFields(OrganizationFormFields, organization);
    }, []);

    const isButtonDisabled = useMemo(() => {
        return isFormFieldsDirty(fields, watchAllFields, OrganizationFormFields) || Object.keys(errors).length;
    }, [watchAllFields]);

    const infoMessage = () => (
        <div className="organization-popup-message">
            <i className="icon icon-attention-black" />
            <span className="text">
                Internal organizations:
                <ul>
                    <li>are only visible to support or superuser roles</li>
                </ul>
            </span>
        </div>
    );

    return (
        <ModalWindow
            disabledSubmit={isButtonDisabled}
            modalIsOpen={modalIsOpen}
            className="create-organization-popup"
            title={isEditMode ? 'EDIT ORGANIZATION' : 'NEW ORGANIZATION'}
            submitButtonName={isEditMode ? 'SAVE' : 'CREATE'}
            onSubmit={handleSubmit(onSubmit)}
            onCancel={() => onCloseModal(false)}
        >
            <Form
                qaId={'create-organization'}
                isEditMode={isEditMode}
                useForm={{
                    register,
                    getValues,
                    setValue,
                    control,
                    formState: { errors },
                    clearErrors
                }}
                fields={fields}
            />
            {infoMessage()}
        </ModalWindow>
    );
};
