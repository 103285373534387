import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useStore } from '@store/StoreEffect';
import { SearchInput } from '@fos/eniram-web-common-ui/dist/components/SearchInput';

import { CreateOrganizationModal, OrganizationsTable } from '@modules/organizations/components';
import { Organization } from '@modules/organizations/models';
import { usePageContainer, useRolesState } from '@modules/shared/hooks';
import { ConfirmModal } from '@modules/shared/components';
import { LoadPlaceholder } from '@modules/core/components';
import { LoadingStatusType } from '@modules/core/models';

import './OrganizationsPage.scss';

export const OrganizationsPage = () => {
    const { organizationStore, organizationsStoreAll } = useStore();
    const { addOrganization, editOrganization, deleteOrganization, loadingOrganizationStatus } = organizationStore;
    const { loadOrganizations, filterOrganizations } = organizationsStoreAll;

    const { isSupportUser } = useRolesState();

    const {
        isAssignModalVisible,
        setAssignModalVisibility,
        setEditModalVisibility,
        setDeleteModalVisibility,
        selectEntity,
        selectedEntity,
        isEditMode,
        query,
        setQuery,
        entities,
        sortEntities,
        addEntity,
        editEntity,
        isCreateEditModalVisible,
        onCloseCreateEditModal,
        isDeleteModalVisible
    } = usePageContainer(loadOrganizations, filterOrganizations, addOrganization, editOrganization);

    const onCreateOrganization = async (data: Organization) => {
        const org = await addEntity(data);
        if (org) {
            organizationsStoreAll.insertOrganization(org);
        }
        setAssignModalVisibility(false);
    };

    const onEditOrganization = async (data: Organization) => {
        const updatedOrg = await editEntity({ ...data, id: selectedEntity?.id });
        if (updatedOrg) {
            organizationsStoreAll.replaceOrganization({
                ...selectedEntity,
                ...updatedOrg
            });
        }
        setEditModalVisibility(false);
        selectEntity(null);
    };

    const onDeleteOrganization = async () => {
        const deletedOrganizationId = await deleteOrganization(selectedEntity?.id);
        if (deletedOrganizationId) {
            organizationsStoreAll.removeOrganization(deletedOrganizationId);
        }
        setDeleteModalVisibility(false);
    };

    const onSubmit = async (data: any) => {
        return isAssignModalVisible ? await onCreateOrganization(data) : await onEditOrganization(data);
    };

    return useObserver(() => (
        <div className="organizations-page" qa-id="organizations-page">
            <main className="card card-organizations">
                <LoadPlaceholder loadingStatus={loadingOrganizationStatus.status || LoadingStatusType.isLoaded}>
                    <header className="card-header">
                        <span>Organizations ({entities.length})</span>
                        <div className="card-header-controls">
                            {!isSupportUser && (
                                <button
                                    className="button primary"
                                    qa-id="organizations-page-new"
                                    onClick={() => setAssignModalVisibility(true)}
                                >
                                    NEW
                                </button>
                            )}
                            <SearchInput
                                onChange={(value: string) => setQuery(value)}
                                autofocus={false}
                                placeholder="Search by Name"
                                search={query}
                            />
                        </div>
                    </header>
                    <section className="card-body">
                        <OrganizationsTable
                            id="organizations_table"
                            organizations={entities}
                            sortEntities={sortEntities}
                            loadingStatus={loadingOrganizationStatus.status}
                            setEditModalVisibility={setEditModalVisibility}
                            setDeleteModalVisibility={setDeleteModalVisibility}
                            setSelectedOrganization={selectEntity}
                        />
                    </section>
                </LoadPlaceholder>
            </main>

            {isCreateEditModalVisible() ? (
                <CreateOrganizationModal
                    organization={selectedEntity}
                    modalIsOpen={isCreateEditModalVisible()}
                    onCloseModal={onCloseCreateEditModal()}
                    isEditMode={isEditMode}
                    onSubmit={onSubmit}
                />
            ) : null}
            {isDeleteModalVisible ? (
                <ConfirmModal
                    modalTitle="DELETE ORGANIZATION"
                    modalContentText="Do you want to delete organisation?"
                    submitButtonTitle="DELETE"
                    modalIsOpen={isDeleteModalVisible}
                    onCloseModal={setDeleteModalVisibility}
                    onSubmit={onDeleteOrganization}
                />
            ) : null}
        </div>
    ));
};
