import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import { EntityTable, WithTooltip } from '@modules/shared/components';
import { ColumnSize, TableColumn } from '@modules/shared/models';
import { Vessel } from '@modules/fleet/models';
import { DateUtil } from '@modules/shared/utils';
import { SortOrderType } from '@modules/shared/constants';
import { CoreUtil, SortingUtils } from '@modules/core/utils';
import { useStore } from '@store/StoreEffect';
import { Organization } from '@modules/organizations/models';
import { LoadingStatusType } from '@modules/core/models';
import { LinkedVessel } from '../linked-vessel/LinkedVessel';

import './VesselsTable.scss';

interface Props {
    id: string;
    vessels: Vessel[];
    ownerOrganization?: Organization;
    sortEntities?: (sortFunc: (prevItem: any, nextItem: any) => number) => void;
    setDeleteModalVisibility: (value: boolean) => any;
    setEditModalVisibility?: (value: boolean) => any;
    setSelectedVessel: (vessel: Vessel) => void;
    loadingStatus: LoadingStatusType;
    loadingDelay: number;
    deletable?: (vessel: Vessel) => boolean;
    editable?: (vessel: Vessel) => boolean;
}

export const VesselsTable = observer<Props>((props) => {
    const {
        vessels,
        setSelectedVessel,
        setDeleteModalVisibility,
        setEditModalVisibility,
        sortEntities,
        id,
        ownerOrganization,
        loadingStatus,
        loadingDelay,
        deletable,
        editable
    } = props;

    const { userStore } = useStore();
    const history = useHistory();

    const dmsIdWithTooltip = (vessel: Vessel) => <WithTooltip content={vessel.dmsId} />;

    const columns: TableColumn[] = [
        {
            title: 'NAME',
            columnSize: ColumnSize.COLUMN4,
            property: 'name',
            className: () => 'divider name-column',
            render: (vessel: Vessel): ReactElement => (
                <LinkedVessel vessel={vessel} ownerOrganization={ownerOrganization} />
            ),
            onClick: (vessel: Vessel): void => CoreUtil.goToPage(history, `/fleet/vessels/${vessel.id}`)
        },
        {
            title: 'IMO',
            columnSize: ColumnSize.COLUMN2,
            property: 'imoNumber',
            className: () => 'imo'
        },
        {
            title: 'DMS ID',
            columnSize: ColumnSize.COLUMN5,
            property: 'dmsId',
            className: () => 'dms-id',
            render: dmsIdWithTooltip
        },
        {
            title: 'REGISTRATION DATE',
            columnSize: ColumnSize.COLUMN4,
            property: 'registrationDate',
            className: () => 'registration-date',
            render: (vessel: Vessel): string => DateUtil.formatDate(vessel.registrationDate)
        },
        {
            title: 'ORGANIZATION',
            columnSize: ColumnSize.COLUMN3,
            property: 'owner.name',
            hidden: !userStore.isSuperUser,
            render: (v) => v?.owner?.name,
            onClick: (vessel: Vessel) =>
                vessel?.owner.id && CoreUtil.goToPage(history, `/organizations/${vessel.owner.id}/fleet`)
        }
    ];

    const sortVessels = (property: string, order: SortOrderType): void => {
        sortEntities(SortingUtils.defaultCompare(property, order));
    };

    return (
        <EntityTable
            id={id}
            listOfEntities={vessels}
            sortEntities={sortVessels}
            loadingStatus={loadingStatus}
            loadingDelay={loadingDelay}
            columns={columns}
            deletable={deletable}
            isArchiveAvailable={false}
            setDeleteModalVisibility={setDeleteModalVisibility}
            setEditModalVisibility={setEditModalVisibility}
            setCurrentEntity={setSelectedVessel}
            editable={editable}
        />
    );
});

VesselsTable.defaultProps = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    setDeleteModalVisibility: (value: boolean) => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSelectedVessel: () => {}
};
