import React, { useCallback, useMemo } from 'react';
import { useFieldArray, Controller, Control } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormErrorsTypes, Option } from '@modules/core/models';
import differenceWith from 'lodash/differenceWith';
import intersectionBy from 'lodash/intersectionBy';
import { Dropdown } from '@fos/eniram-web-common-ui/dist/components/DropDown';

import './SelectCombobox.scss';

interface Props {
    control: Control<any>;
    fieldName: string;
    selectName: string;
    extendSelectName: string;
    defaultValues: any;
    selectOptions: {
        options: Option[];
        placeholder?: string;
    };
    descriptions?: {
        noComboboxes?: string;
        noOptions?: string;
    };
    extendSelectOptions: {
        placeholder?: string;
        options: { [key: string]: Option[] };
    };
    rules?: { [key: string]: any };
    errors?: { [key: string]: any };
    clearErrors?: (p: any) => void;
}

export const SelectCombobox = (props: Props) => {
    const { REQUIRED_ERROR } = FormErrorsTypes;
    const { control, rules, selectName, extendSelectName, extendSelectOptions, errors: errorsProps } = props;

    const { options: selectOptionsProps } = props.selectOptions;

    const errors = useMemo(() => errorsProps.SelectCombobox || [], [errorsProps.SelectCombobox]);

    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'SelectCombobox',
        keyName: 'id'
    });

    const addRow = useCallback(() => {
        append({ [selectName]: null, [extendSelectName]: [] });
    }, []);

    const selectOptions = useMemo(() => {
        return differenceWith(selectOptionsProps, fields, (o1, o2: { [key: string]: any }) => {
            return o1?.value === o2[selectName]?.value;
        });
    }, [selectOptionsProps, fields]);

    const deleteRow = useCallback((i: number) => {
        remove(i);
    }, []);

    const renderInfoMessage = () => {
        const { descriptions } = props;
        if (!fields?.length && !!selectOptionsProps.length) {
            return <div className={'select-combobox__description'}>{descriptions?.noComboboxes}</div>;
        }
        if (!selectOptionsProps.length) {
            return <div className={'select-combobox__description'}>{descriptions?.noOptions}</div>;
        }
    };

    const updateSelectedValue = (index: number, item: { [key: string]: any }) => {
        // descr: it is necessary to explicitly update the list to overwrite
        // the fields and filter out the options available for selection
        // reset the extendSelectName value as it depends on the selectName value
        props.clearErrors(['SelectCombobox']);
        update(index, {
            [extendSelectName]: [],
            [selectName]: item
        });
    };

    const updateMultiselectValues = (index: number, item: { [key: string]: any }) => {
        props.clearErrors(['SelectCombobox']);
        update(index, {
            ...fields[index],
            [extendSelectName]: item
        });
    };

    const renderChildMultiselect = (
        index: number,
        item: { [key: string]: any },
        firstFieldValue: { [key: string]: any }
    ) => (
        <Controller
            control={control}
            rules={rules}
            name={`SelectCombobox.${index}.${extendSelectName}`}
            defaultValue={item[extendSelectName]}
            render={({ field: { onChange, value } }) => {
                const options = firstFieldValue && firstFieldValue[extendSelectName];
                const currentValue = intersectionBy(options, value, 'value');

                return (
                    <div
                        className={`select-combobox__extend-select form-control-block ${
                            errors[index] && errors[index][extendSelectName] && 'has-error'
                        }`}
                    >
                        <Dropdown
                            qaId={`SelectCombobox.${index}.${extendSelectName}`}
                            placeholder={extendSelectOptions?.placeholder}
                            actionFunc={(p) => {
                                onChange(p);
                                updateMultiselectValues(index, p);
                            }}
                            options={options}
                            value={currentValue}
                            isSearchable
                            isClearable
                            isMulti
                        />
                        <div className={'form-control-block__error-block'}>
                            <ErrorMessage
                                errors={errors[index] || {}}
                                name={extendSelectName}
                                render={({ message }) => <p>{message || REQUIRED_ERROR}</p>}
                            />
                        </div>
                    </div>
                );
            }}
        />
    );

    return (
        <div>
            {fields.map((item: { [key: string]: any }, index: number) => {
                return (
                    <div className={'select-combobox'} key={item.id}>
                        <Controller
                            control={control}
                            rules={rules}
                            defaultValue={item[selectName]}
                            name={`SelectCombobox.${index}.${selectName}`}
                            render={({ field: { onChange, value: fieldValue } }) => {
                                const firstFieldValue = selectOptionsProps.find((option: Option) => {
                                    return option?.value?.toLowerCase() === fieldValue?.value?.toLowerCase();
                                }) as { [key: string]: any };

                                return (
                                    <>
                                        <div
                                            className={`select-combobox__base-select form-control-block label-select ${
                                                errors[index] && errors[index][selectName] && 'has-error'
                                            }`}
                                        >
                                            <Dropdown
                                                qaId={`SelectCombobox.${index}.${selectName}`}
                                                placeholder={props.selectOptions?.placeholder}
                                                actionFunc={(p) => {
                                                    onChange(p);
                                                    updateSelectedValue(index, p);
                                                }}
                                                options={selectOptions}
                                                defaultValue={item[selectName]}
                                                isMulti={false}
                                                isClearable={false}
                                                isSearchable
                                            />
                                            <div className={'form-control-block__error-block'}>
                                                <ErrorMessage
                                                    errors={errors[index] || {}}
                                                    name={selectName}
                                                    render={({ message }) => <p>{message}</p>}
                                                />
                                            </div>
                                        </div>
                                        {renderChildMultiselect(index, item, firstFieldValue)}
                                    </>
                                );
                            }}
                        />

                        {fields.length > 1 && (
                            <div className="select-combobox__delete-btn" onClick={() => deleteRow(index)}>
                                <div className="button secondary">
                                    <div className="icon icon-trash" />
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
            <div className="select-combobox__add-btn">
                {fields?.length !== selectOptionsProps.length && !!selectOptions.length && (
                    <div className={`button secondary`} onClick={addRow}>
                        <div className="icon icon-plus" />
                        ADD
                    </div>
                )}
                {renderInfoMessage()}
            </div>
        </div>
    );
};
