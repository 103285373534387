import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useObserver } from 'mobx-react-lite';
import { autorun, IReactionDisposer } from 'mobx';

import { useStore } from '@store/StoreEffect';
import { RolesStore } from '@modules/roles/store/RolesStore';
import { Account } from '@modules/accounts/models';
import { AvailablePermissions, LoadingStatusType, Option } from '@modules/core/models';
import { Form, LoadPlaceholder } from '@modules/core/components';
import { Role } from '@modules/roles/models';
import { CoreUtil, isFormFieldsDirty } from '@modules/core/utils';
import { AccountsFormFields, AccountsFormEditFields } from '@modules/core/constants';
import { useHistory } from 'react-router-dom';
import { usePermissionCheck } from '@modules/shared/hooks';

import './AccountsGeneral.scss';

interface Props {
    account: Account;
    editAccount?: (value: Account) => Promise<void>;
    roles: Role[];
    rolesStore: RolesStore;
}

export const AccountsGeneral = (props: Props) => {
    const { account, editAccount, roles, rolesStore } = props;
    const { userStore, accountsStoreAll } = useStore();
    const { resendInvitation } = accountsStoreAll;
    const { loadingStatusState: loadingStatusStateRoles } = rolesStore;
    const [debounce] = useState(() => CoreUtil.debounce());
    const { isSuperUser, isSupportUser } = userStore;
    const [loading, setLoading] = useState<LoadingStatusType>(LoadingStatusType.isLoading);
    const hasEditRolesPermission = usePermissionCheck(AvailablePermissions.User.EditRoles);
    const rolesDisabledForEdit = !hasEditRolesPermission && !userStore.isUserWithAccount;
    const fieldsCollectionModel = useMemo(
        () => (!!account.id ? AccountsFormEditFields : AccountsFormFields),
        [account]
    );
    const fieldsCollection = useMemo(
        () =>
            Object.keys(fieldsCollectionModel).reduce((acc, field) => {
                return {
                    ...acc,
                    [field]: {
                        ...AccountsFormEditFields[field],
                        disabled: isSupportUser || AccountsFormEditFields[field].disabled,
                        ...(field === 'roles' && {
                            disabled: rolesDisabledForEdit
                        })
                    }
                };
            }, fieldsCollectionModel),
        [account]
    );
    const history = useHistory();

    const {
        register,
        getValues,
        control,
        watch,
        setValue,
        handleSubmit,
        formState: { errors, isValid, isDirty },
        clearErrors
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    const watchAllFields = watch();

    useEffect(() => {
        let disposer: IReactionDisposer;
        debounce(() => {
            disposer = autorun(() => {
                if (account.id && loadingStatusStateRoles.status === LoadingStatusType.isLoaded) {
                    setValue('email', account.email);
                    setLoading(LoadingStatusType.isLoaded);
                }
            });
        }, 400);

        return () => {
            if (disposer) {
                disposer();
            }
        };
    }, [debounce, account, roles, loadingStatusStateRoles]);

    const onSubmit = async (data: any) => {
        if (isValid) {
            await editAccount({
                ...account,
                ...data,
                roles: data.roles.map((role: Option) => role.value)
            });
            clearErrors();
        }
    };

    const onSubmitInvitation = async () => {
        await resendInvitation(account.id, account.organization?.id);
    };

    const fields = useMemo(() => {
        return [
            ...CoreUtil.mapFields(fieldsCollection, account),
            CoreUtil.mapFieldRoles(account.roles, roles, fieldsCollection.roles)
        ];
    }, [account, roles]);

    const isButtonDisabled = useMemo(() => {
        return isFormFieldsDirty(fields, watchAllFields, fieldsCollection) || Object.keys(errors).length;
    }, [isDirty, watchAllFields]);

    const isLoading = loading === LoadingStatusType.isLoaded;
    const formIsLoaded = isLoading && !!account.id && !!fields?.length;

    return useObserver(() => (
        <main className="accounts-general">
            <LoadPlaceholder loadingStatus={loading || LoadingStatusType.isLoaded}>
                <section className="accounts-general-body">
                    {formIsLoaded && (
                        <Form
                            qaId={'accounts-general'}
                            isEditMode={!!account.id}
                            useForm={{
                                register,
                                getValues,
                                setValue,
                                control,
                                formState: { errors },
                                clearErrors
                            }}
                            fields={fields}
                            customFields={
                                <div>
                                    {isSuperUser && (
                                        <div className="form-control-block">
                                            <div className="form-control-block form-control-block--organization">
                                                <label>Organization</label>
                                                <span
                                                    onClick={() =>
                                                        CoreUtil.goToPage(
                                                            history,
                                                            `/organizations/${account.organization.id}/users`
                                                        )
                                                    }
                                                >
                                                    {account.organization.name}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    )}
                    <div className="accounts-general-body-buttons">
                        <button
                            type="submit"
                            className="button primary"
                            disabled={isSupportUser}
                            onClick={() => onSubmitInvitation()}
                        >
                            Resend invitation
                        </button>
                    </div>
                </section>
                <section className="accounts-general-footer">
                    <button
                        disabled={isButtonDisabled}
                        type="submit"
                        className="button primary"
                        onClick={handleSubmit(onSubmit)}
                    >
                        SAVE
                    </button>
                </section>
            </LoadPlaceholder>
        </main>
    ));
};
