import { observable, makeObservable, flow } from 'mobx';
import { LoadingStatusState, HttpError } from '@modules/core/models';
import { Vessel } from '../models';
import { apiStore } from '@modules/core/store/ApiStore';
import { HttpClient } from '@modules/core/utils';
import { alertStore } from '@modules/core/store/AlertStore';

export class VesselStore {
    vessel: Vessel = null;
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            loadingStatusState: observable
        });

        this.getVesselById = this.getVesselById.bind(this);
    }

    getVesselById = flow(function* (this: VesselStore, vesselId: string) {
        const { api, errorMessageHandler } = apiStore.api.vessels.getVesselById;
        try {
            this.loadingStatusState.load();
            this.vessel = yield HttpClient.TCMS.GET(api(vesselId));
            this.loadingStatusState.loadSuccess();
            return this.vessel;
        } catch (e: any) {
            const error: HttpError = e;
            this.loadingStatusState.loadError(error.status);
            alertStore.error(error, errorMessageHandler);
        }
    });
}
