import React, { useState, useEffect } from 'react';
import { Spinner } from '@fos/eniram-web-common-ui/dist/components/Spinner';

import { LoadingStatusType } from '@modules/core/models';

import './LoadPlaceholder.scss';

interface Props {
    loadingStatus?: LoadingStatusType;
    shadowed?: boolean;
    prerenderComponent?: boolean;
    children?: any;
    defaultDelay?: number;
}

export const LoadPlaceholder = (props: Props) => {
    const { children, loadingStatus, defaultDelay, shadowed, prerenderComponent = true } = props;
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const [isVisible, setIsVisible] = useState<boolean>(prerenderComponent);

    useEffect(() => {
        if (defaultDelay) {
            setIsVisible(false);
            const id = setTimeout(() => setIsVisible(true), defaultDelay);
            setTimeoutId(id);

            return () => {
                clearTimeout(timeoutId);
                setIsVisible(false);
            };
        }

        //react hook requires timeoutId, but it will lead to infinity loop
        // eslint-disable-next-line
    }, [defaultDelay, loadingStatus]);

    const render = () => {
        switch (loadingStatus) {
            case LoadingStatusType.isError: // ToDo add error handler
            case LoadingStatusType.isLoaded:
                return !isVisible ? shadowed ? <ShadowedSpinner /> : <Spinner /> : <>{children}</>;
            case LoadingStatusType.isLoading:
                return shadowed ? <ShadowedSpinner /> : <Spinner />;
        }
    };

    return <>{render()}</>;
};

LoadPlaceholder.defaultProps = {
    defaultDelay: 0,
    shadowed: false
};

const ShadowedSpinner = () => (
    <div className="children-wrapper">
        <Spinner />
    </div>
);
