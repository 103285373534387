import { flow } from 'mobx';

import { HttpClient } from '@modules/core/utils';
import { apiStore } from '@modules/core/store/ApiStore';
import { alertStore } from '@modules/core/store/AlertStore';
import ApiClientsStore from '@modules/api-clients/store/ApiClientsStore';

export default class ApiClientsStoreOrganization extends ApiClientsStore {
    constructor() {
        super();
        this.loadApiClients = this.loadApiClients.bind(this);
    }

    loadApiClients = flow(function* (this: ApiClientsStoreOrganization, orgId: string) {
        const { api, errorMessageHandler } = apiStore.api.apiClients.getOrganizationApiClients;
        try {
            this.loadingStatusState.load();
            const apiClients = yield HttpClient.TCMS.GET(api(orgId));
            this.apiClients = apiClients;
            this.loadingStatusState.loadSuccess();
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });
}
