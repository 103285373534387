import React, { useState } from 'react';

import { Product } from '@modules/products/models';
import { ConfirmModal } from '@modules/shared/components';

import './ProductLinks.scss';

interface Props {
    product: Product;
    description?: string;
}

export const ProductLinks = (props: Props) => {
    const { product, description } = props;
    const [isOpen, toggleOpenModal] = useState<boolean>(false);

    return (
        <div className="product-links">
            {product?.userManualUrl ? (
                <a
                    className="product-links-element"
                    href={product.userManualUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    qa-id="products-page-user-manual"
                >
                    <span>USER MANUAL</span>
                </a>
            ) : (
                <span className="product-links-element product-links-element--disabled">USER MANUAL</span>
            )}
            <a className="product-links-element" onClick={() => toggleOpenModal(!isOpen)}>
                <span>DESCRIPTION</span>
            </a>
            {product?.homeUrl ? (
                <a
                    className="product-links-element"
                    href={product.homeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    qa-id="products-page-go-to-product"
                >
                    <span>GO TO PRODUCT</span>
                    <i className="icon icon-arrow-right" />
                </a>
            ) : (
                <span className="product-links-element product-links-element--disabled">
                    GO TO PRODUCT
                    <i className="icon icon-arrow-right-disabled" />
                </span>
            )}
            <ConfirmModal
                qaId="description-modal-info"
                modalTitle="DESCRIPTION"
                modalContentText={description}
                icon={<i className="icon icon-about-big-blue" />}
                submitButtonTitle="OK"
                modalIsOpen={isOpen}
                onCloseModal={toggleOpenModal}
                onSubmit={async () => await toggleOpenModal(false)}
                isCancel={false}
            />
        </div>
    );
};
