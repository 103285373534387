import React, { ReactElement } from 'react';
import { useObserver } from 'mobx-react-lite';

import { ColumnSize, TableColumn } from '@modules/shared/models';
import { DateUtil } from '@modules/shared/utils';
import { ExpandedEntityTable } from '@modules/shared/components/';
import { VesselGroupExtended } from '@modules/vessel-groups/models';
import { LoadingStatusType } from '@modules/core/models';
import { Vessel } from '@modules/fleet/models';
import { Organization } from '@modules/organizations/models';
import { useStore } from '@store/StoreEffect';
import { WithTooltip } from '@modules/shared/components';
import { LinkedVessel } from '@modules/fleet/components';

interface Props {
    groups: VesselGroupExtended[];
    ownerOrganization: Organization;
    loadingStatus?: LoadingStatusType;
    loadingDelay?: number;
}

export const GroupedVesselTable = (props: Props) => {
    const { groups, ownerOrganization } = props;
    const { userStore } = useStore();

    const dmsIdWithTooltip = (vessel: Vessel) => <WithTooltip content={vessel.dmsId} />;

    const columns: TableColumn[] = [
        {
            title: 'NAME',
            columnSize: ColumnSize.COLUMN4,
            property: 'name',
            className: () => 'divider',
            render: (vessel: Vessel): ReactElement => (
                <LinkedVessel vessel={vessel} ownerOrganization={ownerOrganization} />
            )
        },
        {
            title: 'IMO',
            columnSize: ColumnSize.COLUMN1,
            property: 'imoNumber',
            className: () => 'imo'
        },
        {
            title: 'DMS ID',
            columnSize: ColumnSize.COLUMN5,
            property: 'dmsId',
            className: () => 'dms-id',
            render: dmsIdWithTooltip
        },
        {
            title: 'REGISTRATION DATE',
            columnSize: ColumnSize.COLUMN3,
            property: 'registrationDate',
            className: () => 'registration-date',
            render: (vessel: Vessel) => DateUtil.formatDate(vessel.registrationDate)
        },
        userStore.isSuperUser
            ? {
                  title: 'ORGANIZATION',
                  columnSize: ColumnSize.COLUMN4,
                  property: 'owner.name',
                  render: (v) => v?.owner?.name
              }
            : {
                  title: '',
                  columnSize: ColumnSize.COLUMN4
              }
    ];

    return useObserver(() => (
        <ExpandedEntityTable
            loadingStatus={props.loadingStatus}
            loadingDelay={props.loadingDelay}
            columns={columns}
            id="vessel-groups-table"
            groups={groups}
            expandedProperty="vessels"
        />
    ));
};
