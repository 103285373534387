import { useStore } from '@store/StoreEffect';

export const useRolesState = () => {
    const { userStore } = useStore();

    return {
        isUser: userStore.isUser,
        isSuperUser: userStore.isSuperUser,
        isSupportUser: userStore.isSupportUser,
        isUserWithAccount: userStore.isUserWithAccount,
        isAccessable: userStore.isAccessable
    };
};
