import React, { useCallback, useEffect } from 'react';
import { autorun, flow } from 'mobx';
import { observer } from 'mobx-react-lite';
import { SearchInput } from '@fos/eniram-web-common-ui/dist/components/SearchInput';

import { usePageContainer } from '@modules/shared/hooks';
import { Organization } from '@modules/organizations/models';
import { selectedEntityState } from '@modules/core/store/SelectedEntityState';
import { VesselGroupStore } from '@modules/vessel-groups/store';
import { VesselGroup } from '@modules/vessel-groups/models';
import { FleetStore } from '@modules/fleet/store';
import { CreateVesselGroupModal, VesselGroupTable } from '@modules/vessel-groups/components';
import { ConfirmModal } from '@modules/shared/components';
import { CancellablePromise } from 'mobx/dist/api/flow';
import { LoadingStatusType } from '@modules/core/models';
import { useStore } from '@store/StoreEffect';

import './VesselGroupsPage.scss';

interface Props {
    isEditDeleteAllowed?: boolean;
    modalLoadingFunction?: (...args: any[]) => CancellablePromise<void>;
    filterVessels?: (value: string) => any[];
    modalFilterFunction?: (value: string) => any[];
    ownerOrganization?: Organization;
    vesselGroupStore?: VesselGroupStore;
    fleetStore?: FleetStore;
    setIsVesselGroupsHidden?: (value: boolean) => void;
    isVesselGroupsHidden?: boolean;
    isOrganisationDetail?: boolean;
    isFullBlock?: boolean;
}

export const VesselGroupsPage = observer<Props>((props) => {
    const { vesselGroupStore, fleetStore, setIsVesselGroupsHidden, filterVessels } = props;
    const { organization, account } = selectedEntityState;
    const { loadVesselsByGroup } = fleetStore;
    const { loadVesselGroups, filterVesselGroups, addVesselGroup, editVesselGroup, deleteVesselGroup } =
        vesselGroupStore;
    const { userStore } = useStore();

    const containerLoadingFunction = useCallback(
        flow(function* () {
            yield loadVesselGroups();
        }),
        [organization, account]
    );

    const {
        setDeleteModalVisibility,
        selectEntity,
        selectedEntity,
        isAssignModalVisible,
        query,
        setQuery,
        entities,
        sortEntities,
        addEntity,
        deleteEntity,
        isCreateEditModalVisible,
        onCloseCreateEditModal,
        isDeleteModalVisible,
        setAssignModalVisibility,
        setEditModalVisibility,
        editEntity
    } = usePageContainer(
        containerLoadingFunction,
        filterVesselGroups,
        addVesselGroup,
        editVesselGroup,
        deleteVesselGroup,
        true
    );

    useEffect(() => {
        const disposer = autorun(() => {
            selectedEntityState.selectVesselGroup(selectedEntityState.vesselGroup);
            selectEntity(selectedEntityState.vesselGroup);
        });
        return () => disposer();
    }, [selectedEntityState.vesselGroup]);

    useEffect(() => {
        if (selectedEntity) {
            selectedEntityState.selectVesselGroup(selectedEntity);
        }
    }, [selectedEntity]);

    const onSelectVesselGroup = (group: VesselGroup) => {
        selectEntity(group);
    };

    const onSubmitAssign = async (group: VesselGroup) => {
        const addedGroup = await addEntity(group);
        setAssignModalVisibility(false);
        if (addedGroup) {
            await loadVesselsByGroup(addedGroup);
            selectEntity(addedGroup);
        }
    };

    const onSubmitEdit = async (group: VesselGroup) => {
        await editEntity({ ...selectedEntity, ...group });
        setEditModalVisibility(false);
        await loadVesselsByGroup(group);
    };

    const onSubmitDelete = async () => {
        await deleteEntity(selectedEntity);
        setDeleteModalVisibility(false);
        selectEntity(vesselGroupStore.allVesselsGroup);
    };

    const onOpenNewVesselGroupModal = () => {
        setAssignModalVisibility(true);
    };

    const isNameInUse = (name: string, groupId: string) => {
        return !!entities.find((group) => group.id !== groupId && group.name.toLowerCase() === name.toLowerCase());
    };

    const isLoaded = fleetStore.loadingStatusState.status !== LoadingStatusType.isLoading;

    return (
        <div className="vessel-groups-page">
            <main className="card">
                <header className="card-header">
                    <i className="icon icon-close-modal" onClick={() => setIsVesselGroupsHidden(true)} />
                    <span>Vessel Groups ({entities.length})</span>
                    <div className={`card-header-controls`}>
                        {!userStore.isSupportUser && addVesselGroup && (
                            <button className="button primary" onClick={onOpenNewVesselGroupModal}>
                                New
                            </button>
                        )}
                        <SearchInput
                            onChange={(value: string) => setQuery(value)}
                            autofocus={false}
                            placeholder="Search by Name, id"
                            search={query}
                        />
                    </div>
                </header>
                <section className="card-body">
                    <VesselGroupTable
                        id="vessel_groups_table"
                        vesselGroups={entities}
                        sortEntities={sortEntities}
                        loadingStatus={vesselGroupStore.loadingStatusState.status}
                        setSelectedVesselGroup={selectEntity}
                        selectedVesselGroup={selectedEntity}
                        selectVesselGroup={isLoaded ? onSelectVesselGroup : () => null}
                        {...(!userStore.isSupportUser && {
                            setEditModalVisibility: setEditModalVisibility,
                            setDeleteModalVisibility: setDeleteModalVisibility
                        })}
                    />
                </section>
            </main>
            {isCreateEditModalVisible() ? (
                <CreateVesselGroupModal
                    filterVessels={filterVessels}
                    loadingStatusState={fleetStore.loadingStatusState}
                    onSubmit={!isAssignModalVisible ? onSubmitEdit : onSubmitAssign}
                    modalIsOpen={isCreateEditModalVisible()}
                    onCloseModal={onCloseCreateEditModal()}
                    selectedGroup={!isAssignModalVisible ? selectedEntity : null}
                    isNameInUse={isNameInUse}
                />
            ) : null}

            {isDeleteModalVisible ? (
                <ConfirmModal
                    modalTitle="DELETE VESSEL GROUP"
                    modalContentText="Do you want to delete vessel group?"
                    submitButtonTitle="DELETE"
                    modalIsOpen={isDeleteModalVisible}
                    onCloseModal={setDeleteModalVisibility}
                    onSubmit={onSubmitDelete}
                />
            ) : null}
        </div>
    );
});
