const OrganizationPermissionsTypes = {
    Delete: 'Organization.Delete',
    Test: 'Organization.Test'
};

const UserPermissionsTypes = {
    EditRoles: 'User.EditRoles'
};

const ApiClientPermissionsType = {
    read: 'apiclients.read',
    readAll: 'apiclients.read.all',
    write: 'apiclients.readwrite',
    writeAll: 'apiclients.readwrite.all'
};

export const AvailablePermissions = {
    Organization: OrganizationPermissionsTypes,
    User: UserPermissionsTypes,
    ApiClients: ApiClientPermissionsType
};
