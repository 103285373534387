import React, { useCallback, useEffect, useMemo } from 'react';
import { flow } from 'mobx';
import { useHistory } from 'react-router-dom';

import { useStore } from '@store/StoreEffect';
import { DateUtil } from '@modules/shared/utils';
import { Vessel } from '@modules/fleet/models';
import { ExternalOrganizations } from './external-organizations-table/ExternalOrganizations';
import { VesselSharesStore } from '@modules/fleet/store';
import { usePageContainer } from '@modules/shared/hooks';
import { CoreUtil } from '@modules/core/utils';

import './VesselGeneralInformation.scss';

interface Props {
    vessel: Vessel;
    vesselSharesStore: VesselSharesStore;
}

export const VesselGeneralInformation = (props: Props) => {
    const { vessel, vesselSharesStore } = props;
    const { loadVesselSharesByVessel } = vesselSharesStore;
    const { userStore } = useStore();
    const history = useHistory();
    //const userOrganizationId = useMemo(() => userStore.currentUser?.organization?.id, [userStore.currentUser]);
    const organizationId = useMemo(() => {
        return (userStore.isSuperUser || userStore.isSupportUser) && vessel.owner?.id;
    }, [userStore, vessel]);

    // descr: hide for customer (TCMS-2154), return after TCMS-2132
    // const isOwnVessel = vessel.owner.id === userOrganizationId;
    const shouldRenderExternalOrganizations = userStore.isSuperUser || userStore.isSupportUser;

    const containerLoadingFunction = useCallback(
        flow(function* () {
            if (shouldRenderExternalOrganizations) {
                yield loadVesselSharesByVessel(vessel.id, organizationId);
            }
        }),
        [vessel]
    );

    const { entities, sortEntities, setEntities } = usePageContainer(
        containerLoadingFunction,
        () => vesselSharesStore.vesselSharesList
    );

    useEffect(() => {
        return () => {
            setEntities([]);
            vesselSharesStore.resetVesselShares();
        };
    }, []);

    return (
        <>
            <div className="vessel-details">
                <div>
                    <span className="vessel-details-header">Registration date</span>
                    <span className="vessel-details-value">{DateUtil.formatDate(vessel.registrationDate)}</span>
                </div>
                <div>
                    <span className="vessel-details-header">Organization</span>
                    <span
                        className={`vessel-details-value ${organizationId && 'has-url'}`}
                        onClick={() =>
                            organizationId && CoreUtil.goToPage(history, `/organizations/${vessel.owner?.id}/users`)
                        }
                    >
                        {vessel.owner?.name}
                    </span>
                </div>
                <div>
                    <span className="vessel-details-header">DMS ID</span>
                    <span className="vessel-details-value">{vessel.dmsId}</span>
                </div>
                <div>
                    <span className="vessel-details-header">IMO</span>
                    <span className="vessel-details-value">{vessel.imoNumber || '-'}</span>
                </div>
                <div>
                    <span className="vessel-details-header">MMSI</span>
                    <span className="vessel-details-value">{vessel.mmsi || '-'}</span>
                </div>
            </div>
            {shouldRenderExternalOrganizations && (
                <>
                    <span className="vessel-details-title">External Organizations</span>
                    <ExternalOrganizations vesselShares={entities} sortEntities={sortEntities} />
                </>
            )}
        </>
    );
};
