import React, { useEffect, useState, useMemo } from 'react';
import { autorun, configure } from 'mobx';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import { useAccount } from '@azure/msal-react';
import { searchState } from '@modules/core/store/SearchState';
import { Header, NavBar, RoleRestriction } from '@modules/core/components';
import { ProductPage } from '@pages/product-page/ProductPage';
import { OrganizationsPage } from '@pages/organizations-page/OrganizationsPage';
import { NavigationTypes } from '@modules/core/constants';
import { PreviousLocationProvider } from '@modules/core/providers';
import { Toaster } from '@modules/shared/components';
import { AccountsPage } from '@pages/accounts-page/AccountsPage';
import { RolesPage } from '@pages/roles-page/RolesPage';
import { useStore } from '@store/StoreEffect';
import { FleetPage } from '@pages/fleet-page/FleetPage';
import { NavigationItem, RoleType } from '@modules/core/models';
import { HttpClient } from '@modules/core/utils';
import { AboutPage } from '@pages/about-page/AboutPage';
import { EmptyPage } from '@pages/empty-page/EmptyPage';
import { OrganizationsDetailsPage } from '@pages/organizations-page/details/OrganizationsDetailsPage';
import { PreviousLocationDiscarded, PreviousLocationDepth } from '@modules/shared/constants';
import { VesselDetailsPage } from '@pages/vessels-page/details/VesselDetailsPage';
import { ApiClientsPage } from '@pages/api-clients/ApiClientsPage';
import { ApiClientsDetailsPage } from '@pages/api-clients/details/ApiClientsDetailsPage';

import './assets/styles/index.scss';

function App() {
    const {
        userStore,
        vesselGroupsStoreAll,
        fleetStoreVesselGroups,
        dataSourceStore,
        accountsStoreAll,
        rolesStoreAll,
        fleetStoreAll,
        connectedProductsStoreAll,
        vesselStore,
        vesselSharesStore,
        apiClientsStoreAll
    } = useStore();

    configure({
        enforceActions: 'never'
    });

    const [loaded, setLoaded] = useState<boolean>();
    const activeAccount = useAccount();

    useEffect(() => {
        const disposer = autorun(async () => {
            if (!loaded) {
                HttpClient.init();
                userStore.setAzureUser(activeAccount);
                userStore.loadCurrentUser();
                userStore.loadUserRoles();
                document.body.classList.add('theme-light');
                setLoaded(true);
            }
        });
        return () => {
            disposer();
            HttpClient.deinit();
        };
    }, [userStore, userStore.isUser]);

    const ownerOrganization = userStore.isSuperUser ? null : userStore.currentUser.organization;
    const isMainSuperuserFleetPage = userStore.isSuperUser || userStore.isSupportUser;

    const navItems = useMemo(() => {
        const items: NavigationItem[] = [];

        if (userStore.isAccessable([RoleType.Superuser, RoleType.Support], false)) {
            items.push({
                title: 'Organizations',
                link: 'organizations'
            });
        }

        items.push(
            ...[
                // {
                //     title: 'Users Groups',
                //     link: 'users-groups'
                // },
                {
                    title: 'Users',
                    link: 'users'
                },
                {
                    title: 'Fleet',
                    link: 'fleet'
                }
            ]
        );

        if (
            userStore.isAccessable([RoleType.Admin], false) &&
            !userStore.isAccessable([RoleType.Superuser, RoleType.Support], false)
        ) {
            items.push(
                ...[
                    {
                        title: 'Products',
                        link: 'products'
                    },
                    {
                        title: 'Roles',
                        link: 'roles'
                    }
                ]
            );
        }

        if (userStore.canViewApiClients) {
            items.push({
                title: 'API Clients',
                link: 'api-clients'
            });
        }

        // items.push({
        //     title: 'Benchmarking metrics',
        //     link: 'benchmark'
        // })

        return items;
    }, [userStore.roles, userStore.permissions, userStore.canViewApiClients]);

    return useObserver(() => {
        if (!loaded) {
            return null;
        }

        return (
            <RoleRestriction accessRoles={[RoleType.Superuser, RoleType.Support, RoleType.Admin]}>
                <main>
                    <Router>
                        <PreviousLocationProvider
                            depth={PreviousLocationDepth}
                            discardLocations={PreviousLocationDiscarded}
                        >
                            <header>
                                <Header user={userStore.currentUser} userStore={userStore} />
                            </header>
                            <Toaster toasterId="global" />
                            <Switch>
                                <Route path="/about">
                                    <AboutPage />
                                </Route>
                                <Route path="/">
                                    <section>
                                        <nav>
                                            <NavBar
                                                match={false}
                                                navItems={navItems}
                                                navType={NavigationTypes.Left}
                                                onChange={searchState.clearSearches}
                                            />
                                        </nav>
                                        <section className="router-outlet" qa-id="tcms-app-page">
                                            <Switch>
                                                <Route path="/products">
                                                    <ProductPage />
                                                </Route>
                                                <Route path={`/organizations/:organizationId`}>
                                                    {userStore.isAccessable(
                                                        [RoleType.Superuser, RoleType.Support],
                                                        false
                                                    ) && <OrganizationsDetailsPage />}
                                                </Route>
                                                <Route path="/organizations">
                                                    {userStore.isAccessable(
                                                        [RoleType.Superuser, RoleType.Support],
                                                        false
                                                    ) && <OrganizationsPage />}
                                                </Route>
                                                <Route path="/roles">
                                                    <RolesPage
                                                        rolesStore={rolesStoreAll}
                                                        accountsStore={accountsStoreAll}
                                                    />
                                                </Route>
                                                <Route path="/users">
                                                    <AccountsPage
                                                        rolesStore={rolesStoreAll}
                                                        accountsStore={accountsStoreAll}
                                                    />
                                                </Route>
                                                <Route path="/fleet/vessels/:vesselId">
                                                    <VesselDetailsPage
                                                        vesselStore={vesselStore}
                                                        vesselSharesStore={vesselSharesStore}
                                                        connectedProductsStore={connectedProductsStoreAll}
                                                        dataSourceStore={dataSourceStore}
                                                    />
                                                </Route>
                                                <Route path="/fleet">
                                                    <FleetPage
                                                        accessRoles={[RoleType.Admin]}
                                                        connectedProductsStore={connectedProductsStoreAll}
                                                        dataSourceStore={dataSourceStore}
                                                        isShowGroupsFilter={!isMainSuperuserFleetPage}
                                                        fleetStore={
                                                            isMainSuperuserFleetPage
                                                                ? fleetStoreAll
                                                                : fleetStoreVesselGroups
                                                        }
                                                        {...(!isMainSuperuserFleetPage && {
                                                            filterVesselsGroup: fleetStoreAll.filterVessels,
                                                            modalFilterFunction: fleetStoreVesselGroups.filterVessels,
                                                            ownerOrganization: ownerOrganization,
                                                            vesselGroupStore: vesselGroupsStoreAll
                                                        })}
                                                    />
                                                </Route>
                                                {userStore.canViewApiClients && (
                                                    <Route path="/api-clients/:apiClientId">
                                                        <ApiClientsDetailsPage />
                                                    </Route>
                                                )}
                                                {userStore.canViewApiClients && (
                                                    <Route path="/api-clients">
                                                        <ApiClientsPage apiClientsStore={apiClientsStoreAll} />
                                                    </Route>
                                                )}
                                                <Route
                                                    exact
                                                    path="/"
                                                    render={() => {
                                                        return userStore.isSuperUser ? (
                                                            <Redirect to="/organizations" />
                                                        ) : (
                                                            <Redirect to="/users" />
                                                        );
                                                    }}
                                                />
                                                <Route component={EmptyPage} />
                                            </Switch>
                                        </section>
                                    </section>
                                </Route>
                            </Switch>
                        </PreviousLocationProvider>
                    </Router>
                </main>
            </RoleRestriction>
        );
    });
}

export default App;
