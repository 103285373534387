import { action, observable, makeObservable } from 'mobx';

import { LoadingStatusType } from './LoadingStatusType';
import { LoadingStatusErrorCode } from '@modules/core/models/index';

export class LoadingStatusState {
    status: LoadingStatusType;
    resultCode?: number;

    constructor() {
        makeObservable(this, {
            status: observable,
            resultCode: observable,
            load: action,
            loadSuccess: action,
            loadError: action
        });

        this.status = null;
        this.resultCode = null;
    }

    get isRequestFailed() {
        return this.resultCode >= LoadingStatusErrorCode.badRequest;
    }

    resetOrSetResultCode(code: number = null) {
        this.resultCode = code;
    }

    load() {
        this.resetOrSetResultCode();
        this.status = LoadingStatusType.isLoading;
    }

    loadSuccess() {
        this.resetOrSetResultCode();
        this.status = LoadingStatusType.isLoaded;
    }

    loadError(code?: number) {
        this.status = LoadingStatusType.isError;
        this.resetOrSetResultCode(code);
    }
}
