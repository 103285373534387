import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { msal } from '@config/msalConfig';
import { User } from '@modules/core/models';

import './UserMenu.scss';

interface Props {
    user: User;
}

export const UserMenu = (props: Props) => {
    const { user } = props;
    const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false);

    const toggleMenu = () => setIsOverlayVisible((isVisible) => !isVisible);
    const history = useHistory();

    useEffect(() => {
        const onClickHandler = (event: MouseEvent) => {
            const isOverlayClick = !!event
                .composedPath()
                .find(
                    (item: any) => item.className === 'user-menu-overlay' || item.className === 'user-menu-data-wrapper'
                );

            if (!isOverlayClick) {
                toggleMenu();
            }
        };

        if (isOverlayVisible) {
            document.addEventListener('click', onClickHandler);
        }

        return () => {
            document.removeEventListener('click', onClickHandler);
        };
    }, [isOverlayVisible]);

    const menuItems = [
        {
            label: 'Sign out',
            action: () => {
                toggleMenu();
                msal.instance.logout();
            },
            icon: 'icon-logout'
        },
        {
            label: 'About',
            action: () => {
                toggleMenu();
                history.push('/about');
            },
            icon: 'icon-about'
        }
    ];

    const overlayMenu = () => {
        return isOverlayVisible ? (
            <ul className="user-menu-overlay">
                {menuItems.map((item) => (
                    <li
                        className="user-menu-overlay-item"
                        qa-id="user-menu-sign-out"
                        key={item.label}
                        onClick={item.action}
                    >
                        {item.icon && <i className={`icon ${item.icon}`} />}
                        <span>{item.label}</span>
                    </li>
                ))}
            </ul>
        ) : null;
    };

    if (!user?.id) {
        return null;
    }

    return (
        <div className="user-menu" qa-id="user-menu">
            <div className="user-menu-data-wrapper" onClick={toggleMenu}>
                <i className="icon icon-menu-chevron" />
                <div className="user-menu-data">
                    <span className="user-name" qa-id="user-menu-name">
                        {user.firstName} {user.lastName}
                    </span>
                    <span className="user-company" qa-id="user-menu-company">
                        {user.organization?.name}
                    </span>
                </div>
            </div>
            {overlayMenu()}
        </div>
    );
};
