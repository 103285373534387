import React from 'react';

import './CheckboxSlider.scss';

interface Props {
    label?: string;
    id: string;
    handleChange: Function;
    isChecked: boolean;
    disabled?: boolean;
}

export const CheckboxSlider = (props: Props) => {
    const { id, handleChange, isChecked, disabled } = props;
    return (
        <div className="checkbox-table-wrapper">
            <label className="switch checkbox-slider" id={id}>
                <input
                    type="checkbox"
                    disabled={disabled}
                    onChange={(event) => handleChange(event)}
                    checked={isChecked}
                />
                <div className="slider round"></div>
            </label>
        </div>
    );
};
