import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import { EntityTable } from '@modules/shared/components';
import { ColumnSize, TableColumn } from '@modules/shared/models';
import { VesselShare } from '@modules/sharing/models';
import { SortOrderType } from '@modules/shared/constants';
import { CoreUtil, SortingUtils } from '@modules/core/utils';
import { LoadingStatusType } from '@modules/core/models';

interface Props {
    qaId?: string;
    vesselShares: VesselShare[];
    sortEntities: (sortFunc: (prevItem: any, nextItem: any) => number) => void;
    loadingStatus: LoadingStatusType;
    loadingDelay: number;
}

export const VesselSharesTable = observer<Props>((props) => {
    const { vesselShares, sortEntities, qaId, loadingStatus, loadingDelay } = props;

    const history = useHistory();

    const columns: TableColumn[] = [
        {
            title: 'Name',
            columnSize: ColumnSize.COLUMN2,
            property: 'vessel.name',
            className: () => 'divider',
            onClick: (vesselShare: VesselShare): void =>
                CoreUtil.goToPage(history, `/fleet/vessels/${vesselShare.vessel.id}`),
            render: (row: VesselShare) => row.vessel.name
        },
        {
            title: 'External organization',
            columnSize: ColumnSize.COLUMN3,
            property: 'organization.name',
            className: () => 'external',
            render: (row: VesselShare) => row.organization.name
        }
    ];

    const sortVesselShares = (property: string, order: SortOrderType): void => {
        sortEntities(SortingUtils.defaultCompare(property, order));
    };

    return (
        <EntityTable
            id={qaId}
            listOfEntities={vesselShares}
            sortEntities={sortVesselShares}
            loadingStatus={loadingStatus}
            loadingDelay={loadingDelay}
            hiddenControls={() => true}
            columns={columns}
            setEditModalVisibility={null}
            setDeleteModalVisibility={null}
            setCurrentEntity={null}
        />
    );
});

VesselSharesTable.defaultProps = {
    qaId: 'vessel_shares_table'
};
