import React from 'react';

interface Props {
    label?: string;
    id: string;
    handleChange: Function;
    isChecked: boolean;
    disabled?: boolean;
    extraElement?: any;
}

export const CustomCheckbox = (props: Props) => {
    const { label, id, handleChange, isChecked, disabled } = props;

    return (
        <div className={`checkbox ${disabled ? 'disabled' : ''}`}>
            <input
                type="checkbox"
                checked={isChecked}
                id={id}
                disabled={disabled}
                onChange={(event) => handleChange(event)}
            />
            <label htmlFor={id}>
                {isChecked ? <i className="icon icon-checkbox"></i> : null}
                {label}
            </label>
        </div>
    );
};

CustomCheckbox.defaultProps = {
    label: ''
};
