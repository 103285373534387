import React, { useCallback, useEffect } from 'react';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import { SearchInput } from '@fos/eniram-web-common-ui/dist/components/SearchInput';
import { flow } from 'mobx';

import { EntityTable } from '@modules/shared/components';
import { TableColumn } from '@modules/shared/models';
import { usePageContainer } from '@modules/shared/hooks';
import { Vessel } from '@modules/fleet/models';
import { SortOrderType } from '@modules/shared/constants';
import { SortingUtils } from '@modules/core/utils';

import './MultiListCombobox.scss';

interface Props {
    columns: TableColumn[];
    loadList: () => Promise<void>;
    filterLeftTableList: (query: string) => Vessel[];
    vessels?: any[];
    setSelectedRows: (list: any[]) => void;
    rightTableList: Vessel[];
    setRightTableList: React.Dispatch<React.SetStateAction<any[]>>;
}

const Icon = ({ className }: { className?: string }) => (
    <span className={`icon ${className}`}>
        <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.14209 1.39L1.62396 0L7.15094 5.21L12.6779 0L14.1598 1.39L7.15094 8L0.14209 1.39Z"
                fill="#086795"
            />
        </svg>
    </span>
);

export const MultiListCombobox = (props: Props) => {
    const { loadList, rightTableList, filterLeftTableList, vessels, setRightTableList, columns, setSelectedRows } =
        props;

    const tableContainerLoadingFunction = useCallback(
        flow(function* () {
            yield loadList();
        }),
        []
    );

    const findInRightTableList = (element: Vessel, query: string = '') => {
        const queryUpperCase = query.toUpperCase();
        return element.name.toUpperCase().includes(queryUpperCase);
    };

    const filterRightTableList = (query: string) => {
        if (!!query) {
            return rightTableList.filter((el) => findInRightTableList(el, query));
        }

        return rightTableList;
    };

    const leftTablePageContainer = usePageContainer(tableContainerLoadingFunction, filterLeftTableList);
    const rightTablePageContainer = usePageContainer(tableContainerLoadingFunction, filterRightTableList);

    useEffect(() => {
        if (!!vessels?.length) {
            setRightTableList(vessels);
        }
    }, [vessels]);

    if (!leftTablePageContainer || !rightTablePageContainer) {
        return null;
    }

    const onAddClick = (e: any) => {
        e.preventDefault();
        const newEntitiesToRightTable = rightTablePageContainer.entities.concat(leftTablePageContainer.selectedEntity);
        setRightTableList(newEntitiesToRightTable);
        setSelectedRows(newEntitiesToRightTable);

        leftTablePageContainer.selectEntity([]);
        rightTablePageContainer.selectEntity([]);

        const updatedLeftTableList = differenceWith(
            leftTablePageContainer.entities,
            leftTablePageContainer.selectedEntity,
            isEqual
        );
        leftTablePageContainer.setEntities(updatedLeftTableList);
    };

    const onRemoveClick = (e: any) => {
        e.preventDefault();
        leftTablePageContainer.setEntities([
            ...rightTablePageContainer.selectedEntity,
            ...leftTablePageContainer.entities
        ]);

        leftTablePageContainer.selectEntity([]);
        rightTablePageContainer.selectEntity([]);

        const updatedRightTableList = differenceWith(
            rightTablePageContainer.entities,
            rightTablePageContainer.selectedEntity,
            isEqual
        );
        setRightTableList(updatedRightTableList);
        setSelectedRows(updatedRightTableList);
    };

    return (
        <div className="multi-list-combobox box">
            <div className={'multi-list-combobox-table-container'}>
                <SearchInput
                    onChange={(value: string) => leftTablePageContainer.setQuery(value)}
                    autofocus={false}
                    placeholder="Search by Name"
                    search={leftTablePageContainer.query}
                    qaId="left-search-input"
                />
                <div className={'multi-list-combobox-table'}>
                    <EntityTable
                        id={'multi-list-combobox-table-left'}
                        columns={columns}
                        selectable
                        selectableAll={true}
                        listOfEntities={leftTablePageContainer.entities}
                        isArchiveAvailable={false}
                        setSelectedEntities={leftTablePageContainer.selectEntity}
                        sortEntities={(property: string, order: SortOrderType) => {
                            leftTablePageContainer.sortEntities(SortingUtils.defaultCompare(property, order));
                        }}
                        setCurrentEntity={leftTablePageContainer.selectEntity}
                        loadingDelay={400}
                        selectedEntity={leftTablePageContainer.selectedEntity || []}
                    />
                </div>
            </div>
            <div className="multi-list-combobox-controls">
                <button
                    className="button secondary"
                    disabled={!leftTablePageContainer.selectedEntity?.length}
                    onClick={onAddClick}
                >
                    Add
                    <Icon className="right" />
                </button>
                <button
                    className="button secondary"
                    disabled={!rightTablePageContainer.selectedEntity?.length}
                    onClick={onRemoveClick}
                >
                    <Icon className="left" />
                    Remove
                </button>
            </div>
            <div className={'multi-list-combobox-table-container'}>
                <SearchInput
                    onChange={(value: string) => rightTablePageContainer.setQuery(value)}
                    autofocus={false}
                    placeholder="Search by Name"
                    search={rightTablePageContainer.query}
                    qaId="right-search-input"
                />
                <div className={'multi-list-combobox-table'}>
                    <EntityTable
                        id={'multi-list-combobox-table-right'}
                        columns={columns}
                        selectable
                        selectableAll={true}
                        listOfEntities={rightTablePageContainer.entities}
                        isArchiveAvailable={false}
                        setSelectedEntities={rightTablePageContainer.selectEntity}
                        sortEntities={(property: string, order: SortOrderType) => {
                            rightTablePageContainer.sortEntities(SortingUtils.defaultCompare(property, order));
                        }}
                        setCurrentEntity={leftTablePageContainer.selectEntity}
                        loadingDelay={400}
                        selectedEntity={rightTablePageContainer.selectedEntity || []}
                    />
                </div>
            </div>
        </div>
    );
};
