import { RoleType } from '@modules/core/models';
import { Role } from '@modules/roles/models';
import { Product } from '@modules/connected-products/models';
import { CoreUtil } from '@modules/core/utils/CoreUtil';

export class RoleUtils {
    static isAccessible = (accessRoles: RoleType[], exact: boolean, roles: RoleType[]): boolean =>
        exact
            ? accessRoles.length === roles.length && accessRoles.every((accessRole) => roles.includes(accessRole))
            : accessRoles.some((accessRole) => roles.includes(accessRole));

    static isSuperUser = (roles: RoleType[]) =>
        RoleUtils.isAccessible([RoleType.Superuser, RoleType.Support], false, roles);

    static isSupportUser = (roles: RoleType[]) =>
        RoleUtils.isAccessible([RoleType.Support], false, roles) &&
        !RoleUtils.isAccessible([RoleType.Superuser], false, roles);

    static isBasicUser = (roles: RoleType[]) =>
        RoleUtils.isAccessible([RoleType.User], false, roles) &&
        !RoleUtils.isSuperUser(roles) &&
        RoleUtils.isUser(roles);

    static getRoleDataByRoleIds = (ids: string[], rolesJournal: Role[]) => {
        return rolesJournal.filter((role: Role) => {
            return ids.find((id) => role.id === id);
        });
    };

    static mapPermissionsByProduct = (products: Product[], toOptions?: string) => {
        return products.reduce((acc, product: Product) => {
            return {
                ...acc,
                [product.code]: toOptions ? CoreUtil.mapToOption(product.permissions) : product.permissions
            };
        }, {});
    };

    static getRowsHeight = function <rowType, columnType>(
        rows: rowType[],
        columns: columnType[],
        params: {
            [key: string]: {
                mult: number;
                indent?: number;
            };
        }
    ): number[] {
        const height = 40;
        const heights: number[] = [];
        rows.forEach((row: any) => {
            if (row) {
                const cellsHeights = columns.map(() => {
                    const hCells = [height];
                    const hTextRow = 40;
                    for (const key in params) {
                        if (row[key]?.length > params[key].mult) {
                            const temp = +(row[key].length / params[key].mult).toFixed(0);
                            hCells.push(hTextRow * temp);
                        }
                    }
                    return !!hCells.length && row['products'].length > 1 ? Math.max.apply(0, hCells) : height;
                });
                heights.push(Math.max.apply(0, cellsHeights));
            }
        });
        return heights;
    };

    static isUser = (roles: RoleType[]) => RoleUtils.isAccessible([RoleType.User], true, roles);
}
