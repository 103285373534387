import { Vessel } from '@modules/fleet/models';

export interface VesselShare {
    id?: string; //description: custom fe id for correct render in table
    vessel: Partial<Vessel>;
    organization: {
        id: string;
        name: string;
    };
    product?: {
        options: string[];
        code: string;
    };
    creationDate: string;
}

export interface NetworkPartner {
    code: string;
    name: string;
    [key: string]: any;
}

export enum Steps {
    GET_PARTNER_ID = 0,
    ADD_VESSELS = 1,
    ADD_OPTIONS = 2
}

export interface FormFields {
    partnerCode: string;
    partnerName: string;
    vessels: Vessel[];
}
