import { autorun, IReactionDisposer } from 'mobx';
import { useCallback, useEffect, useState } from 'react';
import { CancellablePromise } from 'mobx/dist/api/flow';

import { CoreUtil } from '@modules/core/utils';
import { searchState } from '@modules/core/store/SearchState';

export const usePageContainer = (
    loadEntities: () => CancellablePromise<void>,
    filterEntities: (value: string) => any[],
    addEntityFunction?: (value: any, entities: any[], ...args: any[]) => CancellablePromise<any>,
    editEntityFunction?: (value: any, entities: any[], ...args: any[]) => CancellablePromise<any>,
    deleteEntityFunction?: (value: any, entities: any[], ...args: any[]) => CancellablePromise<any>,
    disableEntityAutoDeselect?: boolean,
    searchArea?: string
) => {
    const [isAssignModalVisible, setAssignModalVisibility] = useState(false);
    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false);
    const [isDeleteInnerModalVisible, setDeleteInnerModalVisibility] = useState(false);
    const [isCreateModalVisible, setCreateModalVisibility] = useState(false);
    const [selectedEntity, selectEntity] = useState<any>();
    const [entities, setEntities] = useState<any[]>([]);
    const [query, setQuery] = useState<any>((searchState.search && searchState.search[searchArea]) || '');
    const [isEditMode, setIsEditMode] = useState(false);
    const [sortingFunction, setSortingFunction] = useState<(prevItem: any, nextItem: any) => number>();
    const [debounce] = useState(() => CoreUtil.debounce());

    useEffect(() => {
        let cancelled = false;
        const request = loadEntities();
        request.catch(() => {
            cancelled = true;
        });

        return () => !cancelled && request.cancel();
    }, [loadEntities]);

    useEffect(() => {
        setIsEditMode(!!selectedEntity);
    }, [selectedEntity]);

    useEffect(() => {
        if (searchArea) {
            searchState.setSearch(searchArea, query);
        }
    }, [query]);

    const sortEntities = useCallback(
        (sortFunc: (prevItem: any, nextItem: any) => number) => {
            setSortingFunction(() => sortFunc);
            setEntities(filterEntities(query.trim()).slice().sort(sortFunc));
        },
        [setSortingFunction, query, setEntities, filterEntities]
    );

    useEffect(() => {
        let disposer: IReactionDisposer;

        debounce(() => {
            disposer = autorun(() => {
                !sortingFunction ? setEntities(filterEntities(query.trim())) : sortEntities(sortingFunction);
            });
        }, 500);

        return () => {
            if (disposer) {
                disposer();
            }
        };
    }, [query, filterEntities, debounce, sortingFunction, setEntities, filterEntities]);

    const addEntity = async (value: any, ...args: any[]): Promise<any> => {
        if (!addEntityFunction) {
            return;
        }

        return await addEntityFunction(value, entities, args);
    };

    const editEntity = async (value: any, ...args: any[]): Promise<any> => {
        if (!editEntityFunction) {
            return;
        }

        return await editEntityFunction(value, entities, args);
    };

    const deleteEntity = async (value: any, ...args: any[]): Promise<any> => {
        if (!deleteEntityFunction) {
            return;
        }

        return await deleteEntityFunction(value, entities, args);
    };

    useEffect(() => {
        if (!isDeleteModalVisible) {
            !disableEntityAutoDeselect && selectEntity(null);
        }
    }, [isDeleteModalVisible]);

    const isCreateEditModalVisible = useCallback(() => {
        return isAssignModalVisible || isEditModalVisible;
    }, [isAssignModalVisible, isEditModalVisible]);

    const onCloseCreateEditModal = () => {
        return () => {
            setEditModalVisibility(false);
            setAssignModalVisibility(false);
            !disableEntityAutoDeselect && selectEntity(null);
        };
    };

    return {
        isAssignModalVisible,
        isCreateModalVisible,
        isEditModalVisible,
        isDeleteModalVisible,
        isDeleteInnerModalVisible,
        setDeleteInnerModalVisibility,
        setAssignModalVisibility,
        setEditModalVisibility,
        setDeleteModalVisibility,
        setCreateModalVisibility,
        selectEntity,
        selectedEntity,
        entities,
        setEntities,
        addEntity,
        editEntity,
        deleteEntity,
        sortEntities,
        query,
        setQuery,
        isEditMode,
        setIsEditMode,
        isCreateEditModalVisible,
        onCloseCreateEditModal
    };
};
