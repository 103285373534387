import React, { useMemo } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import { EntityTable, WithTooltip } from '@modules/shared/components';
import { ColumnSize, Dictionary, TableColumn } from '@modules/shared/models';
import { Account } from '@modules/accounts/models';
import { DateUtil } from '@modules/shared/utils';
import { LoadingStatusType } from '@modules/core/models';
import { SortOrderType } from '@modules/shared/constants';
import { SortingUtils, CoreUtil } from '@modules/core/utils';
import { RoleMultiView } from '@modules/core/components';
import { useRolesState } from '@modules/shared/hooks';

import './AccountsTable.scss';

interface Props {
    id: string;
    accounts: Account[];
    isOrganization?: boolean;
    sortEntities?: (sortFunc: (prevItem: any, nextItem: any) => number) => void;
    setEditModalVisibility: (value: boolean) => any;
    setDeleteModalVisibility: (value: boolean) => any;
    setSelectedAccount: (account: Account) => void;
    loadingStatus: LoadingStatusType;
    rolesMap: Dictionary<string>;
}

export const AccountsTable = (props: Props) => {
    const {
        accounts,
        rolesMap,
        isOrganization,
        setSelectedAccount,
        setEditModalVisibility,
        setDeleteModalVisibility,
        loadingStatus,
        sortEntities,
        id
    } = props;

    const history = useHistory();
    const { isSuperUser, isSupportUser } = useRolesState();
    const isOrganizationColumnHidden = useMemo(
        () => !isSuperUser || (isSuperUser && isOrganization),
        [isSuperUser, isOrganization]
    );
    const isUserColumnHidden = useMemo(() => isSuperUser && !isOrganization, [isSuperUser, isOrganization]);

    const NameWithTooltip = (account: Account) => <WithTooltip content={`${account.firstName} ${account.lastName}`} />;
    const CompanyWithTooltip = (account: Account) => <WithTooltip content={account.organization?.name} />;
    const PositionWithTooltip = (account: Account) => <WithTooltip content={account.position} />;
    const EmailWithTooltip = (account: Account) => <WithTooltip content={account.email} />;
    const RoleWithTooltip = (account: Account) => <RoleMultiView rolesMap={rolesMap} userRoles={account.roles} />;

    const columns: TableColumn[] = [
        {
            title: 'FULL NAME',
            columnSize: ColumnSize.COLUMN3,
            className: () => 'divider',
            property: 'firstName',
            render: NameWithTooltip,
            onClick: (account: Account) => CoreUtil.goToPage(history, `/users/${account.id}`)
        },
        {
            title: 'LOGIN EMAIL',
            columnSize: ColumnSize.COLUMN3,
            property: 'email',
            render: EmailWithTooltip
        },
        {
            title: 'POSITION',
            columnSize: ColumnSize.COLUMN2,
            property: 'position',
            render: PositionWithTooltip
        },
        {
            title: 'ROLE',
            columnSize: ColumnSize.COLUMN2,
            property: 'role',
            render: RoleWithTooltip,
            hidden: isUserColumnHidden
        },
        {
            title: 'REGISTRATION DATE',
            columnSize: ColumnSize.COLUMN4,
            property: 'registrationDate',
            className: () => 'registration-date',
            render: (account: Account) => DateUtil.formatDate(account.registrationDate)
        },
        {
            title: 'ORGANIZATION',
            columnSize: ColumnSize.COLUMN3,
            render: CompanyWithTooltip,
            property: 'organization.name',
            hidden: isOrganizationColumnHidden,
            onClick: (account: Account) =>
                account?.organization?.id &&
                CoreUtil.goToPage(history, `/organizations/${account.organization.id}/users`)
        }
    ];

    const sortAccounts = (property: string, order: SortOrderType) => {
        if (property === 'firstName') {
            sortEntities(SortingUtils.multiPropCompare(order, 'firstName', 'lastName'));
        } else if (property === 'role') {
            sortEntities(SortingUtils.roleCompare('roles', order, rolesMap));
        } else {
            sortEntities(SortingUtils.defaultCompare(property, order));
        }
    };

    const isEntityModifiable = () => !isSupportUser;

    return useObserver(() => (
        <EntityTable
            id={id}
            sortEntities={sortAccounts}
            listOfEntities={accounts}
            loadingStatus={loadingStatus}
            columns={columns}
            setEditModalVisibility={isSupportUser ? null : setEditModalVisibility}
            setDeleteModalVisibility={isSupportUser ? null : setDeleteModalVisibility}
            setCurrentEntity={setSelectedAccount}
            deletable={isEntityModifiable}
            editable={isEntityModifiable}
        />
    ));
};
