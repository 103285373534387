import React, { ReactElement } from 'react';
import { useObserver } from 'mobx-react-lite';

import { EntityTable } from '@modules/shared/components';
import { ColumnSize, TableColumn } from '@modules/shared/models';
import { VesselGroup } from '../models';
import { LoadingStatusType } from '@modules/core/models';
import { SortOrderType } from '@modules/shared/constants';
import { SortingUtils } from '@modules/core/utils';

interface Props {
    id: string;
    vesselGroups: VesselGroup[];
    sortEntities?: (sortFunc: (prevItem: any, nextItem: any) => number) => void;
    setEditModalVisibility: (value: boolean) => any;
    setDeleteModalVisibility: (value: boolean) => any;
    setSelectedVesselGroup: (value: VesselGroup) => void;
    selectedVesselGroup: VesselGroup;
    selectVesselGroup: (group: VesselGroup) => void;
    loadingStatus: LoadingStatusType;
}

export const VesselGroupTable = (props: Props) => {
    const {
        vesselGroups,
        setSelectedVesselGroup,
        setDeleteModalVisibility,
        selectedVesselGroup,
        setEditModalVisibility,
        id,
        sortEntities,
        selectVesselGroup,
        loadingStatus
    } = props;

    const columns: TableColumn[] = [
        {
            title: 'NAME',
            columnSize: ColumnSize.COLUMN5,
            property: 'name',
            // eslint-disable-next-line react/display-name
            render: (group: VesselGroup): ReactElement => (
                <div className="wellknown-group">
                    <span>{group.name}</span>
                    {group.wellknown && <i className="icon icon-lock" />}
                </div>
            ),
            onClick: (value: VesselGroup) => selectVesselGroup(value)
        }
    ];

    const sortVesselGroups = (property: string, order: SortOrderType): void => {
        sortEntities(SortingUtils.defaultCompare(property, order));
    };

    const isAllVessels = (vesselGroup: VesselGroup) => vesselGroup.name.toLowerCase() === 'all vessels';
    const editableGroup = (vesselGroups: VesselGroup) => !isAllVessels(vesselGroups);

    return useObserver(() => (
        <EntityTable
            id={id || 'vessel_groups_table'}
            listOfEntities={vesselGroups}
            columns={columns}
            loadingStatus={loadingStatus}
            isArchiveAvailable={false}
            hiddenControls={(group: VesselGroup) => group?.wellknown}
            setDeleteModalVisibility={setDeleteModalVisibility}
            setCurrentEntity={setSelectedVesselGroup}
            currentEntity={selectedVesselGroup}
            setEditModalVisibility={setEditModalVisibility}
            sortEntities={sortVesselGroups}
            deletable={editableGroup}
            editable={editableGroup}
        />
    ));
};

VesselGroupTable.defaultProps = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function, prettier/prettier
    setSelectedVessel: () => {}
};
