import { Dictionary } from '@modules/shared/models';

export enum RoleType {
    Admin = 'admin',
    Support = 'support',
    Superuser = 'superuser',
    User = 'user'
}

export const AvailableRoles: Dictionary<RoleType> = {
    Administrator: RoleType.Admin,
    Support: RoleType.Support,
    Superuser: RoleType.Superuser,
    User: RoleType.User
};

export type IdentityType = 'User' | 'ApiClient';
