import React, { useCallback, useMemo, useState } from 'react';
import { flow } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { SearchInput } from '@fos/eniram-web-common-ui/dist/components/SearchInput';

import { useStore } from '@store/StoreEffect';
import { usePageContainer, useRolesState, useSelectedState } from '@modules/shared/hooks';
import { FleetStore } from '@modules/fleet/store';
import { Vessel } from '@modules/fleet/models';
import { LoadingStatusType } from '@modules/core/models';
import { LoadPlaceholder } from '@modules/core/components';
import { SharingTable } from '@modules/sharing/components';
import { VesselSharesStore } from '@modules/sharing/store';
import { ConfirmModal } from '@modules/shared/components';
import { ShareVesselsModal } from '@modules/sharing/components';
import { VesselShare } from '@modules/sharing/models';

import './SharingPage.scss';

const SearchArea = 'sharingPage';

interface Props {
    isOrganization?: boolean;
    fleetStore: FleetStore;
    vesselSharesStore: VesselSharesStore;
}

export const SharingPage = (props: Props) => {
    const { isOrganization, vesselSharesStore } = props;

    const { userStore } = useStore();
    const { currentUser } = userStore;
    const { organization } = useSelectedState();
    const { isSupportUser, isSuperUser } = useRolesState();
    const [currentVesselShare, setCurrentVesselShare] = useState<VesselShare>(null);
    const { loadVesselSharesByOrganization, filterVesselShares, loadingStatusState, unshareVessels, vesselShares } =
        vesselSharesStore;
    const shareButtonVisible = !isSupportUser && (!isSuperUser || isOrganization);
    const organizationId = useMemo(
        () => (isOrganization ? organization?.id : currentUser?.organization?.id),
        [organization, currentUser, isOrganization]
    );

    const containerLoadingFunction = useCallback(
        flow(function* () {
            if (organizationId) {
                yield loadVesselSharesByOrganization(organizationId);
            }
        }),
        [isOrganization, organizationId]
    );

    const {
        entities,
        selectedEntity,
        sortEntities,
        isCreateEditModalVisible,
        setAssignModalVisibility,
        onCloseCreateEditModal,
        selectEntity,
        deleteEntity,
        isDeleteModalVisible,
        setDeleteModalVisibility,
        query,
        setQuery
    } = usePageContainer(
        containerLoadingFunction,
        filterVesselShares,
        () => null,
        () => null,
        unshareVessels,
        true,
        SearchArea
    );

    const onUnshare = () => {
        setDeleteModalVisibility(true);
    };

    const onVesselShares = async (partnerCode: string, vessels: Vessel[]) => {
        await vesselSharesStore.addVesselSharesToPartner(organizationId, partnerCode, vessels);
        selectEntity([]);
    };

    const onSubmitDelete = async () => {
        const selectedVesselShares = currentVesselShare ? [currentVesselShare] : selectedEntity;
        await deleteEntity({ organizationId, vesselShares: selectedVesselShares });

        setCurrentVesselShare(null);
        selectEntity([]);
        setDeleteModalVisibility(false);
    };

    const onCancelDelete = () => {
        setCurrentVesselShare(null);
        setDeleteModalVisibility(false);
    };

    return useObserver(() => (
        <div className="sharing-page">
            <main className="card">
                <LoadPlaceholder loadingStatus={loadingStatusState.status || LoadingStatusType.isLoaded}>
                    <header className="card-header">
                        <div className="card-header-controls">
                            {(shareButtonVisible && (
                                <button className="button primary" onClick={() => setAssignModalVisibility(true)}>
                                    Share
                                </button>
                            )) || <div />}
                            <div className="card-header-filter">
                                <SearchInput
                                    onChange={(value: string) => setQuery(value)}
                                    autofocus={false}
                                    placeholder="Search by Name or Partner"
                                    search={query}
                                />
                            </div>
                        </div>
                    </header>
                    <section className="card-body">
                        <SharingTable
                            id="sharing-table"
                            vesselShares={entities}
                            sortEntities={sortEntities}
                            setEditModalVisibility={null}
                            setDeleteModalVisibility={onUnshare}
                            loadingStatus={loadingStatusState && loadingStatusState.status}
                            selectedEntity={selectedEntity || []}
                            setSelectedVesselShares={selectEntity}
                            setCurrentVesselShare={setCurrentVesselShare}
                            ownerOrganization={organization}
                        />
                    </section>
                    {!isSupportUser && selectedEntity?.length > 0 && (
                        <section className="card-footer">
                            <div>{`Total vessels selected: ${selectedEntity?.length}`}</div>
                            <button type="submit" className="button primary" onClick={onUnshare}>
                                Stop sharing
                            </button>
                        </section>
                    )}
                    {isCreateEditModalVisible() && (
                        <ShareVesselsModal
                            fleetStore={props.fleetStore}
                            organization={organization}
                            modalIsOpen={isCreateEditModalVisible()}
                            onSubmit={onVesselShares}
                            onCloseModal={onCloseCreateEditModal()}
                            vesselShares={vesselShares}
                        />
                    )}
                    {isDeleteModalVisible ? (
                        <ConfirmModal
                            qaId="unshare-vessels"
                            modalTitle="Stop sharing vessels"
                            icon={<i className="icon icon-about-big-black" />}
                            modalContentText={
                                !currentVesselShare && selectedEntity?.length > 1
                                    ? `Do you want to stop sharing ${selectedEntity?.length} selected vessels?`
                                    : 'Do you want to stop sharing selected vessel?'
                            }
                            submitButtonTitle="Stop sharing"
                            modalIsOpen={isDeleteModalVisible}
                            onCloseModal={onCancelDelete}
                            onSubmit={onSubmitDelete}
                        />
                    ) : null}
                </LoadPlaceholder>
            </main>
        </div>
    ));
};
