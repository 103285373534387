import { Dictionary } from '@modules/shared/models';
import { VariableGroup } from '../models';

function mapToOptions(groups: VariableGroup[]) {
    return groups.map((group) => ({
        value: group.id,
        label: group.name
    }));
}

export function mapFieldVariableGroups(
    groupIds?: number[],
    groups: VariableGroup[] = [],
    fieldsCollection?: Dictionary<any>
) {
    const groupOptions = mapToOptions(groups);
    const assignedGroups = (groupIds || [])
        .map((id) => groups.find((group) => group.id === id))
        .filter((group) => !!group);

    return {
        fieldName: 'variableGroups',
        defaultValue: mapToOptions(assignedGroups),
        options: groupOptions,
        ...fieldsCollection
    };
}
