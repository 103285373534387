import React, { useCallback, useEffect, useState } from 'react';
import { autorun } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { SearchInput } from '@fos/eniram-web-common-ui/dist/components/SearchInput';

import differenceWith from 'lodash/differenceWith';
import { CoreUtil as CU, SortingUtils } from '@modules/core/utils';
import { EntityTable, ModalWindow } from '@modules/shared/components';
import { getVesselGroupTableColumns, getVesselsTableColumns } from '@modules/fleet/constants';
import { Vessel } from '@modules/fleet/models';
import { Account } from '@modules/accounts/models';
import { Dictionary, ModalProps } from '@modules/shared/models';
import { LoadingStatusState, LoadingStatusType } from '@modules/core/models';
import { VesselGroup } from '@modules/vessel-groups/models';
import { SortOrderType } from '@modules/shared/constants';

import './AssignVesselModal.scss';

interface Props extends ModalProps {
    filterVessels: (name: string) => Vessel[];
    isEditMode: boolean;
    vessel?: Account;
    modalTitle: string;
    linkedVessels: Vessel[];
    skipSelectedVessels: boolean;
    directlyAssignedVessels: Vessel[];
    loadingVesselsStatusState?: LoadingStatusState;
    loadingGroupsStatusState?: LoadingStatusState;
    vesselGroups: VesselGroup[];
    selectedVesselGroups?: VesselGroup[];
    vesselsFromSelectedGroups: Dictionary<boolean>;
    setVesselsFromSelectedGroups: (state: Dictionary<boolean>) => void;
    isGroupsVisible?: boolean;
}

export const AssignVesselModal = (props: Props) => {
    const {
        modalIsOpen,
        onCloseModal,
        filterVessels,
        onSubmit,
        modalTitle,
        skipSelectedVessels,
        loadingVesselsStatusState,
        loadingGroupsStatusState,
        vesselGroups,
        linkedVessels,
        selectedVesselGroups,
        isGroupsVisible,
        vesselsFromSelectedGroups,
        setVesselsFromSelectedGroups,
        directlyAssignedVessels
    } = props;

    const [query, setQuery] = useState<string>('');
    const [vessels, setVessels] = useState<any[]>([]);
    const [selectedVessels, setSelectedVessels] = useState<Vessel[]>(directlyAssignedVessels);
    const [selectedGroups, setSelectedGroups] = useState<VesselGroup[]>(selectedVesselGroups);
    const [selectedGroupsIds, setSelectedGroupsIds] = useState<Dictionary<boolean>>({});
    const [alreadySelectedVesselsIds, setAlreadySelectedVesselsIds] = useState<Dictionary<boolean>>({});

    useEffect(() => {
        const disposer = autorun(() => {
            let vessels = filterVessels(query.trim());

            if (skipSelectedVessels) {
                vessels = vessels.filter(
                    (vessel) => !linkedVessels.find((linkedVessel) => linkedVessel.id === vessel.id)
                );
            }

            setVessels(vessels);
        });
        return () => disposer();
    }, [query, filterVessels, setVessels, skipSelectedVessels, linkedVessels]);

    useEffect(() => {
        const vesselsProps = directlyAssignedVessels ?? linkedVessels;

        const updatedVessels = differenceWith(
            vesselsProps,
            vessels,
            (vesselProps: Vessel, vessel: Vessel) => vesselProps.id !== vessel.id
        );

        if (!!updatedVessels?.length) {
            const alreadySelected = vesselsProps.reduce(
                (result: Dictionary<boolean>, current: Vessel) => ({
                    ...result,
                    [current.id]: true
                }),
                {}
            );
            setAlreadySelectedVesselsIds(alreadySelected);
        }
    }, [linkedVessels, directlyAssignedVessels]);

    useEffect(() => {
        const vesselMap: Dictionary<boolean> = {};

        let combinedVessels: string[] = [];
        selectedGroups.forEach((group) => (combinedVessels = [...combinedVessels, ...group.vessels]));

        const uniqueCombinedVessels: string[] = [...new Set(combinedVessels)];

        uniqueCombinedVessels.forEach((id) => {
            vesselMap[id] = true;
        });

        setVesselsFromSelectedGroups(vesselMap);
    }, [selectedGroups, loadingGroupsStatusState.status]);

    const updateSelectedVesselGroups = useCallback(
        (selected) => {
            const notIsNil = CU.compose(CU.not, CU.isNil);
            const getIds = CU.compose(CU.uniq, CU.pluck('id'), CU.filter(notIsNil));
            const ids: string[] = getIds(selected);
            setSelectedGroupsIds(
                ids.reduce(
                    (result, current) => ({
                        ...result,
                        [current]: true
                    }),
                    {}
                )
            );
        },
        [setSelectedGroupsIds]
    );

    useEffect(() => {
        updateSelectedVesselGroups(selectedVesselGroups);
    }, [selectedVesselGroups, updateSelectedVesselGroups]);

    const onSubmitVessels = async () => {
        const filteredSelectedVessels = selectedVessels.filter(
            (vesselEntity) => !vesselsFromSelectedGroups[vesselEntity?.id]
        );

        const interceptedVessels = selectedVessels.filter((selectedVessel) =>
            directlyAssignedVessels.find((directlyAssignedVessel) => selectedVessel?.id === directlyAssignedVessel?.id)
        );

        const vesselsToSubmit = CU.uniq([...interceptedVessels, ...filteredSelectedVessels]);

        await onSubmit(vesselsToSubmit, selectedGroups);
        onCloseModal(false);
    };

    const sortVesselGroupsInModal = (property: string, order: SortOrderType): void => {
        const sortedGroups = vesselGroups.slice().sort(SortingUtils.defaultCompare(property, order));
        vesselGroups.splice(0, vesselGroups.length, ...sortedGroups);
    };

    const sortVesselsInModal = (property: string, order: SortOrderType): void => {
        setVessels(vessels.slice().sort(SortingUtils.defaultCompare(property, order)));
    };

    const onSelectedGroups = (groupId: string, isSelected: boolean, group?: VesselGroup): void => {
        if (!isSelected) {
            const diff = differenceWith(
                selectedVessels,
                group?.vessels || [],
                (selectedVessel: Vessel, selectedVesselId: string) =>
                    selectedVesselId && selectedVessel.id === selectedVesselId
            );
            const selected = diff.reduce((acc, el) => ({ ...acc, [el.id]: true }), {});
            setAlreadySelectedVesselsIds(selected);
        }
    };

    const assignVesselModalLoadingStatus =
        isGroupsVisible && loadingVesselsStatusState.status === LoadingStatusType.isLoaded
            ? loadingGroupsStatusState.status
            : loadingVesselsStatusState.status;

    return useObserver(() => (
        <ModalWindow
            modalIsOpen={modalIsOpen}
            className="assign-vessel-popup"
            title={modalTitle}
            submitButtonName="SAVE"
            onSubmit={onSubmitVessels}
            onCancel={() => onCloseModal(false)}
            loadingModal={assignVesselModalLoadingStatus}
            disabledSubmit={assignVesselModalLoadingStatus !== LoadingStatusType.isLoaded}
        >
            {isGroupsVisible && (
                <div className="vessel-groups-list">
                    <EntityTable
                        id="vessel_groups_table"
                        selectable
                        selectableAll={false}
                        hiddenControls={() => true}
                        listOfEntities={vesselGroups}
                        alreadySelectedIds={selectedGroupsIds}
                        columns={getVesselGroupTableColumns()}
                        isArchiveAvailable={false}
                        selectedEntity={selectedGroups}
                        setSelectedEntities={setSelectedGroups}
                        onSelectEntity={onSelectedGroups}
                        setCurrentEntity={null}
                        sortEntities={sortVesselGroupsInModal}
                    />
                </div>
            )}
            <div className="vessel-filter">
                <SearchInput
                    onChange={(value: string) => setQuery(value)}
                    autofocus={false}
                    placeholder="Search by Name, DMS ID, IMO, CMS ID"
                    search={query}
                    qaId="assign-vessel-search"
                />
            </div>
            <div className="vessel-list">
                <EntityTable
                    id="add_new_fleet_table"
                    selectable
                    hiddenControls={() => true}
                    listOfEntities={vessels}
                    columns={getVesselsTableColumns(true)}
                    isArchiveAvailable={false}
                    selectedEntity={selectedVessels}
                    setSelectedEntities={setSelectedVessels}
                    setCurrentEntity={null}
                    alreadySelectedIds={alreadySelectedVesselsIds}
                    setAlreadySelectedVesselsIds={setAlreadySelectedVesselsIds}
                    disabledAndSelectedIds={vesselsFromSelectedGroups}
                    sortEntities={sortVesselsInModal}
                />
            </div>
        </ModalWindow>
    ));
};
