import React, { useCallback, useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { CancellablePromise } from 'mobx/dist/api/flow';
import { autorun } from 'mobx';

import { Product, Option } from '@modules/connected-products/models';
import { Dictionary } from '@modules/shared/models';
import { LoadPlaceholder } from '@modules/core/components';
import { hiddenLicenseOptions } from '@modules/products/constants';
import { ConnectedProductsStore } from '@modules/connected-products/store';
import {
    ConnectedProductsTable,
    ConnectedProductsFooter,
    ConnectedProductsHeader
} from '@modules/connected-products/components';

import './ConnectedProductsPage.scss';

interface Props {
    className?: string;
    editable?: boolean;
    isCreateMode?: boolean;
    isProductPageMode?: boolean; // ToDo remove and add correct logic
    isGroupTabsHidden?: boolean;
    loadingFunction: () => CancellablePromise<void>;
    saveFunction?: (productId: string, services: any) => Promise<any>;
    connectedProductsStore: ConnectedProductsStore;
}

export const ConnectedProductsPage = (props: Props) => {
    const { connectedProductsStore } = props;
    const { loadingStatusState, productsTabs, products } = connectedProductsStore;
    const { loadingFunction, editable, isCreateMode, isProductPageMode, isGroupTabsHidden, saveFunction } = props;

    const [availableProducts, setAvailableProducts] = useState<any[]>();
    const [selectedProduct, setSelectedProduct] = useState<Product>(null);
    const [subscribedOptions, setSubscribedOptions] = useState<Dictionary<Option>>({});
    const [optionsToShow, setOptionsToShow] = useState([]);

    useEffect(() => {
        let cancelled = false;
        const request = loadingFunction();
        request.catch(() => {
            cancelled = true;
        });

        return () => !cancelled && request.cancel();
    }, [loadingFunction]);

    useEffect(() => {
        const disposer = autorun(() => {
            if (connectedProductsStore.products?.length) {
                setAvailableProducts(productsTabs);
                if (!selectedProduct) {
                    setSelectedProduct(connectedProductsStore.products[0]);
                }
            }
        });
        return () => disposer();
    }, [connectedProductsStore.products, setAvailableProducts, setSelectedProduct]);

    const updateSubscribedOptions = useCallback(
        (option: Option) => {
            setSubscribedOptions({
                ...subscribedOptions,
                [option.code]: !subscribedOptions[option?.code] ? option : null
            });
        },
        [subscribedOptions, setSubscribedOptions]
    );

    const initSubscribedOptions = (options: Option[] = []) => {
        setSubscribedOptions(
            options
                .filter((option) => option.subscribed)
                .reduce((result: Dictionary<Option>, option) => {
                    return {
                        ...result,
                        [option?.code]: option
                    };
                }, {})
        );
    };

    useEffect(() => {
        initSubscribedOptions(selectedProduct?.options);
    }, [selectedProduct, setSubscribedOptions]);

    const onChange = (option: Option) => {
        updateSubscribedOptions(option);
    };

    const onChangeProduct = useCallback(
        (productCode: string) => {
            const product = products.find((product) => product.code === productCode);
            setSelectedProduct(product);
            if (!subscribedOptions) {
                setSubscribedOptions(
                    product?.options.reduce(
                        (result: Dictionary<Option>, option) => ({
                            ...result,
                            [option.code]: option.subscribed ? option : null
                        }),
                        {}
                    )
                );
            }
        },
        [products, subscribedOptions, setSelectedProduct, setSubscribedOptions]
    );

    const onSubmit = async () => {
        const optionsToBeSubscribed = Object.keys(subscribedOptions)
            .filter((key) => !!subscribedOptions[key] && !subscribedOptions[key].immutable)
            .reduce((acc, current) => {
                return [...acc, current];
            }, []);

        await saveFunction(selectedProduct.code, optionsToBeSubscribed);

        const updatedSelectedProductOptions = selectedProduct.options.reduce((acc, option) => {
            return [
                ...acc,
                {
                    ...option,
                    subscribed: optionsToBeSubscribed.includes(option.code)
                }
            ];
        }, []);
        setSelectedProduct({ ...selectedProduct, options: updatedSelectedProductOptions });
    };

    const setOptions = (options: Option[] = []) => {
        const filteredOptions = options.filter((optionInstance) => !hiddenLicenseOptions.includes(optionInstance.code));

        setOptionsToShow(filteredOptions);
    };

    const onCancel = () => {
        onChangeProduct(selectedProduct.code);
        setSelectedProduct(selectedProduct);
        setOptions(selectedProduct.options);
        initSubscribedOptions(selectedProduct.options);
    };

    useEffect(() => {
        setOptions(selectedProduct?.options);
    }, [selectedProduct, setOptionsToShow, hiddenLicenseOptions]);

    return useObserver(() => (
        <main className="card card-connected-products" qa-id="connected-products">
            <LoadPlaceholder loadingStatus={loadingStatusState.status}>
                <ConnectedProductsHeader
                    products={availableProducts}
                    isHidden={isGroupTabsHidden}
                    selectedProduct={selectedProduct}
                    isCreateButtonVisible={isCreateMode}
                    onChangeProduct={onChangeProduct}
                />

                <section className="card-body">
                    <ConnectedProductsTable
                        isProductMode={isProductPageMode}
                        options={optionsToShow}
                        subscriptions={subscribedOptions}
                        onSubscribe={onChange}
                    />
                </section>
                <ConnectedProductsFooter isHidden={!editable} onSubmit={onSubmit} onCancel={onCancel} />
            </LoadPlaceholder>
        </main>
    ));
};

ConnectedProductsPage.defaultProps = {
    className: ''
};
