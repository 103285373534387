import { flow } from 'mobx';
import { alertStore } from '@modules/core/store/AlertStore';
import { apiStore } from '@modules/core/store/ApiStore';
import { AccountsStore } from '@modules/accounts/store/AccountsStore';
import { HttpClient } from '@modules/core/utils';

export class AccountsStoreAll extends AccountsStore {
    constructor() {
        super();
        this.loadAccounts = this.loadAccounts.bind(this);
        this.resendInvitation = this.resendInvitation.bind(this);
    }

    loadAccounts = flow(function* (this: AccountsStoreAll, organizationId = '', fields = '') {
        const { api, errorMessageHandler } = apiStore.api.users.getUsers;
        try {
            this.loadingStatusState.load();
            this.accounts = yield HttpClient.TCMS.GET(api(organizationId, fields));
            this.loadingStatusState.loadSuccess();
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });

    resendInvitation = flow(function* (this: AccountsStoreAll, accountId: string, orgId: string) {
        const { api, successMessage, errorMessageHandler } = apiStore.api.users.postOrganizationUserInvitations;
        try {
            this.loadingStatusState.load();
            yield HttpClient.TCMS.POST(api(accountId, orgId));
            alertStore.success(successMessage);
            this.loadingStatusState.loadSuccess();
        } catch (error) {
            this.loadingStatusState.loadError();
            alertStore.error(error, errorMessageHandler);
        }
    });
}
