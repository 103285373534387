import moment from 'moment';

export class DateUtil {
    static formatDate(date: string, format = 'DD MMMM YYYY') {
        if (!date) {
            return '';
        }

        return moment(date).format(format);
    }

    static isBefore(date: string) {
        return moment() > moment(date);
    }
}
