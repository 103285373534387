import { observable, action, makeObservable } from 'mobx';
import { Organization } from '@modules/organizations/models';
import { Vessel } from '@modules/fleet/models';
import { Account } from '@modules/accounts/models';
import { VesselGroup } from '@modules/vessel-groups/models';
import { ApiClient } from '@modules/api-clients/models';

export class SelectedEntityState {
    organization: Organization;
    account: Account;
    vessel: Vessel;
    vesselGroup: VesselGroup;
    apiClient: ApiClient;

    constructor() {
        makeObservable(this, {
            organization: observable,
            account: observable,
            vessel: observable,
            vesselGroup: observable,
            apiClient: observable,
            selectOrganization: action,
            selectAccount: action,
            selectVessel: action,
            selectVesselGroup: action,
            selectApiClient: action
        });
    }

    selectOrganization = (organization: Organization) => {
        if (this.organization?.id === organization?.id) {
            return;
        }
        this.organization = organization;
    };

    selectAccount = (account: Account) => {
        if (this.account?.id === account?.id && this.account?.organization?.id === account?.organization?.id) {
            return;
        }
        this.account = account;
    };

    updatedAccount = (account: Account) => {
        this.account = account;
    };

    selectVessel = (vessel: Vessel) => {
        if (this.vessel?.id === vessel?.id) {
            return;
        }
        this.vessel = vessel;
    };

    selectVesselGroup = (group: VesselGroup) => {
        if (this.vesselGroup?.id === group?.id) {
            return;
        }
        this.vesselGroup = group;
    };

    selectApiClient = (apiClient: ApiClient) => {
        this.apiClient = apiClient;
    };

    resetVesselGroup = () => {
        this.vesselGroup = null;
    };

    resetAccount = () => {
        this.account = null;
    };
}

export const selectedEntityState = new SelectedEntityState();
