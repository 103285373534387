import React from 'react';
import { ChangeHandler, Control } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormErrorsTypes, FormField, LoadingStatusType } from '@modules/core/models';
import { RegEmail } from '@modules/core/constants';
import { DefaultValidators, validateFormFields } from '@modules/core/utils';
import { LoadPlaceholder } from '@modules/core/components';
import { MultiSelect, Input, Checkbox } from './Fields';
import { Option } from '@modules/core/models';

import './Form.scss';

interface Props {
    fields: FormField[];
    isEditMode?: boolean;
    useForm: {
        register: (
            Ref: any,
            RegisterOptions?: any
        ) => { onChange: ChangeHandler; onBlur: ChangeHandler; name: string; ref: React.Ref<any> };
        getValues?: (payload?: string | string[]) => Object;
        setValue?: (name: string, value: unknown, config?: Object) => void;
        control: Control;
        formState: { errors: { [key: string]: any } };
        clearErrors: (name?: string | string[]) => void;
    };
    customFields?: any;
    account?: any;
    qaId?: string;
}

export const Form = (props: Props) => {
    const { REQUIRED_ERROR, EMAIL_ERROR } = FormErrorsTypes;
    const { fields: fieldsProps, customFields, useForm } = props;
    const {
        register,
        control,
        formState: { errors },
        clearErrors,
        setValue
    } = useForm;

    const validateEmail = (email: string) => {
        return RegEmail.test(email);
    };

    const getStatus = (v: any, field?: FormField) => {
        if (!field.validate) {
            return true;
        }

        switch (field.type) {
            case 'inputEmail':
                return v?.length > 0 ? validateEmail(v) || EMAIL_ERROR : REQUIRED_ERROR;
            case 'multiselect':
                return v?.length > 0 || REQUIRED_ERROR;
            default:
                return validateFormFields(v, field, DefaultValidators);
        }
    };

    const renderField = (field: FormField) => {
        const tabIndex = 0;
        switch (field.type) {
            case 'multiselect':
                return (
                    <>
                        {field.label && <label>{field.label}</label>}
                        {field.disabled ? (
                            <span qa-id={`${field.fieldName}-read-only`}>
                                {field.defaultValue?.map((el: Option) => el.label).join(', ')}
                            </span>
                        ) : (
                            <MultiSelect
                                tabIndex={tabIndex}
                                control={control}
                                field={field}
                                clearErrors={clearErrors}
                                rules={{
                                    validate: {
                                        positive: (v: any) => {
                                            return getStatus(v, field);
                                        }
                                    }
                                }}
                            />
                        )}
                    </>
                );
            case 'checkbox':
                return <Checkbox tabIndex={tabIndex} register={register} field={field} />;
            case 'input':
            case 'inputEmail':
                return (
                    <>
                        {field.label && <label>{field.label}</label>}
                        {field.disabled ? (
                            <span qa-id={`${field.fieldName}-read-only`}>{field.defaultValue}</span>
                        ) : (
                            <Input
                                tabIndex={tabIndex}
                                register={register}
                                field={field}
                                errors={errors}
                                setValue={setValue}
                                rules={{
                                    validate: {
                                        positive: (v: any) => {
                                            return getStatus(v, field);
                                        }
                                    }
                                }}
                                {...(field.placeholder && {
                                    placeholder: field.placeholder
                                })}
                            />
                        )}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <LoadPlaceholder loadingStatus={fieldsProps.length > 0 && LoadingStatusType.isLoaded}>
                <form>
                    <div className="form" qa-id={props.qaId}>
                        {fieldsProps.map((field: FormField, index) => {
                            if (field.hidden) {
                                return null;
                            }
                            return (
                                <div
                                    qa-id={`${props.qaId}-field-${field.fieldName}`}
                                    className={`form-control-block ${errors[field.fieldName] && 'has-error'}`}
                                    key={index}
                                    {...(field.style && {
                                        style: field.style
                                    })}
                                >
                                    {renderField(field)}
                                    <div className={'form-control-block__error-block'}>
                                        <ErrorMessage
                                            errors={errors}
                                            name={field.fieldName}
                                            render={({ message }) => <p>{message}</p>}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        {customFields}
                    </div>
                </form>
            </LoadPlaceholder>
        </div>
    );
};

Form.defaultProps = {
    qaId: 'form'
};
