import React from 'react';
import { useObserver } from 'mobx-react-lite';

import { EntityTable } from '@modules/shared/components';
import { ColumnSize, TableColumn } from '@modules/shared/models';
import { SortOrderType } from '@modules/shared/constants';
import { SortingUtils } from '@modules/core/utils';
import { DateUtil } from '@modules/shared/utils';
import { VesselShare } from '@modules/sharing/models';

interface Props {
    vesselShares: VesselShare[];
    sortEntities: (sortFunc: (prevItem: any, nextItem: any) => number) => void;
}

export const ExternalOrganizations = (props: Props) => {
    const { vesselShares, sortEntities } = props;

    const columns: TableColumn[] = [
        {
            title: 'Name',
            property: 'organization.name',
            columnSize: ColumnSize.COLUMN2,
            render: (row: VesselShare) => row.organization.name
        },
        {
            title: 'Date of sharing',
            property: 'creationDate',
            columnSize: ColumnSize.COLUMN8,
            render: (row: VesselShare): string => DateUtil.formatDate(row.creationDate)
        }
    ];

    const sortOrganizations = (property: string, order: SortOrderType) => {
        sortEntities(SortingUtils.defaultCompare(property, order));
    };

    return useObserver(() => (
        <EntityTable
            id="external_organizations_table"
            listOfEntities={vesselShares}
            sortEntities={sortOrganizations}
            hiddenControls={() => true}
            columns={columns}
            setEditModalVisibility={null}
            setDeleteModalVisibility={null}
            setCurrentEntity={null}
        />
    ));
};
