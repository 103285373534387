import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react-lite';

import { useStore } from '@store/StoreEffect';
import { ConnectedProductsPage } from '@pages/connected-products-page/ConnectedProductsPage';

import './ProductPage.scss';

export const ProductPage = () => {
    const { connectedProductsStoreAll, userStore } = useStore();
    const { loadProducts } = connectedProductsStoreAll;

    const loadingFunction = useCallback(() => {
        return loadProducts(userStore.isSuperUser);
    }, [userStore.isSuperUser, loadProducts]);

    return useObserver(() => (
        <main className="product-page" qa-id="product-page">
            <header className="page-header">Products</header>
            <section className="page-body">
                <ConnectedProductsPage
                    isProductPageMode={true}
                    loadingFunction={loadingFunction}
                    isCreateMode={userStore.isSuperUser}
                    connectedProductsStore={connectedProductsStoreAll}
                />
            </section>
        </main>
    ));
};
