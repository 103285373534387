import { FunctionComponent, useEffect } from 'react';
import { createPortal } from 'react-dom';

export const Portal: FunctionComponent = ({ children }) => {
    const mount = document.getElementById('root-portal');
    const element = document.createElement('div');

    useEffect(() => {
        mount.appendChild(element);
        return () => {
            mount.removeChild(element);
        };
    }, [element, mount]);

    return createPortal(children, element);
};
