import { AlertMessage } from '@modules/core/models';
import { CoreUtil } from '@modules/core/utils';

export class UsersErrorHandlers {
    static UpdateUserErrorHandler(error: any): AlertMessage {
        const message = 'Failed to update user info';
        let description = '';
        switch (error.status) {
            case 404:
                description = 'User with this id does not exist';
                break;
            case 400:
                description = 'Incorrect format of data was passed upon user update';
                break;
            default:
                return CoreUtil.defaultErrorMessage(error);
        }

        return {
            message: message,
            description: description
        };
    }

    static CreateUserErrorHandler(error: any): AlertMessage {
        const message = 'Failed to create';
        let description = '';
        switch (error.status) {
            case 404:
                description = 'Could not find organization with this id';
                break;
            case 400:
                description = 'Incorrect data format was passed upon user creation';
                break;
            case 422:
                description = 'User with this email already exists';
                break;
            default:
                return CoreUtil.defaultErrorMessage(error);
        }

        return {
            message: message,
            description: description
        };
    }

    static GetUserErrorHandler(error: any): AlertMessage {
        const message = 'Failed to get user info';
        let description = '';
        switch (error.status) {
            case 404:
                description = 'User with this id was not found or does not exist in this organization';
                break;
            default:
                return CoreUtil.defaultErrorMessage(error);
        }

        return {
            message: message,
            description: description
        };
    }
}
