import React, { ReactNode } from 'react';

import { clipboardStore } from '@modules/core/store/ClipboardStore';

import './CopyField.scss';

interface Props {
    children: ReactNode;
    copyText: string;
    hidden?: boolean;
}

export const CopyField = (props: Props) => {
    const { copyText, children, hidden } = props;

    return (
        <div className="copy-field">
            <div className="copy-field__content">{children}</div>
            {!hidden && <i className="icon icon-copy" onClick={() => clipboardStore.copy(copyText)} />}
        </div>
    );
};
