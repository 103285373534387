import { Dictionary } from '@modules/shared/models';

export const redirectUri = window.location.origin;

export const dataSourceConfig = {
    REACT_APP_TCMS_DATASOURCESCHEMAS_URL: ''
};

export const msalConfig: Dictionary<string> = {
    REACT_APP_TCMS_BASE_API_URL: '',
    REACT_APP_TCMS_API_URL: '',
    REACT_APP_TENANT: '',
    REACT_APP_TCMS_CLIENT_ID: '',
    REACT_APP_INSIGHTS_CODE: ''
};

export const IS_DEV = process.env.REACT_APP_DEV;

const loadConfig = async () => {
    const response = await fetch(`${window.location.origin}/dynamic-configs/config.json?timestamp=${Date.now()}`, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });

    return await response.json();
};

export default (async () => {
    const json = await loadConfig();
    const { REACT_APP_TCMS_DATASOURCESCHEMAS_URL, ...jsonConfig } = json;
    dataSourceConfig.REACT_APP_TCMS_DATASOURCESCHEMAS_URL = REACT_APP_TCMS_DATASOURCESCHEMAS_URL;
    Object.keys(jsonConfig).map((param: string) => {
        msalConfig[param] = jsonConfig[param];
    });
})();
