import { flow, observable, makeObservable } from 'mobx';

import { Dictionary } from '@modules/shared/models';
import { HttpClient } from '@modules/core/utils';
import { apiStore } from '@modules/core/store/ApiStore';
import { alertStore } from '@modules/core/store/AlertStore';
import { LoadingStatusState } from '@modules/core/models';
import { VariableGroup } from '../models';

export class VariableGroupStore {
    variableGroups: VariableGroup[] = [];
    variableGroupMap: Dictionary<string[]> = {};
    loadingStatusState = new LoadingStatusState();

    constructor() {
        makeObservable(this, {
            variableGroups: observable.ref,
            variableGroupMap: observable.ref
        });

        this.loadVariableGroups = this.loadVariableGroups.bind(this);
    }

    loadVariableGroups = flow(function* (this: VariableGroupStore, organizationId: string) {
        const { api, errorMessageHandler } = apiStore.api.variableGroups.getOrganizationVariableGroups;
        try {
            this.loadingStatusState.load();
            this.variableGroups = yield HttpClient.TCMS.GET(api(organizationId));
            this.variableGroupMap = this.variableGroups.reduce((acc, group) => {
                acc[group.id] = group.variables;
                return acc;
            }, {} as Dictionary<string[]>);
        } catch (error) {
            this.variableGroups = [];
            alertStore.error(error, errorMessageHandler);
        }
        this.loadingStatusState.loadSuccess();
    });
}
