import ApiClientsStore from './ApiClientsStore';
import ApiClientsStoreAll from './ApiClientsStoreAll';
import ApiClientsStoreOrganization from './ApiClientsStoreOrganization';
import ApiClientStore from './ApiClientStore';

export default {
    apiClientsStore: new ApiClientsStore(),
    apiClientsStoreAll: new ApiClientsStoreAll(),
    apiClientsStoreOrganization: new ApiClientsStoreOrganization(),
    apiClientStore: new ApiClientStore()
};
