import { useStore } from '@store/StoreEffect';

export const usePermissionsState = () => {
    const { userStore } = useStore();

    return {
        canViewApiClients: userStore.canViewApiClients,
        canEditApiClients: userStore.canEditApiClients
    };
};
