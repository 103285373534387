export { FleetStore } from './FleetStore';
export { VesselSharesStore } from '../../sharing/store/VesselSharesStore';
export { VesselStore } from './VesselStore';

import { FleetStoreAll } from './FleetStoreAll';
import { FleetStoreOrganization } from './FleetStoreOrganization';
import { FleetStoreUsers } from './FleetStoreUsers';
import { FleetStoreApiClients } from './FleetStoreApiClients';
import { FleetStoreVesselGroups } from './FleetStoreVesselGroups';
import { VesselSharesStore } from '../../sharing/store/VesselSharesStore';
import { VesselStore } from './VesselStore';

export default {
    fleetStoreAll: new FleetStoreAll(),
    fleetStoreOrganization: new FleetStoreOrganization(),
    fleetStoreUsers: new FleetStoreUsers(),
    fleetStoreApiClients: new FleetStoreApiClients(),
    vesselStore: new VesselStore(),
    fleetStoreVesselGroups: new FleetStoreVesselGroups(),
    vesselSharesStore: new VesselSharesStore()
};
