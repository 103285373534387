import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import omit from 'lodash/omit';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import { flow } from 'mobx';

import { Form } from '@modules/core/components';
import { MultiListCombobox } from '@modules/core/components/form/Fields';
import { ColumnSize } from '@modules/shared/models';
import { CoreUtil, isFormFieldsDirty } from '@modules/core/utils';
import { VesselSharesFormFields } from '@modules/core/constants';
import { FleetStore } from '@modules/fleet/store';
import { VesselShare, Steps, FormFields } from '@modules/sharing/models';
import { Organization } from '@modules/organizations/models';
import { Dropdown } from '@fos/eniram-web-common-ui/dist/components/DropDown';
import { useStore } from '@store/StoreEffect';

import './MultiStepForm.scss';

interface Option {
    value: string;
    label: string;
}

interface Props {
    step: number;
    stepsFormFields: any;
    fleetStore: FleetStore;
    organization: Organization;
    setStepFields: (fields: any) => Promise<void>;
    onCloseModal: (value: boolean) => void;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    vesselShares: VesselShare[];
    setStepsFormFields: (value: React.SetStateAction<FormFields>) => void;
}

export const MultiStepForm = observer<Props>((props) => {
    const {
        step,
        setStepFields,
        onCloseModal,
        setStep,
        stepsFormFields,
        organization,
        fleetStore,
        vesselShares,
        setStepsFormFields
    } = props;

    const { organizationsStoreAll } = useStore();
    const { organizations, loadOrganizations } = organizationsStoreAll;

    useEffect(() => {
        loadOrganizations();
    }, []);

    const options = useMemo(
        () =>
            organizations.length && organization?.id
                ? organizations
                      .filter((org) => org.id !== organization.id)
                      .map((org) => ({
                          value: org.id,
                          label: org.name
                      }))
                : [],
        [organizations, organization]
    );

    const [rightTableList, setRightTableList] = useState([]);

    const fields = useMemo(() => {
        const basicFields = omit(VesselSharesFormFields[step], 'vessels');
        return CoreUtil.mapFields(basicFields, stepsFormFields);
    }, [step, stepsFormFields]);

    const {
        register,
        getValues,
        setValue,
        control,
        watch,
        clearErrors,
        formState: { isDirty, errors }
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: stepsFormFields
    });
    const watchAllFields = watch();
    const isFinalStep = step === VesselSharesFormFields.length - 1;
    const isFieldsDirty = useMemo(() => {
        return (
            isFormFieldsDirty(
                [
                    ...fields,
                    {
                        vessels: stepsFormFields?.vessels || [],
                        fieldName: 'vessels',
                        type: 'multilistcombobox',
                        label: '',
                        validate: true
                    }
                ],
                getValues(),
                VesselSharesFormFields[step],
                false
            ) || Object.keys(errors).length
        );
    }, [isDirty, watchAllFields]);

    const setSelectedRows = useCallback((list) => setValue('vessels', list), [setValue]);

    const onSelectPartner = ({ value, label }: Option | null) => {
        if (value) {
            setValue('partnerCode', value, { shouldDirty: true });
            setValue('partnerName', label, { shouldDirty: true });
        }
    };

    const filterVesselForSharesList = useCallback(
        (query: string) => {
            const list = fleetStore.filterVesselsByOrg(query);
            const ownVesselsList = list.filter((vessel) => vessel.owner.id === organization.id);

            const alreadySharedVesselsToPartner = vesselShares.filter(
                (vessel) => vessel.organization.id === stepsFormFields.partnerCode
            );

            const notSharedVesselsToPartner = ownVesselsList.filter(
                (vessel) => !alreadySharedVesselsToPartner.some((item) => item.vessel.id === vessel.id)
            );

            return differenceWith(notSharedVesselsToPartner, rightTableList, isEqual);
        },
        [stepsFormFields?.vessels, rightTableList, stepsFormFields, vesselShares]
    );

    const loadVessels = useCallback(
        flow(function* () {
            if (VesselSharesFormFields[step].vessels) {
                yield fleetStore.loadVessels();
            }
        }),
        [step]
    );

    const onSubmit = async (fields: FormFields) => {
        setStepFields(fields);
    };

    const onBackClick = () => {
        setStep(step - 1);

        setStepsFormFields(
            step - 1 === Steps.GET_PARTNER_ID
                ? { partnerCode: '', partnerName: '', vessels: [] }
                : { ...stepsFormFields, vessels: [] }
        );
    };

    const formFooter = () => {
        return (
            <footer>
                {step > 0 ? (
                    <button className="button secondary" onClick={onBackClick}>
                        Back
                    </button>
                ) : (
                    <div />
                )}
                <div className={'buttons-container'}>
                    <button disabled={isFieldsDirty} className="button primary" onClick={() => onSubmit(getValues())}>
                        {!isFinalStep ? 'Next' : 'Save'}
                    </button>
                    <button className="button secondary" onClick={() => onCloseModal(false)}>
                        CANCEL
                    </button>
                </div>
            </footer>
        );
    };

    const { partnerCode, partnerName } = stepsFormFields;
    const dropdownDefaultValue = partnerCode && partnerName ? [{ value: partnerCode, label: partnerName }] : [];

    const getCustomField = () => {
        switch (step) {
            case Steps.ADD_VESSELS:
                return (
                    <MultiListCombobox
                        columns={[
                            {
                                title: 'Name',
                                columnSize: ColumnSize.COLUMN12,
                                property: 'name'
                            }
                        ]}
                        loadList={loadVessels}
                        filterLeftTableList={filterVesselForSharesList}
                        setSelectedRows={setSelectedRows}
                        vessels={stepsFormFields.vessels}
                        rightTableList={rightTableList}
                        setRightTableList={setRightTableList}
                    />
                );
            default:
                return (
                    <div className="select-partner-dropdown">
                        <label>Organization</label>
                        <Dropdown
                            qaId={`get-partner-id`}
                            placeholder={'Select partner'}
                            actionFunc={onSelectPartner}
                            options={options}
                            defaultValue={dropdownDefaultValue}
                            isSearchable
                            isClearable={false}
                            isMulti={false}
                        />
                    </div>
                );
        }
    };

    return (
        <>
            <Form
                key={`form-${step}`}
                qaId={`sharing-vessels-step-${step}`}
                useForm={{
                    register,
                    getValues,
                    setValue,
                    control,
                    formState: { errors },
                    clearErrors
                }}
                fields={fields}
                customFields={getCustomField()}
            />
            {formFooter()}
        </>
    );
});
