import { computed, flow, makeObservable } from 'mobx';
import { FleetStore } from './FleetStore';
import FleetStoreModule from '@modules/fleet/store';
import { computedFn } from 'mobx-utils';
import { VesselGroup } from '@modules/vessel-groups/models';

//descr: used only for Admin
export class FleetStoreVesselGroups extends FleetStore {
    constructor() {
        super();

        makeObservable(this, {
            fleetStoreAll: computed({ keepAlive: true })
        });

        this.loadVessels = this.loadVessels.bind(this);
    }

    loadVessels = flow(function* (this: FleetStoreVesselGroups) {
        try {
            this.loadingStatusState.load();
            yield this.fleetStoreAll.loadVessels();
            this.vessels = this.fleetStoreAll.vessels;
            this.loadingStatusState.loadSuccess();
        } catch (error) {
            this.loadingStatusState.loadError();
        }
    });

    loadVesselsByGroup = computedFn((selectedVesselGroup: VesselGroup) => {
        this.vessels = selectedVesselGroup.vessels.reduce((accumulator, current) => {
            const found = this.fleetStoreAll.vessels.find((vessel) => vessel.id === current);
            if (found) accumulator.push(found);
            return accumulator;
        }, []);
    });

    get fleetStoreAll() {
        return FleetStoreModule.fleetStoreAll;
    }
}
