import React, { useEffect, useMemo, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { SearchInput } from '@fos/eniram-web-common-ui/dist/components/SearchInput';
import isEqual from 'lodash/isEqual';
import { useForm } from 'react-hook-form';

import { Form } from '@modules/core/components';
import { EntityTable, ModalWindow } from '@modules/shared/components';
import { getVesselsTableColumns } from '@modules/fleet/constants';
import { Vessel } from '@modules/fleet/models';
import { Dictionary, ModalProps } from '@modules/shared/models';
import { LoadingStatusState } from '@modules/core/models';
import { alertStore } from '@modules/core/store/AlertStore';
import { VesselGroup } from '@modules/vessel-groups/models';
import { SortingUtils } from '@modules/core/utils';
import { SortOrderType } from '@modules/shared/constants';

import './CreateVesselGroupModal.scss';

const getSortedArray = (array: Vessel[]) => array.sort((a, b) => a.id.localeCompare(b.id));

interface Props extends ModalProps {
    filterVessels: (name: string) => Vessel[];
    isEditMode?: boolean;
    loadingStatusState?: LoadingStatusState;
    selectedGroup?: VesselGroup;
    isNameInUse?: (name: string, groupId?: string) => boolean;
}

export const CreateVesselGroupModal = (props: Props) => {
    const { modalIsOpen, onCloseModal, onSubmit, selectedGroup, filterVessels, loadingStatusState } = props;
    const [query, setQuery] = useState<string>('');
    const [vessels, setVessels] = useState<Vessel[]>([]);
    const [selectedVessels, setSelectedVessels] = useState<Vessel[]>([]);
    const [vesselGroupName, setVesselGroupName] = useState(selectedGroup?.name ?? '');
    const [selectedVesselsIds, setSelectedVesselsIds] = useState<Dictionary<boolean>>({});

    const {
        register,
        getValues,
        control,
        watch,
        setValue,
        formState: { errors },
        clearErrors
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    useEffect(() => {
        const subscription = watch((value) => setVesselGroupName((value && value['groupName']) || ''));
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        const vessels = filterVessels(query.trim());
        setVessels(vessels);
    }, [query, filterVessels, loadingStatusState?.status]);

    useEffect(() => {
        if (!selectedGroup) {
            return;
        }

        const selectedGroupVessels: Vessel[] = selectedGroup.vessels.reduce((acc, vesselID) => {
            acc.push(...filterVessels(vesselID));

            return acc;
        }, []);

        setSelectedVessels(selectedGroupVessels);
    }, [selectedGroup?.vessels]);

    useEffect(() => {
        if (selectedVessels.length) {
            const ids = selectedVessels.reduce(
                (acc, cV) => ({
                    ...acc,
                    [cV?.id]: true
                }),
                {}
            );
            setSelectedVesselsIds(ids);
        } else {
            setSelectedVesselsIds({});
        }
    }, [selectedVessels]);

    const onSubmitForm = () => {
        if (props.isNameInUse(vesselGroupName, selectedGroup?.id)) {
            alertStore.warn({
                message: 'Failed to create',
                description: 'This vessel group name is already in use, please, choose another one'
            });
            return;
        }

        const ids = selectedVessels.map((v) => v.id);

        return onSubmit({ name: vesselGroupName, vessels: ids });
    };

    const sortVesselsInModal = (property: string, order: SortOrderType): void => {
        setVessels(vessels.slice().sort(SortingUtils.defaultCompare(property, order)));
    };

    const isEditDisabled = useMemo(() => {
        if (!vesselGroupName.trim().length) {
            return true;
        }
        const selectedGroupVessels = vessels.filter((v) => selectedGroup?.vessels.some((id) => id === v.id));

        return (
            vesselGroupName.trim() === selectedGroup?.name &&
            isEqual(getSortedArray(selectedVessels), getSortedArray(selectedGroupVessels))
        );
    }, [selectedVessels, vesselGroupName, selectedGroup, vessels]);

    const isAssignDisabled = !vesselGroupName.trim().length;

    return useObserver(() => (
        <ModalWindow
            disabledSubmit={selectedGroup ? isEditDisabled : isAssignDisabled}
            modalIsOpen={modalIsOpen}
            className="create-vessel-group-popup"
            title="ASSIGN VESSELS/VESSELS GROUP"
            submitButtonName="SAVE"
            onSubmit={onSubmitForm}
            onCancel={() => onCloseModal(false)}
        >
            <Form
                qaId={'create-vessel-group'}
                useForm={{
                    register,
                    getValues,
                    setValue,
                    control,
                    formState: { errors },
                    clearErrors
                }}
                fields={[
                    {
                        defaultValue: selectedGroup?.name,
                        fieldName: 'groupName',
                        placeholder: 'Group Name',
                        type: 'input',
                        validate: true,
                        style: {
                            width: '100%',
                            maxWidth: '100%'
                        }
                    }
                ]}
            />
            <div className="vessel-group-search">
                <span>VESSEL</span>
                <SearchInput
                    onChange={(value: string) => setQuery(value)}
                    autofocus={false}
                    placeholder="Search by Name, DMS ID, IMO, CMS ID"
                    search={query}
                    qaId="vessel-group-search"
                />
            </div>
            <div className="vessel-list">
                <EntityTable
                    id="group_vessels_modal"
                    selectable
                    loadingStatus={loadingStatusState?.status}
                    hiddenControls={() => true}
                    alreadySelectedIds={selectedVesselsIds}
                    listOfEntities={vessels}
                    columns={getVesselsTableColumns(true)}
                    isArchiveAvailable={false}
                    selectedEntity={selectedVessels}
                    setSelectedEntities={setSelectedVessels}
                    setCurrentEntity={null}
                    sortEntities={sortVesselsInModal}
                />
            </div>
        </ModalWindow>
    ));
};
