import React, { useMemo } from 'react';

import { Dictionary } from '@modules/shared/models';
import { Tooltip } from '@modules/shared/components';
import { SortingUtils } from '@modules/core/utils';

import './RoleMultiView.scss';

interface Props {
    rolesMap: Dictionary<string>;
    userRoles: string[];
    isShowAllRoles?: boolean;
}

export const RoleMultiView = (props: Props) => {
    const { rolesMap = {}, userRoles = [], isShowAllRoles } = props;

    const userRolesNames = useMemo(() => {
        return SortingUtils.sortArrayByParam(
            userRoles.reduce((acc, item) => {
                return [...acc, rolesMap[item]];
            }, [])
        );
    }, [userRoles, rolesMap]);

    const nameCount = userRolesNames.length;

    return !isShowAllRoles ? (
        nameCount > 1 ? (
            <span className={'columnWithInfo'}>
                <span style={{ display: 'flex' }}>
                    {userRolesNames[0]},
                    <Tooltip content={userRolesNames.slice(1, nameCount).join(', ')}>
                        <i className="info">{nameCount - 1} roles more </i>
                    </Tooltip>
                </span>
            </span>
        ) : nameCount !== 0 ? (
            userRolesNames.join()
        ) : (
            <span className={'columnWithInfo'}>
                <Tooltip content={'User has no roles'}>
                    <i className="icon icon-attention-grey" />{' '}
                </Tooltip>
            </span>
        )
    ) : (
        <span>{userRolesNames.join()}</span>
    );
};
