import { observable, action, makeObservable } from 'mobx';

import { Alert, AlertMessage, AlertTypes } from '../models';
import { DefaultAlertOptions } from '../constants';
import { CoreUtil } from '../utils';

export default class AlertStore {
    alerts: Alert[] = [];

    constructor() {
        makeObservable(this, {
            alerts: observable.ref,
            alert: action,
            success: action,
            warn: action,
            error: action,
            info: action,
            clear: action,
            remove: action
        });
    }

    alert = (alert: Alert) => {
        if (alert.type === AlertTypes.clear) {
            this.alerts = this.alerts.filter((alert) => {
                const persit = alert.options?.persist;
                if (!persit) {
                    clearTimeout(alert.timerId);
                }
                return persit;
            });
        } else {
            if (alert.options?.fadeTime) {
                alert.timerId = setTimeout(() => this.remove(alert), alert.options?.fadeTime);
            }
            this.alerts = [...this.alerts, alert];
        }
    };

    success(successMessage: AlertMessage, id = 'global', options = DefaultAlertOptions) {
        const { message, description } = successMessage;
        this.alert({
            id,
            options,
            message,
            description,
            type: AlertTypes.success
        });
    }

    warn(warnMessage: AlertMessage, id = 'global', options = DefaultAlertOptions) {
        const { message, description } = warnMessage;
        this.alert({
            id,
            options,
            message,
            description,
            type: AlertTypes.warning
        });
    }

    error(error: any, errorMessageHandler: any, id = 'global', options = DefaultAlertOptions) {
        if (error?.status === 404) {
            //if 404 dont show error, show empty page
            return;
        }
        const { message, description } = errorMessageHandler
            ? errorMessageHandler(error)
            : CoreUtil.defaultErrorMessage(error);
        this.alert({
            id,
            options,
            message,
            description,
            type: AlertTypes.error
        });
    }

    info(infoMessage: AlertMessage, id = 'global', options = DefaultAlertOptions) {
        const { message, description } = infoMessage;
        this.alert({
            id,
            options,
            message,
            description,
            type: AlertTypes.info
        });
    }

    clear(id = 'default-clear') {
        this.alert({ id, type: AlertTypes.clear });
    }

    remove(alert: Alert) {
        clearTimeout(alert.timerId);
        this.alerts = this.alerts.filter((_alert) => _alert !== alert);
    }
}

export const alertStore = new AlertStore();
