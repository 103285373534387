import React, { useMemo } from 'react';

import { ModalProps } from '@modules/shared/models';
import { DateUtil } from '@modules/shared/utils';
import { ApiClientSecret } from '@modules/api-clients/models';
import { ModalWindow } from '@modules/shared/components';
import { CopyField } from '@modules/core/components';

import './ApiClientSecretModal.scss';

interface Props extends ModalProps {
    secret: ApiClientSecret;
}

export const ApiClientSecretModal = (props: Props) => {
    const { modalIsOpen, secret, onSubmit, onCloseModal } = props;

    const secretText = useMemo(() => secret?.value || 'hidden', [secret]);

    return (
        <ModalWindow
            modalIsOpen={modalIsOpen}
            className="api-client-secret-popup"
            title="API CLIENT SECRET"
            submitButtonName="OK"
            isCancel={false}
            onSubmit={onSubmit}
            onCancel={() => onCloseModal(false)}
        >
            <div className="row row-secret">
                <div className="row-title">Value</div>
                <div className="row-value">
                    <CopyField copyText={secretText} hidden={!secret?.value}>
                        {secretText}
                    </CopyField>
                </div>
            </div>
            <div className="row">
                <div className="row-title">Expires</div>
                <div className="row-value">{DateUtil.formatDate(secret?.expirationDate, 'DD MMMM YYYY HH:mm')}</div>
            </div>
        </ModalWindow>
    );
};
