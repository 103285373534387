import React, { useEffect } from 'react';
import { useGoBack } from '@modules/shared/hooks';
import { useObserver } from 'mobx-react-lite';
import { buildInfoStore } from '@modules/core/store/BuildInfoStore';

import './AboutPage.scss';

export const AboutPage = () => {
    const { goBack } = useGoBack();
    const { buildInfo, loadBuildInfo } = buildInfoStore;

    useEffect(() => {
        loadBuildInfo();
    }, [loadBuildInfo]);

    return useObserver(() => (
        <section className="about-page">
            <header className="about-page-header">
                <i className="icon icon-chevron left" onClick={() => goBack()}></i>
                <span qa-id="about-page-title">About</span>
            </header>
            <section className="about-page-body">
                <header>FOS User Management</header>
                <article>
                    <span className="header">Customer support</span>
                    <div>
                        <span>24/7 hotline:</span>
                        <span>+46 771 460 100</span>
                    </div>
                    <div>
                        <span>E-mail:</span>
                        <span>
                            <a href="mailto:service@transas.com">voyage.support@wartsila.com</a>
                        </span>
                    </div>
                    {/* Commented till requirements from Gleb Mickhailov will appear
                    <div>
                        <span>Web site:</span>
                        <span>
                            <a href="http://www.transas.com">www.transas.com</a>
                        </span>
                    </div> */}
                </article>
                {buildInfo.versionFE || buildInfo.versionBE ? (
                    <footer>
                        <div>{buildInfo.copyright ?? ''}</div>
                        <div>Version FE: {buildInfo.versionFE}</div>
                        <div>Version BE: {buildInfo.versionBE}</div>
                    </footer>
                ) : (
                    <footer>
                        <div>Failed to retrieve build information</div>
                    </footer>
                )}
            </section>
        </section>
    ));
};
